import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AutosaveService, EntityToEdit } from 'src/app/shared/services/autosave.service';
import { EditTemplatesManagementQuery } from './store/edit-template-management.query';
import { EditTemplatesManagementService } from './store/edit-template-management.service';
import { Memoized } from '@ortec/utilities/core';
import { Observable, Subscription, combineLatest, filter, first, map, skip, startWith, tap } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { cache, combineSubscriptions } from '@ortec/utilities/rxjs';

import { ManageTemplate } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { ManageTemplateService } from 'src/app/shared/stores/manage-template-store/manage-template.service';
import { ManageTemplateQuery } from 'src/app/shared/stores/manage-template-store/manage-template.query';
import { BreadcrumbService } from 'src/app/core/breadcrumb/breadcrumb.service';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';
import { EditWeekLengthDialogComponent, IWeekLengthDialogData } from './components/edit-week-length-dialog/edit-week-length-dialog.component';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ManageTimeslotService } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.service';
import { ManageTimeslotQuery } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.query';
import { ManageTemplateCounterQuery } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.query';
import { ManageTemplateCounterService } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.service';

@Component({
    selector: 'app-edit-template',
    templateUrl: './edit-template.component.html',
    styleUrls: ['./edit-template.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTemplateComponent implements OnInit, OnDestroy {
    public displayNameControl = new FormControl('', Validators.required);
    
    private readonly subscription = new Subscription();

    private readonly editTemplatesManagementService = inject(EditTemplatesManagementService);
    private readonly editTemplatesManagementQuery = inject(EditTemplatesManagementQuery);
    private readonly manageTemplateService = inject(ManageTemplateService);
    private readonly manageTemplateQuery = inject(ManageTemplateQuery);
    private readonly manageTimeslotService = inject(ManageTimeslotService);
    private readonly manageTimeslotQuery = inject(ManageTimeslotQuery);
    private readonly manageTemplateCounterService = inject(ManageTemplateCounterService);
    private readonly manageTemplateCounterQuery = inject(ManageTemplateCounterQuery);
    private readonly activityTypeQuery = inject(ActivityTypeQuery);
    private readonly organizationUnitsQuery = inject(OrganizationUnitQuery);
    private readonly autosaveService = inject(AutosaveService);
    private readonly dialogService = inject(MatDialog);
    private readonly route = inject(ActivatedRoute);
    private readonly breadcrumbService = inject(BreadcrumbService);
   
    public ngOnInit(): void {
        const templateConfig = history.state as ManageTemplate;
        this.breadcrumbService.getBreadcrumbs().pipe(first()).subscribe(breadcrumbs => {
            const newBreadcrumbs = [
                ...breadcrumbs,
                {
                    route: '/edit-template',
                    caption: '',
                    dynamicPart: templateConfig.displayName,
                }
            ];
            this.breadcrumbService.setBreadcrumbs(newBreadcrumbs);
        });

        const idOfTheEditedTemplate = +this.route.snapshot.paramMap.get('id');
        this.editTemplatesManagementService.updateCurrentTemplateId(idOfTheEditedTemplate);
        this.manageTemplateService.getSingleTemplate(idOfTheEditedTemplate).pipe(first()).subscribe(template => {
            // NOTE: only fetch other data if the single template call is successful
            // A user might not have permission or the template might not exist
            this.manageTemplateCounterService.getCounters(idOfTheEditedTemplate).pipe(first()).subscribe();
            this.manageTimeslotService.getActivityDemandTimeslots(idOfTheEditedTemplate).pipe(first()).subscribe();

            this.editTemplatesManagementService.updateEditedTemplate(template);
            this.displayNameControl.setValue(template.displayName);
        });

        this.subscription.add(
            combineSubscriptions(
                this.updateDisplayName()
            )
        );
    }

    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChangesWithValidState();
        this.subscription.unsubscribe();
    }

    public onEditweekLength(): void {
        const editedWeekLength = this.editTemplatesManagementQuery.getMaxWeekNumberSync();
        const dialogRef = this.dialogService.open(EditWeekLengthDialogComponent, {
            data: { weekLength: editedWeekLength } as IWeekLengthDialogData,
            width: '700px',
            autoFocus: false,
        });

        this.subscription.add(
            dialogRef.afterClosed().pipe(
                first(),
                filter(dialogData => !!dialogData),
                tap((dialogData: IWeekLengthDialogData) => {
                    this.manageTemplateService.updatePageStatusState(STATUS.HAS_PENDING_CHANGES);
                    const currentEditedTemplate = { 
                        ...this.manageTemplateQuery.getSelectedTemplateForEditSync(), 
                        lengthInWeeks: dialogData.weekLength
                    };
                    this.updateTemplate(currentEditedTemplate);
                })
            ).subscribe()
        );
    }

    @Memoized public get maxWeeks$(): Observable<number> {
        return this.editTemplatesManagementQuery.getMaxWeekNumber().pipe(cache());
    }

    @Memoized public get getErrorState$(): Observable<boolean> {
        return this.editTemplatesManagementQuery.getHasError().pipe(cache());
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return combineLatest([
            this.manageTemplateQuery.getEntitiesLoadingState(),
            this.manageTimeslotQuery.getEntitiesLoadingState(),
            this.manageTemplateCounterQuery.getEntitiesLoadingState(),
            this.activityTypeQuery.getEntitiesLoadingState(),
            this.organizationUnitsQuery.getEntitiesLoadingStateForActivityTypes()
        ]).pipe(
            filter(([loading, timeslotsLoading, countersLoading, actTypeLoading, orgUnitLoading]) => {
                return !loading && !timeslotsLoading && !countersLoading && !actTypeLoading && !orgUnitLoading;
            }),
            map(() => true),
            first(),
            startWith(false),
            cache()
        );
    }

    @Memoized public get updateTimeslotsLoadingFinished$(): Observable<boolean> {
        return this.manageTimeslotQuery.getEntitiesLoadingState().pipe(cache());
    }

    @Memoized public get updateCountersLoadingFinished$(): Observable<boolean> {
        return this.manageTemplateCounterQuery.getEntitiesLoadingState().pipe(cache());
    }

    @Memoized public get statusPageState$(): Observable<STATUS> {
        return combineLatest([
            this.manageTemplateQuery.getStatusPageState(),
            this.manageTimeslotQuery.getStatusPageState(),
            this.manageTemplateCounterQuery.getStatusPageState()
        ]).pipe(
            map(([statusFromTemplate, statusFromTimeslot, statusFromCounters]) => {
                if (statusFromTemplate === STATUS.HAS_PENDING_CHANGES || statusFromTimeslot === STATUS.HAS_PENDING_CHANGES || statusFromCounters === STATUS.HAS_PENDING_CHANGES) {
                    return STATUS.HAS_PENDING_CHANGES;
                }

                else if (statusFromTemplate === STATUS.IS_FINISHED_SAVING || statusFromTimeslot === STATUS.IS_FINISHED_SAVING || statusFromCounters === STATUS.IS_FINISHED_SAVING) {
                    return STATUS.IS_FINISHED_SAVING;
                }

                    return STATUS.DEFAULT;
                }
            ),
            cache()
        );
    }

    private updateTemplate(template: ManageTemplate): void {
        this.manageTemplateService.updateTemplate(template).pipe(first()).subscribe(() => {
            this.editTemplatesManagementService.updateEditedTemplate(template);

            // Update the breadcrumb with the new display name
            this.breadcrumbService.getBreadcrumbs().pipe(first()).subscribe(breadcrumbs => {
                breadcrumbs[breadcrumbs.length - 1].dynamicPart = template.displayName;
                this.breadcrumbService.setBreadcrumbs(breadcrumbs);
            });
        });
    }

    private updateDisplayName(): Subscription {
        return this.displayNameControl.valueChanges.pipe(
            filter(name => !!name),
            skip(1)
        ).subscribe((displayName) => {
            this.manageTemplateService.updatePageStatusState(STATUS.HAS_PENDING_CHANGES);
            const currentEditedTemplateWithValid: EntityToEdit = { entity: { ...this.manageTemplateQuery.getSelectedTemplateForEditSync(),  displayName }, valid: this.displayNameControl.valid };
            this.autosaveService.autoSaveWithValidState(currentEditedTemplateWithValid, this.updateTemplate.bind(this));
        })
    }
}
