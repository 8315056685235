import { Injectable } from '@angular/core';
import { UsersManagementStore } from './users-management.store';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';

@Injectable({
    providedIn: 'root'
})
export class UsersManagementService {
    constructor(
        protected usersManagementStore: UsersManagementStore
    ) { }

    public updateSelectedUserId(id: number): void {
        this.usersManagementStore.updateSelectedUserId(id);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.usersManagementStore.updateSelectedOrganizationUnitId(id);
    }

    public updateShowChildUnits(value: boolean): void {
        this.usersManagementStore.updateShowChildUnits(value);
    }

    public updateShowWithoutUserGroups(value: boolean): void {
        this.usersManagementStore.updateShowWithoutUserGroups(value);
    }

    public updateShowAllUsers(value: boolean): void {
        this.usersManagementStore.updateShowAllUsers(value);
    }

    public updateManageMode(mode: MANAGE_MODE): void {
        this.usersManagementStore.updateManageMode(mode);
    }

    public setStateForNewUser(userId: number): void {
        this.usersManagementStore.setStateForNewUser(userId);
    }
}
