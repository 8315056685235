/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

import { emailValidatorKey, fieldNonEmptyValidatorKey } from 'src/app/shared/validators';
import { getLanguageLabel, languageOptions } from 'src/app/shared/language/language.config';
import { IUserGroupTree, UserGroup } from 'src/app/shared/stores/user-group-store/user-group.model';
import { User } from 'src/app/shared/stores/user-store/user.model';

export function getUserFormFields(
    userGroups: Array<UserGroup>,
    userGroupsTrees: Array<IUserGroupTree>,
    systemLanguage: string,
    translateService: TranslateService,
    disableUserGroups: (selectedIds: any, entities: Array<any>) => Array<any>
): Array<FormlyFieldConfig> {
    return [
        {
            key: 'displayName',
            type: 'bolster-input',
            props: {
                label: 'Display name',
                required: true,
                maxLength: 80,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            },
        },
        {
            key: 'userName',
            type: 'bolster-input',
            props: {
                label: 'Logon name',
                maxLength: 255,
                required: true,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            },
        },
        {
            key: 'emailAddress',
            type: 'bolster-input',
            props: {
                label: 'Email address',
                maxLength: 255,
                required: true,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [emailValidatorKey]
            },
        },
        {
            key: 'language',
            type: 'tree-single-select',
            props: {
                entities: languageOptions
            },
            expressionProperties: {
                'props.placeholderText': (model: User) => {
                    const defaultLanguageLabel = getLanguageLabel(systemLanguage);

                    return model.language ? 'Display language' : `Display default language(${defaultLanguageLabel})`;
                }
            }
        },
        {
            key: 'externalId',
            type: 'bolster-input',
            props: {
                label: 'External id',
                maxLength: 40,
                attributes: {
                    autocomplete: 'off'
                }
            },
        },
        {
            key: 'userGroupIds',
            type: 'multiselect-tree',
            props: {
                entities: userGroupsTrees,
                placeholderText: 'User groups',
                searchProperties: ['displayName'],
                disableOptionsFunction: disableUserGroups
            },
            expressionProperties: {
                'props.disable': (model: User) => !isUserEditable(model, userGroups),
            }
        }
    ];
}

export function getUserSearchProperties(): Array<string> {
    return ['displayName', 'userName', 'emailAddress', 'externalId'];
}

export function isUserEditable(user: User, userGroups: Array<UserGroup>): boolean {
    return user.userGroupIds === undefined || user.userGroupIds?.length === 0 || user.userGroupIds === null ||
        userGroups.some(ug => user.userGroupIds.includes(ug.id) && ug.maxPermissionForCurrentUser > 1);
}
