<div class="bol-modal__header">
    <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.EDIT-WEEK-LENGTH</span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    <mat-form-field>
        <mat-label translate>Length in weeks</mat-label>
        <input
            matInput
            type="number"
            min="1"
            max="53"
            required
            [formControl]="weekLengthForm"
        />
        @if (weekLengthForm.errors?.required) {
            <mat-error>{{ 'VALIDATORS.REQUIRED' | translate }}</mat-error>
        }
        @if (weekLengthForm.errors?.min) {
            <mat-error>{{ 'VALIDATORS.MIN_VALUE' | translate: { minValue: 1 } }}</mat-error>
        }
        @if (weekLengthForm.errors?.max) {
            <mat-error>{{ 'VALIDATORS.MAX_VALUE' | translate: { maxValue: 53 } }}</mat-error>
        }
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <ng-container>
            <button
                mat-button
                type="button"
                class="bol-button-outlined"
                (click)="onCancel()"
                data-cy="cancelEntityButton"
            >
                <span translate>Cancel</span>
            </button>

            <button
                mat-button
                type="button"
                class="bol-button-solid bol-positive"
                [disabled]="weekLengthForm.invalid"
                (click)="onSave()"
                data-cy="saveEntityButton"
            >
                <span translate>Save</span>
            </button>
        </ng-container>
    </div>
</mat-dialog-actions>

