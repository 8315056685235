<div class="bol-modal__header">
    @if (mode === manageMode.EDIT) {
        <span class="modal-title">{{ 'EDIT-ENTITY-TITLE' | translate: { entityName: prefixedEntityName | translate } }}</span>
    }
    @if (mode === manageMode.ADD) {
        <span class="modal-title">{{ 'ADD-ENTITY-TITLE' | translate: { entityName: prefixedEntityName | translate } }}</span>
    }
    @if (mode === manageMode.CLONE) {
        <span class="modal-title">{{ 'CLONE-ENTITY-TITLE' | translate: { entityName: prefixedEntityName | translate } }}</span>
    }
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    @if (mode === manageMode.CLONE) {
        <h4 class="bol-text bol-text-regular">Clone template "{{ entity.displayName }}" to:</h4>
    }
    <form
        [formGroup]="form"
        (ngSubmit)="onAddEntity()"
    >
        <formly-form
            [form]="form"
            [model]="internalModel"
            [fields]="internalFormFields"
        ></formly-form>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        @if (mode === manageMode.ADD || mode === manageMode.EDIT) {
            <button
                mat-button
                type="button"
                class="bol-button-outlined"
                (click)="onCancelAdd()"
                data-cy="cancelEntityButton"
            >
                <span translate>Cancel</span>
            </button>
            <button
                mat-button
                type="button"
                class="bol-button-solid bol-positive"
                [disabled]="form.invalid"
                (click)="onAddEntity()"
                data-cy="saveEntityButton"
            >
                <span translate>Save</span>
            </button>
        }
        @if (mode === manageMode.CLONE) {
            <button
                mat-button
                type="button"
                class="bol-button-outlined"
                (click)="onCancelAdd()"
                data-cy="cancelEntityButton"
            >
                <span translate>Cancel</span>
            </button>
            <button
                mat-button
                type="button"
                class="bol-button-solid bol-positive"
                (click)="onClone()"
                data-cy="cloneEntityButton"
            >
                <span translate>Clone</span>
            </button>
        }
    </div>
</mat-dialog-actions>

