<div class="counters_export__container">
    <div class="filter-setting-selector">
        @if ((filterSettings$ | async) && (filterSettings$ | async).length > 0) {
            <div>
                <mat-form-field class="bol-form-field">
                    <mat-label>{{ 'Filters' | translate }}</mat-label>
                    <mat-select
                        #filterSettingSelect
                        required
                        [formControl]="filterSettingControl"
                        (openedChange)="openedFilterSettingSelect($event)"
                    >
                        @for (filterSetting of filterSettings$ | async; track filterSetting.id) {
                            <mat-option [value]="filterSetting.id">
                                {{ filterSetting.displayName }}
                            </mat-option>
                        }
                    </mat-select>
                    @if (filterSettingControl.invalid) {
                        <mat-error translate>Field is required</mat-error>
                    }
                </mat-form-field>
            </div>
        }

        @if (!(filterSettings$ | async).length) {
            <div
                class="highlight-summary"
                translate
            >
                No filter selected
            </div>
        }
    </div>

    <div class="placeholder__header"></div>
    <app-counters-filters
        filtersContent
        [isExport]="true"
        [countersFiltersOptions]="countersFiltersOptions"
        [countersFiltersSelectedOptions]="countersFiltersSelectedOptions"
        (organizationUnitIdsSelectedChanged)="onSelectedOrganizationUnitIdsChanged($event)"
        (startDateChanged)="onStartDateChanged($event)"
        (endDateChanged)="onEndDateChanged($event)"
        (activityTypeCategoryIdsSelectedChanged)="onSelectedActivityTypeCategoryIdsChanged($event)"
        (activityTypeIdsSelectedChanged)="onSelectedActivityTypeIdsChanged($event)"
        (organizationUnitForActivityIdsSelectedChanged)="onOrganizationUnitsForActivityIdsChanges($event)"
        (resourceTypeIdsSelectedChanged)="onSelectedResourceTypeIdsChanged($event)"
        (extraResourceTypeIdsSelectedChanged)="onSelectedExtraResourceTypeIdsChanged($event)"
        (enabledExportUnplannedLeavesStateChanged)="onEnabledExportUnplannedLeavesStateChanged($event)"
        (intervalDatesValidityStateChanged)="onIntervalDatesValidityStateChanged($event)"
    >
    </app-counters-filters>
    <div class="counters_export__footer">
        <button
            mat-button
            class="bol-button-solid"
            [disabled]="!(isExportEnabled$ | async)"
            (click)="exportReports()"
            translate
        >
            {{ 'Export' | translate }}
        </button>
    </div>

    <div class="filter-settings">
        <app-counters-export-filters-settings></app-counters-export-filters-settings>
    </div>
</div>

@if ((isLoadingExportFileSubject | async) || (isLoadingFilters$ | async)) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

