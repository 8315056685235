<div
    class="daypart-item"
    [ngClass]="{
        'error-daypart-item': daypartItemErrorClass,
        'field-error': formGroup.invalid,
        'no-error-first-daypart': showFirstDaypartInfo && formGroup.valid,
    }"
>
    <div class="daypart-name">
        <mat-form-field class="bol-form-field">
            <mat-label translate>Daypart name</mat-label>
            <input 
                #nameInput 
                matInput 
                required 
                [formControl]="formControls.name" 
            />
            @if (formControls.name.errors?.required || formControls.name.errors?.['no-empty-spaces']) {
                <mat-error translate>{{ requiredErrorMessage }}</mat-error>
            }
            @if (formControls.name.errors?.notUnique) {
                <mat-error translate>{{ uniqueNameErrorLabel }}</mat-error>
            }
        </mat-form-field>
    </div>
    <div class="daypart-start">
        <mat-form-field class="bol-form-field date-input">
            <mat-label translate>Start time</mat-label>
            <input
                matInput
                bolTimeInput
                autocomplete="off"
                required
                [formControl]="formControls.start"
                [bolTimeAutocomplete]="autocompleteFrom"
            />
            <mat-icon matSuffix svgIcon="clock-outline"></mat-icon>
            @if (formControls.start.errors?.required && !formControls.start.errors?.bolTimeInputParse) {
                <mat-error translate> Please enter a start time </mat-error>
            }
            @if (formControls.start.errors?.bolTimeInputParse) {
                <mat-error translate> Please enter a valid start time </mat-error>
            }
            @if (formControls.start.errors?.notUnique) {
                <mat-error translate> Please choose a unique daypart start </mat-error>
            }
            <bol-time-autocomplete
                #autocompleteFrom="bolTimeAutocomplete"
                [timeValues]="timeRange$ | async"
            ></bol-time-autocomplete>
        </mat-form-field>
    </div>

    @if (enableDelete$ | async) {
        <div class="delete-daypart-button">
            <button
                mat-button
                class="bol-button-text icon-only bol-negative"
                [disabled]="disableDelete$ | async"
                (click)="onDeleteDaypart(daypart)"
            >
                <mat-icon svgIcon="delete"></mat-icon>
            </button>
        </div>
    }
</div>

@if (showFirstDaypartInfo) {
    <div class="first-daypart-info">
        <div class="info" translate>The first daypart should always have the start time set to midnight (00:00).</div>
    </div>
}
