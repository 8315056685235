<mat-form-field class="bol-form-field">
    <mat-label>{{ props.label }}</mat-label>
    @if (props.type !== 'number') {
        <input
            matInput
            [id]="key"
            [name]="field.name"
            [type]="type || 'text'"
            [readonly]="props.readonly"
            [required]="props.required"
            [formControl]="formControl"
            [tabIndex]="props.tabindex"
            [placeholder]="props.placeholder"
            [formlyAttributes]="field"
        />
    } @else {
        <input
            matInput
            type="number"
            [id]="key"
            [name]="field.name"
            [readonly]="props.readonly"
            [required]="props.required"
            [formControl]="formControl"
            [tabIndex]="props.tabindex"
            [placeholder]="props.placeholder"
            [formlyAttributes]="field"
        />
    }
    <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
    </mat-error>
</mat-form-field>
