import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

export class TranslateExtension {
    constructor(private translateService: TranslateService) {}

    prePopulate(field: FormlyFieldConfig) {
        const props = field.props || {};

        if (props.label) {
            if (!props._originalLabel) {
                props._originalLabel = props.label;
            }

            const translationKey = props._originalLabel;

            field.expressions = {
                ...(field.expressions || {}),
                'props.label': () => this.translateService.instant(translationKey),
            };
        }

        if (props.placeholder) {
            if (!props._originalPlaceholder) {
                props._originalPlaceholder = props.placeholder;
            }

            const translationKey = props._originalPlaceholder;

            field.expressions = {
                ...(field.expressions || {}),
                'props.placeholder': () => this.translateService.instant(translationKey),
            };
        }

        if (props.description) {
            if (!props._originalDescription) {
                props._originalDescription = props.description;
            }

            const translationKey = props._originalDescription;

            field.expressions = {
                ...(field.expressions || {}),
                'props.description': () => this.translateService.instant(translationKey),
            };
        }

        // make sure change detection is triggered (formly >= 6 uses OnPush strategy)
        field.props = { ...props };

        if (field.validators) {
            for (const key in field.validators) {
                if (!field.validators.hasOwnProperty(key)) {
                    continue;
                }

                if (field.validators[key] != null && field.validators[key].message != null && typeof field.validators[key].message !== 'function') {
                    if (!field.validators[key]._originalMessage) {
                        field.validators[key]._originalMessage = field.validators[key].message;
                    }

                    const translationKey = field.validators[key]._originalMessage;

                    // Set via expressionProperty doesn't work, set message directly:
                    field.validators[key].message = this.translateService.instant(translationKey);
                }
            }
        }

        if (field.validation && field.validation.messages) {
            for (const key in field.validation.messages) {
                if (!field.validation.messages.hasOwnProperty(key)) {
                    continue;
                }

                if (field.validation.messages[key] != null && field.validation.messages[key] != null && typeof field.validation.messages[key] !== 'function') {

                    if (field.validation._originalMessages == null)
                    {
                        field.validation._originalMessages = {};
                    }

                    if (!field.validation._originalMessages[key]) {
                        field.validation._originalMessages[key] = field.validation.messages[key];
                    }

                    const translationKey = field.validation._originalMessages[key];

                    // Set via expressionProperty doesn't work, set message directly:
                    field.validation.messages[key] = this.translateService.instant(translationKey);
                }
            }
        }
    }
}

export function registerTranslateExtension(translateService: TranslateService) {
    return {
        extensions: [{
            name: 'translate',
            extension: new TranslateExtension(translateService)
        }],
    };
}
