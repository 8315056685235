import { Injectable } from '@angular/core';
import { EntityUIQuery, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { NestedTreeService } from '../../services';
import { EntityUI, EntityUIState } from '../entity-ui-models';
import { IUserGroupTree, UserGroup } from './user-group.model';
import { UserGroupState, UserGroupStore } from './user-group.store';

@Injectable({
    providedIn: 'root'
})
export class UserGroupQuery extends QueryEntity<UserGroupState> {
    public ui: EntityUIQuery<EntityUIState>;

    constructor(
        protected store: UserGroupStore,
        private readonly nestedTreeService: NestedTreeService,
    ) {
        super(store);
        this.createUIQuery();
    }

    public getUserGroups(): Observable<Array<UserGroup>> {
        return this.selectAll().pipe(
            filter(userGroups => userGroups && userGroups.length > 0),
        );
    }

    public getUserGroupsSync(): Array<UserGroup> {
        const userGroups = Object.values(this.getValue().entities);

        return userGroups.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
    }

    public getAllChildrenForUserGroupSync(userGroupId: number): Array<UserGroup> {
        const userGroups = this.getUserGroupsSync();
        const mutableUserGroups = userGroups.map(userGroup => ({ ...userGroup }));

        const userGroupTree = this.nestedTreeService.nestArray(mutableUserGroups as any);
        const branchOfUnit = this.nestedTreeService.getBranchOfEntity(userGroupTree, userGroupId);
        const children = this.nestedTreeService.getChildren(branchOfUnit, []) as any as Array<UserGroup>;

        return children;
    }

    public getUserGroupsForFiltering(): Observable<Array<IUserGroupTree>> {
        return this.selectAll().pipe(
            filter(userGroups => userGroups && userGroups.length > 0),
            map((groups: Array<IUserGroupTree>) => {
                groups.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
                const mutableUnits = groups.map(unit => {
                    return { ...unit };
                });

                return this.nestedTreeService.nestArray(mutableUnits) as Array<IUserGroupTree>;
            })
        );
    }

    public getUserGroupsForFilteringWithEmpty(): Observable<Array<IUserGroupTree>> {
        return this.getUserGroupsForFiltering().pipe(
            map(userGroups => {
                userGroups.unshift({children: [], parentId: undefined, displayName: '', id: 0});

                return userGroups;
            })
        );
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getUserGroup(id: number): Observable<UserGroup> {
        return this.selectEntity(id);
    }

    public getUserGroupSync(id: number): UserGroup {
        return this.getEntity(id);
    }

    public getUIUserGroup(id: number): Observable<EntityUI> {
        return this.ui.selectEntity(id);
    }
}
