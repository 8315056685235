<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<table
    mat-table
    class="bol-table"
    [dataSource]="dataSource"
>
    <ng-container matColumnDef="displayName">
        <th
            *matHeaderCellDef
            mat-header-cell
        >
            <span
                class="display-name-header"
                translate
            >
                Name
            </span>
        </th>
        <td
            mat-cell
            *matCellDef="let data"
        >
            <div class="permission-label">
                <button
                    mat-icon-button
                    [class.button-children-selected]="hasChildrenSelected(data)"
                    [style.visibility]="!data.expandable ? 'hidden' : ''"
                    [style.marginLeft.px]="data.level * 32"
                    (click)="toggleExpandableEntity(data)"
                >
                    <mat-icon
                        class="mat-icon-rtl-mirror"
                        [svgIcon]="treeControl.isExpanded(data) ? 'chevron-down' : 'chevron-right'"
                    >
                    </mat-icon>
                </button>
                {{ data.displayName }}
            </div>
        </td>
    </ng-container>

    @for (column of definedColumnDefinitions; track $index) {
        <ng-container [matColumnDef]="column.entityProperty">
            <th
                *matHeaderCellDef
                mat-header-cell
                translate
            >
                {{ column.columnDisplayName }}
            </th>
            <td
                *matCellDef="let data"
                mat-cell
            >
                <mat-checkbox
                    class="bol-checkbox bol-primary"
                    [checked]="getNodePermissionState(data, column.permissionType)"
                    [disabled]="disabledPermissions"
                    (change)="onSelectEntity(data.id, column.entityProperty, column.permissionType)"
                >
                </mat-checkbox>
            </td>
        </ng-container>
    }

    <tr
        *matHeaderRowDef="displayedColumns"
        mat-header-row
    ></tr>
    <tr
        *matRowDef="let row; columns: displayedColumns"
        mat-row
    ></tr>
</table>

@if (this.dataSource?.data?.length === 0) {
    <p>{{ 'NO-ENTITIES-FOUND' | translate: { entityName: entityName | translate | lowercase } }}</p>
}

