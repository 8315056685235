import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { SkillQuery } from 'src/app/shared/stores/skill-store/skill.query';
import { SkillService } from 'src/app/shared/stores/skill-store/skill.service';
import { UserGroupQuery } from 'src/app/shared/stores/user-group-store/user-group.query';
import { UserGroupService } from 'src/app/shared/stores/user-group-store/user-group.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PermissionQuery } from 'src/app/shared/stores/permission-store/permission.query';
import { MANAGE_MODE, PERMISSIONS } from 'src/app/shared/models/Enums';
import { PermissionsTreeStateService } from 'src/app/shared/components/inputs/permissions-tree/services/permissions-tree-state.service';

import { ResourceTypesManagementQuery } from './store/resource-types-management.query';
import { ResourceTypesManagementService } from './store/resource-types-management.service';
import { getResourceTypesFormFields } from './resource-types-form-definition';

@Component({
    selector: 'app-resource-types',
    templateUrl: './resource-types.component.html',
    styleUrls: ['./resource-types.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceTypesComponent implements OnInit, OnDestroy {


    public filteredResourceTypes$: Observable<Array<ResourceType>>;
    public loadingError$: Observable<boolean>;
    public manageMode$: Observable<MANAGE_MODE>;
    public selectedUIResourceType$: Observable<EntityUI>;
    public selectedResourceTypeId$: Observable<number>;
    public selectedResourceType$ = new BehaviorSubject<ResourceType>(undefined);
    public showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
    public initialLoadingFinished$: Observable<boolean>;
    public formFields: Array<FormlyFieldConfig>;
    public canDeleteSelectedEntity$: Observable<boolean>;
    public searchProperties: Array<string>;

    private readonly subscription = new Subscription();

    constructor(
        private readonly resourceTypeService: ResourceTypeService,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly resourceTypesManagementQuery: ResourceTypesManagementQuery,
        private readonly resourceTypesManagementService: ResourceTypesManagementService,
        private readonly organizationQuery: OrganizationUnitQuery,
        private readonly skillQuery: SkillQuery,
        private readonly skillService: SkillService,
        private readonly userGroupService: UserGroupService,
        private readonly userGroupQuery: UserGroupQuery,
        private readonly autosaveService: AutosaveService,
        private readonly notificationService: NotificationService,
        private readonly permissionQuery: PermissionQuery,
        private readonly permissionsTreeStateService: PermissionsTreeStateService
    ) { }
    
    public ngOnInit(): void {
        this.skillService.getSkills().pipe(first()).subscribe();
        this.resourceTypeService.getResourceTypes().pipe(first()).subscribe();
        this.userGroupService.getUserGroupNames().pipe(first()).subscribe();

        this.setResourceTypeFormFields();

        this.searchProperties = ['displayName', 'externalId'];
        this.loadingError$ = this.resourceTypeQuery.selectError();
        this.selectedUIResourceType$ = this.resourceTypesManagementQuery.getSelectedUIResourceType();
        this.selectedResourceTypeId$ = this.resourceTypesManagementQuery.getCurrentResourceTypeId();
        this.manageMode$ = this.resourceTypesManagementQuery.getManageMode();
        this.filteredResourceTypes$ = this.resourceTypesManagementQuery.getFilteredResourceTypes();
        this.initialLoadingFinished$ = this.resourceTypesManagementQuery.allEntitiesLoaded();
        this.canDeleteSelectedEntity$ = this.resourceTypesManagementQuery.getCanDeleteSelectedEntityState();

        this.subscription.add(
            this.resourceTypesManagementQuery.getSelectedResourceType().pipe(
                tap(resourceType => this.selectedResourceType$.next(resourceType))
            ).subscribe()
        );
    }
    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
        this.subscription.unsubscribe();
        this.permissionsTreeStateService.clearExpandedNodeIds();
    }

    public onSelectedResourceTypeChange(id: number): void {
        this.autosaveService.saveUnsavedChanges();
        this.resourceTypeService.setResourceTypeToCleanUIState(id);
        const manageMode = id === undefined ? undefined : MANAGE_MODE.EDIT;
        this.resourceTypesManagementService.updateManageMode(manageMode);
        this.resourceTypesManagementService.updateSelectedResourceTypeId(id);
    }

    public onAddNewResourceType(): void {
        const newResourceType = this.resourceTypesManagementQuery.getNewResourceTypeObject();
        const lastCorrectVersionOfResourceType = this.resourceTypeQuery.getResourceTypeSync(this.resourceTypesManagementQuery.getCurrentResourceTypeIdSync());

        this.showLoadingSpinner$.next(true);
        
        this.resourceTypeService.saveResourceType(newResourceType, lastCorrectVersionOfResourceType).pipe(
            tap((addedResourceType: ResourceType) => {
                this.showLoadingSpinner$.next(false);
                this.resourceTypesManagementService.setStateForNewResourceType(addedResourceType.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onCloneResourceType(resourceTypeId: number): void {
        this.showLoadingSpinner$.next(true);

        const lastCorrectVersionOfResourceType = this.resourceTypeQuery.getResourceTypeSync(resourceTypeId);
        this.resourceTypeService.cloneResourceType(
            lastCorrectVersionOfResourceType,
            this.resourceTypeQuery.getResourceTypesSync().map(rt => rt.displayName)    
        ).pipe(
            tap((clonedResourceType: ResourceType) => {
                this.showLoadingSpinner$.next(false);
                this.resourceTypesManagementService.updateSelectedResourceTypeId(clonedResourceType.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    }

    public onDeleteResourceType(resourceTypeId: number): void {
        this.showLoadingSpinner$.next(true);
        this.resourceTypeService.deleteResourceType(resourceTypeId).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
                this.resourceTypesManagementService.updateSelectedResourceTypeId(undefined);
                this.resourceTypesManagementService.updateManageMode(undefined);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onEditResourceType(resourceType: ResourceType): void {
        this.resourceTypeService.setResourceTypeToPendingChanges(resourceType.id);
        this.autosaveService.autoSave(resourceType, this.saveResourceType.bind(this));
    }
    
    public onCancelAdd(): void {
        this.resourceTypesManagementService.updateManageMode(undefined);
        this.resourceTypesManagementService.updateSelectedResourceTypeId(undefined);
    };

    private saveResourceType(resourceType: ResourceType): void {
        const lastCorrectVersionOfResourceType = this.resourceTypeQuery.getResourceTypeSync(resourceType.id);

        this.resourceTypeService.saveResourceType(resourceType, lastCorrectVersionOfResourceType).pipe(
            first(),
        ).subscribe({
            next: () => {
                const resourceTypeVisible = this.resourceTypesManagementQuery.isResourceTypeVisible(resourceType.id);
                if (resourceType.id === this.resourceTypesManagementQuery.getCurrentResourceTypeIdSync() && !resourceTypeVisible) {
                    this.notificationService.showEntityDisabledMessage('resource types');
                }
            },
            error: this.handleSaveError.bind(this)
        });
    }

    private setResourceTypeFormFields(): void {
        this.subscription.add(
            this.resourceTypesManagementQuery.getCurrentResourceTypeId().pipe(
                switchMap((currentResourceTypeId) => combineLatest([
                    this.organizationQuery.getOrganizationsForFiltering(),
                    this.userGroupQuery.getUserGroups(),
                    this.skillQuery.getSkills(),
                    this.resourceTypeQuery.getResourceTypes()
                ]).pipe(
                    map(([units, userGroups, skills, resourceTypes]) => ({
                        currentResourceTypeId,
                        units,
                        userGroups,
                        skills,
                        resourceTypes
                    })),
                    first()
                )),
                filter(({ units, userGroups, skills, currentResourceTypeId }) => 
                    !!units && !!skills && !!userGroups && currentResourceTypeId > 0),
            ).subscribe(({ units, userGroups, skills, resourceTypes })  => {
                const restrictedResourceTypePermission = this.permissionQuery.getPermissionSync(PERMISSIONS.MANAGE_RESTRICTED_RESOURCETYPES);
                const hasRestrictedResourceTypePermission = restrictedResourceTypePermission !== undefined && restrictedResourceTypePermission.numberUserUserGroupsWithThisPermission >= 1;
                this.formFields = getResourceTypesFormFields(units, userGroups, skills, resourceTypes, hasRestrictedResourceTypePermission);
            })
        );
    }

    private handleSaveError(resourceType: ResourceType): void {
        if (this.resourceTypesManagementQuery.getCurrentResourceTypeIdSync() === resourceType.id) {
            this.selectedResourceType$.next({...resourceType});
        }
    }

    private handleError(): void {
        this.showLoadingSpinner$.next(false);
    }
}