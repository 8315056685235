<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <app-entity-filter-panel [cardClass]="'top-card'">
                <app-resource-types-filters></app-resource-types-filters>
            </app-entity-filter-panel>

            <div class="flex-growing-child">
                @if (initialLoadingFinished$ | async) {
                    <app-entity-list-panel
                        entityName="Resource types"
                        [entities]="filteredResourceTypes$ | async"
                        [mode]="manageMode$ | async"
                        [searchProperties]="searchProperties"
                        [loadingError]="loadingError$ | async"
                        [selectedEntityId]="selectedResourceTypeId$ | async"
                        [cardClass]="'bottom-card'"
                        (selectedEntityIdChanged)="onSelectedResourceTypeChange($event)"
                        (addEntityClicked)="onAddNewResourceType()"
                    >
                    </app-entity-list-panel>
                }
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="Resource type"
                    canClone="true"
                    [mode]="manageMode$ | async"
                    [formFields]="formFields"
                    [model]="selectedResourceType$ | async"
                    [uiEntity]="selectedUIResourceType$ | async"
                    [canDelete]="canDeleteSelectedEntity$ | async"
                    (cancelAdd)="onCancelAdd()"
                    (deleteEntity)="onDeleteResourceType($event)"
                    (cloneEntity)="onCloneResourceType($event)"
                    (editEntity)="onEditResourceType($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>

@if ((initialLoadingFinished$ | async) === false || (showLoadingSpinner$ | async)) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

