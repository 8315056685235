<bol-toolbar id="topbar">
    <div start>
        <div class="topbar__logocontainer">
            <a href="{{ rootUrl$ | async }}">
                <img
                    class="topbar__icon"
                    src="{{ logoSrc }}"
                    alt="Ortec Logo"
                />
            </a>
        </div>
        <app-breadcrumb rootUrl="{{ rootUrl$ | async }}"></app-breadcrumb>
    </div>

    <div end>
        <button
            mat-button
            [matMenuTriggerFor]="menu"
            data-cy="UserMenuButton"
        >
            {{ displayName$ | async }}
            <mat-icon
                svgIcon="menu-down"
                iconPositionEnd
            ></mat-icon>
        </button>

        <mat-menu 
            #menu="matMenu"
            class="bol-menu"
        >
            @if (showLanguageSelector) {
                <app-language-selector></app-language-selector>
            }
            <button
                mat-menu-item
                (click)="onLogout()"
                data-cy="LogoutButton"
            >
                <mat-icon svgIcon="power"></mat-icon>
                <span>Logout</span>
            </button>

            @if ((isFullUser$ | async) === true && (featurePlanboardAPlanningBoard$ | async) === false) {
                <button
                    mat-menu-item
                    (click)="onNavigateToUserPreferences()"
                >
                    <mat-icon svgIcon="cog"></mat-icon>
                    <span translate>Preferences</span>
                </button>
            }
        </mat-menu>

        @if (helpPath$ | async) {
            <button
                mat-button
                class="btn bol-button-text icon-only"
                (click)="onNavigateToHelp()"
            >
                <mat-icon svgIcon="help-circle-outline">help_outline</mat-icon>
            </button>
        }
    </div>
</bol-toolbar>
<div class="stripe"></div>

