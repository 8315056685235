<div class="filters__container">
    <div class="counter-type_interval-date">
        <mat-form-field class="bol-form-field bol-datepicker default-mat-form-field">
            <mat-label>{{ 'Start date' | translate }}</mat-label>
            <input
                #fromDateInput
                matInput
                required
                [matDatepicker]="fromDatePicker"
                [formControl]="dateTimeControls.fromDate"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="fromDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker
                #fromDatePicker
                (closed)="fromDatePickerClosed()"
            >
            </mat-datepicker>
            @if (dateTimeControls.fromDate.invalid) {
                <mat-error translate>
                    <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                    {{ getDateErrorMessage(fromDateInput) | translate }}
                </mat-error>
            }
        </mat-form-field>

        <mat-form-field class="bol-form-field bol-datepicker default-mat-form-field">
            <mat-label>{{ 'End date' | translate }}</mat-label>
            <input
                #tillDateInput
                matInput
                required
                [matDatepicker]="tillDatePicker"
                [min]="dateTimeControls.fromDate.value"
                [formControl]="dateTimeControls.tillDate"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="tillDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #tillDatePicker> </mat-datepicker>
            @if (dateTimeControls.tillDate.invalid && !dateTimeControls.tillDate.errors?.matDatepickerMin) {
                <mat-error translate>
                    <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                    {{ getDateErrorMessage(tillDateInput) | translate }}
                </mat-error>
            }
            @if (dateTimeControls.tillDate.errors?.matDatepickerMin) {
                <mat-error>{{ 'The end date is before the start date' | translate }}</mat-error>
            }
        </mat-form-field>

        @if (!isExport) {
            <div class="multiselect-tree-filters">
                <app-multiselect-tree
                    multiselectTreePlaceholder="Counter types"
                    errorMessage="This field is required"
                    class="default-mat-form-field"
                    [entities]="countersFiltersOptions.counterTypes$ | async"
                    [preSelectedEntities]="countersFiltersSelectedOptions.selectedCounterTypes$ | async"
                    [showSelectAll]="true"
                    [showSearch]="false"
                    (selectedEntityIdsChanged)="onCounterFiltersChanged($event)"
                    data-cy="CountersCounterType"
                >
                </app-multiselect-tree>
            </div>
        }

        @if (scenarioFilterVisibility && !isExport) {
            <div>
                <mat-form-field class="bol-form-field default-mat-form-field">
                    <mat-label>{{ 'Scenario' | translate }}</mat-label>
                    <mat-select
                        required
                        panelClass="scenario-selector"
                        [formControl]="scenarioControl"
                    >
                        @if ((scenarioItems$ | async).length === 0) {
                            <div class="selector-empty-item">{{ 'No values found' | translate }}</div>
                        }
                        @for (scenario of scenarioItems$ | async; track scenario.id) {
                            <mat-option
                                [value]="scenario.id"
                                [disabled]="!scenario.valid"
                            >
                                {{ scenario.displayName }}
                                {{ scenario.start | date: (dateFormat$ | async) }} -
                                {{ scenario.end | date: (dateFormat$ | async) }}
                            </mat-option>
                        }
                    </mat-select>
                    @if (scenarioControl.invalid) {
                        <mat-error>{{ 'Field is required' | translate }}</mat-error>
                    }
                </mat-form-field>
            </div>
        }
    </div>

    <div class="multiselect-tree-filters">
        <app-multiselect-tree
            class="organization-unit_input"
            multiselectTreePlaceholder="Organization Units"
            errorMessage="This field is required"
            [entities]="countersFiltersOptions.organizationUnits$ | async"
            [preSelectedEntities]="countersFiltersSelectedOptions.selectedOrganizationUnits$ | async"
            [showSelectAll]="true"
            (selectedEntityIdsChanged)="onFilteredOrganizationsChanged($event)"
            data-cy="CountersOrganizationUnitsFilter"
        >
        </app-multiselect-tree>

        <app-multiselect-tree
            class="default-mat-form-field"
            multiselectTreePlaceholder="Resource types"
            errorMessage="This field is required"
            [entities]="countersFiltersOptions.resourceTypes$ | async"
            [preSelectedEntities]="countersFiltersSelectedOptions.selectedResourceTypeIds$ | async"
            [showSelectAll]="true"
            (selectedEntityIdsChanged)="onFilteredResourceTypesChanged($event)"
            data-cy="CountersResourceTypesFilter"
        >
        </app-multiselect-tree>

        @if (!isExport) {
            <app-multiselect-tree
                class="default-mat-form-field"
                multiselectTreePlaceholder="Resource skills"
                [entities]="countersFiltersOptions.skills$ | async"
                [preSelectedEntities]="countersFiltersSelectedOptions.selectedSkillIds$ | async"
                [required]="false"
                [showSelectAll]="true"
                (selectedEntityIdsChanged)="onFilteredResourceSkillsChanged($event)"
                data-cy="CountersResourceSkillsFilter"
            >
            </app-multiselect-tree>
        }

        @else {
            <app-multiselect-tree
                multiselectTreePlaceholder="Extra resource types"
                class="default-mat-form-field"
                [entities]="countersFiltersOptions.extraResourceTypes$ | async"
                [preSelectedEntities]="countersFiltersSelectedOptions.selectedExtraResourceTypeIds$ | async"
                [required]="false"
                [showSelectAll]="true"
                (selectedEntityIdsChanged)="onFilteredExtraResourceTypesChanged($event)"
                data-cy="CountersExtraResourceTypesFilter"
            >
            </app-multiselect-tree>
        }
    </div>

    <div class="activity-type">
        <div class="activity-type-selection">
            <app-activity-type-selection
                [activityTypes]="countersFiltersOptions.activityTypes$ | async"
                [activityTypeCategories]="countersFiltersOptions.activityTypeCategories$ | async"
                [selectedActivityTypeIds]="countersFiltersSelectedOptions.selectedActivityTypeIds$ | async"
                [selectedActivityTypeCategoryIds]="
                    countersFiltersSelectedOptions.selectedActivityTypeCategoryIds$ | async
                "
                [organizationUnits]="countersFiltersOptions.organizationUnits$ | async"
                [selectedOrganizationUnitsIds]="
                    countersFiltersSelectedOptions.selectedOrganizationUnitsForActivities$ | async
                "
                [required]="true"
                (updateSelectedActivityTypeIdsEvent)="updateSelectedActivityTypeIds($event)"
                (updateSelectedActivityTypeCategoryIdsEvent)="updateSelectedActivityTypeCategoryIds($event)"
                (updateSelectedActivitiesOrganizationUnitIdsEvent)="
                    updateSelectedOrganizationUnitForActivityIds($event)
                "
            ></app-activity-type-selection>
        </div>

        @if (isExport) {
            <mat-checkbox
                class="bol-checkbox unplanned_leaves__checkbox"
                [checked]="countersFiltersOptions.exportUnplannedLeavesState$ | async"
                (change)="onUpdateEnabledExportUnplannedLeavesState($event)"
                >{{ 'Export unplanned leaves' | translate }}</mat-checkbox
            >
        }

        @else {
            <mat-checkbox
                class="bol-checkbox"
                [checked]="countersFiltersOptions.enableActivityTypeDetailState$ | async"
                (change)="onUpdateEnabledActivityTypeDetailState($event)"
            >
                <span>{{ 'Show counters for activity types' | translate }}</span>
            </mat-checkbox>
        }
    </div>

    @if (!isExport) {
        <div class="grouping-option_total">
            <app-filter-chip-list
                class="grouping-options__container"
                errorMessage="{{ 'Field is required' | translate }}"
                noResultsFoundText="{{ 'No grouping options found' | translate }}"
                placeholderText="{{ 'Grouping options...' | translate }}&nbsp;*"
                [entities]="countersFiltersOptions.groupingOptions$ | async"
                [preselectedEntities]="countersFiltersSelectedOptions.selectedGroupingOptions$ | async"
                (selectedEntityIdsChanged)="onFilteredGroupingOptionsChanged($event)"
            ></app-filter-chip-list>
            <div
                class="total"
                [ngClass]="{ 'extended-container': (groupingOptionsLength$ | async) > 0 }"
            >
                <app-multiselect-tree
                    class="default-mat-form-field"
                    multiselectTreePlaceholder="Totals"
                    [entities]="countersFiltersOptions.totals$ | async"
                    [preSelectedEntities]="countersFiltersSelectedOptions.selectedTotals$ | async"
                    [showSelectAll]="true"
                    (selectedEntityIdsChanged)="onFilteredTotalOptionsChanged($event)"
                    data-cy="CountersTotalOptionsFilter"
                >
                </app-multiselect-tree>
            </div>
        </div>
    }
</div>

