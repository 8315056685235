<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div
    class="holiday-item"
    [ngClass]="{ 'field-error': formGroup.invalid }"
    [attr.data-cy]="'HolidayItem' + index"
>
    <div
        class="holiday-date"
        data-cy="HolidayDate"
    >
        <mat-form-field class="bol-form-field bol-datepicker">
            <mat-label translate>Date</mat-label>
            <input
                matInput
                required
                [matDatepicker]="dp"
                [formControl]="formControls.holidayDate"
                [min]="minDate"
                [max]="maxDate"
                [attr.data-cy]="formControls.holidayDate.value"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="dp"
            ></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
            @if (formControls.holidayDate.invalid) {
                <mat-error translate>
                    {{ formControls.holidayDate.errors?.required ? requiredErrorMessage : validErrorMessage }}
                </mat-error>
            }
        </mat-form-field>
    </div>

    <div
        class="holiday-name"
        data-cy="HolidayDescription"
    >
        <mat-form-field class="bol-form-field">
            <mat-label translate>Description</mat-label>
            <input
                #nameInput
                matInput
                required
                [formControl]="formControls.text"
                [attr.data-cy]="formControls.text.value"
            />
            @if (formControls.text.errors?.required || formControls.text.errors?.[noEmptySpacesValidator]) {
                <mat-error translate>{{ requiredErrorMessage }}</mat-error>
            }
        </mat-form-field>
    </div>

    <div class="delete-holiday-button">
        <button
            mat-button
            class="bol-button-text icon-only bol-negative"
            [attr.data-cy]="'DeleteEntityButton' + index"
            (click)="onDeleteHoliday(holiday)"
        >
            <mat-icon svgIcon="delete"></mat-icon>
        </button>
    </div>
</div>

