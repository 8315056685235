<mat-card
    class="bol-card"
    class="content-wrapper"
>
    <mat-card-content>
        <div class="title-status">
            <h3
                class="filtering-title"
                translate
                data-cy="filterPaletteTitle"
            >
                FILTER-PALETTE.PAGE-TITLE
            </h3>

            <app-loading-status-indication [statusPageState]="statusPageState$ | async"></app-loading-status-indication>
        </div>

        <div class="activities-checkbox">
            <mat-checkbox
                class="bol-checkbox"
                [checked]="applyFilterState$ | async"
                (change)="onApplyFilter($event)"
                data-cy="applyFilterPalette"
            >
                <span>{{ 'Apply filter' | translate }}</span>
            </mat-checkbox>
        </div>
        <div>
            <div class="filters-activity-types">
                <app-multiselect-tree
                    multiselectTreePlaceholder="Activity type categories"
                    class="activity-type-categories-filter-activity-type"
                    [entities]="activityTypeCategories$ | async"
                    [preSelectedEntities]="selectedActivityTypeCategoryIds"
                    [showSelectAll]="true"
                    [required]="false"
                    [showSearch]="false"
                    [disable]="disableFilterFields"
                    (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnActivityTypeCategories($event)"
                    data-cy="activityTypeCategoriesFilter"
                ></app-multiselect-tree>

                <app-multiselect-tree
                    searchFieldPlaceholder="Search organization unit"
                    multiselectTreePlaceholder="Organization units"
                    class="organization-unit-filter-activity-type"
                    [entities]="organizationUnits$ | async"
                    [preSelectedEntities]="selectedOrganizationIds"
                    [showSelectAll]="true"
                    [required]="false"
                    [disable]="disableFilterFields"
                    (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnOrganizationUnits($event)"
                    data-cy="organizationUnitFilter"
                ></app-multiselect-tree>
            </div>

            <div class="activities-checkbox">
                <mat-checkbox
                    class="bol-checkbox"
                    [checked]="showOnlySelectedState$ | async"
                    (change)="showOnlyActivityTypesSelected($event)"
                    data-cy="showOnlySelected"
                >
                    <span>{{ 'Show only selected' | translate }}</span>
                </mat-checkbox>
            </div>
            <div>
                <app-multiselect-table
                    searchFieldPlaceholder="Activity type filter"
                    [entities]="filteredActivityTypes$ | async"
                    [columnDefinition]="columnDefinition"
                    [searchProperties]="searchProperties"
                    [hideSearch]="false"
                    (selectedEntityIdsChanged)="updateActivityTypeStates($event)"
                ></app-multiselect-table>
            </div>

            <div class="activities-length-info-message">
                <div
                    class="bol-text bol-text-regular"
                    data-cy="selectedActTypeMessage"
                >
                    {{ selectedActivityTypeIds?.length }} {{ '' + ACTIVITY_SELECTED_MESSAGE | translate }}
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

@if ((initialLoadingFinished$ | async) === false) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

