/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormlyFieldConfig } from '@ngx-formly/core';

import { fieldNonEmptyValidatorKey } from 'src/app/shared/validators';
import { Permission } from 'src/app/shared/models/Enums';
import { ReportUri } from 'src/app/shared/stores/report-uri-store/report-uri.model';
import { IUserGroupTree, UserGroup } from 'src/app/shared/stores/user-group-store/user-group.model';
import { User } from 'src/app/shared/stores/user-store/user.model';

export function getUserGroupFormFields(
    userGroups: Array<UserGroup>,
    userGroupTrees: Array<IUserGroupTree>,
    selectedItemWritable: boolean,
    usersForSelectedUserGroup: Array<User>,
    permissions: Array<{ id: number; displayName: string }>,
    selectedEntityId: number,
    hideReportsField: boolean,
    reportUris: Array<ReportUri>
): Array<FormlyFieldConfig> {
    return [
        {
            key: 'displayName',
            type: 'bolster-input',
            props: {
                label: 'Display name',
                required: true,
                maxLength: 80,
                disabled: !selectedItemWritable,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        },
        {
            key: 'parentId',
            type: 'tree-single-select',
            props: {
                disabled: !selectedItemWritable,
                entities: userGroupTrees,
                placeholderText: 'User group parent',
                hiddenEntityId: selectedEntityId
            }
        },
        {
            key: 'selectedUserIds',
            type: 'entity-list-dropdown',
            props: {
                entities: usersForSelectedUserGroup,
                placeholderText: 'View users belonging to this user group',
                disabled: true,
            }
        },
        {
            key: 'selectedPermissionIds',
            type: 'multiselect',
            props: {
                entities: permissions,
                placeholderText: 'Permissions',
                disabled: !selectedItemWritable,
            }
        },
        {
            key: 'selectedReportUriIds',
            type: 'multiselect-tree',
            props: {
                entities: reportUris,
                placeholderText: 'Reports',
                searchProperties: ['displayName'],
                disable: !selectedItemWritable,
            },
            hideExpression: hideReportsField,
        },
        {
            key: 'userGroupPermissionList',
            type: 'permissions-tree',
            props: {
                title: 'User group permissions for the selected user group',
                entities: userGroups,
                entityName: 'User groups',
                disabled: !selectedItemWritable,
                columnDefinition: [Permission.read, Permission.readWrite, Permission.owner]
            }
        },
    ];
}

export function getUserGroupSearchProperties(): Array<string> {
    return ['displayName'];
}
