import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { PermissionsTreeAdapterService } from '../../components/inputs/permissions-tree/services/permissions-tree-adapter.service';
import { PermissionFlatNode, PermissionItem } from '../../components/inputs/permissions-tree/permissions-tree.model';
import { Permission } from '../../models/Enums';
import { NestedTreeService } from '../../services';

@Component({
    selector: 'app-formly-permissions-tree',
    templateUrl: './formly-permissions-tree.component.html',
    styleUrls: ['./formly-permissions-tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PermissionsTreeAdapterService]
})
export class FormlyPermissionsTreeComponent extends FieldType implements OnInit {
    public entities$: Observable<Array<PermissionFlatNode>>;
    public panelOpenState = true;
    public initialEntitiesWithPermissions;

    constructor(
        private readonly permissionsTreeAdapterService: PermissionsTreeAdapterService,
        private readonly nestedTreeService: NestedTreeService,
    ) {
        super();
    }

    public ngOnInit(): void {
        const initialSelectedPermissions: Array<PermissionItem> = this.model[this.field.key as string] || [];
        this.initialEntitiesWithPermissions = initialSelectedPermissions.filter(permission => permission.permission !== Permission.noPermission);

        this.entities$ = this.field.formControl.valueChanges.pipe(
            startWith(initialSelectedPermissions),
            map((permissions: Array<PermissionItem>) => {
                const mutableEntities = this.props.entities.map(entity => {
                    return { ...entity };
                });

                return this.transformToPermissionsTable(mutableEntities, permissions);
            })
        );

        this.entities$.subscribe();
    }

    public onPermissionItemsChanged(permissionItems: Array<PermissionItem>): void {
        const selectedPermissionItems: Array<PermissionItem> = this.formControl.value || [];
        
        permissionItems.map(permissionItem => {
            this.updateSelectedPermissionsOnItemChange(selectedPermissionItems, permissionItem);
        });

        this.formControl.setValue(selectedPermissionItems);
    }

    private transformToPermissionsTable(entities: Array<any>, permissions: Array<PermissionItem>): Array<PermissionFlatNode> {
        const updatedPermissions = entities.map(entity => {
            const permission = permissions?.find(p => p.userGroupId === entity.id);
            const nodePermissionType: Permission = permission ? permission.permission : undefined;

            return {
                ...entity,
                readPermission: this.permissionsTreeAdapterService.getNodeStatusForPermissionType(true, nodePermissionType, Permission.read),
                readAndWritePermission: this.permissionsTreeAdapterService.getNodeStatusForPermissionType(true, nodePermissionType, Permission.readWrite),
                ownerPermission: this.permissionsTreeAdapterService.getNodeStatusForPermissionType(true, nodePermissionType, Permission.owner),
            };
        });

        return this.nestedTreeService.nestArray(updatedPermissions) as Array<PermissionFlatNode>;
    }

    private updateSelectedPermissionsOnItemChange(selectedPermissionItems: Array<PermissionItem>, updatedPermissionItem: PermissionItem): Array<PermissionItem> {
        const nodePermissionIndex = selectedPermissionItems.findIndex((item => item.userGroupId === updatedPermissionItem.userGroupId));

        if (nodePermissionIndex !== -1) {
            selectedPermissionItems[nodePermissionIndex].permission = updatedPermissionItem.permission;
        } else {
            selectedPermissionItems.push({ userGroupId: updatedPermissionItem.userGroupId, permission: updatedPermissionItem.permission });
        }

        return selectedPermissionItems;
    } 
}
