<mat-card
    class="bol-card"
    class="content-wrapper"
>
    <mat-card-content>
        <div class="status-title">
            <h3
                class="other-settings-title"
                translate
                data-cy="otherSettingsTitle"
            >
                Other settings
            </h3>
            <app-loading-status-indication [statusPageState]="statusPageState$ | async"></app-loading-status-indication>
        </div>
        <div class="configurations-container">
            <p
                class="bol-text label-filter-leave-shift"
                translate
            >
                Daymark to schedule for a shift replaced by a Leave shift:
            </p>
            <app-filter-tree-single-select
                labelText="Daymark"
                [entities]="activityTypes$ | async"
                [disabled]="false"
                [selectedEntityId]="selectedDaymarkForLeaveShift$ | async"
                (selectedEntityIdChanged)="onSelectedDaymarkForLeaveShiftChange($event)"
                data-cy="daymarkForLeaveShiftFilter"
            >
            </app-filter-tree-single-select>
            <p
                class="bol-text label-filter-sick-filter"
                translate
            >
                Daymark to schedule for a shift replaced by a Sick shift:
            </p>
            <app-filter-tree-single-select
                labelText="Daymark"
                [entities]="activityTypes$ | async"
                [disabled]="false"
                [selectedEntityId]="selectedDaymarkForSickLeaveShift$ | async"
                (selectedEntityIdChanged)="onSelectedDaymarkForSickLeaveChange($event)"
                data-cy="daymarkForSickShiftFilter"
            >
            </app-filter-tree-single-select>
        </div>
    </mat-card-content>
</mat-card>

@if ((initialLoadingFinished$ | async) === false) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

