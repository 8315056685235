import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Inject, InjectionToken } from '@angular/core';

export const IS_PRODUCTION_ENVIRONMENT = new InjectionToken<boolean>('isProductionEnvironment');

export class OmrpTranslateLoader implements TranslateLoader, MissingTranslationHandler {
    constructor(
        private http: HttpClient,
        @Inject(IS_PRODUCTION_ENVIRONMENT) private isProductionEnvironment: boolean,
        private baseFolder: string = '/Site/UI.Angular/assets/i18n/'
    ) {
    }

    public getTranslation(lang: string): Observable<any> {
        return this.http.get(this.baseFolder + `${lang}.json`).pipe(map(
            (result: object) => {
                return this.removeEmptyNodes(result);
            }
        ));
    }

    public handle(params: MissingTranslationHandlerParams): string {
        if (!this.isProductionEnvironment) {
            return '[MISSING] ' + params.key;
        }
        else {
            return params.key;
        }
    }

    private removeEmptyNodes(object: object) {
        return Object.keys(object)
            .filter(key => object.hasOwnProperty(key) && object[key] !== '')
            .reduce((result, key) => (result[key] = typeof object[key] === 'object' ? this.removeEmptyNodes(object[key]) : object[key], result), {});
    }
}
