import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export interface IInformationModalData {
    header: string;
    message: string;
    buttonText?: string;
    buttonClass?: string;
    headerClass?: string;
    showErrorIcon?: boolean;
}

@Component({
    standalone: true,
    selector: 'app-information-modal',
    templateUrl: './information-modal.component.html',
    styleUrl: './information-modal.component.scss',
    imports: [
        CommonModule,
        TranslateModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule
    ]
})
export class InformationModalComponent {
    header: string;
    message: string;
    buttonText = 'Cancel';
    buttonClass = 'bol-button-text bol-gray';
    headerClass = 'default';
    showErrorIcon = false;

    private readonly dialogRef = inject<MatDialogRef<IInformationModalData>>(MatDialogRef);
    private readonly data = inject<IInformationModalData>(MAT_DIALOG_DATA);

    public ngOnInit(): void {
        this.header = this.data.header;
        this.message = this.data.message;
        if (this.data.buttonText) {
            this.buttonText = this.data.buttonText;
        }
        if (this.data.buttonClass) {
            this.buttonClass = this.data.buttonClass;
        }
        if (this.data.headerClass) {
            this.headerClass = this.data.headerClass;
        }
        if (this.data.showErrorIcon) {
            this.showErrorIcon = this.data.showErrorIcon;
        }
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }
}

