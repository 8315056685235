<div class="filters-header">
    <div class="filters-settings">
        @if ((filterSettings$ | async) && (filterSettings$ | async).length > 0) {
            <div class="filter-field">
                <mat-form-field
                    class="bol-form-field default-mat-form-field"
                    data-cy="overviewFilters"
                >
                    <mat-label>{{ 'Filters' | translate }}</mat-label>
                    <mat-select
                        #filterSettingSelect
                        required
                        [formControl]="filterSettingControl"
                        (openedChange)="openedFilterSettingSelect($event)"
                    >
                        @for (filterSetting of filterSettings$ | async; track filterSetting.id) {
                            <mat-option [value]="filterSetting.id">
                                {{ filterSetting.displayName }}
                            </mat-option>
                        }
                    </mat-select>

                    @if (filterSettingControl.invalid) {
                        <mat-error translate>Field is required</mat-error>
                    }
                </mat-form-field>
            </div>
        }

        @if (!(filterSettings$ | async).length) {
            <div
                class="highlight-summary"
                data-cy="noFilterSelected"
            >
                <div class="no-filter-selected">{{ 'No filter selected' | translate }}</div>
            </div>
        }

        @if (selectedGroupingOptionType$ | async; as selectedOption) {
            <div
                class="summary"
                data-cy="groupingOptionSelectionHeader"
            >
                <div class="highlight-summary">{{ 'Grouping option' | translate }}</div>
                &nbsp;&nbsp;&nbsp;{{ selectedOption | translate }}
            </div>
        }
    </div>
    <div
        class="manage_buttons"
        [style.padding-top.px]="(filterSettings$ | async).length === 0 ? 20 : 0"
    >
        <app-schedule-filters-settings></app-schedule-filters-settings>
    </div>
</div>
