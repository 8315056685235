import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-entity-filter-panel',
    templateUrl: './entity-filter-panel.component.html',
    styleUrls: ['./entity-filter-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityFilterPanelComponent {
    @Input() public disableCollapse: boolean = false;
    @Input() public cardClass?: string;
}
