import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';

export interface SkillLevelsManagementState {
    selectedSkillLevelId: number;
    selectedOrganizationUnitId: number;
    manageMode: MANAGE_MODE;
}

const initialState = {
    selectedSkillLevelId: undefined,
    selectedOrganizationUnitId: undefined,
    manageMode: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'skill-levels-management' })
export class SkillLevelsManagementStore extends Store<SkillLevelsManagementState> {
    constructor() {
        super(initialState);
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.update(state => ({ ...state, selectedOrganizationUnitId: id }));
    }

    public updateSelectedSkillLevelId(id: number): void {
        this.update(state => ({ ...state, selectedSkillLevelId: id }));
    }

    public updateManageMode(manageMode: MANAGE_MODE): void {
        this.update(state => ({ ...state, manageMode }));
    }

    public setStateForNewSkillLevel(id: number): void {
        this.update(state => (
            { 
                ...state, 
                selectedSkillLevelId: id,
                manageMode: MANAGE_MODE.EDIT
            }
        ));
    }
}