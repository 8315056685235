/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnEntity, defaultColumnDefinition, EntityListColumnDefinition, SelectionClickEvent } from 'src/app/shared/models';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';

@Component({
    selector: 'app-entity-list-panel',
    templateUrl: './entity-list-panel.component.html',
    styleUrls: ['./entity-list-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityListPanelComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @Output() public readonly selectedEntityIdChanged = new EventEmitter<number>();
    @Output() public readonly selectionClickChanged = new EventEmitter<SelectionClickEvent>();
    @Output() public readonly selectAllClicked = new EventEmitter<boolean>();
    @Output() public readonly addEntityClicked = new EventEmitter<undefined>();

    @Input() public cardClass?: string;
    @Input() public readonly entityName: string;
    @Input() public readonly icon: string;
    @Input() public readonly selectAllDisabled: boolean;
    @Input() public readonly enableSelectAll: boolean = false;
    @Input() public readonly popoverMessage: string;
    @Input() public readonly columnDefinition: Array<EntityListColumnDefinition> = defaultColumnDefinition;
    @Input() public readonly loadingError: boolean;
    @Input() public readonly canAdd: boolean = true;
    @Input() public readonly selectedEntityId: number;
    @Input() public readonly dataCyLabel: string;
    @Input() public readonly mode: MANAGE_MODE;
    @Input() public readonly hideAddButton: boolean = false;
    @Input() public readonly showCheckboxes: boolean = false;
    @Input() public readonly showRadioButtons: boolean = false;
    @Input() public readonly searchProperties: Array<string>;
    @Input() private set entities(entities: Array<ColumnEntity>) {
        this.setEntities(entities);
    };

    public dataSource = new MatTableDataSource<ColumnEntity>();
    public displayedColumns = [];
    public selectedEntities: Array<ColumnEntity> = [];

    public ngOnInit(): void {
        this.displayedColumns = this.columnDefinition.map(column => column.entityProperty);
    }

    public ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    public onEntitySelected(id: number): void {
        this.selectedEntityIdChanged.emit(id);
    }

    public onAddEntityClicked(): void {
        this.addEntityClicked.emit(undefined);
    }

    public onCheckboxClick(event: MatCheckboxChange, entity): void {
        if (event.checked) {
            this.selectedEntities.push(entity);
        } else {
            const index = this.selectedEntities.findIndex(selectedEntity => selectedEntity === entity);
            if (index !== -1) {
                this.selectedEntities.splice(index, 1);
            }
        }
        
        this.selectionClickChanged.emit({ selected: event.checked, entity });
    }

    public onRadioButtonClick(event: MatRadioChange, entity): void { 
        if (!entity.disabled) {
            this.selectionClickChanged.emit({selected: event.value, entity});
        }
    }

    public searchEntities(filterValue: string): void {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    public onSelectAll(event: MatCheckboxChange): void {
        this.selectAllClicked.emit(event.checked);
    }

    public isAllSelected(): boolean {
        const numSelected = this.selectedEntities.length;
        const numRows = this.dataSource.filteredData.length;

        return numSelected === numRows;
    }

    private setEntities(entities: Array<ColumnEntity>): void {
        if (!!entities) {
            this.dataSource.data = entities;

            this.dataSource.filterPredicate = (entity, filter: string): boolean => {
                return this.searchProperties.some(col => 
                    entity[col]?.toLowerCase().includes(filter)
                );
            };
            this.selectedEntities = [];
            for (const entity of entities as any) {
                if (entity?.checked) {
                  this.selectedEntities.push(entity);
                }
            }
        }

        // If the selected entity cannot be found in the entities, deselect the entity
        if (this.mode !== MANAGE_MODE.ADD && this.selectedEntityId && !entities.find(entity => entity.id === this.selectedEntityId)) {
            this.selectedEntityIdChanged.emit(undefined);
        }

        // Navigate to the page of the selected element
        else {
            const indexOfSelectedElement = this.dataSource?.filteredData.findIndex(row => row.id === this.selectedEntityId);
            if (indexOfSelectedElement > -1 && this.paginator !== undefined) {
                const pageSize = this.paginator.pageSize;
                const pageNumberOfSelectedEntity = Math.ceil((indexOfSelectedElement + 1) / pageSize) - 1;

                this.paginator.pageIndex = pageNumberOfSelectedEntity;
                this.paginator.page.next({      
                    pageIndex: pageNumberOfSelectedEntity,
                    pageSize: this.paginator.pageSize,
                    length: this.paginator.length
                });
            }
        }
    }
}
