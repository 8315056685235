import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ColumnEntity, DEFAULT_COLUMN_OPTIONS, EntityListColumnDefinition } from 'src/app/shared/models';

interface SingleSelectTableColumnDefinition extends EntityListColumnDefinition {
    sort?: { direction: 'asc' | 'desc' };
    sortValue?: (element: any) => any;
    cell?: (element: any) => any;
}

@Component({
    standalone: true,
    selector: 'app-single-select-table',
    templateUrl: './single-select-table.component.html',
    styleUrls: ['./single-select-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,

        MatButtonModule,
        TranslateModule,
        MatOptionModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule
    ],
})
export class SingleSelectTableComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('matSelect') public matSelect: MatSelect;
    @ViewChild(MatSort) public sort: MatSort;

    @Output() public readonly selectedEntityIdChanged = new EventEmitter<number | string>();

    @Input() public readonly entityName: string = 'items';
    @Input() public entityProperty: string;
    @Input() public singleSelectTablePlaceholder: string;
    @Input() public searchFieldPlaceholder: string = 'Search';
    @Input() public columnDefinition: Array<SingleSelectTableColumnDefinition>;
    @Input() public removeUnderlinePadding: boolean = false;
    @Input() public stripedTable: boolean = false;
    @Input() public readonly loadingError: boolean;
    @Input() public required: boolean = true;
    @Input() public errorMessage: string = 'This field is required';
    @Input() public searchProperties: Array<string>;
    @Input() private set disable(value: boolean) {
        if (value) {
            this.entitySelectControl.disable();
        } 
    }
    @Input() private set selectedEntityId(id: number | string) {
        this.setSelectedEntity(id);
    };
    @Input() public set entities(entities: Array<ColumnEntity>) {
        this.setEntities(entities);

        if (this.internalSelectedEntityId) {
            this.setSelectedEntity(this.internalSelectedEntityId);
        }
    };

    public searchControl = new UntypedFormControl('');
    public entitySelectControl = new UntypedFormControl('');
    public placeholderText: string;
    public floatLabel = false;
    public internalSelectedEntityId = undefined;
    
    public dataSource = new MatTableDataSource<ColumnEntity>();
    public displayedColumns = [];
    
    private readonly subscription: Subscription = new Subscription();

    constructor(
        @Inject(DEFAULT_COLUMN_OPTIONS) columnDefinitionOptions: EntityListColumnDefinition
    ){
        if (!this.columnDefinition && columnDefinitionOptions) {
            this.columnDefinition = [columnDefinitionOptions];
        }

        if (!this.entityProperty && columnDefinitionOptions.entityProperty) {
            this.entityProperty = columnDefinitionOptions.entityProperty;
        }

        if (!this.searchProperties && columnDefinitionOptions.entityProperty) {
            this.searchProperties = [columnDefinitionOptions.entityProperty];
        }
    }

    public ngOnInit(): void {
        this.displayedColumns = this.columnDefinition.map(column => column.entityProperty);

        this.subscription.add(
            this.searchControl.valueChanges.subscribe(entityFilterValue => {
                this.dataSource.filter = entityFilterValue.trim().toLowerCase();
            })
        );
    }

    public ngAfterViewInit() {
        this.subscription.add(
            this.matSelect.openedChange.subscribe(panelOpen => {
                if (!panelOpen) {
                    this.onResetValue();

                    this.columnDefinition.forEach(key => {
                        if (!!key.sort) {
                            (this.sort.sortables.get(key.entityProperty) as MatSortHeader)._toggleOnInteraction();
                        }
                    });
                } else {
                    this.columnDefinition.forEach(key => {
                        if (!!key.sort) {
                            this.sort.sort({ id: key.entityProperty, start: key.sort.direction, disableClear: false });
                            this.sort.direction = key.sort.direction;
                            this.sort.start = key.sort.direction;
                            (this.sort.sortables.get(key.entityProperty) as MatSortHeader)._sort.sortChange.next(this.sort);
                        }
                    });
                }
                this.dataSource.sort = this.sort;
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onEntitySelected(entity: ColumnEntity): void {
        this.selectedEntityIdChanged.emit(entity.id);
        this.internalSelectedEntityId = entity.id;

        this.matSelect.close();
        this.setPlaceholderText(entity);
        this.onResetValue();
    }

    public onResetValue(): void {
        this.searchControl.setValue('');
    }

    private setPlaceholderText(entity: any): void {
        if (!entity) {
            this.placeholderText = undefined;
            this.floatLabel = false;
            this.entitySelectControl.setValue(undefined);

            return;
        }

        this.placeholderText = entity[this.entityProperty];
        this.floatLabel = true;
        this.entitySelectControl.setValue([entity.id]);
    }

    private setEntities(entities: Array<ColumnEntity>): void {
        if (!!entities) {
            this.dataSource.data = entities;

            this.dataSource.filterPredicate = (entity, filter: string): boolean => {
                return this.searchProperties.some(col =>
                {
                    const columnConfig = this.columnDefinition.find(c => c.entityProperty === col);
                    if (columnConfig && columnConfig.cell) {
                        return columnConfig.cell(entity)?.toString().toLowerCase().includes(filter);
                    } else {
                        return entity[col]?.toString().toLowerCase().includes(filter);
                    }
                });
            };

            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        }
    }

    private setSelectedEntity(id: number | string): void {
        this.internalSelectedEntityId = id;

        if (this.dataSource.data) {            
            const selectedEntity = this.dataSource.data.find(el => el.id === this.internalSelectedEntityId);
                
            if (selectedEntity) {
                this.setPlaceholderText(selectedEntity);
            } else {
                this.setPlaceholderText(undefined);
            }
        }
    }

    private readonly sortingDataAccessor = (data: any, sortHeaderId: string): string => {
        const sortHeaderIds: Array<string> = sortHeaderId.split('.');
        let dataValue = { ...data };
        sortHeaderIds.forEach((id: string) => dataValue = dataValue ? dataValue[id] : dataValue);

        const columnConfig = this.columnDefinition.find(c => c.entityProperty === sortHeaderId);
        if (columnConfig && columnConfig.sortValue) {
            return columnConfig.sortValue(data);
        }

        if (typeof dataValue === 'string') {
            return dataValue.toLocaleLowerCase();
        }

        return data[sortHeaderId];
    };
}
