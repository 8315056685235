/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewChecked, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { MANAGE_MODE } from 'src/app/shared/models/Enums';
import { DialogService } from 'src/app/shared/services';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';

@Component({
    selector: 'app-entity-manage-panel',
    templateUrl: './entity-manage-panel.component.html',
    styleUrls: ['./entity-manage-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityManagePanelComponent implements OnInit, AfterViewChecked {
    @Output() public readonly editEntity = new EventEmitter<any>();
    @Output() public readonly addNewEntity = new EventEmitter<any>();
    @Output() public readonly deleteEntity = new EventEmitter<number>();
    @Output() public readonly cloneEntity = new EventEmitter<number>();
    @Output() public readonly cancelAdd = new EventEmitter<any>();

    @Input() public readonly mode: MANAGE_MODE;
    @Input() public readonly canDelete: boolean = true;
    @Input() public readonly canClone: boolean = false;
    @Input() public readonly entityName: string;
    @Input() public readonly uiEntity: EntityUI;
    @Input() public readonly message: string;
    @Input() public readonly messageStyleClass: string;
    @Input() private set model(value: any) {
        this.onInputModelChanged(value);
    }
    @Input() public set formFields(value: FormlyFieldConfig) {
        this.onFormlyFieldChanged(value);
    }
    public form = new UntypedFormGroup({});
    public internalModel: any;
    public entity: any;
    public manageMode = MANAGE_MODE;
    public prefixedEntityName: string;
    public internalFormFields: FormlyFieldConfig;
    public details: Observable<string>;

    constructor(
        private readonly utilsService: UtilsService,
        private readonly translateService: TranslateService,
        private readonly dialogService: DialogService
    ) { }

    public ngOnInit(): void {
        this.prefixedEntityName = 'MANAGE-NAME.' + this.entityName.toUpperCase();
    }

    public ngAfterViewChecked(): void {
        if (this.mode === MANAGE_MODE.VIEW) {
            this.form.disable({ emitEvent: false });
        } else {
            this.form.enable({ emitEvent: false });
        }
    }

    public onModelChanged(): void {
        if (this.form.valid && this.mode === MANAGE_MODE.EDIT) {
            this.editEntity.emit(this.internalModel);
        }
    }

    public onAddEntity(): void {
        if (this.form.valid) {
            this.addNewEntity.emit(this.internalModel);
        }
    }

    public onCancelAdd(): void {
        this.cancelAdd.emit();
    }

    public onDelete(): void {
        const lowerCaseEntityName = this.entityName.toLowerCase();
        const title = this.translateService.instant('Delete') + ' ' + this.translateService.instant(lowerCaseEntityName);
        const message = this.translateService.instant('DELETE-MESSAGE', { entityName: this.translateService.instant(lowerCaseEntityName) });
        const cancelButton = this.translateService.instant('Cancel');
        const deleteButton = this.translateService.instant('Delete');

        this.dialogService.showConfirmDialog(title, message, deleteButton, cancelButton).pipe(
            first(),
            map(confirm => {
                if (confirm) {
                    this.deleteEntity.emit(this.internalModel.id);
                }
            })
        ).subscribe();
    }

    public onClone(): void {
        this.cloneEntity.emit(this.internalModel.id);
    }

    private onInputModelChanged(model: any) {
        // we need to unfreeze the model so formly can alter it
        this.entity = model;
        this.internalModel = this.utilsService.deepCopy(model);

        this.form.markAsUntouched();
        this.form = new UntypedFormGroup({});
        this.onFormlyFieldChanged(this.internalFormFields);
    }

    private onFormlyFieldChanged(value: FormlyFieldConfig) {
        this.internalFormFields = value;
        const firstField = this.internalFormFields?.[0];
        if (firstField !== undefined && !firstField.focus) {
            firstField.focus = true;
        }
    }
}
