<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel [disableCollapse]="true" [cardClass]="'top-card'">
                    <app-filter-tree-single-select
                        [entities]="organizationsForFiltering$ | async"
                        [labelText]="'Organization unit'"
                        [selectedEntityId]="selectedOrganizationUnit$ | async"
                        (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
                        [dataCyLabel]="'organizationUnitFilter'"
                    ></app-filter-tree-single-select>

                    <app-filter-tree-single-select
                        [entities]="activityTypeCategories$ | async"
                        [labelText]="'Activity type categories'"
                        [selectedEntityId]="selectedActivityTypeCategory$ | async"
                        (selectedEntityIdChanged)="onSelectActivityTypeCategoryChange($event)"
                        [dataCyLabel]="'activityTypeCategoryFilter'"
                    ></app-filter-tree-single-select>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showUnderlyingUnits$ | async"
                            (change)="onUpdateShowChildUnits($event)"
                            data-cy="showActivityTypesForUnderlyingOrganizationUnits"
                        >
                            {{ 'Also show activity types for underlying organization units' | translate }}
                        </mat-checkbox>
                    </div>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showPastActivityTypes$ | async"
                            (change)="onUpdateShowPastActivityTypes($event)"
                            data-cy="showActivityTypesValidInPast"
                        >
                            {{ 'Show activity types that were valid in the past' | translate }}
                        </mat-checkbox>
                    </div>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showFutureActivityTypes$ | async"
                            (change)="onUpdateShowFutureActivityTypes($event)"
                            data-cy="showActivityTypesValidInFuture"
                        >
                            {{ 'Show activity types that will become valid in the future' | translate }}
                        </mat-checkbox>
                    </div>
                </app-entity-filter-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel [disableCollapse]="true" [cardClass]="'top-card'">
                    <app-filter-tree-single-select
                        [entities]="owsDutySets$ | async"
                        [labelText]="'Duty sets'"
                        [selectedEntityId]="selectedDutySet$ | async"
                        [dataCyLabel]="'dutySetFilter'"
                        (selectedEntityIdChanged)="onSelectedDutySetChange($event)"
                    ></app-filter-tree-single-select>

                    @if (visibleOwsDuties$ | async) {
                        <div>
                            <div>
                                <mat-checkbox
                                    class="bol-checkbox"
                                    [checked]="hideUnlinkedOwsDuties$ | async"
                                    (change)="toggleUnlinkedOwsDutyVisibility()"
                                    data-cy="hideLinkedDuties"
                                >
                                    {{ 'Hide linked Duties' | translate }}
                                </mat-checkbox>
                            </div>

                            <div class="unlinked__container">
                                <span class="unlinked__title">{{ 'Unlinked Duties' | translate }}:</span>
                                <div
                                    class="bol-badge-solid"
                                    [ngClass]="{
                                        'bol-warning': (unlinkedDutiesCount$ | async) > 0,
                                        'bol-primary': (unlinkedDutiesCount$ | async) === 0,
                                    }"
                                    data-cy="numberUnlinkedDuties"
                                >
                                    {{ unlinkedDutiesCount$ | async }}
                                </div>
                            </div>
                        </div>
                    }

                    @if ((selectedActivityTypeId$ | async) === undefined && (visibleOwsDuties$ | async)?.length > 0) {
                        <div data-cy="noSelectionWarningMessage">
                            <mat-icon
                                svgIcon="alert"
                                class="inline-icon"
                            ></mat-icon>
                            <span
                                class="bol-text"
                                translate
                            >
                                Please select an OMRP Activity Type from the left panel to map to!
                            </span>
                        </div>
                    }
                </app-entity-filter-panel>
            </div>
        </div>
    </div>
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OMRP Activity Types"
                    hideAddButton="true"
                    [entities]="visibleActivityTypes$ | async"
                    [selectedEntityId]="selectedActivityTypeId$ | async"
                    [searchProperties]="searchProperties"
                    [dataCyLabel]="'activityTypesOMRP'"
                    [cardClass]="'bottom-card'"
                    (selectedEntityIdChanged)="onSelectActivityType($event)"
                >
                </app-entity-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OWS Duties"
                    showCheckboxes="true"
                    hideAddButton="true"
                    [entities]="visibleOwsDuties$ | async"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'dutiesOWS'"
                    [cardClass]="'bottom-card'"
                    (selectionClickChanged)="onSelectOwsDutyForMapping($event)"
                >
                </app-entity-list-panel>
            </div>
        </div>
    </div>
</div>

