<mat-menu 
    #optionsMenu="matMenu"
    class="bol-menu"
>
    <ng-template matMenuContent>
        <button mat-menu-item (click)="onEditTimeslots()">
            <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.ACTIVITY-MENU.EDIT-TIMESLOTS</span>
        </button>
        <button mat-menu-item (click)="onEditDistribution()">
            <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.ACTIVITY-MENU.EDIT-DISTRIBUTION</span>
        </button>
        <button mat-menu-item (click)="onCopyDistribution()">
            <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.ACTIVITY-MENU.COPY-DISTRIBUTION</span>
        </button>
        <button mat-menu-item (click)="onSelectPreferredResources()">
            <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.ACTIVITY-MENU.SELECT-PREFERRED-RESOURCES</span>
        </button>
        <button mat-menu-item (click)="onDeleteActivity()">
            <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.ACTIVITY-MENU.DELETE</span>
        </button>
    </ng-template>
</mat-menu>