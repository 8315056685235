import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { Memoized } from '@ortec/utilities/core';
import { Observable, Subject, Subscription, filter, first, mergeMap } from 'rxjs';

import { MANAGE_MODE } from 'src/app/shared/models/Enums';
import { ManageTemplateQuery } from 'src/app/shared/stores/manage-template-store/manage-template.query';
import { ManageTimeslotQuery } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.query';
import { ManageTimeslotService } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.service';
import { AdtActivityTypeTimeslotsDialogComponent, IActivityTypeTimeslotsDialogCloseData, IActivityTypeTimeslotsDialogData } from '../adt-activity-type-timeslots-dialog/adt-activity-type-timeslots-dialog.component';
import { AdtPreferredResourceDialogComponent, IPreferredResourceDialogData } from '../adt-preferred-resource-dialog/adt-preferred-resource-dialog.component';
import { ActivityTypeTimeslot } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { EditTemplatesManagementService } from '../../store';

@Component({
    selector: 'app-adt-activity-type-table-menu',
    templateUrl: './adt-activity-type-table-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdtActivityTypeTableMenuComponent implements OnInit {
    @Input({ required: true }) public readonly activityTypeTimeslot?: ActivityTypeTimeslot;
    @ViewChild(MatMenu) public readonly menu: MatMenu;

    private currentTemplateId?: number;
    private onEditTimeslotsClickedSubject = new Subject<void>();
    private readonly subscriptions = new Subscription();

    private readonly manageTimeslotService = inject(ManageTimeslotService);
    private readonly manageTemplateQuery = inject(ManageTemplateQuery);
    private readonly manageTimeslotQuery = inject(ManageTimeslotQuery);
    private readonly editTemplatesManagementService = inject(EditTemplatesManagementService);
    private readonly dialogService = inject(MatDialog);
    
    public ngOnInit(): void {
        this.currentTemplateId = this.manageTemplateQuery.getSelectedTemplateForEditSync().id;
        this.subscriptions.add(
            this.updateEditedTimeslotsAfterDialogClose()
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onEditTimeslots(): void {
        this.editTemplatesManagementService.updateSelectedActivityTimeslotId(this.activityTypeTimeslot?.id);
        this.onEditTimeslotsClickedSubject.next();
    }

    public onEditDistribution(): void {
        console.log(this.activityTypeTimeslot);
    }

    public onCopyDistribution(): void {
        console.log(this.activityTypeTimeslot);
    }

    public onSelectPreferredResources(): void {
        this.editTemplatesManagementService.updateSelectedActivityTimeslotId(this.activityTypeTimeslot?.id);

        // NOTE: there are no "close" actions in this dialog, everything is handled by the dialog itself
        this.dialogService.open(AdtPreferredResourceDialogComponent, {
            data: {
                activityTypeTimeslot: this.activityTypeTimeslot,
                selectedTemplateId: this.currentTemplateId
            } as IPreferredResourceDialogData,
            width: '1000px',
            autoFocus: false,
        });
    }

    public onDeleteActivity(): void {
        this.manageTimeslotService.deleteActivityDemandTimeslots(this.activityTypeTimeslot?.id, this.currentTemplateId).pipe(first()).subscribe();
    }

    @Memoized private get editTimeslotsDialogResponse$(): Observable<{ data: IActivityTypeTimeslotsDialogCloseData}> {
        return this.onEditTimeslotsClickedSubject.pipe(
            mergeMap(() => this.dialogService.open(AdtActivityTypeTimeslotsDialogComponent, {
                data: {
                    selectedActivityTypeId: this.activityTypeTimeslot?.activityTypeInfo?.id,
                    manageMode: MANAGE_MODE.EDIT
                } as IActivityTypeTimeslotsDialogData,
                width: '600px',
                autoFocus: false,
            }).afterClosed())
        );
    }

    private updateEditedTimeslotsAfterDialogClose(): Subscription {
        return this.editTimeslotsDialogResponse$.pipe(
            filter((dialogData) => !!dialogData)
        ).subscribe((dialogData: { data: IActivityTypeTimeslotsDialogCloseData }) => {
            const updatedTimeslots = this.manageTimeslotQuery.getUpdatedActivityTypeTimeslotsSync(dialogData.data.selectedTimeslotIds, dialogData.data.selectedActivityTypeId);

            if (updatedTimeslots.addedTimeslots.length > 0) {
                this.manageTimeslotService.updateActivityDemandTimeslots(updatedTimeslots.addedTimeslots, this.currentTemplateId).pipe(first()).subscribe();
            }

            if (updatedTimeslots.deletedTimeslotIds.length > 0) {
                updatedTimeslots.deletedTimeslotIds.forEach(timeslotId => {
                    this.manageTimeslotService.deleteActivityDemandTimeslots(timeslotId, this.currentTemplateId).pipe(first()).subscribe();
                });
            }
        });
    }
}
