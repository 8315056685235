import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-field-info-block',
    template: `
       <div class="form-info-block">{{props.label | translate}}</div>
    `,
    styleUrls: ['./formly-field-info-block.component.scss']
})
// tslint:disable-next-line: component-class-suffix
export class FormlyFieldInfoBlock extends FieldType  {
}
