<div class="filter-settings-managing">
    @if ((filterSettings$ | async).length > 0) {
        <button
            mat-button
            class="bol-button-outlined default-button manage-filters"
            (click)="onDisplayFilterSettingConfigurationDialog()"
            data-cy="manageFiltersButton"
        >
            <mat-icon svgIcon="cog"></mat-icon>
            <span translate>Manage filters</span>
        </button>
    }

    @if (filterChangesInPending$ | async) {
        <button
            mat-button
            class="bol-button-outlined default-button"
            (click)="onDisplayFilterSettingSaveDialog()"
        >
            <mat-icon svgIcon="plus-circle-outline"></mat-icon>
            @if (!(selectedFilterSettingId$ | async)) {
                <span
                    translate
                    data-cy="saveNewFilterButton"
                >
                    Save new filter
                </span>
            }
            @else {
                <span
                    translate
                    data-cy="saveFilterChangesButton"
                >
                    Save filter changes
                </span>
            }
        </button>
    }
</div>
