<mat-card class="bol-card content-wrapper">
    <mat-card-content>
        <div class="content-wrapper">
            <div class="flex-wrapper-card">
                @for (item of menuItems; track $index) {
                    <menu-card
                        [url]="item.url"
                        [title]="item.title"
                        [icon]="item.icon"
                    >
                    </menu-card>
                }
            </div>
        </div>
    </mat-card-content>
</mat-card>

