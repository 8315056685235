<div class="bol-modal__header">
    <span class="modal-title">{{ 'Select activity types' | translate }}</span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    <div>
        <mat-form-field class="bol-form-field">
            <mat-label>{{ 'Activity type categories' | translate }}</mat-label>
            <bol-select
                required
                [selectionMode]="'multiple'"
                [control]="activityTypeCategoryFormControl"
                [items]="activityTypeCategories$ | async"
                [isSearchable]="true"
                [textConfiguration]="selectLabels$ | async"
            >
            </bol-select>
            @if (activityTypeCategoryFormControl.invalid) {
                <mat-error>{{ 'Field is required' | translate }}</mat-error>
            }
        </mat-form-field>
    </div>

    <div class="activity__type__filter">
        <mat-form-field class="bol-form-field">
            <mat-label>{{ 'Activity type filter' | translate }}</mat-label>
            <input
                matInput
                type="text"
                [formControl]="filterInputControl"
            />
        </mat-form-field>
    </div>

    @if (filteredActivityTypes$ | async; as activityTypes) {
        <div class="checkbox__container">
            <mat-checkbox
                class="bol-checkbox bol-primary mapping-checkbox"
                [checked]="selectAllState"
                [disabled]="activityTypes.length === 0"
                [(ngModel)]="selectAllState"
                (click)="updateActivityTypeStates(activityTypes, selectAllState, false)"
            ></mat-checkbox>
            <div class="select__all__description">
                <span translate>Select all</span>&nbsp;
                <span>
                    {{ '(' + activityTypes.length + ')' }}
                </span>
            </div>
        </div>
    }
    <br />

    <div class="activity__type__container">
        @for (activityType of filteredActivityTypes$ | async | slice: startIndex : endIndex; track activityType.id) {
            <div class="checkbox__container">
                <mat-checkbox
                    class="bol-checkbox bol-primary mapping-checkbox"
                    [checked]="activityType.state"
                    (click)="updateActivityTypeStates([activityType], !activityType.state, true)"
                >
                </mat-checkbox>
                <div class="activity__type__description">
                    <div
                        class="activity__type__short"
                        [style.color]="'#' + activityType.textColor"
                        [style.background-color]="'#' + activityType.backColor"
                    >
                        {{ activityType.shortName }}
                    </div>
                    {{ activityType.displayName }}
                </div>
            </div>
        }
    </div>

    @if (filteredActivityTypes$ | async; as activityTypes) {
        <div>
            <mat-paginator
                [length]="activityTypes.length"
                [pageSize]="10"
                (page)="getPaginatorData($event)"
            >
            </mat-paginator>
        </div>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            class="bol-button-solid bol-positive"
            (click)="onConfirm()"
            data-cy="activityTypeDialogConfirmButton"
        >
            <span translate>Confirm</span>
        </button>
    </div>
</mat-dialog-actions>
