import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
    public getHttpParamByName(name, url = window.location.href): string {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results) { return undefined ; }
        if (!results[2]) { return ''; }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
}
