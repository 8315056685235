
import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-test-border',
  templateUrl: './test-border.component.html',
  styleUrls: ['./test-border.component.scss'],
  imports: []
})
export class TestBorderComponent {
}
