import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { filterUndefined } from 'src/app/core/rxjs-utils';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { UsersManagementQuery } from '../../store/users-management.query';
import { UsersManagementService } from '../../store/users-management.service';

@Component({
    selector: 'app-users-filters',
    templateUrl: './users-filters.component.html',
    styleUrls: ['./users-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersFiltersComponent implements OnInit {
    public organizationUnits$: Observable<Array<IOrganizationUnitTree>>;
    public onlyShowWithoutUserGroups$: Observable<boolean>;
    public selectedOrganizationUnit$: Observable<number>;
    public showUnderlyingUnits$: Observable<boolean>;
    public showAllUsers$: Observable<boolean>;

    private readonly organizationUnitService = inject(OrganizationUnitService);
    private readonly organizationUnitQuery = inject(OrganizationUnitQuery);
    private readonly displaySettingQuery = inject(DisplaySettingQuery);
    private readonly displaySettingService = inject(DisplaySettingService);
    private readonly usersManagementService = inject(UsersManagementService);
    private readonly usersManagementQuery = inject(UsersManagementQuery);

    public ngOnInit(): void {
        this.organizationUnitService.get().pipe(first()).subscribe();

        this.organizationUnits$ = this.organizationUnitQuery.getOrganizationsForFiltering();
        this.selectedOrganizationUnit$ = this.usersManagementQuery.getSelectedOrganizationUnit();
        this.showUnderlyingUnits$ = this.usersManagementQuery.getShowUnderlyingUnitsState();
        this.onlyShowWithoutUserGroups$ = this.usersManagementQuery.getShowWithoutUserGroupsState();
        this.showAllUsers$ = this.usersManagementQuery.getShowAllUsersState();

        this.setInitialStatesFromDisplaySettings();
    }

    public onSelectedOrganizationUnitChange(id: number): void {
        this.usersManagementService.updateSelectedOrganizationUnitId(id);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, id).pipe(first()).subscribe();
    }

    public onUpdateShowChildUnits(event: MatCheckboxChange): void {
        this.usersManagementService.updateShowChildUnits(event.checked);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, event.checked).pipe(first()).subscribe();
    }

    public onUpdateShowWithoutUserGroups(event: MatCheckboxChange): void {
        this.usersManagementService.updateShowWithoutUserGroups(event.checked);
    }

    public onUpdateShowAllUsers(event: MatCheckboxChange): void {
        this.usersManagementService.updateShowAllUsers(event.checked);
    }

    private setInitialStatesFromDisplaySettings(): void {
        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, 'number').pipe(
            filterUndefined(), 
            first(),
            tap(id => this.usersManagementService.updateSelectedOrganizationUnitId(id))
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => this.usersManagementService.updateShowChildUnits(state))
        ).subscribe();
    }
}
