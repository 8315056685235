<div
    class="event-container"
    (click)="onOpenDetailDialog()"
>
    @for (act of allLeaves; track act; let last = $last; let first = $first) {
        <div
            class="event-element"
            [bolPopoverHover]="popoverHover"
            [bolPopoverDisabled]="!hasMemo && !hasDescription"
            [class.event-element__root]="allLeaves.length > 1 && first"
            [class.has-memo]="hasMemo && last"
            [style.background]="'#' + act?.activityType?.backColor"
            [style.color]="'#' + act?.activityType?.textColor"
        >
            {{ act?.name }}
        </div>
    }
</div>

<ng-template #popoverHover>
    <div class="activity-popover">
        @for (act of activitiesWithMemoAndDescription; track act.id; let last = $last; let first = $first) {
            <div [class.activity-details]="!last && !!act.memo">
                @if (!!act?.description && first) {
                    <div
                        class="bol-text bol-text-bold"
                        [ngClass]="{ 'description-row': !!act?.description && !!act?.memo }"
                    >
                        {{ act?.description }}
                    </div>
                }
                @if (!!act?.memo && (!!act?.rootId || (act?.rootId === null && !!act?.resourceId))) {
                    <div class="resource-name-row">{{ act?.resource }}</div>
                }
                @if (!!act?.memo) {
                    <div class="memo-row bol-text">{{ act?.memo }}</div>
                }
            </div>
        }
    </div>
</ng-template>
