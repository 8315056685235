<mat-expansion-panel
    hideToggle="true"
    class="expansion-panel mat-elevation-z"
    [expanded]="true"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
>
    <mat-expansion-panel-header
        class="header-container"
        data-cy="PermissionTreeHeader"
    >
        <mat-panel-title>
            <div class="header-title">{{ props.title | translate }}</div>
        </mat-panel-title>

        <mat-panel-description>
            <mat-icon [svgIcon]="panelOpenState ? 'menu-down' : 'menu-up'"></mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>

    @if (entities$ | async; as entities) {
        <app-permissions-tree
            [formlyAttributes]="field"
            [entityName]="props.entityName"
            [entities]="entities"
            [disabledPermissions]="props.disabled"
            [initialEntitiesWithPermissions]="initialEntitiesWithPermissions"
            [columnDefinition]="props.columnDefinition"
            (permissionItemsChanged)="onPermissionItemsChanged($event)"
        >
        </app-permissions-tree>
    }
</mat-expansion-panel>

