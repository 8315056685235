import { Inject, Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import { CallbacksPerMessageType, BROADCAST_SETTING, BroadcastMessage } from './broadcast-declarations';

@Injectable({
    providedIn: 'root'
  })
export class BroadcastService {
  private broadcastChannel: BroadcastChannel;

  constructor(
    @Inject(BROADCAST_SETTING) callbacksPerMessageType: CallbacksPerMessageType,
    private zone: NgZone,
  ) {
    // NOTE: BroadCast doesn't work in Safari, try/catch added to remove error
    // This does mean that broadcast will not work in Safari at all
    try {
      this.broadcastChannel = new BroadcastChannel('broadcast');
      this.broadcastChannel.onmessage = (messageEvent) => {
        const message: BroadcastMessage = messageEvent.data;
        for (const key in callbacksPerMessageType) {
          if (message.type === key) {
            this.zone.run(() => callbacksPerMessageType[key](message));
          }
        }
      };
    }
    catch {
      this.broadcastChannel = null;
    }
  }

  public publish(message: BroadcastMessage): void {
    this.broadcastChannel?.postMessage(message);
  }
}
