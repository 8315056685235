<div
    class="interval-item"
    data-cy="intervalItem"
>
    <div class="interval-start">
        <mat-form-field class="bol-form-field date-input dynamic-subscript-height">
            <mat-label translate>Start time</mat-label>
            <input
                matInput
                bolTimeInput
                required
                autocomplete="off"
                [formControl]="formControls.startMinutes"
                [bolTimeAutocomplete]="autocompleteFrom"
                data-cy="ResourceImportsStartTime"
            />
            <mat-icon
                matSuffix
                svgIcon="clock-outline"
            ></mat-icon>
            @if (formControls.startMinutes.errors?.required) {
                <mat-error translate>{{ requiredErrorMessage }}</mat-error>
            }
            <bol-time-autocomplete
                #autocompleteFrom="bolTimeAutocomplete"
                [timeValues]="timeRange$ | async"
            ></bol-time-autocomplete>
        </mat-form-field>
    </div>

    <div class="interval-end">
        <mat-form-field class="bol-form-field date-input dynamic-subscript-height">
            <mat-label translate>End time</mat-label>
            <input
                matInput
                bolTimeInput
                required
                autocomplete="off"
                [formControl]="formControls.endMinutes"
                [bolTimeAutocomplete]="autocompleteFrom"
                data-cy="ResourceImportsEndTime"
            />
            <mat-icon
                matSuffix
                svgIcon="clock-outline"
            ></mat-icon>
            @if (formControls.endMinutes.errors?.required) {
                <mat-error translate>{{ requiredErrorMessage }}</mat-error>
            }
            @if (formControls.endMinutes.errors?.startEndMinuteValidator) {
                <mat-error translate>{{ startTimeAfterEndTime }}</mat-error>
            }
            <bol-time-autocomplete
                #autocompleteFrom="bolTimeAutocomplete"
                [timeValues]="timeRange$ | async"
            ></bol-time-autocomplete>
        </mat-form-field>
    </div>

    <div class="interval">
        <mat-form-field class="bol-form-field dynamic-subscript-height">
            <mat-label>Interval (min)</mat-label>
            <input
                matInput
                type="number"
                name="interval"
                placeholder="{{ 'Interval (min)' | translate }}"
                [formControl]="formControls.interval"
                data-cy="intervalDuration"
            />
            @if (formControls.interval.errors?.required) {
                <mat-error translate>{{ requiredErrorMessage }}</mat-error>
            }
            @if (formControls.interval.errors?.min) {
                <mat-error translate>{{ 'Invalid duration format' }}</mat-error>
            }
            @if (formControls.interval.errors?.max) {
                <mat-error translate>{{ intervalExceedsAmountOfMinutes }}</mat-error>
            }
        </mat-form-field>
    </div>

    <div class="delete-interval-button">
        <button
            mat-button
            class="bol-button-text icon-only bol-negative"
            (click)="onDeleteImport(interval)"
            data-cy="deleteEntityButton"
        >
            <mat-icon svgIcon="delete"></mat-icon>
        </button>
    </div>
</div>
