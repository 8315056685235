import { Injectable } from '@angular/core';

import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeCategory } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.model';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { Resource } from 'src/app/shared/stores/resource-store/resource.model';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { Skill } from 'src/app/shared/stores/skill-store/skill.model';
import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';
import { NestedTreeService } from 'src/app/shared/services';
import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';
import { Daypart } from 'src/app/shared/stores/day-part-store/day-part.model';

import { CountersQuery } from '../stores/counters-store/counters.query';
import { CounterData } from '../stores/counters-store/counters-matrix.models';
import { COUNTERS_EXTRA_GROUPING_OPTIONS, COUNTERS_GROUPING_OPTIONS } from './enums';

@Injectable({
    providedIn: 'root'
})
export class CountersResourceTreeHelperService {

    constructor(
        protected countersQuery: CountersQuery,
        protected resourceQuery: ResourceQuery,
        protected daypartQuery: DaypartQuery,
        private readonly nestedTreeService: NestedTreeService
    ) { }

    public getGroupingOptionCounters(groupingOption: COUNTERS_GROUPING_OPTIONS): Array<CounterData> {
        switch (groupingOption) {
            case COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT: {
                const organizationUnits = this.countersQuery.getSelectedOrganizationUnitsSync();
                const mutableUnits = organizationUnits.map(unit => ({ ...unit }));
                const nestedOrganizationUnits = this.nestedTreeService.nestArray(mutableUnits as Array<IOrganizationUnitTree>);

                return this.getNestedOrganizationUnitsToNestedCounters(nestedOrganizationUnits as Array<IOrganizationUnitTree>);
            }

            case COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE_CATEGORY: {
                const activityTypesCategories = this.countersQuery.getSelectedActivityTypeCategoriesSync();

                return this.transformActivityTypeCategoriesToCounterFormat(activityTypesCategories);
            }

            case COUNTERS_GROUPING_OPTIONS.RESOURCE_TYPE: {
                const resourceTypes = this.countersQuery.getSelectedResourceTypesSync();

                return this.transformResourceTypesToCounterFormat(resourceTypes);
            }

            case COUNTERS_GROUPING_OPTIONS.SKILL: {
                const skills = this.countersQuery.getSelectedSkillsSync();

                return this.transformSkillsToCounterFormat(skills);
            }

            case COUNTERS_GROUPING_OPTIONS.DAY_PART: {
                const dayparts = this.daypartQuery.getDaypartsWithEndSync();

                return this.transformDaypartsToCounterFormat(dayparts);
            }

            case COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE: {
                return this.getActivityCounters();
            }
        }
    }

    public getResourceCounters(): Array<CounterData> {
        const resources = this.resourceQuery.getResourcesSync();

        return this.transformResourcesToCounterFormat(resources);
    }

    public getActivityCounters(): Array<CounterData> {
        const activityTypes = this.countersQuery.getSelectedActivityTypesSync();

        return this.transformActivityTypeToCounterFormat(activityTypes);
    }

    private transformResourceTypesToCounterFormat(entities: Array<ResourceType>): Array<CounterData> {
        return entities.map(entity => ({
            name: entity.displayName,
            groupingOptionType: COUNTERS_GROUPING_OPTIONS.RESOURCE_TYPE,
            resourceTypeId: entity.id,
            data: [],
            hasZeroValue: false
        }));
    }

    private transformActivityTypeCategoriesToCounterFormat(entities: Array<ActivityTypeCategory>): Array<CounterData> {
        return entities.map(entity => ({
            name: entity.displayName,
            groupingOptionType: COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE_CATEGORY,
            activityTypeCategoryId: entity.id,
            data: [],
            hasZeroValue: false
        }));
    }

    private transformSkillsToCounterFormat(entities: Array<Skill>): Array<CounterData> {
        return entities.map(entity => ({
            name: entity.displayName,
            groupingOptionType: COUNTERS_GROUPING_OPTIONS.SKILL,
            skillId: entity.id,
            data: [],
            hasZeroValue: false
        }));
    }

    private transformDaypartsToCounterFormat(entities: Array<Daypart>): Array<CounterData> {
        return entities.map(entity => ({
            name: entity.name,
            groupingOptionType: COUNTERS_GROUPING_OPTIONS.DAY_PART,
            data: [],
            daypartStart: entity.start,
            daypartEnd: entity.end,
            hasZeroValue: false
        }));
    }

    private transformResourcesToCounterFormat(entities: Array<Resource>): Array<CounterData> {
        return entities.map(entity => ({
            name: entity.displayName,
            groupingOptionType: COUNTERS_EXTRA_GROUPING_OPTIONS.RESOURCE,
            resourceId: entity.id,
            data: [],
            hasZeroValue: false
        }));
    }

    private transformActivityTypeToCounterFormat(entities: Array<ActivityType>): Array<CounterData> {
        return entities.map(entity => ({
            name: entity.displayName,
            groupingOptionType: COUNTERS_GROUPING_OPTIONS.ACTIVITY_TYPE,
            activityTypeCategoryId: entity.categoryId,
            activityTypeId: entity.id,
            data: [],
            hasZeroValue: false
        }));
    }

    private getNestedOrganizationUnitsToNestedCounters(organizationUnits: Array<IOrganizationUnitTree>): Array<CounterData> {
        return organizationUnits.map(unit => {
            const children = unit.children ? unit.children.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1) : undefined;
            
            return {
                name: unit.displayName,
                data: [],
                hasZeroValue: false,
                groupingOptionType: COUNTERS_GROUPING_OPTIONS.ORGANIZATION_UNIT,
                organizationUnitId: unit.id,
                children: children ? this.getNestedOrganizationUnitsToNestedCounters(children) : undefined,
                cssClassName: 'organization-unit-counter'
            };
        });
    }
}
