<mat-card
    class="bol-card full-height sorting-table"
    [style.display]="(tableRendered$ | async) === false ? 'none' : ''"
>
    <mat-card-content>
        <div class="sorting-buttons">
            <button
                mat-button
                class="bol-button-text icon-only"
                [disabled]="selectedEntityIds.length === 0"
                (click)="onMoveToTop(); $event.stopPropagation()"
                data-cy="moveToTopButton"
            >
                <mat-icon svgIcon="menu-up"></mat-icon>
            </button>
            <button
                mat-button
                class="bol-button-text icon-only"
                [disabled]="selectedEntityIds.length === 0"
                (click)="onMoveUp(); $event.stopPropagation()"
                data-cy="moveUpButton"
            >
                <mat-icon svgIcon="chevron-up"></mat-icon>
            </button>
            <button
                mat-button
                class="bol-button-text icon-only"
                [disabled]="selectedEntityIds.length === 0"
                (click)="onMoveDown(); $event.stopPropagation()"
                data-cy="moveDownButton"
            >
                <mat-icon svgIcon="chevron-down"></mat-icon>
            </button>
            <button
                mat-button
                class="bol-button-text icon-only"
                [disabled]="selectedEntityIds.length === 0"
                (click)="onMoveToBottom(); $event.stopPropagation()"
                data-cy="moveToBottomButton"
            >
                <mat-icon svgIcon="menu-down"></mat-icon>
            </button>
            <button
                mat-button
                class="bol-button-text icon-only"
                [disabled]="selectedEntityIds.length === 0"
                (click)="onSortAlphabetically(); $event.stopPropagation()"
                data-cy="sortAlphabeticallyButton"
            >
                <mat-icon svgIcon="sort-alphabetical-variant"></mat-icon>
            </button>
        </div>
        <section class="sorting-table-container">
            <mat-table
                class="multiselect-sorting-table bol-table bol-table-bordered-rows"
                [dataSource]="dataSource"
                [ngClass]="{ 'striped-table': stripedTable, 'narrow-rows': narrowRows }"
                (contentChanged)="hideLoader()"
                data-cy="multiselectSortingTable"
            >
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="checkbox-column">
                            <mat-checkbox
                                class="bol-checkbox"
                                [checked]="isAllSelected$ | async"
                                (change)="$event ? onSelectAll($event) : null"
                            >
                            </mat-checkbox>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <div class="checkbox-column">
                            @if (!row.hideCheckbox) {
                                <mat-checkbox
                                    class="bol-checkbox"
                                    [disabled]="row.disabled"
                                    [checked]="row.checked"
                                    (click)="$event.stopPropagation()"
                                    (change)="onCheckboxClick($event, row)"
                                >
                                </mat-checkbox>
                            }
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Dynamic Columns -->
                @for (column of columnDefinition; track $index) {
                    <ng-container [matColumnDef]="column.entityProperty">
                        <mat-header-cell
                            *matHeaderCellDef
                            [attr.data-cy]="column.header"
                        >
                            <div>
                                {{ column.columnDisplayName | translate }}
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <div
                                class="table-cell"
                                [ngStyle]="column.stylingProperty ? row[column.stylingProperty] : ''"
                            >
                                {{ column.cell ? column.cell(row) : row[column.entityProperty] }}
                                @if (column.showInfoIcon && row.showInfoIcon) {
                                    <div
                                        class="info-icon-sign"
                                        [bolPopoverHover]="popoverMessageHover"
                                        data-cy="sortTableInfoIcon"
                                    >
                                        <mat-icon svgIcon="information"></mat-icon>
                                    </div>
                                }
                            </div>
                            <ng-template #popoverMessageHover>
                                <div
                                    class="popover-message"
                                    translate
                                    data-cy="popoverMessage"
                                >
                                    {{ popoverMessage }}
                                </div>
                            </ng-template>
                        </mat-cell>
                    </ng-container>
                }

                <!-- Header Row -->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <!-- Data Row -->
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    tabindex="0"
                ></mat-row>

                <tr
                    *matNoDataRow
                    class="bol-table-empty-state"
                >
                    @if (dataSource.data.length === 0) {
                        <td data-cy="noEntityFound">
                            {{ 'NO-ENTITIES-FOUND' | translate: { entityName: entityName | translate | lowercase } }}
                        </td>
                    }
                </tr>

                <tr
                    *matNoDataRow
                    class="bol-table-empty-state"
                >
                    @if (!loadDataComplete) {
                        <td
                            translate
                            data-cy="noDataAvailable"
                        >
                            No data available.
                        </td>
                    }
                </tr>
            </mat-table>
        </section>
    </mat-card-content>
</mat-card>
@if ((tableRendered$ | async) === false) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

