/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { first, map, tap } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { DialogService } from 'src/app/shared/services';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';
import { EntityManageDialogComponent, IEntityManageModalData } from '../entity-manage-dialog/entity-manage-dialog.component';
import { EntityManageGridMenuOptions, FILTER_TYPE, FilterEntityManageGridColumn, MultiselectFilterSelection, RequestFinished, UpdatedEntityStructure } from './entity-manage-grid.model';

@Component({
    selector: 'app-entity-manage-grid',
    templateUrl: './entity-manage-grid.component.html',
    styleUrls: ['./entity-manage-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityManageGridComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatSort) public sort: MatSort;
    @Output() public readonly deleteEntity = new EventEmitter<any>();
    @Output() public readonly updateEntity = new EventEmitter<UpdatedEntityStructure>();
    @Output() public readonly addEntity = new EventEmitter<any>();
    @Output() public readonly cloneEntity = new EventEmitter<any>();
    @Output() public readonly multiselectFilterSelection = new EventEmitter<MultiselectFilterSelection>();
	@Input() public set entities(entities: Array<any>) {
		this.setEntities(entities);
	}
    @Input() public set requestFinished(requestFinishedState: RequestFinished) {
        if (requestFinishedState !== undefined) {
            this.updateTable();
        }
	}
    @Input() public rowMenuOptions: Array<EntityManageGridMenuOptions> = [];
	@Input() public columns: Array<FilterEntityManageGridColumn> = [];
    @Input() public specificStylingColumn: string;
    @Input() public formFields: Array<FormlyFieldConfig>;
    @Input() public entityName: string;
    @Input() public emptyStateMessage: string;
    @Input() public newEntityModel: any;
   
    public entitiesLoadingStateSubject = new BehaviorSubject<boolean>(true);
    public entitiesLoadingState$: Observable<boolean>;
	public dataSource = new MatTableDataSource<any>([]);
    public selectedEntity: any;
	public displayedColumns: Array<string> = [];
	public filterValues = {};
    public filterType = FILTER_TYPE;
    public showGridSpinner = false;

    private readonly subscription = new Subscription;

    constructor(
		public translateService: TranslateService,
		private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly matDialogService: MatDialog,
        private readonly dialogService: DialogService
	) {}

    public ngOnInit(): void {
        this.entitiesLoadingState$ = this.entitiesLoadingStateSubject.asObservable();
		this.displayedColumns = this.columns.map((c) => c.columnDef);
		this.changeDetectorRef.detectChanges();
		this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
	}

	public ngAfterViewInit(): void {
		this.dataSource.sort = this.sort;
	}

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

	public getFilterFieldPerColumns(): Array<string> {
		return this.displayedColumns.map((column) => 'filter-' + column);
	}

	public getFilterFieldPerColumn(column: FilterEntityManageGridColumn): string {
		return 'filter-' + column.columnDef;
	}

	public onUpdateMultiselectFilter(value: Array<any>, column: any): void {
        this.multiselectFilterSelection.emit({value, column})
		const selectedValues = value.join(', ');
		this.applyFilter(selectedValues, column);
	}

    public addNewGridEntity(): void {
        this.selectedEntity = null;
        this.showGridSpinner = true;
   
        const dialogRef = this.matDialogService.open(EntityManageDialogComponent, {
            data: {
                model: this.newEntityModel,
                formFields: this.formFields,
                manageMode: MANAGE_MODE.ADD,
                entityName: this.entityName
            } as IEntityManageModalData<any>,
            width: '700px',
            autoFocus: false,
        });

        this.subscription.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: IEntityManageModalData<any> }) => {
                    if (dialogData) {
                        this.addEntity.emit(dialogData.data.model);
                        this.entitiesLoadingStateSubject.next(true);
                    }
                })
            ).subscribe()
        );
    }

    public cloneGridEntity(entity: any): void {
        const clonedEntity = { ...entity, displayName: entity.displayName + ' (1)' };
        this.selectedEntity = clonedEntity;
        this.showGridSpinner = true;
        if (this.selectedEntity !== null) {
            const dialogRef = this.matDialogService.open(EntityManageDialogComponent, {
                data: {
                    model: this.selectedEntity,
                    formFields: this.formFields,
                    manageMode: MANAGE_MODE.CLONE,
                    entityName: this.entityName
                } as IEntityManageModalData<any>,
                width: '700px',
                autoFocus: false,
            });

            this.subscription.add(
                dialogRef.afterClosed().pipe(
                    first(),
                    tap((dialogData: { data: IEntityManageModalData<any> }) => {
                        if (dialogData) {
                            this.cloneEntity.emit(dialogData.data.model);
                            this.entitiesLoadingStateSubject.next(true);
                        }
                    })
                ).subscribe()
            );
        }
    }

    public editSelectedGridEntity(entity: any): void {
        this.selectedEntity = entity;
        this.showGridSpinner = true;
        if (this.selectedEntity !== null) {
            const dialogRef = this.matDialogService.open(EntityManageDialogComponent, {
                data: {
                    model: this.selectedEntity,
                    formFields: this.formFields,
                    manageMode: MANAGE_MODE.EDIT,
                    entityName: this.entityName
                } as IEntityManageModalData<any>,
                width: '700px',
                autoFocus: false,
            });

            this.subscription.add(
                dialogRef.afterClosed().pipe(
                    first(),
                    tap((dialogData: { data: IEntityManageModalData<any> }) => {
                        if (dialogData) {
                            this.updateEntity.emit({newVersionOfEntity: dialogData.data.model, lastCorrectVersionOfEntity: this.selectedEntity});
                            this.entitiesLoadingStateSubject.next(true);
                        }
                    })
                ).subscribe()
            );
        }
    }

    public deleteSelectedGridEntity(entity: any, deleteMessage?: string): void {
        this.showGridSpinner = true;
        this.selectedEntity = entity;
    
        const lowerCaseEntityName = this.entityName.toLowerCase();
        const title = this.translateService.instant('Delete') + ' ' + lowerCaseEntityName;
        
        let message; 
        if (deleteMessage) {
            message = this.translateService.instant(deleteMessage);
        } else {
            message = this.translateService.instant('DELETE-MESSAGE', { entityName: lowerCaseEntityName });
        }
        
        const cancelButton = this.translateService.instant('Cancel');
        const deleteButton = this.translateService.instant('Delete');
    
        this.dialogService.showConfirmDialog(title, message, deleteButton, cancelButton).pipe(
            first(),
            map(confirm => {
                if (confirm) {
                    this.deleteEntity.emit(this.selectedEntity);
                    this.entitiesLoadingStateSubject.next(true);
                }
            })
        ).subscribe();
    }

	public applyFilter(value: string, column: any): void {
		this.filterValues[column.columnDef] = value.trim().toLowerCase();
		this.applyFilters();
	}

	// Apply filters for all columns using filterValues object
	public applyFilters(): void {
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		this.dataSource.filterPredicate = (data: any) => {
			for (const col of this.columns) {
				const filterValue = this.filterValues[col.columnDef];
				if (filterValue) {
					if (col.filterType === FILTER_TYPE.SEARCH) {
						if (!this.applySearchFilter(data, col.columnDef, filterValue)) {
							return false;
						}
					} else if (col.filterType === FILTER_TYPE.MULTISELECT) {
						if (!this.applySelectionFilter(data, col.columnDef, filterValue)) {
							return false;
						}
					}
				}
			}

			return true;
		};

		const filterValuesArray = Object.entries(this.filterValues).map(([key, value]) => value);
		this.dataSource.filter = filterValuesArray.length ? filterValuesArray.join(' ') : '';
	}

	private applySearchFilter(data: any, column: any, filterValue: string): boolean {
		return data[column].toString().toLowerCase().includes(filterValue);
	}

	private applySelectionFilter(data: any, column: any, filterValue: string): boolean {
        const filterValues = filterValue.split(',').map((val) => val.trim());
        const lowerCaseValues = data[column]?.toString().toLowerCase().split(',').map((val) => val.trim());

        return lowerCaseValues ? filterValues.some((selectedValue) => lowerCaseValues.includes(selectedValue)) : false;
    }

	private readonly sortingDataAccessor = (data: any, sortHeaderId: string): string => {
		const sortHeaderIds: Array<string> = sortHeaderId.split('.');
		let dataValue = { ...data };
		sortHeaderIds.forEach((id: string) => (dataValue = dataValue ? dataValue[id] : dataValue));

		const columnConfig = this.columns.find((c) => c.columnDef === sortHeaderId);
		if (columnConfig && columnConfig.sortValue) {
			return columnConfig.sortValue(data);
		}

		if (typeof dataValue === 'string') {
			return dataValue.toLocaleLowerCase();
		}

		return data[sortHeaderId];
	};

    private updateTable(): void {
        this.entitiesLoadingStateSubject.next(false);
    }

	private setEntities(entities: Array<any>): void {
        this.entitiesLoadingStateSubject.next(true);
		if (!!entities) {
			this.dataSource.data = entities;
            this.entitiesLoadingStateSubject.next(false);
		}
	}
}
