<mat-card class="bol-card" class="content-wrapper">
    <mat-card-content>
        <div>
            <div>
                <h3 translate>Dayparts</h3>
            </div>
            <div class="add-dayparts">
                <button
                    mat-button
                    class="bol-button-solid bol-positive"
                    [disabled]="addDaypart$ | async"
                    (click)="showAddDaypartContainer()"
                >
                    <div translate>Add daypart</div>
                </button>
            </div>
            @if ((dayparts$ | async).length > 0 || (addDaypart$ | async)) {
                <div
                    class="dayparts-list-container"
                    [ngClass]="{ 'error-dayparts-list-container': errorAddDaypart }"
                >
                    <div class="dayparts-list-header daypart-row">
                        <div class="daypart-name" translate>Daypart</div>
                        <div class="daypart-start" translate>Start time</div>
                    </div>
                    @for (daypart of dayparts$ | async; track daypart.id) {
                        <div>
                            <app-settings-daypart-item
                                [ngModel]="daypart"
                                [errorOnUpdate]="errorOnUpdate$ | async"
                                [dayparts]="dayparts$ | async"
                                [daypartItemErrorClass]="errorAddDaypart"
                                (removeDaypartEvent)="onDeleteDaypart($event)"
                                (updateDaypartEvent)="onUpdateDaypart($event)"
                            ></app-settings-daypart-item>
                        </div>
                    }
                    @if (addDaypart$ | async) {
                        <div>
                            <app-settings-daypart-item
                                [ngModel]="newDaypart"
                                [dayparts]="dayparts$ | async"
                                [addDaypart]="true"
                                [daypartItemErrorClass]="errorAddDaypart"
                                (removeDaypartEvent)="resetAddDaypartContainer()"
                                (addDaypartEvent)="onAddDaypart($event)"
                            ></app-settings-daypart-item>
                        </div>
                    }
                </div>
            }
            @if (errorAddDaypart) {
                <div class="error-delete-daypart">
                    <mat-error translate>Please enter at least two dayparts.</mat-error>
                    <mat-error translate>The daypart will be saved when there are at least two.</mat-error>
                </div>
            }
            @if (showEmptyState$ | async) {
                <div class="empty-state">
                    <mat-icon svgIcon="timelapse"></mat-icon>
                    <div class="empty-state-description" translate>No dayparts available yet</div>
                </div>
            }
        </div>
    </mat-card-content>
</mat-card>
