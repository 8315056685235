import { CommonModule } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

export interface IConfirmationModalData {
    confirmationHeader: string;
    confirmationMessage: string;
    cancelButtonText?: string;
    cancelButtonClass?: string;
    confirmButtonText?: string;
    confirmButtonClass?: string;
    headerClass?: string;
}

@Component({
    standalone: true,
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule
    ]
})
export class ConfirmationModalComponent {
    confirmationHeader: string;
    confirmationMessage: string;
    cancelButtonText = 'Cancel';
    cancelButtonClass = 'bol-button-text bol-gray';
    confirmButtonText = 'Delete';
    confirmButtonClass = 'bol-button-solid bol-negative';
    headerClass = 'default';

    private readonly dialogRef = inject<MatDialogRef<IConfirmationModalData>>(MatDialogRef);
    private readonly data = inject<IConfirmationModalData>(MAT_DIALOG_DATA);

    public ngOnInit(): void {
        this.confirmationHeader = this.data.confirmationHeader;
        this.confirmationMessage = this.data.confirmationMessage;
        if (this.data.cancelButtonClass) { this.cancelButtonClass = this.data.cancelButtonClass; }
        if (this.data.cancelButtonText) { this.cancelButtonText = this.data.cancelButtonText; }
        if (this.data.confirmButtonClass) { this.confirmButtonClass = this.data.confirmButtonClass; }
        if (this.data.confirmButtonText) { this.confirmButtonText = this.data.confirmButtonText; }
        if (this.data.headerClass) { this.headerClass = this.data.headerClass; }
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }
}
