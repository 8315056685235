<div class="breadcrumbs__container">
    <div>
        <button
            mat-button
            class="bol-button-text breadcrumbs__button"
            (click)="onNavigateToRoot()"
        >
            <span translate>OMRP</span>
        </button>
    </div>

    <span class="breadcrumbs__divider">/</span>

    @for (breadcrumb of breadcrumbs$ | async; track breadcrumb.caption; let isLast = $last) {
        <span>
            @if (!isLast) {
                <button
                    mat-button
                    class="bol-button-text breadcrumbs__button"
                    (click)="onNavigate(breadcrumb.route)"
                >
                    <span translate>{{ breadcrumb.caption }}</span>
                    @if (breadcrumb?.dynamicPart) {
                        {{ breadcrumb?.dynamicPart }}
                    }
                </button>
                <span class="breadcrumbs__divider">/</span>
            }
            @else {
                <button
                    mat-button
                    disabled
                    class="bol-button-text breadcrumbs__button"
                >
                    <span translate>{{ breadcrumb.caption }}</span>
                    @if (breadcrumb?.dynamicPart) {
                        {{ breadcrumb?.dynamicPart }}
                    }
                </button>
            }
        </span>
    }
</div>

