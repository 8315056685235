import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { IConfirmationModalData, ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { IInformationModalData, InformationModalComponent } from './components/information-modal/information-modal.component';

export const enum DialogType {
    Info = 0,
    Warning = 1,
    Error = 2
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {
    private dialog = inject(MatDialog);

    /**
     * Show a confirmation dialog with a boolean choice
     */
    public showConfirmDialog(
        titleTranslated: string, messageTranslated: string,
        yesButtonText?: string, noButtonText?: string,
        yesButtonClass?: string, noButtonClass?: string,
        modalClass?: string
    ): Observable<boolean> {
        const dialogData: IConfirmationModalData = {
            confirmationHeader: titleTranslated,
            confirmationMessage: messageTranslated,
            cancelButtonText: noButtonText,
            cancelButtonClass: noButtonClass,
            confirmButtonText: yesButtonText,
            confirmButtonClass: yesButtonClass,
            headerClass: modalClass
        };

        return this.dialog.open(ConfirmationModalComponent, { data: dialogData }).afterClosed();
    }

    /**
     * Shows an information dialog, with a specified title and message.
     * @param dialogType For example info, warning or error
     */
    public showInfoDialog(
        titleTranslated: string, 
        messageTranslated: string, 
        buttonText?: string, 
        buttonClass?: string,
        modalClass?: string,
        modalWidth?: string,
        showErrorIcon?: boolean
    ): Observable<boolean> {
        const dialogData: IInformationModalData = {
            header: titleTranslated,
            message: messageTranslated,
            buttonText: buttonText,
            buttonClass: buttonClass,
            headerClass: modalClass,
            showErrorIcon
        };
        const panelClass = modalWidth ? modalWidth : 'dialog-medium';

        return this.dialog.open(InformationModalComponent, { data: dialogData, panelClass: panelClass }, ).afterClosed();
    }
}
