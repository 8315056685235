import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, first, map, skip, tap } from 'rxjs/operators';
import { createTimeRange, TimeAdapter } from '@ortec/bolster/time-select';

import { EntityUtilityService } from 'src/app/shared/services';
import { DateTimeUtilityService } from 'src/app/shared/services/date-time-utility.service';
import { OwsInterfaceSchedulingQuery } from './ows-interface-scheduling-store/ows-interface-scheduling.query';
import { OwsInterfaceSchedulingService } from './ows-interface-scheduling-store/ows-interface-scheduling.service';
import { OwsInterfaceScheduling } from './ows-interface-scheduling-store/ows-interface-scheduling.model';
import { OwsIntervalSchedulingQuery } from './ows-interval-scheduling-store/ows-interval-scheduling.query';
import { OwsIntervalSchedulingService } from './ows-interval-scheduling-store/ows-interval-scheduling.service';
import { OwsIntervalScheduling } from './ows-interval-scheduling-store/ows-interval-scheduling.model';
import { UpdatedInterval } from './components/resource-activity-import-item/resource-activity-import-item.component';

export interface SelectObject {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-ows-interface-scheduling',
    templateUrl: './ows-interface-scheduling.component.html',
    styleUrls: ['./ows-interface-scheduling.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwsInterfaceSchedulingComponent implements OnInit, OnDestroy {
    public interfaceConfig$: Observable<OwsInterfaceScheduling>;
    public dataLoaded$: Observable<boolean>;
    public intervals: Array<SelectObject> = [];
    public daysOfWeek: Array<SelectObject> = [];
    public daysOfMonth: Array<SelectObject> = [];
    public addInterval$: Observable<boolean>;
    public timeRange$!: Observable<any>;
    public intervals$: Observable<Array<OwsIntervalScheduling>>;
    public showEmptyState$: Observable<boolean>;
    public errorMessage: string = 'This field requires a numeric value greater than 0 and smaller then 366.';
    public newInterval: OwsIntervalScheduling = { id: this.entityUtility.emptyGuid, startMinutes: undefined, endMinutes: undefined, intervalType: 0, interval: undefined };

    public readonly formControls = {
        daysInPast: new UntypedFormControl(0, [Validators.min(0), Validators.max(366)]),
        daysInFuture: new UntypedFormControl(0, [Validators.min(0), Validators.max(366)]),
        daysOld: new UntypedFormControl(0, [Validators.min(0), Validators.max(366)])
    };
   
    private readonly subscription = new Subscription();
    private readonly currentIntervalsSubject = new BehaviorSubject<Array<OwsIntervalScheduling>>([]);
    private readonly addIntervalSubject = new BehaviorSubject<boolean>(false);
    private readonly updateIntervalsSubject = new BehaviorSubject<Array<OwsIntervalScheduling>>(undefined);

    constructor(
        private readonly owsInterfaceSchedulingQuery: OwsInterfaceSchedulingQuery,
        private readonly owsInterfaceSchedulingService: OwsInterfaceSchedulingService,
        private readonly owsIntervalSchedulingQuery: OwsIntervalSchedulingQuery,
        private readonly owsIntervalSchedulingService: OwsIntervalSchedulingService,
        private readonly entityUtility: EntityUtilityService,
        private readonly dateTimeUtility: DateTimeUtilityService,
        private readonly timeAdapter: TimeAdapter<moment.Moment>,
    ) {
        this.timeAdapter.locale = 'nl-NL';
        this.timeRange$ = of(createTimeRange(timeAdapter, undefined, this.timeAdapter.create(23, 59, 0, 0), 30));
    }

    public ngOnInit(): void {
        this.owsInterfaceSchedulingService.get().pipe(first()).subscribe();
        this.owsIntervalSchedulingService.get().pipe(first()).subscribe();
        this.dataLoaded$ = this.owsInterfaceSchedulingQuery.getDataLoadedState();
        this.interfaceConfig$ = this.owsInterfaceSchedulingQuery.getInterfaceConfiguration();
        this.addInterval$ = this.addIntervalSubject.asObservable();

        this.owsIntervalSchedulingQuery.getIntervals().pipe(
            skip(1),
            first(),
            map(intervals => this.currentIntervalsSubject.next(intervals))
        ).subscribe();

        this.intervals$ = this.currentIntervalsSubject.asObservable().pipe(
            map(intervals => intervals.sort((a, b) => { return this.sortingIntervals(a, b); }))
        );

        this.showEmptyState$ = combineLatest([
            this.intervals$,
            this.addInterval$
        ]).pipe(
            map(([intervals, addInterval]) => !(intervals.length > 0 || addInterval)),
        );

        this.subscription.add(
            this.interfaceConfig$.pipe(
                filter((config: OwsInterfaceScheduling) => !!config),
                first()
            ).subscribe((config: OwsInterfaceScheduling) => {
                this.formControls.daysInPast.setValue(config.miscSettings.daysInPast);
                this.formControls.daysInFuture.setValue(config.miscSettings.daysInFuture);
                this.formControls.daysOld.setValue(config.miscSettings.daysOld);
            })
        )

        this.subscription.add(
            this.formControls.daysInPast.valueChanges.pipe(skip(1)).subscribe(value=> {
                if(this.formControls.daysInPast.valid && typeof(value) === 'number') {
                    this.owsInterfaceSchedulingService.onConfigurationChange('miscSettings.daysInPast', value);
                }
            })
        );
        
        this.subscription.add(
            this.formControls.daysInFuture.valueChanges.pipe(skip(1)).subscribe(value => {
                if(this.formControls.daysInFuture.valid && typeof(value) === 'number') {
                    this.owsInterfaceSchedulingService.onConfigurationChange('miscSettings.daysInFuture', value);
                }
            })
        );
        
        this.subscription.add(
            this.formControls.daysOld.valueChanges.pipe(skip(1)).subscribe(value => {
                if(this.formControls.daysOld.valid && typeof(value) === 'number') {
                    this.owsInterfaceSchedulingService.onConfigurationChange('miscSettings.daysOld', value);
                }
            })
        );
    }

    public onConfigurationChange(configurationName: string, value: any): void {
        this.owsInterfaceSchedulingService.onConfigurationChange(configurationName, value);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onDeleteInterval(interval: OwsIntervalScheduling): void {
        const updatedIntervals = this.currentIntervalsSubject.value.filter(currentInterval => currentInterval.id !== interval.id);
        this.currentIntervalsSubject.next(updatedIntervals);
        this.owsIntervalSchedulingService.deleteInterval(interval.id).pipe(
            first()
        ).subscribe({
            error: this.handleUpdateError.bind(this)
        });
        this.updateIntervalsSubject.next(updatedIntervals);
    }

    public resetAddIntervalContainer(): void {
        this.newInterval = this.getNewDefaultInterval();
        this.addIntervalSubject.next(false);
    }

    public showAddIntervalContainer(): void {
        this.newInterval = this.getNewDefaultInterval();
        this.addIntervalSubject.next(true);
    }

    public onUpdateInterval(updatedInterval: UpdatedInterval): void {
        const intervals = this.currentIntervalsSubject.value;

        this.owsIntervalSchedulingService.updateInterval(updatedInterval.new, updatedInterval.old).pipe(
            first()
        ).subscribe({
            error: this.handleUpdateError.bind(this)
        });

        const index = intervals.findIndex(int => int.id === updatedInterval.new.id);
        intervals[index] = { ...updatedInterval.new };
        this.currentIntervalsSubject.next(intervals);
        this.updateIntervalsSubject.next(intervals);
    }

    public onAddInterval(interval: OwsIntervalScheduling): void {
        const updatedIntervals = this.currentIntervalsSubject.value;
        updatedIntervals.push({ startMinutes: interval.startMinutes, endMinutes: interval.endMinutes, intervalType: 0, interval: interval.interval, id: this.entityUtility.emptyGuid });
        this.currentIntervalsSubject.next([...updatedIntervals]);
        this.updateIntervalsSubject.next(updatedIntervals);
        this.owsIntervalSchedulingService.saveInterval(updatedIntervals.find(int => int.id === this.entityUtility.emptyGuid)).pipe(
            first(),
            tap((newInterval) => {
                this.getNewIntervals(newInterval.id, updatedIntervals, interval);
            })
        ).subscribe({
            error: this.handleUpdateError.bind(this)
        });

        this.resetAddIntervalContainer();
    }

    private sortingIntervals(interval1: OwsIntervalScheduling, interval2: OwsIntervalScheduling): number {
        const startA = this.dateTimeUtility.convertHoursToMinutes(interval1.startMinutes as any);
        const startB = this.dateTimeUtility.convertHoursToMinutes(interval2.startMinutes as any);
        const endA = this.dateTimeUtility.convertHoursToMinutes(interval1.endMinutes as any);
        const endB = this.dateTimeUtility.convertHoursToMinutes(interval2.endMinutes as any);
        const intervalA = interval1.interval;
        const intervalB = interval2.interval;

        if (startA === startB) {
            if (endA === endB) {
                return intervalA - intervalB;
            } else {
                return endA - endB;
            }
        } else {
            return startA - startB;
        }
    }
    private getNewIntervals(intervalId: string, currentIntervals: Array<OwsIntervalScheduling>, newInterval: OwsIntervalScheduling): void {
        const newIntervals = currentIntervals.filter(int => int.id !== this.entityUtility.emptyGuid);
        newIntervals.push({ id: intervalId, startMinutes: newInterval.startMinutes, endMinutes: newInterval.endMinutes, interval: newInterval.interval, intervalType: newInterval.intervalType });
        this.currentIntervalsSubject.next([...newIntervals]);
    }

    private getNewDefaultInterval(): OwsIntervalScheduling {
        return { id: this.entityUtility.emptyGuid, startMinutes: undefined, endMinutes: undefined, intervalType: 0, interval: undefined };
    }

    private handleUpdateError(): void {
        this.currentIntervalsSubject.next(this.owsIntervalSchedulingQuery.getIntervalsSync());
    }
}
