import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const noEmptySpacesValidatorKey = 'no-empty-spaces';

export const noEmptySpacesValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value === '') {
        return null;
    }
    else {
        return !control.value.trim().length ? {[noEmptySpacesValidatorKey]: true} : null;
    }
}