<div
    class="bol-modal__header"
    ngClass="modal__header-{{ headerClass }}"
    [attr.data-cy]="header"
>
    <span class="modal-title">{{ header }}</span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
        data-cy="btnClose"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    <div class="confirmationDialogContent">
        @if (showErrorIcon) {
            <mat-icon svgIcon="alert-circle-outline" class="error-icon"></mat-icon>
        }
        {{ message }}
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            id="btnOK"
            ngClass="{{ buttonClass }}"
            [mat-dialog-close]="true"
            data-cy="btnOK"
            translate
        >
            {{ buttonText }}
        </button>
    </div>
</mat-dialog-actions>
