import { Injectable } from '@angular/core';

import { MANAGE_MODE } from 'src/app/shared/models/Enums';
import { User } from 'src/app/shared/stores/user-store/user.model';

import { UserGroupsManagementStore } from './user-groups-management.store';

@Injectable({
    providedIn: 'root'
})
export class UserGroupsManagementService {
    constructor(
        protected userGroupsManagementStore: UserGroupsManagementStore
    ) { }

    public updateSelectedUserGroupId(id: number): void {
        this.userGroupsManagementStore.updateSelectedUserGroupId(id);
    }

    public updateSelectedUsers(users: Array<User>): void {
        this.userGroupsManagementStore.updateSelectedUsers(users);
    }

    public updateManageMode(mode: MANAGE_MODE): void {
        this.userGroupsManagementStore.updateManageMode(mode);
    }

    public setStateForNewUserGroup(userGroupId: number): void {
        this.userGroupsManagementStore.setStateForNewUserGroup(userGroupId);
    }
}
