import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ConfigurationQuery } from 'src/app/shared/stores/configuration-store/configuration.query';

@Component({
    selector: 'app-ows-interface',
    templateUrl: './ows-interface.component.html',
    styleUrls: ['./ows-interface.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwsInterfaceComponent implements OnInit {
    public menuItems: Array<any> = [];
    private urlBasedOnFeatureFlagForUseActivityRequestHandling: string;
    private featureOWSInterfaceReplacedShifts: boolean;

    constructor(
        private readonly configurationQuery: ConfigurationQuery
    ) { }

    public ngOnInit(): void {
        this.urlBasedOnFeatureFlagForUseActivityRequestHandling = this.configurationQuery.getFeatureUseActivityRequestHandlingSync() ? '/ows-interface/activity-type-mapping-new' : '/ows-interface/activity-type-mapping';
        this.featureOWSInterfaceReplacedShifts = this.configurationQuery.getFeatureOWSInterfaceReplacedShiftsSync();

        this.menuItems.push(
            {
                title: 'Interface scheduling',
                icon: 'clock-outline',
                url: '/ows-interface/scheduling'
            },
            {
                title: 'Department mapping',
                icon: 'domain',
                url: '/ows-interface/department-mapping'
            },
            {
                title: 'Resource Type mapping',
                icon: 'folder-account',
                url: '/ows-interface/resource-type-mapping'
            },
            {
                title: 'Skill mapping',
                icon: 'library',
                url: '/ows-interface/skill-mapping'
            },
            {
                title: 'Activity Type mapping',
                icon: 'subtitles',
                url: this.urlBasedOnFeatureFlagForUseActivityRequestHandling
            },
            {
                title: 'What is work',
                icon: 'briefcase',
                url: '/ows-interface/what-is-work'
            }
        );

        if (this.featureOWSInterfaceReplacedShifts) {
            this.menuItems.push({
                title: 'Other settings',
                icon: 'cog',
                url: '/ows-interface/other-settings'
            });
        }
    }
}
