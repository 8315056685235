import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ErrorDialogService } from '../../services/error-dialog.service';
import { StatusPageService } from '../status-page-store/status-page.service';
import { PreferredResourcesState, PreferredResourcesStore } from './preferred-resources.store';
import { PreferredResourcesForTimeSlot, PreferredResourcesPutRequest, PreferredResourcesPutResponse } from './preferred-resources.model';
import { ActivityTypeQuery } from '../activity-type-store/activity-type.query';
import { PreferredResourcesQuery } from './preferred-resources.query';

@Injectable({
    providedIn: 'root'
})
export class PreferredResourcesService extends StatusPageService<PreferredResourcesState> {

    constructor(
        private readonly http: HttpClient,
        protected readonly preferredResourcesStore: PreferredResourcesStore,
        protected readonly preferredResourcesQuery: PreferredResourcesQuery,
        protected readonly activityTypeQuery: ActivityTypeQuery,
        private readonly errorDialogService: ErrorDialogService,
    ) {
        super(preferredResourcesStore);
    }

    public getPreferredResourcesForCurrentWeekDay(templateId: number, timeslotId: number): Observable<PreferredResourcesForTimeSlot> {
        const week = this.preferredResourcesQuery.getCurrentWeekSync();
        const day = this.preferredResourcesQuery.getCurrentDaySync();
        this.preferredResourcesStore.updateEntitiesLoadingState(true);
    
        return this.http.get<PreferredResourcesForTimeSlot>(`/api/v1/ActivityDemand/Templates/${templateId}/ActivityTypeTimeslots/${timeslotId}/PreferredResources/${week}/${day}`).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                this.preferredResourcesStore.updateEntitiesLoadingState(false);

                return throwError(() => error);
            }),
            tap((preferredResources: PreferredResourcesForTimeSlot) => {
                this.preferredResourcesStore.updatePreferredResourcesPerWeekDay(preferredResources, week, day);
                this.preferredResourcesStore.updateEntitiesLoadingState(false);
            })
        );
    }

    public updatePreferredResourcesForCurrentWeekDay(templateId: number, timeslotId: number, preferredResourceIds: Array<number>, activityTypeId: number): Observable<PreferredResourcesPutResponse> {
        const week = this.preferredResourcesQuery.getCurrentWeekSync();
        const day = this.preferredResourcesQuery.getCurrentDaySync();
        this.preferredResourcesStore.updateEntitiesLoadingState(true);

        const requestBody: PreferredResourcesPutRequest = {
            preferredResourceIds,
            activityTypeId
        };

        return this.http.put<PreferredResourcesPutResponse>(`/api/v1/ActivityDemand/Templates/${templateId}/ActivityTypeTimeslots/${timeslotId}/PreferredResources/${week}/${day}`, requestBody).pipe(
            catchError((error) => {
                this.errorDialogService.showErrorDialogV1(error.error.messageCode, error.error.statusText);
                this.preferredResourcesStore.updateEntitiesLoadingState(false);

                return throwError(() => error);
            }),
            tap((response) => {
                const matchingDay = response.weekDays.find(days => days.dayNumber === day);

                this.preferredResourcesStore.updatePreferredResourcesPerWeekDay(matchingDay, week, day);
                this.preferredResourcesStore.updateEntitiesLoadingState(false);
            })
        );

    }

    public updateCurrentWeek(week: number): void {      
        this.preferredResourcesStore.updateCurrentWeek(week);
    }

    public updateCurrentDay(day: number): void {
        this.preferredResourcesStore.updateCurrentDay(day);
    }
}
