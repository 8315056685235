import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import { MANAGE_MODE } from 'src/app/shared/models/Enums';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { UserGroupService } from 'src/app/shared/stores/user-group-store/user-group.service';
import { UserGroupQuery } from 'src/app/shared/stores/user-group-store/user-group.query';
import { PermissionsTreeStateService } from 'src/app/shared/components/inputs/permissions-tree/services/permissions-tree-state.service';

import { OrganizationUnitsManagementQuery } from './store/organization-units-management.query';
import { OrganizationUnitsManagementService } from './store/organization-units-management.service';
import { getOrganizationUnitsFormFields } from './organization-units-form-definition';

@Component({
    selector: 'app-organization-units',
    templateUrl: './organization-units.component.html',
    styleUrls: ['./organization-units.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationUnitsComponent implements OnInit, OnDestroy {
    public organizationUnits$: Observable<Array<OrganizationUnit>>;
    public searchProperties: Array<string>;
    public loadingError$: Observable<boolean>;
    public manageMode$: Observable<MANAGE_MODE>;
    public initialLoadingFinished$: Observable<boolean>;
    public formFields: Array<FormlyFieldConfig>;
    public showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
    public selectedUIOrganizationUnit$: Observable<EntityUI>;
    public selectedOrganizationUnitId$: Observable<number>;
    public selectedOrganizationUnit$ = new BehaviorSubject<OrganizationUnit>(undefined);

    private readonly subscription = new Subscription();

    constructor(
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly organizationUnitManagementQuery: OrganizationUnitsManagementQuery,
        private readonly organizationUnitManagementService: OrganizationUnitsManagementService,
        private readonly userGroupService: UserGroupService,
        private readonly userGroupQuery: UserGroupQuery,
        private readonly autosaveService: AutosaveService,
        private readonly permissionsTreeStateService: PermissionsTreeStateService
    ){}

    public ngOnInit(): void {
        this.searchProperties = ['displayName'];

        this.organizationUnitService.get().pipe(first()).subscribe();
        this.userGroupService.getUserGroupNames().pipe(first()).subscribe();

        this.loadingError$ = this.organizationUnitQuery.selectError();
        this.selectedUIOrganizationUnit$ = this.organizationUnitManagementQuery.getSelectedUIOrganizationUnit();
        this.selectedOrganizationUnitId$ = this.organizationUnitManagementQuery.getCurrentOrganizationUnitId();
        this.manageMode$ = this.organizationUnitManagementQuery.getManageMode();
        this.organizationUnits$ = this.organizationUnitQuery.getOrganizationsForFiltering();
        this.initialLoadingFinished$ = this.organizationUnitManagementQuery.allEntitiesLoaded();

        this.subscription.add(
            this.organizationUnitManagementQuery.getSelectedOrganizationUnit().pipe(
                tap(organizationUnit => this.selectedOrganizationUnit$.next(organizationUnit))
            ).subscribe()
        );

        this.setOrganizationUnitFormFields();
    }

    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
        this.subscription.unsubscribe();
        this.permissionsTreeStateService.clearExpandedNodeIds();
    }

    public onSelectedOrganizationUnitChange(id: number): void {
        this.autosaveService.saveUnsavedChanges();
        this.organizationUnitService.setOrganizationUnitToCleanUIState(id);
        const manageMode = id === undefined ? undefined : MANAGE_MODE.EDIT;
        this.organizationUnitManagementService.updateManageMode(manageMode);
        this.organizationUnitManagementService.updateSelectedOrganizationUnitId(id);
    }

    public onAddNewOrganizationUnit(): void {
        const newOrganizationUnit = this.organizationUnitManagementQuery.getNewOrganizationUnitObject();
        const lastCorrectVersionOfOrgUnit = this.organizationUnitQuery.getOrganizationUnitSync(this.organizationUnitManagementQuery.getCurrentOrganizationUnitIdSync());

        this.showLoadingSpinner$.next(true);
        
        this.organizationUnitService.saveOrganizationUnit(newOrganizationUnit, lastCorrectVersionOfOrgUnit).pipe(
            tap((addedOrgUnit: OrganizationUnit) => {
                this.showLoadingSpinner$.next(false);
                this.organizationUnitManagementService.setStateForNewOrganizationUnit(addedOrgUnit.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onDeleteOrganizationUnit(orgUnitId: number): void {
        this.showLoadingSpinner$.next(true);
        this.organizationUnitService.deleteOrganizationUnit(orgUnitId).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
                this.organizationUnitManagementService.updateSelectedOrganizationUnitId(undefined);
                this.organizationUnitManagementService.updateManageMode(undefined);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onEditOrganizationUnit(orgUnit: OrganizationUnit): void {
        this.organizationUnitService.setOrganizationUnitToPendingChanges(orgUnit.id);
        this.autosaveService.autoSave(orgUnit, this.saveOrganizationUnit.bind(this));

    }

    private saveOrganizationUnit(orgUnit: OrganizationUnit): void {
        const lastCorrectVersionOfOrgUnit = this.organizationUnitQuery.getOrganizationUnitSync(orgUnit.id);

        this.organizationUnitService.saveOrganizationUnit(orgUnit, lastCorrectVersionOfOrgUnit).pipe(
            first(),
        ).subscribe({
            error: this.handleSaveError.bind(this)
        });
    }

    private handleSaveError(orgUnit: OrganizationUnit): void {
        if (this.organizationUnitManagementQuery.getCurrentOrganizationUnitIdSync() === orgUnit.id) {
            this.selectedOrganizationUnit$.next({...orgUnit});
        }
    }

    private handleError(): void {
        this.showLoadingSpinner$.next(false);
    }

    private setOrganizationUnitFormFields(): void {
        this.subscription.add(
            this.organizationUnitManagementQuery.getCurrentOrganizationUnitId().pipe(
                switchMap((currentOrganizationUnitId) => combineLatest([
                    this.userGroupQuery.getUserGroups(),
                    this.organizationUnitQuery.getOrganizationsForFilteringWithEmpty(),
                ]).pipe(
                    map(([userGroups, organizationUnitTrees]) => ({
                        currentOrganizationUnitId,
                        userGroups,
                        organizationUnitTrees
                    })),
                    first()
                )),
                filter(({ userGroups, organizationUnitTrees, currentOrganizationUnitId }) => 
                    !!userGroups && !!organizationUnitTrees && currentOrganizationUnitId && currentOrganizationUnitId > 0),
            ).subscribe(({ userGroups, organizationUnitTrees, currentOrganizationUnitId }) => {
                this.formFields = getOrganizationUnitsFormFields(userGroups, organizationUnitTrees, currentOrganizationUnitId);
            })
        );
    }
}
