<div class="manage-templates-panel">
    <mat-card class="bol-card">
        <h3 class="manage-templates-title" translate data-cy="manageTemplatesTitle">
            Manage activity demand templates
        </h3>
        @if ((initialLoadingFinished$ | async) === false && (initialLoadingStateForGrid$ | async) === false) {
            <div class="content">
                <div class="add-button">
                    <button
                        (click)="onAddEntityClicked()"
                        mat-button
                        class="bol-button-solid bol-default"
                        data-cy="addEntityButton"
                    >
                        <span translate>Add</span>
                    </button>
                </div>
                <app-entity-manage-grid
                    #entityManageGrid
                    [entities]="entities$ | async"
                    [columns]="columns"
                    [newEntityModel]="newEntityModel"
                    [entityName]="'Template'"
                    [formFields]="formFields"
                    [requestFinished]="requestFinished$ | async"
                    [rowMenuOptions]="rowMenuOptions"
                    [emptyStateMessage]="'No templates'"
                    (deleteEntity)="onDeleteTemplate($event)"
                    (cloneEntity)="onCloneNewTemplate($event)"
                    (addEntity)="onAddNewTemplate($event)"
                >
                </app-entity-manage-grid>
            </div>
        }
    </mat-card>
</div>

@if ((initialLoadingFinished$ | async) === true || (initialLoadingStateForGrid$ | async) === true) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}
