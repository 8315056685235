import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable, combineLatest, filter, map } from 'rxjs';

import { EditTemplatesManagementState, EditTemplatesManagementStore } from './edit-template-management.store';
import { ManageTemplateQuery } from 'src/app/shared/stores/manage-template-store/manage-template.query';
import { ADTActivityType } from './models';
import { ManageTimeslotQuery } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.query';
import { ActivityTypeTimeslotsPutPost } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.model';
import { ManageTemplateCounterQuery } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.query';
import { AdtCounterPutPost } from 'src/app/shared/stores/manage-template-counter-store/manage-template-counter.model';
import { AdtCounter } from 'src/app/shared/stores/manage-template-store/manage-template.model';

@Injectable({
    providedIn: 'root'
})
export class EditTemplatesManagementQuery extends Query<EditTemplatesManagementState> {
    constructor(
        protected store: EditTemplatesManagementStore,
        protected manageTemplateQuery: ManageTemplateQuery,
        protected manageTimeslotQuery: ManageTimeslotQuery,
        protected manageTemplateCounterQuery: ManageTemplateCounterQuery
    ) {
        super(store);
    }

    public getCurrentWeekNumber(): Observable<number> {
        return this.select(value => value.currentWeek);
    }

    public getCurrentWeekNumberSync(): number {
        return this.getValue().currentWeek;
    }

    public getMaxWeekNumberSync(): number {
        return this.getValue().maxWeeks;
    }

    public getMaxWeekNumber(): Observable<number> {
        return this.select(value => value.maxWeeks);
    }
    
    public getSearchActivityTypesValue(): Observable<string> {
        return this.select(value => value.searchActivityTypesValue);
    }

    public getSearchCountersValue(): Observable<string> {
        return this.select(value => value.searchCountersValue);
    }

    public getCurrentTemplateIdSync(): number {
        return this.getValue().currentTemplateId;
    }

    public getSelectedActivityTimeslotId(): Observable<number> {
        return this.select(value => value.selectedActivityTimeslotId);
    }

    public getHasError(): Observable<boolean> {
        return this.select(value => value.hasError);
    }

    public createTimeslotsForAdding(timeslotIds: Array<number>): Array<ActivityTypeTimeslotsPutPost> {
        return timeslotIds.map(id => ({
            id: 0,
            activityTypeTimeslotId: id, 
            weeksDemand: [], 
            activityDemandTotal: 0 
        }));
    }

    public createCountersForAdding(counterIds: Array<number>): Array<AdtCounterPutPost> {
        return counterIds.map(id => ({
            id: 0,
            counterId: id,
            weeksDemand: [], 
            counterDemandTotal: 0 
        }));
    }

    public getNewValuesTransformedSync(activityTypeTableTimeslots: Array<ADTActivityType>): Array<ActivityTypeTimeslotsPutPost> {
        const allActivityTypeTimeslots = this.manageTimeslotQuery.getActivityTypeTimeslotsSync();
        const modifiedTimeslots = activityTypeTableTimeslots.map(activityTypeTableTimeslot => {
            let matchingTimeslot = allActivityTypeTimeslots.find(activityTypeTimeslot => activityTypeTimeslot.id === activityTypeTableTimeslot.id);
            const weekDemandNeedsTransformation = Array.isArray(activityTypeTableTimeslot.weekDemand) && activityTypeTableTimeslot.weekDemand.every(Number.isFinite) && activityTypeTableTimeslot.weekDemand.length > 0;
        
                if (matchingTimeslot && weekDemandNeedsTransformation) {
                    const updatedWeeksDemand = matchingTimeslot.weeksDemand.map(week => {
                        if (week.weekNumber === activityTypeTableTimeslot.currentWeekNumber) {

                            return {
                                ...week,
                                weekdaysDemand: week.weekdaysDemand.map((weekday, weekdayIndex) => ({
                                    ...weekday,
                                    amount: activityTypeTableTimeslot.weekDemand[weekdayIndex] as any
                                }))
                            };
                        }

                        return week;
                    });
                    
                    matchingTimeslot = {
                        ...matchingTimeslot,
                        weeksDemand: updatedWeeksDemand
                    };
                }
        
                return {
                    id: matchingTimeslot.id,
                    activityTypeTimeslotId: matchingTimeslot.activityTypeTimeslotInfo.id,
                    weeksDemand: matchingTimeslot.weeksDemand,
                    activityDemandTotal: activityTypeTableTimeslot.distributionTotal
                } as ActivityTypeTimeslotsPutPost;
            }
        );
        
        return modifiedTimeslots;
    }

    public getSumOfWeeksDemandOfTimeslotsWithoutCurrentWeek(): Observable<Map<number, number>> {
        return combineLatest([
            this.manageTimeslotQuery.getActivityTypeTimeslots(),
            this.getCurrentWeekNumber()
        ]).pipe(
            map(([timeslots, currentWeekNumber]) => {
                const totalsMap = new Map<number, number>();
                timeslots.forEach(timeslot => {
                    const sumForTimeslot = timeslot.weeksDemand.reduce((timeslotSum, weekDemand) => {
                        if (weekDemand.weekNumber === currentWeekNumber) {
                            return timeslotSum; // Skip the current week
                        }
                        const sumForWeek = weekDemand.weekdaysDemand.reduce((weekSum, day) => weekSum + day.amount, 0);
                        
                        return timeslotSum + sumForWeek;
                    }, 0);
                    totalsMap.set(timeslot.id, sumForTimeslot);
                });

                return totalsMap;
            })
        );
    }

    public getActivityTypesEntities(): Observable<Array<ADTActivityType>> {
        return combineLatest([
            this.manageTimeslotQuery.getActivityTypeTimeSlotsForSelectedTemplateForEdit(),
            this.getSearchActivityTypesValue(),
            this.getCurrentWeekNumber()
        ]).pipe(
            filter(([activityTypes, _]) => !!activityTypes),
            map(([activityTypes, searchTerm, currentWeekNumber]) => {
                const trimmedSearchTerm = searchTerm.trim().toLowerCase();
    
                if (!trimmedSearchTerm) {
                    // If search term is empty or only contains spaces, return all activity types
                    return activityTypes.map(activityType => ({
                        ...activityType,
                        currentWeekNumber,
                        weekDemand: activityType.weekDemand.filter(week => week.weekNumber === currentWeekNumber)
                    }));
                }
    
                // Filter activity types based on the search term, ignoring leading and trailing spaces
                return activityTypes.filter(activityType => activityType.displayName.toLowerCase().includes(trimmedSearchTerm)).map(activityType => ({
                    ...activityType,
                    currentWeekNumber,
                    weekDemand: activityType.weekDemand.filter(week => week.weekNumber === currentWeekNumber)
                }));
            })
        );
    }

    public getCountersEntities(): Observable<Array<AdtCounter>> {
        return combineLatest({
            counters: this.manageTemplateCounterQuery.getTemplateCounters(),
            searchTerm: this.getSearchCountersValue()
        }).pipe(
            filter(({counters}) => !!counters),
            map(({counters, searchTerm}) => {
                if (!searchTerm.trim()) {
                    // If search term is empty or only contains spaces, return all activity types
                    return counters;
                }
        
                // Filter activity types based on the search term, ignoring leading and trailing spaces
                return counters.filter(counters =>
                    counters.activityDemandCounterInfo.displayName.toLowerCase().includes(searchTerm.trim().toLowerCase())
                );
            })
        );
    }
}
