<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div class="filters-header">
    @if ((filterSettings$ | async) && (filterSettings$ | async).length > 0) {
        <div class="filters-field">
            <mat-form-field class="bol-form-field">
                <mat-label>{{ 'Filters' | translate }}</mat-label>
                <mat-select
                    #filterSettingSelect
                    [formControl]="filterSettingControl"
                    (openedChange)="openedFilterSettingSelect($event)"
                >
                    @for (filterSetting of filterSettings$ | async; track filterSetting.id) {
                        <mat-option [value]="filterSetting.id">
                            {{ filterSetting.displayName }}
                        </mat-option>
                    }
                </mat-select>
                @if (filterSettingControl.invalid) {
                    <mat-error translate>Field is required</mat-error>
                }
            </mat-form-field>
        </div>
    }

    @if (!(filterSettings$ | async).length) {
        <div class="highlight-summary">
            <div class="no-filter-selected">{{ 'No filter selected' | translate }}</div>
        </div>
    }

    <div class="counter-details">
        <div class="counter-details-fields">
            @if (selectedStartDate$ | async; as start) {
                <div class="selectedStartDate">
                    <div class="highlight-summary">{{ 'Start' | translate }}</div>
                    &nbsp;{{ start | date: (dateFormat$ | async) }}
                </div>
            }

            @if (selectedEndDate$ | async; as end) {
                <div class="selectedEndDate">
                    <div class="highlight-summary">{{ 'End' | translate }}</div>
                    &nbsp;{{ end | date: (dateFormat$ | async) }}
                </div>
            }

            @if (selectedScenario$ | async; as scenario) {
                <div class="selectedScenario">
                    <div class="highlight-summary">{{ 'Scenario' | translate }}</div>
                    &nbsp;
                    {{ scenario.displayName }}
                    {{ scenario.start | date: (dateFormat$ | async) }} -
                    {{ scenario.end | date: (dateFormat$ | async) }}
                </div>
            }

            @if (!(scenarioValidity$ | async)) {
                <div class="scenarioValidity">
                    <div class="highlight-summary">{{ 'No scenario selected' | translate }}</div>
                </div>
            }
        </div>

        @if (countersVisibleSubject | async) {
            <div class="counters-explanation">
                <span>{{ countersExplanation }}</span>
            </div>
        }
    </div>

    <div class="counters-button">
        <button
            mat-button
            class="bol-button-solid bol-positive"
            [disabled]="!(countersFiltersValidity$ | async)"
            (click)="onGetCounters()"
        >
            <span translate>Calculate Counters</span>
        </button>
    </div>
</div>

