@if (dataLoaded$ | async) {
    <div class="content-wrapper">
        @if (interfaceConfig$ | async; as interfaceConfig) {
            <div class="card-content">
                <div class="content-column">
                    <div>
                        <mat-card
                            class="bol-card"
                            data-cy="ResourceImportsHolder"
                        >
                            <mat-card-content>
                                <div>
                                    <div>
                                        <h3
                                            class="resource-activity-title"
                                            translate
                                        >
                                            Resource and Activity Import
                                        </h3>
                                    </div>
                                    <div class="add-intervals">
                                        <button
                                            mat-button
                                            class="bol-button-solid bol-positive"
                                            [disabled]="addInterval$ | async"
                                            (click)="showAddIntervalContainer()"
                                            data-cy="addIntervalButton"
                                        >
                                            <div translate>Add interval</div>
                                        </button>
                                    </div>
                                    @if ((intervals$ | async).length > 0 || (addInterval$ | async)) {
                                        <div class="intervals-list-container">
                                            <div class="intervals-list-header interval-row">
                                                <div
                                                    class="interval-start"
                                                    translate
                                                >
                                                    Start time
                                                </div>
                                                <div
                                                    class="interval-end"
                                                    translate
                                                >
                                                    End time
                                                </div>
                                                <div
                                                    class="interval"
                                                    translate
                                                >
                                                    Interval (min)
                                                </div>
                                            </div>
                                            @for (interval of intervals$ | async; track interval.id) {
                                                <div>
                                                    <app-resource-activity-import-item
                                                        [ngModel]="interval"
                                                        [intervals]="intervals$ | async"
                                                        (removeIntervalEvent)="onDeleteInterval($event)"
                                                        (updateIntervalEvent)="onUpdateInterval($event)"
                                                    ></app-resource-activity-import-item>
                                                </div>
                                            }
                                            @if (addInterval$ | async) {
                                                <div>
                                                    <app-resource-activity-import-item
                                                        [ngModel]="newInterval"
                                                        [intervals]="intervals$ | async"
                                                        [addInterval]="true"
                                                        (removeIntervalEvent)="resetAddIntervalContainer()"
                                                        (addIntervalEvent)="onAddInterval($event)"
                                                    ></app-resource-activity-import-item>
                                                </div>
                                            }
                                        </div>
                                    }
                                    @if (showEmptyState$ | async) {
                                        <div class="empty-state">
                                            <mat-icon svgIcon="timelapse"></mat-icon>
                                            <div
                                                class="empty-state-description"
                                                translate
                                            >
                                                No intervals available yet
                                            </div>
                                        </div>
                                    }
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="column__full-width">
                    <mat-card class="bol-card card__full-height">
                        <h3
                            translate
                            class="other-settings-title"
                        >
                            Other Settings
                        </h3>
                        <mat-card-content class="bol-card-content">
                            <div>
                                <mat-form-field class="bol-form-field">
                                    <mat-label translate>Instance name</mat-label>
                                    <input
                                        matInput
                                        placeholder="{{ 'name' | translate }}"
                                        (change)="onConfigurationChange('instanceName', interfaceConfig.instanceName)"
                                        [(ngModel)]="interfaceConfig.instanceName"
                                        data-cy="InstanceName"
                                    />
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="bol-form-field">
                                    <mat-label translate>Read data from x days in the past</mat-label>
                                    <input
                                        matInput
                                        type="number"
                                        placeholder="{{ 'amount of days in past' | translate }}"
                                        [formControl]="formControls.daysInPast"
                                        data-cy="ReadDataFromPast"
                                    />
                                    @if (formControls.daysInPast.invalid) {
                                        <mat-error translate>
                                            {{ errorMessage }}
                                        </mat-error>
                                    }
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="bol-form-field">
                                    <mat-label translate>Read data up to x days in the future</mat-label>
                                    <input
                                        matInput
                                        type="number"
                                        placeholder="{{ 'amount of days in future' | translate }}"
                                        [formControl]="formControls.daysInFuture"
                                        data-cy="ReadDataFromFuture"
                                    />
                                    @if (formControls.daysInFuture.invalid) {
                                        <mat-error translate>
                                            {{ errorMessage }}
                                        </mat-error>
                                    }
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-checkbox
                                    class="bol-checkbox"
                                    (change)="
                                        onConfigurationChange(
                                            'miscSettings.weekend',
                                            interfaceConfig.miscSettings.weekend
                                        )
                                    "
                                    [(ngModel)]="interfaceConfig.miscSettings.weekend"
                                    data-cy="MarkWeekendsAsAbsences"
                                >
                                    <span translate>Mark weekends as absences</span>
                                </mat-checkbox>
                            </div>
                            <div>
                                <mat-checkbox
                                    class="bol-checkbox"
                                    [(ngModel)]="interfaceConfig.miscSettings.deleteOld"
                                    (change)="
                                        onConfigurationChange(
                                            'miscSettings.deleteOld',
                                            interfaceConfig.miscSettings.deleteOld
                                        )
                                    "
                                    data-cy="RemovePastAbsences"
                                >
                                    <span translate>Remove past absences</span>
                                </mat-checkbox>
                            </div>
                            @if (interfaceConfig.miscSettings.deleteOld) {
                                <div>
                                    <mat-form-field class="bol-form-field">
                                        <mat-label translate>Remove absences older than</mat-label>
                                        <input
                                            matInput
                                            type="number"
                                            placeholder="{{ 'amount of days old' | translate }}"
                                            [formControl]="formControls.daysOld"
                                            data-cy="RemovePastAbsencesInDays"
                                        />
                                        @if (formControls.daysOld.invalid) {
                                            <mat-error translate>
                                                {{ errorMessage }}
                                            </mat-error>
                                        }
                                    </mat-form-field>
                                </div>
                            }
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        }
    </div>
}
