<app-topbar></app-topbar>

@if ((setupInProgress$ | async) === false) {
    <router-outlet></router-outlet>
}

@if (
    (setupInProgress$ | async) &&
    (authenticationInProgress$ | async) === false &&
    (errorInAuthentication$ | async) === false
) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

@if (showRedBorder) {
    <app-test-border></app-test-border>
}

