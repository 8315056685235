@if (daypartsReadySubject | async) {
    <div class="schedule__overview__container">
        <app-empty-filters-panel
            [collapsePanelMessage]="'Show schedule'"
            [filtersPanelState]="filtersPanelState"
            (updateShowFiltersState)="updateShowFiltersStateAndGetScheduleData($event)"
        >
            <app-schedule-filters-header-old
                filtersHeader
                (hideFiltersPanelOnSelectedFilterSetting)="hidePanelOnSelectedFilterSetting()"
            ></app-schedule-filters-header-old>
            <div
                filtersContent
                class="filters__content"
            >
                <app-schedule-filters-old></app-schedule-filters-old>
                <app-schedule-filters-settings-old></app-schedule-filters-settings-old>
            </div>
        </app-empty-filters-panel>
        @if ((schedules$ | async)?.length === 0 || (schedules$ | async) === undefined || (schedules$ | async) === null) {
            <div class="empty__state__container">
                @if ((isLoadingSchedule$ | async) === false) {
                    <div class="empty__state__container__content">
                        <mat-icon svgIcon="calendar-today"></mat-icon>
                        <div class="empty__state__container__message__huge">
                            {{ 'No schedules to show yet.' | translate }}
                        </div>
                        <div>
                            {{ 'Use the filters panel to create a planning overview.' | translate }}
                        </div>
                    </div>
                }
                @else {
                    <div class="overlay-spinner-container">
                        <mat-spinner diameter="100"></mat-spinner>
                    </div>
                }
            </div>
        }
        @else {
            <div
                class="schedule__container"
                [ngClass]="{ 'extended-schedule-container': (filterSettings$ | async) > 0 }"
            >
                <div class="schedule">
                    <div class="navigation-header">
                        <button
                            mat-button
                            class="bol-button-text bol-gray"
                            (click)="onUpdatePeriod(-28)"
                        >
                            <mat-icon svgIcon="chevron-left"></mat-icon>
                            M
                        </button>
                        <button
                            mat-button
                            class="bol-button-text bol-gray"
                            (click)="onUpdatePeriod(-7)"
                        >
                            <mat-icon svgIcon="chevron-left"></mat-icon>
                            W
                        </button>
                        <button
                            mat-button
                            class="bol-button-text bol-gray"
                            (click)="onUpdatePeriod(-1)"
                        >
                            <mat-icon svgIcon="chevron-left"></mat-icon>
                            D
                        </button>
                        <button
                            mat-button
                            class="bol-button-text bol-gray"
                            (click)="onUpdatePeriod(1)"
                        >
                            D
                            <mat-icon
                                iconPositionEnd
                                svgIcon="chevron-right"
                            ></mat-icon>
                        </button>
                        <button
                            mat-button
                            class="bol-button-text bol-gray"
                            (click)="onUpdatePeriod(7)"
                        >
                            W
                            <mat-icon
                                iconPositionEnd
                                svgIcon="chevron-right"
                            ></mat-icon>
                        </button>
                        <button
                            mat-button
                            class="bol-button-text bol-gray"
                            (click)="onUpdatePeriod(28)"
                        >
                            M
                            <mat-icon
                                iconPositionEnd
                                svgIcon="chevron-right"
                            ></mat-icon>
                        </button>
                        <div class="date-period">
                            @if (scheduleStartDate$ | async; as startDate) {
                                <div>{{ startDate | date: (dateFormat$ | async) }}</div>
                            }
                            @if (scheduleEndDate$ | async; as endDate) {
                                <div>&nbsp;-&nbsp;{{ endDate | date: (dateFormat$ | async) }}</div>
                            }
                            &nbsp;(<span translate>week&nbsp;</span>
                            @if (scheduleStartDate$ | async; as scheduleStartDate) {
                                <strong>{{ scheduleStartDate | date: 'w' }}</strong>
                            }
                            )
                        </div>
                        <div class="configuration-container">
                            <mat-slide-toggle
                                class="bol-slide-toggle slide-toggle-position"
                                [formControl]="activityDialogControl"
                            >
                                <span
                                    class="activity-dialog"
                                    translate
                                >
                                    Enable activity details dialog
                                </span>
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <app-schedule-calendar-old></app-schedule-calendar-old>
                </div>
            </div>
        }
    </div>
}
