import { CommonModule, DecimalPipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BolNotificationModule } from '@ortec/bolster/notification';
import { BolSpinnerModule } from '@ortec/bolster/spinner';
import { BolPopoverModule } from '@ortec/bolster/popover';

import { SharedModule } from 'src/app/shared/shared.module';

import { PlanboardComponent } from './planboard.component';
import { PlanboardRoutingModule } from './planboard-routing.module';
import { ChangesComponent } from './changes/changes.component';
import { ChangesTableComponent } from './changes/components/changes-table/changes-table.component';
import { ChangesHelperService } from './changes/changes-helpers/changes-helpers.service';
import { SortingComponent } from './sorting/sorting.component';
import { FilteringComponent } from './filtering/filtering.component';
import { SortingPaletteComponent } from './sorting-palette/sorting-palette.component';
import { FilteringPaletteComponent } from './filtering-palette/filtering-palette.component';
import { MultiselectTreeComponent } from 'src/app/shared/components/inputs/multiselect-tree/multiselect-tree.component';
import { LoadingStatusIndicationComponent } from 'src/app/shared/components/loading-status-indication/loading-status-indication.component';

@NgModule({
    declarations: [
        PlanboardComponent,
        ChangesComponent,
        SortingComponent,
        FilteringComponent,
        ChangesTableComponent,
        SortingPaletteComponent,
        FilteringPaletteComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        SharedModule,
        ReactiveFormsModule,
        BolNotificationModule,
        BolPopoverModule,
        BolSpinnerModule,
        PlanboardRoutingModule,
        MultiselectTreeComponent,
        LoadingStatusIndicationComponent,
    ],
    providers: [
        DecimalPipe, 
        ChangesHelperService, 
        provideHttpClient(withInterceptorsFromDi())
    ],
})
export class PlanboardModule {}
