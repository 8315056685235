import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';

export interface OrganizationUnitsManagementState {
    manageMode: MANAGE_MODE;
    selectedOrganizationUnitId: number;
}

const initialState = {
    selectedOrganizationUnitId: undefined,
    manageMode: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization-units-management' })
export class OrganizationUnitsManagementStore extends Store<OrganizationUnitsManagementState> {
    constructor() {
        super(initialState);
    }
    public updateManageMode(manageMode: MANAGE_MODE): void {
        this.update(state => ({ ...state, manageMode }));
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.update(state => ({ 
            ...state,
            selectedOrganizationUnitId: id,
        }));
    }

    public setStateForNewOrganizationUnit(id: number): void {
        this.update(state => (
            { 
                ...state, 
                selectedOrganizationUnitId: id,
                manageMode: MANAGE_MODE.EDIT,
            })
        );
    }
}
