import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication';
import { API_SERVICE_OPTIONS, ApiServiceOptions } from 'src/app/app.module';

@Injectable({ providedIn: 'root' })
export class AuthenticationHttpInterceptor implements HttpInterceptor {

    constructor(
        private readonly authenticationService: AuthenticationService,
        @Inject(API_SERVICE_OPTIONS) protected apiServiceOptions: ApiServiceOptions
    ) { }

    public intercept(request: HttpRequest<any>, nextHttpHandler: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf(this.apiServiceOptions.apiUrl) > -1 && this.authenticationService.currentUserInStorage != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authenticationService.currentUserInStorage.token}`
                }
            });
        }

        return nextHttpHandler.handle(request);
    }
}
