<mat-form-field class="bol-form-field chip-list">
    <mat-chip-grid #chipList>
        @for (entity of selectedEntities$ | async; track entity.id) {
            <mat-chip-row
                [removable]="entity?.removable !== false"
                (removed)="removeEntity(entity)"
                class="bol-chip-input"
            >
                <span class="bol-chip-input-content">
                    {{ entity.displayName }}

                    @if (entity?.removable !== false) {
                        <button
                            mat-button
                            matChipRemove
                            class="bol-button-text bol-gray icon-only"
                            aria-label="Remove chip"
                        >
                            <mat-icon svgIcon="close-circle" />
                        </button>
                    }
                </span>
            </mat-chip-row>
        }
        <input
            #chipListInput
            placeholder="{{ placeholderText }}{{ required ? '*' : '' }}"
            [formControl]="chipFormControl"
            [matAutocomplete]="chipListAuto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (click)="focusEntitiesInput()"
            (matChipInputTokenEnd)="addEntity($event)"
        />
    </mat-chip-grid>
    <mat-autocomplete
        #chipListAuto="matAutocomplete"
        (optionSelected)="selectedEntity($event)"
        class="chip-autocomplete-dropdown"
    >
        @for (filteredEntity of filteredEntities$ | async; track filteredEntity.id) {
            <mat-option [value]="filteredEntity">
                {{ filteredEntity.displayName }}
            </mat-option>
        }
        @if (showNoResultsFoundText) {
            <mat-option disabled>{{ noResultsFoundText }}</mat-option>
        }
    </mat-autocomplete>
    <mat-error>{{ errorMessage }}</mat-error>
</mat-form-field>

