import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { ColumnEntity, EntityListColumnDefinition } from 'src/app/shared/models';
import { ISortSelectionModalData, SortSelectionDialogComponent } from './sort-selection-dialog/sort-selection-dialog.component';

export interface MultiselectSortingTableElement extends ColumnEntity {
    checked?: boolean;
    displayName: string;
    showInfoIcon?: boolean;
}

enum MOVE_DIRECTION {
    TOP = 'top',
    UP = 'up',
    DOWN = 'down',
    BOTTOM = 'bottom',
}

@Component({
    selector: 'app-multiselect-sorting-table',
    templateUrl: './multiselect-sorting-table.component.html',
    styleUrls: ['./multiselect-sorting-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiselectSortingTableComponent implements OnInit, OnDestroy {
    @Input() public hideSearch: boolean = false;
    @Input() public columnDefinition: Array<EntityListColumnDefinition>;
    @Input() public stripedTable: boolean = false;
    @Input() public entityName: string;
    @Input() public narrowRows: boolean = false;
    @Input() public readonly popoverMessage: string;
    @Input() public set entities(entities: Array<MultiselectSortingTableElement>) {
        this.setEntities(entities);
    };
    @Output() public readonly entitiesSortedIdsChanged = new EventEmitter<Array<number | string>>();
    @Output() public readonly selectedEntityIdsChanged = new EventEmitter<Array<number | string>>();
   
    public tableRendered$ = new BehaviorSubject<boolean>(false);
    public displayedColumns = [];
    public loadDataComplete = false;
    public initialEntities = [];
    public isAllSelected$: Observable<boolean>;
    public selectedEntityIds: Array<number | string> = [];
    public dataSource = new MatTableDataSource<MultiselectSortingTableElement>();

    private readonly isAllSelectedSubject = new BehaviorSubject<boolean>(false);
    private readonly subscription = new Subscription();

    constructor(private readonly dialogService: MatDialog) {}

    public ngOnInit(): void {
        this.isAllSelected$ = this.isAllSelectedSubject.asObservable();
        const selectedAll = this.isAllSelected();
        this.isAllSelectedSubject.next(selectedAll);
        this.displayedColumns = ['select', ...this.columnDefinition.map(column => column.entityProperty)];

        this.subscription.add(
            this.tableRendered$.pipe().subscribe()
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hideLoader(): void {
        setTimeout(() => {
            this.tableRendered$.next(true);
        }, 100);
    }

    public onSelectAll(event: MatCheckboxChange): void {
        const allEntityIds = this.dataSource.data.map(entity => entity.id);
        this.selectedEntityIds = event.checked ? allEntityIds : [];
        this.updateEntityStateForAll(event.checked);
        this.isAllSelectedSubject.next(event.checked);
        this.selectedEntityIdsChanged.emit(this.selectedEntityIds);
    }

    public onCheckboxClick(event: MatCheckboxChange, entity: MultiselectSortingTableElement): void {
        this.toggleEntitySelection(entity, event.checked);
        this.isAllSelectedSubject.next(this.isAllSelected());
        this.selectedEntityIdsChanged.emit(this.selectedEntityIds);
    }

    public onMoveToTop(): void {
        this.moveSelectedEntitiesTo(MOVE_DIRECTION.TOP);
    }

    public onMoveUp(): void {
        this.onMoveEntities(MOVE_DIRECTION.UP);
    }

    public onMoveDown(): void {
        this.onMoveEntities(MOVE_DIRECTION.DOWN);
    }

    public onMoveToBottom(): void {
        this.moveSelectedEntitiesTo(MOVE_DIRECTION.BOTTOM);
    }

    public onSortAlphabetically(): void {
        const dialogRef = this.dialogService.open(SortSelectionDialogComponent, {
            panelClass: 'dialog-small',
        });

        this.subscription.add(
            dialogRef.afterClosed().pipe(
                first(),
                tap((dialogData: { data: ISortSelectionModalData }) => {
                    if (dialogData) {
                        const dataCopy = [...this.dataSource.data];
                        const selectedEntities = dataCopy.filter(entity => this.selectedEntityIds.includes(entity.id));
                        selectedEntities.sort((a, b) => a.displayName.localeCompare(b.displayName));

                        if (dialogData.data.moveToTop) {
                            this.updateDataSource(selectedEntities, dataCopy, true);
                        } else if (dialogData.data.moveToBottom) {
                            this.updateDataSource(selectedEntities, dataCopy, false);
                        }
                    }
                })
            ).subscribe()
        );
    }

    private onMoveEntities(direction: MOVE_DIRECTION.UP | MOVE_DIRECTION.DOWN): void {
        if (this.selectedEntityIds.length === 0) {
            return;
        }
        const dataCopy = [...this.dataSource.data];

        if (direction === MOVE_DIRECTION.DOWN) {
            // Move selected entities down while preserving order
            for (let i = dataCopy.length - 1; i >= 0; i--) {
                const entity = dataCopy[i];
                if (this.selectedEntityIds.includes(entity.id)) {
                    const nextIndex = i + 1;
                    if (nextIndex < dataCopy.length && !this.selectedEntityIds.includes(dataCopy[nextIndex].id)) {
                        [dataCopy[i], dataCopy[nextIndex]] = [dataCopy[nextIndex], dataCopy[i]]; // Swap elements
                    }
                }
            }
        } else if (direction === MOVE_DIRECTION.UP) {
            // Move selected entities up while preserving order
            for (let i = 0; i < dataCopy.length; i++) {
                const entity = dataCopy[i];
                if (this.selectedEntityIds.includes(entity.id)) {
                    const previousIndex = i - 1;
                    if (previousIndex >= 0 && !this.selectedEntityIds.includes(dataCopy[previousIndex].id)) {
                        [dataCopy[i], dataCopy[previousIndex]] = [dataCopy[previousIndex], dataCopy[i]]; // Swap elements
                    }
                }
            }
        }

        this.dataSource.data = dataCopy;
        const orderedIds = dataCopy.map(entity => entity.id);
        this.entitiesSortedIdsChanged.emit(orderedIds);
    }

    private moveSelectedEntitiesTo(direction: MOVE_DIRECTION.TOP | MOVE_DIRECTION.BOTTOM): void {
        if (this.selectedEntityIds.length === 0) {
            return;
        }
    
        const dataCopy = [...this.dataSource.data];

        // Filter out the selected entities
        const selectedEntities = dataCopy.filter(entity => this.selectedEntityIds.includes(entity.id));
        
        // Filter out the unselected entities
        const unselectedEntities = dataCopy.filter(entity => !this.selectedEntityIds.includes(entity.id));
    
        // Combine the selected entities at the start/end and the unselected entities
        const newData = direction === MOVE_DIRECTION.TOP ? [...selectedEntities, ...unselectedEntities] : [...unselectedEntities, ...selectedEntities];
    
        // Update the data source with the new order
        this.dataSource.data = newData;
    
        const orderedIds = newData.map(entity => entity.id);
        this.entitiesSortedIdsChanged.emit(orderedIds);
    }

    private updateDataSource(selectedEntities: Array<MultiselectSortingTableElement>, dataCopy: Array<MultiselectSortingTableElement>, moveToTop: boolean): void {
        const unselectedEntities = dataCopy.filter(entity => !this.selectedEntityIds.includes(entity.id));
        const newData = moveToTop ? [...selectedEntities, ...unselectedEntities] : [...unselectedEntities, ...selectedEntities];
        this.dataSource.data = newData;
      
        const orderedIds = newData.map(entity => entity.id);
        this.entitiesSortedIdsChanged.emit(orderedIds);
    }

    private updateEntityStateForAll(checked: boolean): void {
        this.dataSource.data.forEach(entity => entity.checked = checked);
    }

    private toggleEntitySelection(entity: MultiselectSortingTableElement, checked: boolean): void {
        const index = this.selectedEntityIds.indexOf(entity.id);

        if (checked && index === -1) {
            this.selectedEntityIds.push(entity.id);
        } else if (!checked && index !== -1) {
            this.selectedEntityIds.splice(index, 1);
        }

        this.updateEntityState(entity, checked);
    }

    private updateEntityState(entity: MultiselectSortingTableElement, checked: boolean): void {
        const targetEntity = this.dataSource.data.find(e => e.id === entity.id);
        if (targetEntity) {
          targetEntity.checked = checked;
        }
    }

    private isAllSelected(): boolean {
        const selectedIdsLength = this.selectedEntityIds.length;
        const numRows = this.dataSource.data.length;

        return selectedIdsLength === numRows && numRows > 0;
    }

    private setEntities(entities: Array<MultiselectSortingTableElement>): void {
        if (!!entities) {
            this.selectedEntityIds = this.selectedEntityIds.filter(id => entities.some(entity => entity.id === id));
            entities.forEach(entity => {
                entity.checked = this.selectedEntityIds.includes(entity.id);
            });
            this.dataSource.data = entities;
            this.loadDataComplete = true;
            this.isAllSelectedSubject.next(this.isAllSelected());
        }
    }
}
