import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions, EventInput } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { audit, debounceTime, filter, map } from 'rxjs/operators';

import { cache } from 'src/app/core/rxjs-utils';
import { LanguageService } from 'src/app/shared/language';
import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';
import { GROUPING_OPTIONS, SHOW_OPTIONS } from '../../schedule-helpers/enums';
import { ScheduleHelperService } from '../../schedule-helpers/schedule-helper.service';
import {
    Schedule,
    ScheduleActivity,
    ScheduleResource,
    ScheduleShowOption,
} from '../../stores/schedule-store/schedule.model';
import { ScheduleQuery } from '../../stores/schedule-store/schedule.query';
import { ScheduleService } from '../../stores/schedule-store/schedule.service';
import { ScheduleDetailDialogComponent, ScheduleDetailDialogData } from '../../../schedule/components/schedule-detail-dialog/schedule-detail-dialog.component';

export interface LongActivity {
    maxStartDate: moment.Moment;
    maxEndDate: moment.Moment;
    activityId: number;
    activity: ScheduleActivity;
}

@Component({
    selector: 'app-schedule-calendar-old',
    templateUrl: './schedule-calendar.component.html',
    styleUrls: ['./schedule-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleCalendarOldComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('calendar', { static: false }) public calendarComponent: FullCalendarComponent;

    public schedules$!: Observable<Array<Schedule>>;
    public calendarOptions$!: Observable<CalendarOptions>;
    public showOptions$!: Observable<Array<ScheduleShowOption>>;
    public filteredScheduleResources$!: Observable<Array<ScheduleResource>>;
    public isLoadingSchedule$!: Observable<boolean>;
    public updatedResourceAreaWidth$!: Observable<number>;
    public getResourceAreaWidth$!: Observable<undefined>;

    public resourceAreaElement = null;
    public oneDayViewMode = false;

    private readonly updatedResourceAreaWidthSubject = new BehaviorSubject(this.scheduleQuery.getResourceAreaWidthSync());
    private readonly getResourceAreaWidthSubject = new BehaviorSubject(undefined);

    private slotMinWidth = this.scheduleQuery.getSlotMinWidthSync();
    private readonly subscription = new Subscription();
    private periodFrom = '';

    private readonly calendarView = {
        resourceTimelineDays: {
            type: 'resourceTimeline',
            dayCount: 7,
            slotLabelFormat: 'ddd D-M',
        }
    };
    private readonly defaultSlotMinWidth = 170;
    private readonly calendarOptions: CalendarOptions = {
        plugins: [momentPlugin, interactionPlugin, resourceTimelinePlugin],
        views: this.calendarView,
        navLinks: false,
        initialView: 'resourceTimelineDays',
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        slotDuration: '24:00:00',
        resourceAreaColumns: [{ field: 'title' }],
        viewDidMount: (info) => {
            this.resourceAreaElement = info.el.querySelectorAll('.fc-scroller-harness.fc-scroller-harness-liquid')[0];

            this.updateScheduleDates(info.view.activeStart, info.view.activeEnd);
        },
        resourceAreaHeaderContent: () => {
            //** The const is added so the resource area width can be set again in store when the user is moving the divider */
            this.getResourceAreaWidthSubject.next(undefined);

            return this.translateService.instant('Resources');
        },
        eventClick: (info) => { this.showActivityDialog(info); },
        eventContent: (eventInfo) => {
            if (eventInfo.event.extendedProps.customHtml) {
                return { html: eventInfo.event.extendedProps.customHtml };
            } 
            
            return true;
        },
        headerToolbar: false,
        height: '100%',
        slotMinWidth: this.defaultSlotMinWidth,
        eventOrder: 'sort',
        resourceAreaWidth: this.getResourceAreaWidth(),
        resources: [],
        events: [],
    };
    private readonly calendarOptionsSubject = new BehaviorSubject<CalendarOptions>({ ...this.calendarOptions });

    constructor(
        private readonly scheduleQuery: ScheduleQuery,
        private readonly scheduleService: ScheduleService,
        private readonly languageService: LanguageService,
        private readonly translateService: TranslateService,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly daypartQuery: DaypartQuery,
        private readonly dialogService: MatDialog,
    ) {
        this.calendarOptions$ = this.calendarOptionsSubject.asObservable();

        /**
         * Introduced to fix error: Please import the top-level fullcalendar lib before attempting to import a plugin.
         * Importing 'Calendar' forces loading/initializing of the core component before the plugin needs it.
         * The const is added for the imported Calendar so it will not be removed by a linting rule.
         */
        const name = Calendar.name;
    }

    public ngAfterViewInit(): void {
        this.subscription.add(
            this.updatedResourceAreaWidth$.subscribe(width => {
                if (width) {
                    this.scheduleService.updateResourceAreaWidth(width);
                }
            })
        );

        this.subscription.add(
            this.getResourceAreaWidth$.pipe(
                debounceTime(2000)
            ).subscribe(() => {
                this.getResourceAreaWidth();
            })
        );

        this.setScheduleData();
    }

    public ngOnInit(): void {
        this.showOptions$ = this.scheduleQuery.getShowOptions();
        this.updatedResourceAreaWidth$ = this.updatedResourceAreaWidthSubject.asObservable();
        this.getResourceAreaWidth$ = this.getResourceAreaWidthSubject.asObservable();

        this.setNumberOfDays();
        this.setCurrentLanguage();
        this.setGroupingOptionType();
        this.setSlotMinWidth();

        this.schedules$ = this.scheduleQuery.getSchedule().pipe(cache());
        this.filteredScheduleResources$ = this.scheduleQuery.getFilteredScheduleResources();
        this.isLoadingSchedule$ = this.scheduleQuery.getScheduleLoadingState().pipe(
            audit(x => x ? timer(500) : this.scheduleQuery.getScheduleLoadingState())
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private showActivityDialog(info): void {
        if (info.event.extendedProps.activityDialogEnabled && info.event.extendedProps.activityDialogId !== '' && info.event.extendedProps.activityDialogId !== 'null') {
            const dialogRef = this.dialogService.open(ScheduleDetailDialogComponent, { 
                data: {
                    eventId: +info.event.extendedProps.activityDialogId
                } as ScheduleDetailDialogData
            });
        }
    }

    private resetSchedule(): void {
        if ((this.calendarComponent !== undefined) && (this.calendarComponent.getApi() !== null)) {
            this.calendarComponent.getApi().destroy();
            this.calendarComponent.getApi().setOption('weekends', this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_SAT_SUN));

            // use incrementDate() from FullCalendar only when the user is navigating to a previous day
            if (this.scheduleQuery.getScheduleNoOfDaysToNavigateSync() === -1) {
                // if periodFrom it's on a Friday increment with -3 days
                const incrementNoOfDays = !this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_SAT_SUN) &&
                    new Date(this.periodFrom).getDay() === 5 ? -3 : -1;

                this.calendarComponent.getApi().incrementDate({days: incrementNoOfDays});
            }
            this.calendarComponent.getApi().changeView('resourceTimelineDays', this.periodFrom);
            this.calendarComponent.getApi().gotoDate(this.periodFrom);
            this.calendarComponent.getApi().render();
        }
    }

    private setSlotMinWidth(): void {
        this.subscription.add(
            this.scheduleQuery.getSlotMinWidth().subscribe(minWidth => {
                if (this.calendarView.resourceTimelineDays.dayCount > 7) {
                    this.slotMinWidth = minWidth;
                    this.calendarOptionsSubject.next({ ...this.calendarOptionsSubject.value, slotMinWidth: minWidth });
                }
            })
        );
    }

    private setCurrentLanguage(): void {
        this.subscription.add(
            this.languageService.currentLanguage$.subscribe((lang) => {
                this.calendarOptionsSubject.next({
                    ...this.calendarOptionsSubject.value,
                    locale: lang.code
                });
            })
        );
    }

    private setNumberOfDays(): void {
        this.subscription.add(
            this.scheduleQuery.getNumberOfDays().pipe(filter(numberOfDays => !!numberOfDays)).subscribe(numberOfDays => {
                this.calendarView.resourceTimelineDays.dayCount = numberOfDays;
        
                const calendarOptions = numberOfDays > 7 ?
                    { ...this.calendarOptionsSubject.value, views: {...this.calendarView}, slotMinWidth: this.slotMinWidth } :
                    { ...this.calendarOptionsSubject.value, views: {...this.calendarView}, slotMinWidth: this.defaultSlotMinWidth };
                this.calendarOptionsSubject.next(calendarOptions);

                this.oneDayViewMode = numberOfDays === 1;
            })
        );
    }

    private setGroupingOptionType(): void {
        this.subscription.add(
            this.scheduleQuery.getGroupingOptionType().subscribe(optionType => {
                switch (optionType) {
                    case GROUPING_OPTIONS.RESOURCE_TYPE: {
                        this.calendarOptionsSubject.next({
                            ...this.calendarOptionsSubject.value,
                            resourceOrder: 'resourceType',
                            resourceGroupField: 'resourceType'
                        });

                        return;
                    }
                    case GROUPING_OPTIONS.ORGANIZATION_UNIT: {
                        this.calendarOptionsSubject.next({
                            ...this.calendarOptionsSubject.value,
                            resourceOrder: 'organizationUnit',
                            resourceGroupField: 'organizationUnit'
                        });

                        return;
                    }
                    case GROUPING_OPTIONS.DAY_PART: {
                        this.calendarOptionsSubject.next({
                            ...this.calendarOptionsSubject.value,
                            resourceOrder: '',
                            resourceGroupField: 'daypart'
                        });

                        return;
                    }
                    case GROUPING_OPTIONS.NO_GROUPING: {
                        this.calendarOptionsSubject.next({
                            ...this.calendarOptionsSubject.value,
                            resourceOrder: '',
                            resourceGroupField: ''
                        });

                        return;
                    }
                }
            })
        );
    }

    private updateScheduleDates(startDate: Date, endDate: Date): void {
        this.scheduleService.updateScheduleStartDate(startDate);
        this.scheduleService.updateScheduleEndDate(moment(endDate).add(-1, 'days').toDate());
    }

    private setScheduleData(): void {
        this.subscription.add(
            this.filteredScheduleResources$.pipe(
                map((resources) => {
                    if (!resources) {
                        return;
                    }

                    const periodFrom = this.scheduleQuery.getScheduleStartDateSync();
                    this.periodFrom = periodFrom;
                    const dayOfWeek = (new Date(periodFrom)).getDay();
                    const daypartsOrder = this.daypartsOrderedByStartTime();
                    const resourceProperties = this.scheduleQuery.getFilteredResourcePropertiesSync();

                    const internalEvents = [];
                    const calendarResources = [];
                    const rootActivityIds = [];
                    resources.slice().map(res => res.activities.map(act => rootActivityIds.push(act.id)));

                    resources.forEach((resource => {
                        let resourceId = this.scheduleQuery.getGroupingOptionTypeSync() !== GROUPING_OPTIONS.ORGANIZATION_UNIT ?
                            resource.resourceName + resource.resourceOrganizationUnits.join(', ') : resource.resourceName;

                        const daypartResourceId = resource.isCurrentUser && this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ME_ON_TOP) ? this.getResourceDaypartOrder(resource, daypartsOrder) + ' |' + resourceId : this.getResourceDaypartOrder(resource, daypartsOrder) + resourceId;
                        resourceId = resource.daypart ? daypartResourceId : '|' + resourceId;

                        // adding the resource type id genereates more rows in the calendar for the same resource in the Resource Type grouping option mode  
                        if ((!resource.isCurrentUser || !this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ME_ON_TOP)) && this.scheduleQuery.getGroupingOptionTypeSync() === GROUPING_OPTIONS.RESOURCE_TYPE) {
                            resourceId += resource.resourceTypeId;
                        }

                        // adding the resource organization unit genereates more rows in the calendar for the same resource in the Organization unit grouping option mode  
                        if (this.scheduleQuery.getGroupingOptionTypeSync() === GROUPING_OPTIONS.ORGANIZATION_UNIT && (!resource.isCurrentUser || !this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ME_ON_TOP))) {
                            resourceId += resource.resourceOrganizationUnits[0];
                        }

                        if (resource.isCurrentUser && this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ME_ON_TOP) && this.scheduleQuery.getGroupingOptionTypeSync() !== GROUPING_OPTIONS.DAY_PART) {
                            resourceId = this.daypartQuery.getDaypartsSync().length > 0 ?
                                ' ' + resourceId : '| ' + resourceId;
                            calendarResources.push(
                                {
                                    id: resourceId,
                                    title: this.scheduleHelperService.getResourceDescription(resource, resourceProperties),
                                    resourceType: ' ' + this.translateService.instant('My schedule overview'),
                                    organizationUnit: ' ' + this.translateService.instant('My schedule overview'),
                                    daypart: resource.daypart ? resource.daypart : 'No activities',
                                }
                            );
                        } else {
                            calendarResources.push(
                                {
                                    id: resourceId,
                                    title: this.scheduleHelperService.getResourceDescription(resource, resourceProperties),
                                    resourceType: resource.resourceType,
                                    organizationUnit: resource.resourceOrganizationUnits.join(', '),
                                    daypart: resource.daypart ? resource.daypart : 'No activities',
                                }
                            );
                        }

                        if (resource.activities.length > 0) {
                            const sortedActivities = resource.activities.slice()
                                .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
                            let eventSort = 0;

                            sortedActivities.forEach(activity => {
                                internalEvents.push(...this.setCalendarEvents(
                                    activity,
                                    resourceId,
                                    resource.resourceName,
                                    eventSort
                                ));
                                const lastEventSort = internalEvents.reduce((prev, current) =>
                                    (prev.eventSort > current.eventSort) ? prev : current).eventSort;
                                eventSort = lastEventSort + 1;
                            });
                        }
                    }));

                    this.calendarOptionsSubject.next({
                        ...this.calendarOptionsSubject.value,
                        resources: calendarResources,
                        events: internalEvents,
                        firstDay: dayOfWeek,
                        initialDate: this.periodFrom,
                        resourceAreaWidth: this.scheduleQuery.getResourceAreaWidthSync()
                    });
                    this.resetSchedule();
                }),
            ).subscribe()
        );
    }

    private setCalendarEvents(
        activity: ScheduleActivity,
        scheduleResourceId: string,
        scheduleResourceName: string,
        eventSort: number
    ): Array<EventInput> {
        const startDate = moment(activity.date, 'MM/DD/YYYY HH:mm:ss').toDate();
        const internalEvents = [];

        const resourceTitle = this.scheduleHelperService.getResourceOrExtraResourceTitle(
            activity.organizationUnitName,
            activity.displayName,
            activity.shortName,
            this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ACTIVITY_NAME),
            this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME),
            this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ORGANIZATION_UNIT)
        );
        const activityEvents = this.scheduleHelperService.getResourcesOrExtraResourcesEvents(
            activity,
            resourceTitle,
            startDate,
            this.scheduleQuery.getActivityDialogStateSync(),
            eventSort,
            scheduleResourceId,
            this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_START_AND_END_TIME),
            this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_MEMO),
            false,
            activity.rootMemo
        );
        internalEvents.push(...activityEvents);
        eventSort += 3;

        const sortedExtraResources = this.scheduleHelperService.sortLeafActivities(activity.extraResources.slice(), activity.activityTypeId);
        const filteredDuplicatedAndNotRequiredExtraResources = sortedExtraResources.filter(extraResource =>
            extraResource.description.substr(0, extraResource.description.indexOf(' -')) !== scheduleResourceName && !extraResource.notRequired);

        filteredDuplicatedAndNotRequiredExtraResources.map((extraResource, i) => {
            let extraResourceName = extraResource.hasResource ? extraResource.extraResourceName : '<i>' + this.translateService.instant('Not assigned') + '</i>';
            if (extraResource.hasResource && !extraResource.extraResourceName) {
                extraResourceName = '<i>' + this.translateService.instant('Assigned') + '</i>';
            }

            const extraResourceDescription = this.scheduleHelperService.getResourceOrExtraResourceTitle(
                extraResource.organizationUnitName,
                extraResource.displayName,
                extraResource.shortName,
                this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ACTIVITY_NAME_EXTRA_RESOURCE),
                this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ACTIVITY_SHORT_NAME_EXTRA_RESOURCE),
                this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_ORGANIZATION_UNIT_EXTRA_RESOURCE),
                extraResourceName
            );

            const extraResourceEvents = this.scheduleHelperService.getResourcesOrExtraResourcesEvents(
                extraResource,
                extraResourceDescription,
                startDate,
                this.scheduleQuery.getActivityDialogStateSync(),
                eventSort,
                scheduleResourceId,
                this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_START_AND_END_TIME_EXTRA_RESOURCE),
                this.scheduleQuery.getShowOptionState(SHOW_OPTIONS.SHOW_MEMO_EXTRA_RESOURCE),
                true,
                undefined,
                extraResourceDescription,
            );
            internalEvents.push(...extraResourceEvents);
            eventSort += 3;
        });

        return internalEvents;
    }

    private daypartsOrderedByStartTime(): Map<string, number> {
        const orderedDayparts = new Map<string, number>();

        let orderNo = 1;
        this.daypartQuery.getDaypartsOrderByStartTime().map(daypart => {
            orderedDayparts.set(daypart.name, orderNo);
            orderNo++;
        });

        return orderedDayparts;
    }

    private getResourceAreaWidth(): string {
        if (this.resourceAreaElement) {
            const resourceAreaWidth = this.resourceAreaElement.clientWidth + 1.25;
            this.updatedResourceAreaWidthSubject.next(resourceAreaWidth);

            return resourceAreaWidth + 'px';
        }

        return '11%';
    }

    private getResourceDaypartOrder(resource: ScheduleResource, daypartsOrdered: Map<string, number>): number {
        return daypartsOrdered.get(resource.daypart);
    }
}
