<div class="bol-modal__header">
    <span class="modal-title">{{ 'Save filter changes' | translate }}</span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    @if (selectedFilterSetting) {
        <div>
            <mat-radio-group
                class="bol-radio-group bol-radio-group-vertical"
                [(ngModel)]="selectedOption"
            >
                <mat-radio-button
                    class="bol-radio-button"
                    [value]="overwriteSelectedOption"
                >
                    <span translate>Overwrite the selected filter with the new filter changes.</span>
                </mat-radio-button>
                <mat-radio-button
                    class="bol-radio-button"
                    [value]="saveNewOption"
                >
                    <span translate>Save the new filter changes as a new filter.</span>
                </mat-radio-button>
            </mat-radio-group>
            <br />
            @if (selectedOption === saveNewOption) {
                <mat-form-field class="bol-form-field no-errors-form-field">
                    <mat-label translate>Filter name</mat-label>
                    <input
                        matInput
                        [formControl]="filterSettingNameControl"
                        data-cy="saveNewFilterName"
                    />
                    @if (filterSettingNameControl.invalid) {
                        <mat-error translate>{{ 'Field is required' }}</mat-error>
                    }
                </mat-form-field>
            }
        </div>
    }

    @if (!selectedFilterSetting) {
        <p translate>Save the new filter changes as a new filter.</p>
        <mat-form-field class="bol-form-field no-errors-form-field">
            <mat-label translate>Filter name</mat-label>
            <input
                matInput
                [formControl]="filterSettingNameControl"
                data-cy="saveFirstFilterName"
            />
            @if (filterSettingNameControl.invalid) {
                <mat-error translate>{{ 'Field is required' }}</mat-error>
            }
        </mat-form-field>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            class="bol-button-solid bol-positive"
            [disabled]="filterSettingNameControl.invalid && selectedOption !== overwriteSelectedOption"
            (click)="onSaveChanges()"
            data-cy="saveFilterButton"
        >
            <span translate>Save</span>
        </button>
    </div>
</mat-dialog-actions>

