<mat-card
    class="bol-card"
    class="content-wrapper"
>
    <mat-card-content>
        <div class="title-status">
            <h3
                class="sorting-title"
                translate
                data-cy="sortPaletteTitle"
            >
                SORT-PALETTE.PAGE-TITLE
            </h3>

            <app-loading-status-indication [statusPageState]="statusPageState$ | async"></app-loading-status-indication>
        </div>

        <div class="activities-checkbox">
            <mat-checkbox
                class="bol-checkbox"
                [checked]="applySortingState$ | async"
                (change)="onApplySorting($event)"
                data-cy="applySortPalette"
            >
                <span translate="">SORT-PALETTE.APPLY-SORT</span>
            </mat-checkbox>
        </div>

        <div>
            <div class="filters-activity-types">
                <app-filter-tree-single-select
                    labelText="Activity type categories"
                    [entities]="activityTypeCategories$ | async"
                    [disabled]="false"
                    [selectedEntityId]="selectedActivityTypeCategoryId$ | async"
                    (selectedEntityIdChanged)="onSelectedActivityTypeCategoryChange($event)"
                    data-cy="activityTypeCategoryFilter"
                ></app-filter-tree-single-select>
            </div>
            <div>
                <app-multiselect-sorting-table
                    [entities]="filteredSortableActivityTypes$ | async"
                    [columnDefinition]="columnDefinition"
                    [narrowRows]="true"
                    [entityName]="'activity types'"
                    (entitiesSortedIdsChanged)="onReorderdEntitiesChanged($event)"
                ></app-multiselect-sorting-table>
            </div>
        </div>
    </mat-card-content>
</mat-card>

@if ((initialLoadingFinished$ | async) === false) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

