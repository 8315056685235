<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel
                    [disableCollapse]="true"
                    [cardClass]="'top-card'"
                >
                    <app-filter-tree-single-select
                        [entities]="organizationsForFiltering$ | async"
                        [labelText]="'Organization unit'"
                        [selectedEntityId]="selectedOrganizationUnit$ | async"
                        [dataCyLabel]="'organizationUnitFilter'"
                        (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
                    >
                    </app-filter-tree-single-select>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showUnderlyingUnits$ | async"
                            (change)="onUpdateShowChildUnits($event)"
                            data-cy="showResourceTypesForUnderlyingOrganizationUnits"
                        >
                            {{ 'Also show resource types for underlying organization units' | translate }}
                        </mat-checkbox>
                    </div>
                </app-entity-filter-panel>
            </div>
        </div>
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel
                    [disableCollapse]="true"
                    [cardClass]="'top-card'"
                >
                    <mat-button-toggle-group
                        #viewMode="matButtonToggleGroup"
                        class="bol-button-toggle-group"
                        [value]="defaultToggle"
                    >
                        <mat-button-toggle
                            class="toggle-button-text"
                            [value]="defaultToggle"
                            (change)="onToggleChange($event.value)"
                            data-cy="defaultMapping"
                        >
                            <span
                                class="text"
                                translate
                            >
                                Default mapping
                            </span>
                        </mat-button-toggle>
                        <mat-button-toggle
                            class="toggle-button-text"
                            [value]="departmentSpecificToggle"
                            (change)="onToggleChange($event.value)"
                            data-cy="specificMapping"
                        >
                            <span
                                class="text"
                                translate
                            >
                                Department specific mapping
                            </span>
                            <mat-icon
                                svgIcon="domain"
                                class="icon-specific-mapping"
                            ></mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>

                    <app-filter-tree-single-select
                        [entities]="owsDepartmentsForFiltering$ | async"
                        [labelText]="'OWS department'"
                        [selectedEntityId]="selectedOwsDepartment$ | async"
                        (selectedEntityIdChanged)="onSelectedOwsDepartmentChange($event)"
                        [dataCyLabel]="'departmentFilterOWS'"
                    >
                    </app-filter-tree-single-select>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showAllOwsResourceTypes$ | async"
                            (change)="onShowAllOwsResourceTypesChange($event)"
                            data-cy="showOwsPositionsFromAllDepartments"
                        >
                            {{ 'Show OWS Positions from all Departments' | translate }}
                        </mat-checkbox>
                    </div>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="hideUnlinkedOwsResourceTypes$ | async"
                            (change)="onHideUnlinkedOwsResourceTypesChange($event)"
                            data-cy="hideLinkedPositions"
                        >
                            {{ 'Hide linked Positions' | translate }}
                        </mat-checkbox>
                    </div>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showLinkedOwsResourceTypes$ | async"
                            (change)="onShowLinkedOwsResourceTypesChange($event)"
                            data-cy="onlySelectedPositions"
                        >
                            {{ 'Show linked Positions' | translate }}
                        </mat-checkbox>
                    </div>

                    <div class="unlinked__container">
                        <span class="unlinked__title">{{ 'Unlinked Positions' | translate }}:</span>
                        <div
                            class="bol-badge-solid"
                            [ngClass]="{
                                'bol-warning': (unlinkedResourceTypeCount$ | async) > 0,
                                'bol-primary': (unlinkedResourceTypeCount$ | async) === 0,
                            }"
                            data-cy="numberUnlinkedPositions"
                        >
                            {{ unlinkedResourceTypeCount$ | async }}
                        </div>
                    </div>

                    @if (
                        (selectedResourceTypeId$ | async) === undefined &&
                        (visibleOwsResourceTypes$ | async)?.length > 0
                    ) {
                        <div data-cy="noSelectionWarningMessage">
                            <mat-icon
                                svgIcon="alert"
                                class="inline-icon"
                            ></mat-icon>
                            <span
                                class="bol-text"
                                translate
                            >
                                Please select an OMRP Resource Type from the left panel to map to!
                            </span>
                        </div>
                    }

                    @if (currentMapping === departmentSpecificToggle) {
                        <div>
                            <hr class="line" />
                            <div
                                class="bol-text bol-text-medium"
                                translate
                            >
                                Select OWS department for mapping
                            </div>
                            <app-filter-tree-single-select
                                required
                                [entities]="owsDepartmentsForFiltering$ | async"
                                [labelText]="'OWS department'"
                                [selectedEntityId]="selectedOwsDepartmentForMapping$ | async"
                                [dataCyLabel]="'departmentFilterOWS'"
                                (selectedEntityIdChanged)="onSelectedOwsDepartmentSpecificMappingChange($event)"
                            >
                            </app-filter-tree-single-select>
                            @if ((selectedOwsDepartmentIdForMapping$ | async) === undefined) {
                                <div data-cy="noSelectionOwsDepartmentWarningMessage">
                                    <mat-icon
                                        svgIcon="alert"
                                        class="inline-icon"
                                    ></mat-icon>
                                    <span
                                        class="bol-text"
                                        translate
                                    >
                                        Please select an OWS department from the list!</span
                                    >
                                </div>
                            }
                        </div>
                    }
                </app-entity-filter-panel>
            </div>
        </div>
    </div>

    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OMRP Resource Types"
                    hideAddButton="true"
                    [entities]="visibleResourceTypes$ | async"
                    [selectedEntityId]="selectedResourceTypeId$ | async"
                    [icon]="icon"
                    [popoverMessage]="popoverMessage"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'resourceTypesOMRP'"
                    [cardClass]="'bottom-card'"
                    (selectedEntityIdChanged)="onSelectResourceType($event)"
                >
                </app-entity-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OWS Positions"
                    showCheckboxes="true"
                    hideAddButton="true"
                    enableSelectAll="true"
                    [entities]="visibleOwsResourceTypes$ | async"
                    [selectAllDisabled]="enableSelectAllPosition$ | async"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'positionsOWS'"
                    [cardClass]="'bottom-card'"
                    (selectionClickChanged)="onSelectOwsResourceTypeForMapping($event)"
                    (selectAllClicked)="onSelectAllOwsResourceTypeForMapping($event)"
                >
                </app-entity-list-panel>
            </div>
        </div>
    </div>
</div>

