import { Injectable } from '@angular/core';
import { EntityUIQuery, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Permission } from '../../models/Enums';
import { NestedTreeService } from '../../services';
import { EntityUI, EntityUIState } from '../entity-ui-models';
import { UserInfoQuery } from '../user-info-store/user-info.query';
import { IOrganizationUnitTree, OrganizationUnit } from './organization-unit.model';
import { OrganizationUnitState, OrganizationUnitStore } from './organization-unit.store';

@Injectable({
    providedIn: 'root'
})
export class OrganizationUnitQuery extends QueryEntity<OrganizationUnitState> {
    public ui: EntityUIQuery<EntityUIState>;

    constructor(
        protected store: OrganizationUnitStore,
        private readonly nestedTreeService: NestedTreeService,
        protected userInfoQuery: UserInfoQuery
    ) {
        super(store);
        this.createUIQuery();
    }

    public getUnsortedOrganiztionUnitsSync(): Array<OrganizationUnit> {
        return Object.values(this.getValue().entities);
    }

    public getOrganizationUnitSync(id: number): OrganizationUnit {
        return this.getEntity(id);
    }

    public getOrganizationUnitsSync(): Array<OrganizationUnit> {
        const organizationUnits = this.getUnsortedOrganiztionUnitsSync();

        return organizationUnits.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
    }

    public getOrganizationUnits(): Observable<Array<OrganizationUnit>> {
        return this.selectAll().pipe(map(organizationUnits =>
            organizationUnits.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1)
        ));
    }

    public getOrganizationUnitByIdSync(id: number): OrganizationUnit {
        const organizationUnits = this.getUnsortedOrganiztionUnitsSync();

        return organizationUnits.find(unit => unit.id === id);
    }

    public getFilteredIds(): Observable<Array<number>> {
        return this.select(state => state.ui.filteredIds);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getEntitiesLoadingStateForActivityTypes(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoadingForActivityTypes);
    }

    public getEntitiesLoadingStateSync(): boolean {
        return this.getValue().ui.entitiesLoading;
    }

    public getFilteredIdsSync(): Array<number> {
        return this.getValue().ui.filteredIds;
    }

    public getUIOrganizationUnit(id: number): Observable<EntityUI> {
        return this.ui.selectEntity(id);
    }

    public getOrganizationUnit(id: number): Observable<OrganizationUnit> {
        return this.selectEntity(id);
    }
    
    public getAllChildIdsForUnitSync(unitId: number): Array<number> {
        const children = this.getAllChildUnitsForUnitSync(unitId);
        const childrenIds = children.map(child => child.id);
        
        return childrenIds;
    }

    public getAllChildUnitsForUnitSync(unitId: number): Array<OrganizationUnit> {
        const organizationUnits = this.getUnsortedOrganiztionUnitsSync();
        const mutableUnits = organizationUnits.map(unit => ({ ...unit }));
        const unitTree = this.nestedTreeService.nestArray(mutableUnits as any);
        const branchOfUnit = this.nestedTreeService.getBranchOfEntity(unitTree, unitId);
        const children = this.nestedTreeService.getChildren(branchOfUnit, []) as any as Array<OrganizationUnit>;

        return children;
    }

    public getAllOrganizationUnitsIdsSync(orgUnits: Array<IOrganizationUnitTree>): Array<number> {
        let ids = [];
        orgUnits.forEach(orgUnit => {
            ids.push(orgUnit.id);
            if (orgUnit.children && orgUnit.children.length > 0) {
                ids = ids.concat(this.getAllOrganizationUnitsIdsSync(orgUnit.children));
            }
        });

        return ids;
    }

    public getSelectedOrganizationsSync(): Array<OrganizationUnit> {
        const organizationUnits = this.getUnsortedOrganiztionUnitsSync();
        const selectedOrganizationUnits = organizationUnits.filter(ou => this.getValue().ui.filteredIds.includes(ou.id));

        return selectedOrganizationUnits;
    }

    public getOrganizationsForFiltering(): Observable<Array<IOrganizationUnitTree>> {
        return this.getOrganizationUnits().pipe(
            map((units: Array<IOrganizationUnitTree>) => {
                const mutableUnits = units.map(unit => {
                    return { ...unit };
                });

                return this.nestedTreeService.nestArray(mutableUnits) as Array<IOrganizationUnitTree>;
            })
        );
    }

    public getOrganizationUnitsForActivityTypes(): Observable<Array<IOrganizationUnitTree>> {
        return this.select(state => state.organizationUnitsForActivityTypes).pipe(
            map((units: Array<IOrganizationUnitTree>) => {
                const mutableUnits = units.map(unit => ({ ...unit }));
                mutableUnits.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);

                return this.nestedTreeService.nestArray(mutableUnits) as Array<IOrganizationUnitTree>;
            })
        );
    }

    public getOrganizationUnitsForActivityTypesSync(): Array<IOrganizationUnitTree> {
        const organizationUnits = this.getValue().organizationUnitsForActivityTypes as Array<IOrganizationUnitTree>;
        const mutableUnits = organizationUnits.map(unit => ({ ...unit }));
        mutableUnits.sort((a, b) => a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);

        return this.nestedTreeService.nestArray(mutableUnits) as Array<IOrganizationUnitTree>;
    }

    public getOrganizationsWithMaxPermissionForFiltering(maxPermissionForCurrentUser: Permission): Observable<Array<IOrganizationUnitTree>> {
        return this.getOrganizationUnits().pipe(
            map((units: Array<IOrganizationUnitTree>) => {
                const filteredUnits = units.filter(unit => unit.maxPermissionForCurrentUser >= maxPermissionForCurrentUser);
                const mutableUnits = filteredUnits.map(unit => {
                    return { ...unit };
                });

                return this.nestedTreeService.nestArray(mutableUnits) as Array<IOrganizationUnitTree>;
            })
        );
    }

    public getOrganizationsForFilteringWithEmpty(): Observable<Array<IOrganizationUnitTree>> {
        return this.getOrganizationsForFiltering().pipe(
            map((units: Array<IOrganizationUnitTree>) => {
                units.unshift({children: [], parentId: undefined, displayName: '', id: 0});

                return units;
            })
            
        );
    }

    public getAllIds(): Observable<Array<number>> {
        return this.getOrganizationUnits()
            .pipe(
                map(units => units.map(unit => unit.id))
            );
    }

    public getOrganizationUnitsByIdsSync(ids: Array<number>): Array<OrganizationUnit> {
        const units = this.getUnsortedOrganiztionUnitsSync();

        return units.filter(u => ids.includes(u.id));
    }
}
