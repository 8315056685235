<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-table
    matSort
    class="activity-changes-table bol-table"
    [dataSource]="dataSource"
    data-cy="changesTable"
>
    <!-- Define the columns -->
    @for (column of columns; track $index) {
        <ng-container [matColumnDef]="column.columnDef">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [attr.data-cy]="column.header"
            >
                {{ translateService.get(column.header) | async }}
            </mat-header-cell>

            <mat-cell
                *matCellDef="let row"
                [class.custom-column-class]="column.columnDef === specificStylingColumn"
                [bolPopoverOverflow]="popoverHover"
                [ngStyle]="column.stylingProperty ? row[column.stylingProperty] : ''"
                [attr.data-cy]="column.cell(row)"
            >
                <ng-template #popoverHover> {{ column.cell(row) }} </ng-template>
                {{ column.cell(row) }}
            </mat-cell>
        </ng-container>
    }

    @for (column of columns; track $index) {
        <ng-container [matColumnDef]="getFilterFieldPerColumn(column)">
            <mat-header-cell *matHeaderCellDef>
                <div class="filter-container">
                    @if (column.filterType === changeFilterTypes.SEARCH) {
                        <div>
                            <mat-form-field class="filter-container-field bol-form-field">
                                <input
                                    matInput
                                    placeholder="{{ 'Search' | translate }}..."
                                    (input)="applyFilter($event.target.value, column)"
                                />
                            </mat-form-field>
                        </div>
                    }

                    @if (column.filterType === changeFilterTypes.MULTISELECT) {
                        <div class="filter-container-field">
                            <app-multiselect-tree
                                multiselectTreePlaceholder="{{ column.filterOptions.name }}"
                                [entities]="column.filterOptions.entities"
                                [preSelectedEntities]="column.filterOptions.selectedIds$ | async"
                                [required]="false"
                                [showSelectAll]="false"
                                [translationNeeded]="true"
                                [attr.data-cy]="column.filterOptions.name"
                                (selectedEntityIdsChanged)="onUpdateMultiselectFilter($event, column)"
                            >
                            </app-multiselect-tree>
                        </div>
                    }
                </div>
            </mat-header-cell>
        </ng-container>
    }

    <!-- Add the rows -->
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-header-row
        class="filter-header"
        *matHeaderRowDef="getFilterFieldPerColumns(); sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    <tr
        *matNoDataRow
        class="bol-table-empty-state"
    >
        @if ((entitiesLoadingState$ | async) === false) {
            <td
                [attr.colspan]="displayedColumns.length"
                translate
                data-cy="noEntityFound"
            >
                No results found.
            </td>
        }
    </tr>
    <tr
        *matNoDataRow
        class="bol-table-empty-state"
    >
        @if ((entitiesLoadingState$ | async) === false && entities?.length === 0) {
            <td
                translate
                data-cy="noDataAvailable"
            >
                {{ emptyStateMessage }}
            </td>
        }
    </tr>
</mat-table>

@if (entitiesLoadingState$ | async) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}
