import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationMode, AuthenticationService, OmrpAuthenticationService } from '../services/authentication';

@Injectable({
    providedIn: 'root'
})
export class OmrpAuthGuard  {
    constructor(
        private readonly omrpAuthenticationService: OmrpAuthenticationService,
        private readonly authenticationService: AuthenticationService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.omrpAuthenticationService.isAuthenticated) {
            return true;
        }

        if (this.omrpAuthenticationService.authMode === AuthenticationMode.LocalLogin) {
            this.omrpAuthenticationService.redirectToLocalLogin();

            return false;
        }

        if (this.omrpAuthenticationService.authMode === AuthenticationMode.Saml2) {
            this.omrpAuthenticationService.redirectToSaml2Login();

            return false;
        }

        this.authenticationService.login(state.url, true);
        return false;
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }
}
