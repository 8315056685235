import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { filter, first, map, tap } from 'rxjs/operators';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { MANAGE_MODE } from 'src/app/shared/models/Enums';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { ResourceProperty } from 'src/app/shared/stores/resource-property-store/resource-property.model';
import { ResourcePropertyService } from 'src/app/shared/stores/resource-property-store/resource-property.service';
import { ResourcePropertyQuery } from 'src/app/shared/stores/resource-property-store/resource-property.query';
import { UserInfoQuery } from 'src/app/shared/stores/user-info-store/user-info.query';

import { ResourcePropertiesManagementQuery } from './store/resource-properties-management.query';
import { ResourcePropertiesManagementService } from './store/resource-properties-management.service';
import { getResourcePropertyFormFields } from './resource-properties-form-definition';

@Component({
    selector: 'app-resource-properties',
    templateUrl: './resource-properties.component.html',
    styleUrls: ['./resource-properties.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ResourcePropertiesComponent implements OnInit, OnDestroy {
    public loadingError$: Observable<boolean>;
    public initialLoadingFinished$: Observable<boolean>;
    public manageMode$: Observable<MANAGE_MODE>;
    public formFields: Array<FormlyFieldConfig>;
    public showLoadingSpinner$ = new BehaviorSubject<boolean>(false);
    public selectedResourceProperty$= new BehaviorSubject<ResourceProperty>(undefined);
    public selectedResourcePropertyId$: Observable<number>;
    public resourceProperties$: Observable<Array<ResourceProperty>>;
    public selectedUIResourceProperty$: Observable<EntityUI>;
    public searchProperties: Array<string>;

    private readonly subscription = new Subscription();
    
    constructor(
        private readonly resourcepropertyService: ResourcePropertyService,
        private readonly resourcepropertyQuery: ResourcePropertyQuery,
        private readonly resourcepropertiesManagementQuery: ResourcePropertiesManagementQuery,
        private readonly resourcepropertiesManagementService: ResourcePropertiesManagementService,
        private readonly userInfoQuery: UserInfoQuery,
        private readonly autosaveService: AutosaveService
    ) { }

    public ngOnInit(): void {
        this.userInfoQuery.getUserLanguageCode().pipe(
            filter(userLanguageCode => !!userLanguageCode),
            first(),
        ).subscribe(userLanguageCode =>  {
            this.resourcepropertyService.getResourceProperties(userLanguageCode).pipe(first()).subscribe();
        });

        this.subscription.add(
            this.resourcepropertiesManagementQuery.getSelectedResourceProperty().pipe(
                tap(resourceProperty => this.selectedResourceProperty$.next(resourceProperty))
            ).subscribe()
        );

        this.formFields = getResourcePropertyFormFields();
        this.searchProperties = ['displayName'];
        this.loadingError$ = this.resourcepropertyQuery.selectError();
        this.selectedUIResourceProperty$ = this.resourcepropertiesManagementQuery.getSelectedUIResourceProperty();
        this.selectedResourcePropertyId$ = this.resourcepropertiesManagementQuery.getCurrentResourcePropertyId();
        this.manageMode$ = this.resourcepropertiesManagementQuery.getManageMode();
        this.resourceProperties$ = this.resourcepropertyQuery.getResourceProperties();

        this.initialLoadingFinished$ = this.resourcepropertyQuery.getEntitiesLoadingState().pipe(
            filter(loading => !loading),
            first(),
            map(loading => !loading)
        );
    }

    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
        this.subscription.unsubscribe();
    }

    public onSelectedResourcePropertyChange(id: number): void {
        this.autosaveService.saveUnsavedChanges();
        this.resourcepropertyService.setResourcePropertyToCleanUIState(id);
        const manageMode = id === undefined ? undefined : MANAGE_MODE.EDIT;
        this.resourcepropertiesManagementService.updateManageMode(manageMode);
        this.resourcepropertiesManagementService.updateSelectedResourcePropertyId(id);
    }
    
    public onDeleteResourceProperty(resourcepropertyId: number): void {
        this.showLoadingSpinner$.next(true);
        this.resourcepropertyService.deleteResourceProperty(resourcepropertyId).pipe(
            tap(() => {
                this.showLoadingSpinner$.next(false);
                this.resourcepropertiesManagementService.updateSelectedResourcePropertyId(undefined);
                this.resourcepropertiesManagementService.updateManageMode(undefined);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };
    
    public onAddNewResourceProperty(): void {
        const newResourceProperty = this.resourcepropertiesManagementQuery.getNewResourcePropertyObject();

        this.showLoadingSpinner$.next(true);
        this.resourcepropertyService.saveResourceProperty(newResourceProperty).pipe(
            tap((addedResourceProperty: ResourceProperty) => {
                this.showLoadingSpinner$.next(false);
                this.resourcepropertiesManagementService.setStateForNewResourceProperty(addedResourceProperty.id);
            }),
            first()
        ).subscribe({
            error: this.handleError.bind(this)
        });
    };

    public onEditResourceProperty(resourceproperty: ResourceProperty): void {
        this.resourcepropertyService.setResourcePropertyToPendingChanges(resourceproperty.id);
        this.autosaveService.autoSave(resourceproperty, this.saveResourceProperty.bind(this));   
    }

    private saveResourceProperty(resourceproperty: ResourceProperty): void {
        this.resourcepropertyService.saveResourceProperty(resourceproperty).pipe(
            first(),
        ).subscribe({
            error: this.handleSaveError.bind(this)
        });
    }

    private handleSaveError(resourceproperty: ResourceProperty): void {
        if (this.resourcepropertiesManagementQuery.getCurrentResourcePropertyIdSync() === resourceproperty.id) {
            this.selectedResourceProperty$.next({...resourceproperty});
        }
    }
    
    private handleError(): void {
        this.showLoadingSpinner$.next(false);
    }
}
    
       


