/* eslint-disable @typescript-eslint/member-ordering */
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Subscription } from 'rxjs';

import { NestedTreeService, TreeSearchService } from 'src/app/shared/services';
import { SelectionClickEvent } from 'src/app/shared/models';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';

export interface CheckboxTableElement {
    displayName: string;
    checked: boolean;
    disabled: boolean;
}

interface ColumnEntity {
    id: number;
}

@Component({
    selector: 'app-entity-tree-list-panel',
    templateUrl: './entity-tree-list-panel.component.html',
    styleUrls: ['./entity-tree-list-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TreeSearchService],
})
export class EntityTreeListPanelComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @Output() public readonly selectedEntityIdChanged = new EventEmitter<number>();
    @Output() public readonly selectionClickChanged = new EventEmitter<SelectionClickEvent>();
    @Output() public readonly addEntityClicked = new EventEmitter<undefined>();

    @Input() public cardClass?: string;
    @Input() public readonly entityName: string;
    @Input() public readonly loadingError: boolean;
    @Input() public readonly selectedEntityId: number;
    @Input() public readonly mode: MANAGE_MODE;
    @Input() public readonly dataCyLabel: string;
    @Input() public readonly hideAddButton: boolean = false;
    @Input() public readonly showCheckboxes: boolean = false;
    @Input() public readonly disableSelection: boolean = false;
    @Input() public readonly searchProperties: Array<string> = ['displayName'];
    @Input() private set entities(entities: Array<ColumnEntity>) {
        this.setEntities(entities);

        this.allEntityIds = this.nestedTreeService.getAllChildIdsForNestedEntities(entities);
    }

    public dataSource = new MatTreeNestedDataSource<any>();
    public treeControl = new NestedTreeControl<any>(node => node.children);
    public noVisibleEntities: boolean;
    public allEntityIds: Array<number>;

    private expandedEntityIds: Array<number> = [];
    private readonly subscription = new Subscription();

    constructor(
        private readonly treeSearchService: TreeSearchService,
        private readonly nestedTreeService: NestedTreeService,
    ) { }

    public ngOnInit(): void {
        this.subscription.add(
            this.treeControl.expansionModel.changed.subscribe((event) => {
                this.setNodesWithIndexValue(this.dataSource.data);
    
                if (event.added.length > 0 && !this.expandedEntityIds.find(id => id === event.added[0]?.id)) {
                    this.expandedEntityIds.push(event.added[0]?.id);
                } else if (event.removed.length > 0) {
                    this.expandedEntityIds = this.expandedEntityIds.filter(id => id !== event.removed[0]?.id);
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onEntitySelected(entity: any): void {
        if (!this.disableSelection) {
            this.selectedEntityIdChanged.emit(entity.id);
        }
    }

    public onAddEntityClicked(): void {
        this.addEntityClicked.emit(undefined);
    }

    public onCheckboxClick(event: MatCheckboxChange, entity): void {
        this.selectionClickChanged.emit({selected: event.checked, entity});
    }

    public searchEntities(value: string) {
        const filterValue = value.toLowerCase();
        this.treeSearchService.setEntityVisiblityState(filterValue, this.dataSource.data, this.searchProperties);
        this.setNodesWithIndexValue(this.dataSource.data);

        this.noVisibleEntities = this.treeSearchService.noVisibleEntities;
        if (filterValue === '') {
            this.noVisibleEntities = false;
        }
    }

    public hasChild(_: number, node: any): boolean {
        return !!node.children && node.children.length > 0;
    }

    private setEntities(entities: Array<ColumnEntity>): void {
        if (entities && this.searchProperties) {
            this.dataSource.data = entities;
            this.treeControl.dataNodes = entities;

            this.expandDataNodes(this.expandedEntityIds, this.treeControl.dataNodes);

            this.treeSearchService.setEntityVisiblityState('', this.dataSource.data, this.searchProperties);
            this.setNodesWithIndexValue(entities);
            this.noVisibleEntities = this.treeSearchService.noVisibleEntities;
        }

        // If the selected entity cannot be found in the entities, deselect the entity
        if (this.mode !== MANAGE_MODE.ADD && this.selectedEntityId && !this.allEntityIds.find(id => id === this.selectedEntityId)) {
            this.selectedEntityIdChanged.emit(undefined);
        }
    }

    private setNodeIndex(nodes: Array<any>, index: number, expandedNodes: Array<number>): void {
        return nodes.forEach(entity => {
            if (entity.visible) {
                entity.index = index;

                index++;
            } else {
                entity.index = undefined;
            }

            if (entity.children && expandedNodes.includes(entity.id) && entity.visible) {
                this.setNodeIndex(entity.children, index, expandedNodes);

                index += entity.children.filter(node => node.visible).length;
            }
        });
    }
    
    private setNodesWithIndexValue(entities: Array<any>): void {
        const expandedNodes = (this.treeControl.expansionModel.selected || []).map(node => node?.id);

        this.setNodeIndex(entities, 1, expandedNodes || []);
    }

    private expandDataNodes(expandedEntityIds: Array<number>, dataNodes: Array<any>): void {
        expandedEntityIds.forEach(id => {
            const expandableNode = dataNodes.find(node => node.id === id);

            if (expandableNode) {
                this.treeControl.expand(expandableNode);

                if (expandableNode.children) {
                    this.expandDataNodes(expandedEntityIds, expandableNode.children);
                }
            }
        });
    }
}
