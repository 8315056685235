<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-table
    data-cy="entityManageGridTable"
    matSort
    [dataSource]="dataSource"
    class="entity-manage-grid bol-table bol-table-bordered-rows"
>
    @for (column of columns; track column.columnDef; let lastColumn = $last) {
        <ng-container [matColumnDef]="column.columnDef">
            <mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                [attr.data-cy]="column.header"
            >
                <div
                    class="popover-container-grid"
                    [bolPopoverOverflow]="popoverHoverHeader"
                >
                    <ng-template #popoverHoverHeader> {{ column.header | translate }} </ng-template>
                    {{ translateService.get(column.header) | async }}
                </div>
            </mat-header-cell>

            <mat-cell *matCellDef="let row; let lastCell = last">
                <div
                    class="table-cell"
                    [class.custom-column-class]="column.columnDef === specificStylingColumn"
                    [ngStyle]="column.stylingProperty ? row[column.stylingProperty] : ''"
                    [attr.data-cy]="column.cell(row)"
                >
                    @if (column.icon) {
                        <div data-cy="iconManageGrid">
                            <mat-icon [svgIcon]="column.icon(row)"></mat-icon>
                        </div>
                    }

                    <div
                        class="popover-container-grid"
                        [bolPopoverOverflow]="popoverHover"
                    >
                        {{ column.cell(row) }}
                    </div>

                    @if (lastColumn && rowMenuOptions.length > 0) {
                        <button
                            mat-icon-button
                            class="row-menu-button"
                            [matMenuTriggerFor]="rowMenu"
                            data-cy="rowMenuButton"
                        >
                            <mat-icon svgIcon="dots-vertical"></mat-icon>
                        </button>

                        <mat-menu 
                            #rowMenu="matMenu"
                            class="bol-menu"
                        >
                            @for (rowMenuOption of rowMenuOptions; track rowMenuOption.label) {
                                <button
                                    mat-menu-item
                                    [attr.data-cy]="rowMenuOption.dataCy ?? rowMenuOption.label"
                                    [attr.data-cy]="rowMenuOption.label"
                                    (click)="rowMenuOption.click(row)"
                                >
                                    <mat-icon [svgIcon]="rowMenuOption.icon"></mat-icon>
                                    <span translate>{{ rowMenuOption.label }}</span>
                                </button>
                            }
                        </mat-menu>
                    }

                    <ng-template #popoverHover> {{ column.cell(row) }} </ng-template>
                </div>
            </mat-cell>
        </ng-container>
    }

    <!-- Create filter fields based on filter type -->
     @for (column of columns; track column.columnDef) {
        <ng-container [matColumnDef]="getFilterFieldPerColumn(column)">
            <mat-header-cell
                *matHeaderCellDef
                class="filter-header-cell"
            >
                <div class="filter-container">
                    @if (column.filterType === filterType.SEARCH) {
                        <div>
                            <mat-form-field
                                class="bol-form-field filter-container-field-search"
                                data-cy="searchEntityField"
                            >
                                <input
                                    matInput
                                    placeholder="{{ 'Search' | translate }}..."
                                    (input)="applyFilter($event.target.value, column)"
                                />
                            </mat-form-field>
                        </div>
                    }

                    @if (column.filterType === filterType.MULTISELECT) {
                        <div class="filter-container-field">
                            <app-multiselect-tree
                                multiselectTreePlaceholder="{{ column.filterOptions.name }}"
                                [entities]="column.filterOptions.entities"
                                [preSelectedEntities]="column.filterOptions.selectedIds$ | async"
                                [required]="false"
                                [showSelectAll]="false"
                                [translationNeeded]="column.filterOptions.translationNeeded"
                                [attr.data-cy]="column.filterOptions.name"
                                (selectedEntityIdsChanged)="onUpdateMultiselectFilter($event, column)"
                            >
                            </app-multiselect-tree>
                        </div>
                    }
                </div>
            </mat-header-cell>
        </ng-container>
     }

    <!-- Add the rows -->
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-header-row
        class="filter-header"
        *matHeaderRowDef="getFilterFieldPerColumns(); sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    <tr
        *matNoDataRow
        class="bol-table-empty-state"
    >
        @if ((entitiesLoadingState$ | async) === false) {
            <td
                [attr.colspan]="displayedColumns.length"
                data-cy="noEntityFound"
            >
                {{ 'NO-ENTITIES-FOUND' | translate: { entityName: entityName | translate | lowercase } }}
            </td>
        }
    </tr>
</mat-table>

@if ((entitiesLoadingState$ | async) === true) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}
