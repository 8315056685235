<mat-card
    class="bol-card full-height"
    [style.display]="(tableRendered$ | async) === false ? 'none' : ''"
>
    <mat-card-content>
        @if (!hideSearch && loadDataComplete) {
            <mat-form-field class="bol-form-field entity-search">
                <mat-label>{{ searchFieldPlaceholder | translate }}</mat-label>
                <input
                    #input
                    matInput
                    autocomplete="off"
                    [formControl]="searchControl"
                    data-cy="searchSingleSelectTable"
                />
                @if (!searchControl || !searchControl.value || searchControl.value.length === 0) {
                    <button
                        mat-button
                        matSuffix
                        disabled
                        class="bol-button-text"
                    >
                        <mat-icon
                            svgIcon="magnify"
                            class="search-icon"
                        ></mat-icon>
                    </button>
                }
                @if (searchControl && searchControl.value && searchControl.value.length > 0) {
                    <button
                        mat-button
                        matSuffix
                        class="cancel-button bol-button-text icon-only bol-gray"
                        (click)="onResetValue()"
                    >
                        <mat-icon
                            svgIcon="close"
                            class="search-icon"
                        ></mat-icon>
                    </button>
                }
            </mat-form-field>
        }
        <section
            #tableContainer
            class="multiselect-table-container"
        >
            <mat-table
                class="multiselect-table bol-table bol-table-bordered-rows"
                [dataSource]="dataSource"
                [ngClass]="{ 'striped-table': stripedTable, 'narrow-rows': narrowRows }"
                (contentChanged)="hideLoader()"
                data-cy="multiselectTable"
            >
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                            class="bol-checkbox first-column"
                            [checked]="isAllSelected$ | async"
                            (change)="$event ? onSelectAll($event) : null"
                        >
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let row"
                        class="cell-wrapper"
                    >
                        <div class="cell-select">
                            @if (row.expandable) {
                                <button
                                    mat-icon-button
                                    class="button-expandable"
                                    [class.button-children-selected]="row.childrenSelected"
                                    [style.visibility]="!row.expandable ? 'hidden' : ''"
                                    [style.marginLeft.px]="row.level * 18"
                                    (click)="treeControl.toggle(row)"
                                >
                                    <mat-icon
                                        class="mat-icon-rtl-mirror"
                                        [svgIcon]="treeControl.isExpanded(row) ? 'chevron-down' : 'chevron-right'"
                                    >
                                    </mat-icon>
                                </button>
                            }
                            @if (!row.hideCheckbox) {
                                <mat-checkbox
                                    class="bol-checkbox"
                                    [style.marginLeft.px]="!row.expandable ? 40 + row.level * 18 : ''"
                                    [style.width.px]="!row.expandable ? 40 + row.level * 18 : ''"
                                    [disabled]="row.disabled"
                                    [checked]="row.checked"
                                    (click)="$event.stopPropagation()"
                                    (change)="onCheckboxClick($event, row)"
                                >
                                </mat-checkbox>
                            }
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- Dynamic Columns -->
                @for (column of columnDefinition; track $index) {
                    <ng-container [matColumnDef]="column.entityProperty">
                        <mat-header-cell
                            *matHeaderCellDef
                            [attr.data-cy]="column.header"
                        >
                            <div
                                class="header-popover-container bol-text bol-text-medium"
                                [bolPopoverOverflow]="popoverHoverHeader"
                            >
                                <ng-template #popoverHoverHeader>
                                    {{ column.columnDisplayName | translate }}
                                </ng-template>
                                {{ column.columnDisplayName | translate }}
                            </div>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <ng-template #popoverHover>
                                {{ column.cell ? column.cell(row) : row[column.entityProperty] }}
                            </ng-template>
                            <div
                                class="table-cell popover-multi-line"
                                [bolPopoverOverflow]="popoverHover"
                                [ngStyle]="column.stylingProperty ? row[column.stylingProperty] : ''"
                                [style.marginLeft.px]="
                                    column.entityProperty === firstColumnAfterCheckbox ? row.level * 18 : ''
                                "
                            >
                                {{ column.cell ? column.cell(row) : row[column.entityProperty] }}
                            </div>
                        </mat-cell>
                    </ng-container>
                }

                <!-- Header Row -->
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

                <!-- Data Row -->
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    tabindex="0"
                ></mat-row>
                <tr
                    *matNoDataRow
                    class="bol-table-empty-state"
                >
                    <td
                        [attr.colspan]="displayedColumns.length"
                        translate
                        data-cy="noEntityFound"
                    >
                        No results found.
                    </td>
                </tr>

                <tr
                    *matNoDataRow
                    class="bol-table-empty-state"
                >
                    @if (!loadDataComplete) {
                        <td
                            translate
                            data-cy="noDataAvailable"
                        >
                            No data available.
                        </td>
                    }
                </tr>
            </mat-table>
        </section>
    </mat-card-content>
</mat-card>
@if (!(tableRendered$ | async)) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

