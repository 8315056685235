import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';

export const dataCyExtension: FormlyExtension = {
    prePopulate(field: FormlyFieldConfig): void {
        const attributes = field.props?.attributes ?? {};
        attributes['data-cy'] = field.key as string;
        field.props = {
            ...(field.props || {}),
            attributes
        };
    }
};