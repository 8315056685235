import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { filterUndefined } from 'src/app/core/rxjs-utils';
import { LanguageService } from 'src/app/shared/language';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';

import { ActivityChangeQuery } from '../../store/activity-change.query';
import { ActivityChangeTypeForTable, CHANGE_COLUMNS_FILTER, CHANGE_FILTER_TYPES } from '../../store/activity-change.model';
import { ActivityChangeService } from '../../store/activity-change.service';
import { ChangesHelperService } from '../../changes-helpers/changes-helpers.service';

export interface FilterGridColumn {
	columnDef: string;
	header: string;
	stylingProperty?: string;
	filterType?: string;
	filterOptions?: FilterDetails;
	cell: (element: any) => any;
	class?: (element: any) => string;
	sort?: {
		direction: 'asc' | 'desc';
	};
	sortValue?: (element: any) => any;
}

export interface FilterDetails {
	entities?: Array<any>;
	name?: string;
	selectedIds$?: Observable<Array<number>>;
}

@Component({
	selector: 'app-changes-table',
	templateUrl: './changes-table.component.html',
	styleUrls: ['./changes-table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangesTableComponent implements OnInit, AfterViewInit {
	@ViewChild(MatSort) public sort: MatSort;
	@Input() public title: string;
	@Input() public set entities(entities: Array<ActivityChangeTypeForTable>) {
		this.setEntities(entities);
	}
	@Input() public columns: Array<FilterGridColumn> = [];
	public dataSource = new MatTableDataSource<any>([]);
	public entitiesLoadingState$: Observable<boolean>;
	public specificStylingColumn = 'activityTypeDisplayName';
	public displayedColumns: Array<string> = [];
	public changeFilterTypes = CHANGE_FILTER_TYPES;
	public eventTypeColumn: string = CHANGE_COLUMNS_FILTER.CHANGE_TYPE;
	public orginColumn: string = CHANGE_COLUMNS_FILTER.ORIGIN;
	public filterValues = {};
	public emptyStateMessage = 'No changes.';
	public dateFormat: string;

	public translateService = inject(TranslateService);
	private readonly changeDetectorRef = inject(ChangeDetectorRef);
	private readonly activityChangeService = inject(ActivityChangeService);
	private readonly activityChangeQuery = inject(ActivityChangeQuery);
	protected languageService = inject(LanguageService);
	private readonly displaySettingQuery = inject(DisplaySettingQuery);
	private readonly displaySettingService = inject(DisplaySettingService);
	private readonly changesHelperService = inject(ChangesHelperService);

	public ngOnInit(): void {
		this.dateFormat = this.languageService.getDateTimeFormatMomentByCurrentLanguage();
		this.displaySettingService.getDisplaySettings().pipe(first()).subscribe();
		this.entitiesLoadingState$ = this.activityChangeQuery.getEntitiesLoadingState();
		this.displayedColumns = this.columns.map((c) => c.columnDef);
		this.changeDetectorRef.detectChanges();
		this.dataSource = new MatTableDataSource();
		this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
		this.setInitialStatesFromDisplaySettingsForEventType();
		this.setInitialStatesFromDisplaySettingsForOrigin();
	}

	public ngAfterViewInit(): void {
		this.dataSource.sort = this.sort;
	}

	public getFilterFieldPerColumns(): Array<string> {
		return this.displayedColumns.map((column) => 'filter-' + column);
	}

	public getFilterFieldPerColumn(column: FilterGridColumn): string {
		return 'filter-' + column.columnDef;
	}

	public onUpdateMultiselectFilter(value: Array<any>, column: any): void {
		if (column.columnDef === this.eventTypeColumn) {
			this.activityChangeService.updateSelectedActionTypes(value);
			this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.EVENT_TYPE, value).pipe(first()).subscribe();
		} else if (column.columnDef === this.orginColumn) {
			this.activityChangeService.updateSelectedOrginsState(value);
			this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.ORIGIN_CHANGES, value).pipe(first()).subscribe();
		}

		const selectedValues = value.join(', ');
		this.applyFilter(selectedValues, column);
	}

	public applyFilter(value: string, column: any): void {
		this.filterValues[column.columnDef] = value.trim().toLowerCase();
		this.applyFilters();
	}

	// Apply filters for all columns using filterValues object
	public applyFilters(): void {
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
		this.dataSource.filterPredicate = (data: any) => {
			for (const col of this.columns) {
				const filterValue = this.filterValues[col.columnDef];
				if (filterValue) {
					if (col.filterType === this.changeFilterTypes.SEARCH) {
						if (!this.applySearchFilter(data, col.columnDef, filterValue)) {
							return false;
						}
					} else if (col.filterType === this.changeFilterTypes.MULTISELECT) {
						if (!this.applySelectionFilter(data, col.columnDef, filterValue)) {
							return false;
						}
					}
				}
			}

			return true;
		};

		const filterValuesArray = Object.entries(this.filterValues).map(([key, value]) => value);
		this.dataSource.filter = filterValuesArray.length ? filterValuesArray.join(' ') : '';
	}

	private applySearchFilter(data: any, column: any, filterValue: string): boolean {
		return data[column].toString().toLowerCase().includes(filterValue);
	}

	private applySelectionFilter(data: any, column: any, filterValue: string): boolean {
		const filterValues = filterValue.split(',').map((val) => val.trim());
		const lowerCaseValue = data[column].toString().toLowerCase();

		if(column === this.eventTypeColumn) {
			const eventTypeNames = filterValues.map((id: string) => {
				const eventType = this.changesHelperService.getActionTypeByNumber(parseInt(id, 10));

				return eventType ? eventType.toLowerCase() : '';
			});

			return eventTypeNames.includes(lowerCaseValue);
		} else if(column === this.orginColumn) {
			const originNames = filterValues.map((id: string) => {
				const originType = this.changesHelperService.getOriginTypeByNumber(parseInt(id, 10));
				
				return originType ? originType.toLowerCase() : '';
			});
	
			return originNames.some((name: string) => {
				return data[column].toString().toLowerCase().includes(name);
			});
		}
	}

	private readonly sortingDataAccessor = (data: any, sortHeaderId: string): string => {
		const sortHeaderIds: Array<string> = sortHeaderId.split('.');
		let dataValue = { ...data };
		sortHeaderIds.forEach((id: string) => (dataValue = dataValue ? dataValue[id] : dataValue));

		const columnConfig = this.columns.find((c) => c.columnDef === sortHeaderId);
		if (columnConfig && columnConfig.sortValue) {
			return columnConfig.sortValue(data);
		}

		if (typeof dataValue === 'string') {
			return dataValue.toLocaleLowerCase();
		}

		return data[sortHeaderId];
	};

	private setInitialStatesFromDisplaySettingsForEventType(): void {
        this.displaySettingQuery.getValueBySettingName<Array<number>>(DISPLAY_SETTING_NAMES.EVENT_TYPE, 'array').pipe(
            filterUndefined(), 
            first(),
            tap(ids => {
				const selectedValues = ids.join(', ');
				const column = this.columns.find(c => c.columnDef === CHANGE_COLUMNS_FILTER.CHANGE_TYPE);
				this.applyFilter(selectedValues, column);
				this.activityChangeService.updateSelectedActionTypes(ids)
			})
        ).subscribe();
    }

	private setInitialStatesFromDisplaySettingsForOrigin(): void {
        this.displaySettingQuery.getValueBySettingName<Array<number>>(DISPLAY_SETTING_NAMES.ORIGIN_CHANGES, 'array').pipe(
            filterUndefined(), 
            first(),
            tap(ids => {
				const selectedValues = ids.join(', ');
				const column = this.columns.find(c => c.columnDef === CHANGE_COLUMNS_FILTER.ORIGIN);
				this.applyFilter(selectedValues, column);
				this.activityChangeService.updateSelectedOrginsState(ids)
			})
        ).subscribe();
    }

	private setEntities(entities: Array<ActivityChangeTypeForTable>): void {
		if (!!entities) {
			this.dataSource.data = entities;
		}
	}
}
