<mat-card
    class="bol-card"
    class="content-wrapper"
>
    <mat-card-content>
        <div class="status-title">
            <h3
                class="filtering-title"
                translate
                data-cy="planboardFiltersTitle"
            >
                FILTERING.PAGE-TITLE
            </h3>
            <app-loading-status-indication [statusPageState]="statusPageState$ | async"></app-loading-status-indication>
        </div>
        <div class="resources-container">
            <app-multiselect-tree
                multiselectTreePlaceholder="FILTERING.RESOURCE-ORGANIZATION"
                [entities]="organizationUnits$ | async"
                [preSelectedEntities]="selectedOrganizationUnits$ | async"
                [required]="false"
                [showSelectAll]="true"
                (selectedEntityIdsChanged)="onFilteredOrganizationsChanged($event)"
                data-cy="filteringOrganizationUnitsFilter"
            >
            </app-multiselect-tree>

            <app-multiselect-tree
                multiselectTreePlaceholder="FILTERING.RESOURCE-SKILLS"
                [entities]="skills$ | async"
                [preSelectedEntities]="selectedSkillIds$ | async"
                [required]="false"
                [showSelectAll]="true"
                (selectedEntityIdsChanged)="onFilteredResourceSkillsChanged($event)"
                data-cy="filteringResourceSkillsFilter"
            >
            </app-multiselect-tree>
        </div>

        <app-resource-selection
            buttonText="FILTERING.HIDE-RESOURCES-LABEL"
            [resources]="resources$ | async"
            [resourceTypes]="resourceTypes$ | async"
            [selectedResourcesToHide]="selectedResourceIds$ | async"
            [selectedResourceTypeId]="selectedResourceTypeId$ | async"
            [buttonIcon]="'account-supervisor-circle'"
            (updateSelectedResourcesToHideEvent)="updateSelectedResourceIdsEvent($event)"
        ></app-resource-selection>

        <hr class="section-divider" />

        <app-activity-type-selection-new
            dialogTitle="FILTERING.VISIBLE-ACTIVITIES-LABEL"
            buttonTitle="FILTERING.VISIBLE-ACTIVITIES-LABEL"
            [showApplyFilter]="false"
            [activityTypes]="activityTypes$ | async"
            [activityTypeCategories]="activityTypeCategories$ | async"
            [selectedActivityTypeIds]="selectedActivityTypeIds$ | async"
            [organizationUnits]="organizationUnits$ | async"
            (updateSelectedActivityTypeIdsEvent)="updateSelectedActivityTypeIds($event)"
        ></app-activity-type-selection-new>

        <hr class="section-divider" />

        <div
            class="description-field_resource-properties"
            translate
        >
            Select visible columns for resource properties
        </div>
        <app-filter-chip-list
            noResultsFoundText="{{ 'No resource properties found' | translate }}"
            placeholderText="{{ 'Select visible columns' | translate }}"
            [entities]="allResourceProperties$ | async"
            [preselectedEntities]="selectedResourceProperties$ | async"
            (selectedEntityIdsChanged)="onFilteredResourcePropertiesChanged($event)"
            data-cy="selectVisibleColumnsField"
        ></app-filter-chip-list>
    </mat-card-content>
</mat-card>

@if ((initialLoadingFinished$ | async) === false) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

