<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-tree-list-panel
                    entityName="Organization Units"
                    [entities]="organizationUnits$ | async"
                    [mode]="manageMode$ | async"
                    [loadingError]="loadingError$ | async"
                    [searchProperties]="searchProperties"
                    [selectedEntityId]="selectedOrganizationUnitId$ | async"
                    (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
                    (addEntityClicked)="onAddNewOrganizationUnit()"
                ></app-entity-tree-list-panel>
            </div>
        </div>
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="Organization unit"
                    [mode]="manageMode$ | async"
                    [model]="selectedOrganizationUnit$ | async"
                    [uiEntity]="selectedUIOrganizationUnit$ | async"
                    [formFields]="formFields"
                    (deleteEntity)="onDeleteOrganizationUnit($event)"
                    (editEntity)="onEditOrganizationUnit($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>

@if ((initialLoadingFinished$ | async) === false || (showLoadingSpinner$ | async)) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}
