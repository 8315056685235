import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, first, map, startWith, take, tap } from 'rxjs/operators';
import { Memoized } from '@ortec/utilities/core';

import { filterUndefined } from 'src/app/core/rxjs-utils';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';

import { OrganizationUnitsConfigurationsQuery } from './store/organization-units-configurations.query';
import { OrganizationUnitsConfigurationsService } from './store/organization-units-configurations.service';
import { OrganizationUnitsConfigurations } from './store/organization-units-configurations.store';

@Component({
    selector: 'app-organization-units-configurations',
    templateUrl: './organization-units-configurations.component.html',
    styleUrls: ['./organization-units-configurations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationUnitsConfigurationsComponent implements OnInit {  
    private readonly organizationUnitQuery = inject(OrganizationUnitQuery);
    private readonly organizationUnitService = inject(OrganizationUnitService);
    private readonly displaySettingQuery = inject(DisplaySettingQuery);
    private readonly displaySettingService = inject(DisplaySettingService);
    private readonly activityTypeQuery = inject(ActivityTypeQuery);
    private readonly activityTypeService = inject(ActivityTypeService);
    private readonly organizationUnitsConfigurationsQuery = inject(OrganizationUnitsConfigurationsQuery);
    private readonly organizationUnitsConfigurationsService = inject(OrganizationUnitsConfigurationsService);

    public ngOnInit(): void {
       this.initCallsForDataRetrieval();
    }

    public onFilteredOrganizationsChanged(organizationId: number): void {
        this.organizationUnitsConfigurationsService.updatePageStatusState(STATUS.IS_LOADING);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, organizationId).pipe(
            first(),
            tap(() => this.organizationUnitsConfigurationsService.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        ).subscribe();
        this.organizationUnitsConfigurationsService.updateSelectedOrganizationUnitId(organizationId);
        this.organizationUnitsConfigurationsService.getActivityTypeForParttimeSchedules(organizationId).pipe(first()).subscribe();
    }

    public onSelectedActivityTypeChange(id: number): void {
        this.organizationUnitsConfigurationsService.updatePageStatusState(STATUS.IS_LOADING);
        this.organizationUnitsConfigurationsService.updateSelectedActivityTypeId(id);

        const activityTypeForParttimeSchedules: OrganizationUnitsConfigurations = { 
            activityTypeId: id, 
            organizationUnitId: this.organizationUnitsConfigurationsQuery.getCurrentOrganizationUnitIdSync()
        }

        this.organizationUnitsConfigurationsService.saveActivityTypeForParttimeSchedules(activityTypeForParttimeSchedules).pipe(first()).subscribe();
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return combineLatest([
            this.organizationUnitQuery.getEntitiesLoadingState(),
            this.activityTypeQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([orgUnitsLoading, activityTypeLoading]) => {
                return !orgUnitsLoading && !activityTypeLoading;
            }),
            map(() => true),
            take(1),
            startWith(false)
        );
    }

    @Memoized public get organizationUnits$(): Observable<Array<IOrganizationUnitTree>> {
        return this.organizationUnitQuery.getOrganizationsForFiltering();
    }

    @Memoized public get selectedOrganizationUnits$(): Observable<number> {
        return this.organizationUnitsConfigurationsQuery.getCurrentOrganizationUnitId();
    }

    @Memoized public get activityTypes$(): Observable<Array<ActivityType>> {
        return this.organizationUnitsConfigurationsQuery.getMutableActivityTypesFilteredByOrganizationUnitId();
    }

    @Memoized public get selectedActivityTypeId$(): Observable<number> {
        return this.organizationUnitsConfigurationsQuery.getCurrentActivityTypeId();
    }

    @Memoized public get statusPageState$(): Observable<STATUS> {
        return this.organizationUnitsConfigurationsQuery.getStatusPageState();
    }

    private initCallsForDataRetrieval(): void {
        this.activityTypeService.getActivityTypes().pipe(first()).subscribe();
        this.organizationUnitService.get().pipe(first()).subscribe();
        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, 'number').pipe(
            filterUndefined(),
            first(),
            tap(id => {
               this.organizationUnitsConfigurationsService.updateSelectedOrganizationUnitId(id);
               this.organizationUnitsConfigurationsService.getActivityTypeForParttimeSchedules(id).pipe(first()).subscribe();
            })
        ).subscribe();
    }
}