<div class="bol-modal__header">
    <span class="modal-title">{{ dialogTitle | translate }}</span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content [style.min-height.px]="(applyFilterState$ | async) || !showApplyFilter ? 710 : 0">
    @if (showApplyFilter) {
        <div class="activities-checkbox">
            <mat-checkbox
                class="bol-checkbox"
                [checked]="applyFilterState$ | async"
                (change)="onApplyFilter($event)"
                data-cy="applyFilterCheckbox"
            >
                <span>{{ 'Apply filter' | translate }}</span>
            </mat-checkbox>
        </div>
    }
    @if ((applyFilterState$ | async) || !showApplyFilter) {
        <div>
            <div class="filters-activity-types">
                <app-multiselect-tree
                    multiselectTreePlaceholder="Activity type categories"
                    class="activity-type-categories-filter-activity-type"
                    [entities]="activityTypeCategories$ | async"
                    [preSelectedEntities]="selectedActivityTypeCategoryIds"
                    [showSelectAll]="true"
                    [required]="false"
                    [showSearch]="false"
                    [disable]="disableFilterFields"
                    (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnActivityTypeCategories($event)"
                    data-cy="activityTypeCategoriesFilter"
                ></app-multiselect-tree>
                <app-multiselect-tree
                    searchFieldPlaceholder="Search organization unit"
                    multiselectTreePlaceholder="Organization units"
                    class="organization-unit-filter-activity-type"
                    [entities]="organizationUnits$ | async"
                    [preSelectedEntities]="selectedOrganizationIds"
                    [showSelectAll]="true"
                    [required]="false"
                    [disable]="disableFilterFields"
                    (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnOrganizationUnits($event)"
                    data-cy="activityTypeDialogOrganizationUnitsFilter"
                ></app-multiselect-tree>
            </div>
            <div class="activities-checkbox">
                <mat-checkbox
                    class="bol-checkbox"
                    [checked]="showOnlySelectedState$ | async"
                    (change)="showOnlyActivityTypesSelected($event)"
                    data-cy="showOnlySelectedCheckbox"
                >
                    <span>{{ 'Show only selected' | translate }}</span>
                </mat-checkbox>
            </div>
            <app-multiselect-table
                searchFieldPlaceholder="Activity type filter"
                [entities]="filteredActivityTypes$ | async"
                [columnDefinition]="columnDefinition"
                [searchProperties]="searchProperties"
                [hideSearch]="false"
                (selectedEntityIdsChanged)="updateActivityTypeStates($event)"
                data-cy="activityTypesMultiselectTable"
            ></app-multiselect-table>
            <div
                class="activities-length-info-message"
                data-cy="numberOfSelectedActivityTypes"
            >
                <div class="bol-text bol-text-regular">
                    {{ selectedActivityTypeIds.length }} {{ '' + ACTIVITY_SELECTED_MESSAGE | translate }}
                </div>
            </div>
        </div>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            class="bol-button-solid bol-positive"
            (click)="onConfirm()"
            data-cy="activityTypeDialogConfirmButton"
        >
            <span translate>Confirm</span>
        </button>
    </div>
</mat-dialog-actions>
