import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BehaviorSubject, Observable, Subscription, combineLatest, filter, first, map, switchMap } from 'rxjs';
import { Memoized } from '@ortec/utilities/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BolCalloutModule } from '@ortec/bolster/callout';

import { cache } from 'src/app/core/rxjs-utils';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { MultiselectTreeComponent } from 'src/app/shared/components/inputs/multiselect-tree/multiselect-tree.component';
import { SingleSelectTableComponent } from 'src/app/shared/components/inputs/single-select-table/single-select-table.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActivityTypeInfo, ActivityTypeTimeslotInfoForTable } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { ManageTimeslotQuery } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.query';
import { EditTemplatesManagementQuery } from '../../store/edit-template-management.query';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { UtilsService } from 'src/app/shared/services/utils.service';

export interface IActivityTypeTimeslotsDialogData {
    selectedActivityTypeId?: number;
    manageMode: MANAGE_MODE;
}

export interface IActivityTypeTimeslotsDialogCloseData {
    selectedTimeslotIds: Array<number>;
    selectedActivityTypeId: number;
    activityTypeAlreadyAdded: boolean;
}

@Component({
    standalone: true,
    selector: 'app-adt-activity-type-timeslots-dialog',
    templateUrl: './adt-activity-type-timeslots-dialog.component.html',
    styleUrls: ['./adt-activity-type-timeslots-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,

        MatButtonModule,
        TranslateModule,
        MatIconModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        SharedModule,
        BolCalloutModule,

        MultiselectTreeComponent,
        SingleSelectTableComponent
    ],
})
export class AdtActivityTypeTimeslotsDialogComponent implements OnDestroy {
    public filteredActivityTypesSubject = new BehaviorSubject<Array<ActivityType>>([]);
    public selectedOrganizationUnitIdsSubject = new BehaviorSubject<Array<number>>([]);
    public selectedActivityTypeIdSubject = new BehaviorSubject<number>(null);
    public activityTypes: Array<ActivityType>;
    public columnDefinition = [];
    public searchProperties: Array<string> = ['displayName', 'shortName'];
    public displayedColumns: Array<string> = ['startTime', 'endTime', 'addToTemplate'];
    public dataSource = new MatTableDataSource<any>([]);
    public mode: MANAGE_MODE;
    public manageMode = MANAGE_MODE;
    public isDaymark$: Observable<boolean>;

    private initialTimeSlotSelectionSubject = new BehaviorSubject<Array<number>>([]);
    private timeslotsOfActivityTypeSubject = new BehaviorSubject<Array<ActivityTypeTimeslotInfoForTable>>([]);
    private readonly subscription = new Subscription();

    private readonly dialogRef = inject(MatDialogRef<number>);
    private readonly activityTypeQuery = inject(ActivityTypeQuery);
    private readonly manageTimeslotQuery = inject(ManageTimeslotQuery);
    private readonly organizationUnitsQuery = inject(OrganizationUnitQuery);
    private readonly editTemplatesManagementQuery = inject(EditTemplatesManagementQuery);
    private readonly utilService = inject(UtilsService);
    private readonly dialogData = inject<IActivityTypeTimeslotsDialogData>(MAT_DIALOG_DATA);

    public ngOnInit(): void {
        this.mode = this.dialogData.manageMode;
        this.columnDefinition.push( 
            {
                columnDisplayName: 'Short name',
                entityProperty: 'shortName',
                stylingProperty: 'nameStyling'
            },
            {
                columnDisplayName: 'Display name',
                entityProperty: 'displayName',
            }
        );

        this.setDataForManageModes();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onConfirm(): void {
        const data: IActivityTypeTimeslotsDialogCloseData = {
            selectedTimeslotIds: this.timeslotsOfActivityTypeSubject.getValue().filter(timeslot => timeslot.addToTemplate).map(timeslot => timeslot.id),
            selectedActivityTypeId: this.selectedActivityTypeIdSubject.value ?? null,
            activityTypeAlreadyAdded: this.initialTimeSlotSelectionSubject.value.length > 0
        };
        this.dialogRef.close({ data });
    }

    public updateActivityTypeTableBasedOnOrganizationUnits(organizationUnitsIds: Array<number>): void {
        this.selectedOrganizationUnitIdsSubject.next(organizationUnitsIds);
        const organizationUnitsIdsSet = new Set(organizationUnitsIds);

        // Filter activity types based on selected organization units
        // If no organization units are selected, show all activity types
        let filteredActivityTypesBasedOnOrgUnitIds = this.activityTypes;
        if (organizationUnitsIds.length > 0) {
            filteredActivityTypesBasedOnOrgUnitIds = this.activityTypes.filter(activityType => organizationUnitsIdsSet.has(activityType.ownerOrganizationUnitId));
        }
    
        this.filteredActivityTypesSubject.next(filteredActivityTypesBasedOnOrgUnitIds);
        if (!filteredActivityTypesBasedOnOrgUnitIds.some(activityType => activityType.id === this.selectedActivityTypeIdSubject.value)) {
            this.selectedActivityTypeIdSubject.next(null);
        }
    }
    
    public onSelectedActivityTypeChange(activityTypeId: number, addMode = true): void {
        this.isDaymark$ = this.activityTypeQuery.isActivityTypeDaymark(activityTypeId);
        this.selectedActivityTypeIdSubject.next(activityTypeId);
        const selectedTimeslots = this.manageTimeslotQuery.getSelectedTimeslotIdsForActivityType(activityTypeId);
        
        this.activityTypeQuery.getActivityTypeTimeslotsForSelectedActivityType(activityTypeId, selectedTimeslots, addMode)
            .pipe(first())
            .subscribe(checkedTimeslots => {
                this.timeslotsOfActivityTypeSubject.next(checkedTimeslots);
                const preselectedIds = checkedTimeslots.filter(timeslot => timeslot.addToTemplate).map(timeslot => timeslot.id)
                this.initialTimeSlotSelectionSubject.next(preselectedIds);
            }
        );
    }

    public onUpdateTimeslot(state: MatCheckboxChange, timeslot: ActivityTypeTimeslotInfoForTable): void {
        const currentTimeslots = this.timeslotsOfActivityTypeSubject.getValue();
        const index = currentTimeslots.findIndex(t => t.id === timeslot.id);
  
        if (index !== -1) {
            currentTimeslots[index].addToTemplate = state.checked;
            this.timeslotsOfActivityTypeSubject.next(currentTimeslots);
        }
    }

    @Memoized public get timeslotsOfActivityType$(): Observable<Array<ActivityTypeTimeslotInfoForTable>> {
        return this.timeslotsOfActivityTypeSubject.asObservable().pipe(cache());
    }

    @Memoized public get activityTypesForFiltering$(): Observable<Array<ActivityType>> {
        return this.filteredActivityTypesSubject.asObservable().pipe(cache());
    }

    @Memoized public get selectedOrganizationIds$(): Observable<Array<number>> {
        return this.selectedOrganizationUnitIdsSubject.asObservable().pipe(cache());
    }

    @Memoized public get selectedActivityTypeId$(): Observable<number> {
        return this.selectedActivityTypeIdSubject.asObservable().pipe(cache());
    }

    @Memoized public get initialTimeSlotSelection$(): Observable<Array<number>> {
        return this.initialTimeSlotSelectionSubject.asObservable().pipe(cache());
    }

    @Memoized public get disableConfirmButton$(): Observable<boolean> {
        // Disable confirm button when current selection is identical to the initial selection
        return combineLatest({
            currentTimeslots: this.timeslotsOfActivityType$,
            initialSelection: this.initialTimeSlotSelection$
        }).pipe(
            map(({currentTimeslots, initialSelection}) => {
                const selectedTimeslotIds = currentTimeslots.filter(timeslot => timeslot.addToTemplate).map(timeslot => timeslot.id);
                return this.utilService.arraysEqual(selectedTimeslotIds, initialSelection);
            }),
            cache()
        );
    }

    @Memoized public get selectedActivityType$(): Observable<ActivityTypeInfo> {
        return this.editTemplatesManagementQuery.getSelectedActivityTimeslotId().pipe(
            filter(id => id !== undefined && id !== 0),
            switchMap(id => this.manageTimeslotQuery.getActivityTypeInfoByActivityTypeTimeslotId(id)),
            cache()
        );
    }

    @Memoized public get organizationUnits$(): Observable<Array<IOrganizationUnitTree>> {
        return this.organizationUnitsQuery.getOrganizationUnitsForActivityTypes().pipe(cache());
    }

    @Memoized public get showAllDayMarksAddedCallout$(): Observable<boolean> {
        return this.timeslotsOfActivityType$.pipe(map(timeslots => {
            if (this.mode === MANAGE_MODE.EDIT || timeslots.length === 0) {
                return false;
            }

            return timeslots.every(timeslot => timeslot.addToTemplate && timeslot.disabledInAddMode);
        }))
    }

    private setDataForManageModes(): void {
        if (this.mode === MANAGE_MODE.ADD) {
            this.subscription.add(
                combineLatest({
                    activityTypes: this.activityTypeQuery.getMainActivityTypesWithConfiguredTimeslots(),
                    organizationUnits: this.organizationUnits$
                }).pipe(
                    filter(({activityTypes, organizationUnits}) => 
                        activityTypes !== undefined && activityTypes.length > 0 &&
                        organizationUnits !== undefined && organizationUnits.length > 0),
                ).subscribe(({activityTypes}) => {
                    const styledActivityTypes = activityTypes.map(activityType => ({
                        ...activityType,
                        nameStyling: {
                            backgroundColor: '#' + activityType.backColor,
                            color: '#' + activityType.textColor
                        }
                    }));

                    this.activityTypes = styledActivityTypes;
                    this.filteredActivityTypesSubject.next(styledActivityTypes);
                })
            );
        }

        if (this.mode === MANAGE_MODE.EDIT) {
            this.onSelectedActivityTypeChange(this.dialogData.selectedActivityTypeId, false);
        }
    }
}