<div
    class="entity-list-dropdown-container"
    [attr.data-cy]="field.key"
>
    <div
        class="custom-entity-field"
        [matMenuTriggerFor]="menu"
    >
        {{ props.placeholderText | translate }}
        <mat-icon
            svgIcon="menu-down"
            class="arrow-icon"
        ></mat-icon>
    </div>
</div>

<mat-menu
    #menu="matMenu"
    class="dropdown bol-menu"
>
    @if (props.entities.length > 0) {
        <mat-form-field
            class="bol-form-field dropdown-search"
            (click)="$event.stopPropagation()"
        >
            <input
                #input
                matInput
                placeholder="{{ props.searchFieldPlaceholder ? props.searchFieldPlaceholder : ('Search' | translate) }}"
                autocomplete="off"
                [formControl]="searchControl"
            />
            @if (!searchControl || !searchControl.value || searchControl.value.length === 0) {
                <button
                    matSuffix
                    mat-button
                    disabled
                    class="bol-button-text"
                >
                    <mat-icon
                        svgIcon="magnify"
                        class="search-icon"
                    ></mat-icon>
                </button>
            }
            @if (searchControl && searchControl.value && searchControl.value.length > 0) {
                <button
                    matSuffix
                    mat-button
                    class="cancel-button bol-button-text icon-only bol-gray"
                    (click)="onResetValue()"
                >
                    <mat-icon
                        svgIcon="close"
                        class="search-icon"
                    ></mat-icon>
                </button>
            }
        </mat-form-field>
    }

    <div class="items-container">
        @for (entity of filteredEntities$ | async; track entity.id) {
            <button
                mat-menu-item
                [value]="entity.id"
                [disabled]="props.disabled"
                (click)="$event.stopPropagation()"
            >
                {{ entity.displayName }}
            </button>
        }

        @if ((filteredEntities$ | async)?.length === 0) {
            <div
                class="no-results-founds"
                translate
            >
                No results found.
            </div>
        }
    </div>
</mat-menu>

