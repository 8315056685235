import { Observable, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import moment from 'moment';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { TranslateService } from '@ngx-translate/core';

import { IManageEntitiesGridColumn } from 'src/app/shared/models';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { GlobalSettingsQuery } from 'src/app/shared/stores/global-settings/global-settings.query';
import { DateTimeUtilityService } from 'src/app/shared/services/date-time-utility.service';
import { LanguageService } from 'src/app/shared/language';

import { ActivityChangeRequestParameters, ActivityChangeTypeForTable } from './store/activity-change.model';
import { ActivityChangeService } from './store/activity-change.service';
import { ActivityChangeQuery } from './store/activity-change.query';
import { getActivityChangesGridColumns } from './changes-grid-definition';

@Component({
    selector: 'app-changes',
    templateUrl: './changes.component.html',
    styleUrls: ['./changes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangesComponent implements OnInit, OnDestroy {
    @ViewChild('actionTillDatePicker', { static: true }) private readonly actionTillDatePicker!: MatDatepicker<Date>;
    @ViewChild('activityTillDatePicker', { static: true }) private readonly activityTillDatePicker!: MatDatepicker<Date>;

    public loadedData: boolean = true;
    public readonly dateTimeControls = {
        actionFromDate: new UntypedFormControl('', [Validators.required]),
        actionTillDate: new UntypedFormControl('', [Validators.required]),
        activityFromDate: new UntypedFormControl(''),
        activityTillDate: new UntypedFormControl(''),
    };
    public columns: Array<IManageEntitiesGridColumn>;
    public entities$: Observable<Array<ActivityChangeTypeForTable>>;
    public actionNewStartDate: string;
    public actionNewEndDate: string;
    public activityNewStartDate: string;
    public activityNewEndDate: string;
    public currentDate: Date = new Date();
    
    private readonly subscription = new Subscription();
    constructor(
        private readonly activityChangesService: ActivityChangeService,
        private readonly activityChangesQuery: ActivityChangeQuery,
        private readonly activityTypeService: ActivityTypeService,
        private readonly globalSettingsQuery: GlobalSettingsQuery,
        private readonly dateTimeUtilityService: DateTimeUtilityService,
        private readonly translateService: TranslateService,
        protected languageService: LanguageService,
    ) {}

    public ngOnInit(): void {
        this.activityTypeService.getActivityTypes().pipe(first()).subscribe();
        this.setStartAndEndDates();
        this.getChangesForSelectedDates();
        this.entities$ = this.activityChangesQuery.transformActivityChangesForTable();
        this.columns = getActivityChangesGridColumns(this.translateService, this.activityChangesQuery);
       
        this.subscription.add(
            this.dateTimeControls.actionFromDate.valueChanges.pipe(
                filter(date => !!date)
            ).subscribe((startDate) => {
                if (this.dateTimeControls.actionFromDate.valid) {
                    this.actionNewStartDate = moment(startDate).format('YYYY-MM-DDT00:00:00');
                }
            })
        );
        
        this.subscription.add(
            this.dateTimeControls.actionTillDate.valueChanges.pipe(
                filter(date => !!date)
            ).subscribe((endDate) => {
                if (this.dateTimeControls.actionTillDate.valid) {
                    this.actionNewEndDate = moment(endDate).format('YYYY-MM-DDT23:59:59');
                }
            })
        );

        this.subscription.add(
            this.dateTimeControls.activityFromDate.valueChanges.pipe(
            ).subscribe((startDate) => {
                if (this.dateTimeControls.activityFromDate.valid && startDate !== null) {
                    this.activityNewStartDate = moment(startDate).format('YYYY-MM-DDT00:00:00');
                } else {
                    this.activityNewStartDate = null;
                }
            })
        );
        
        this.subscription.add(
            this.dateTimeControls.activityTillDate.valueChanges.pipe(
            ).subscribe((endDate) => {
                if (this.dateTimeControls.activityTillDate.valid && endDate !== null) {
                    this.activityNewEndDate = moment(endDate).format('YYYY-MM-DDT23:59:59');
                } else {
                    this.activityNewEndDate = null;
                }
            })
        );
    }
    
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
    
    public getMaxDateForActionEndDate(): Date {
        const startDate = new Date(this.dateTimeControls.actionFromDate.value);
        const currentDate = new Date();
        const maxEndDate = new Date(Math.min(startDate.getTime() + 28 * 24 * 60 * 60 * 1000, currentDate.getTime()));
    
        return maxEndDate;
    }

    public getMinDateStartDate(): Date {
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - 1);
    
        return currentDate;
    }

    public getMinDateForActivityEndDate(): Date | null {
        return this.dateTimeControls.activityFromDate.value !== null
        ? new Date(this.dateTimeControls.activityFromDate.value)
        : null;
    }

    public actionFromDateClosed(): void {
        this.actionTillDatePicker.open();
        // Set the till value to from's value
        if (!this.dateTimeControls.actionTillDate.value && moment.isMoment(this.dateTimeControls.actionFromDate.value)) {
            this.dateTimeControls.actionTillDate.setValue(this.dateTimeControls.actionFromDate.value.clone());
        }
    }

    public activityFromDateClosed(): void {
        this.activityTillDatePicker.open();
        // Set the till value to from's value
        if (!this.dateTimeControls.activityTillDate.value && moment.isMoment(this.dateTimeControls.activityFromDate.value)) {
            this.dateTimeControls.activityTillDate.setValue(this.dateTimeControls.activityFromDate.value.clone());
        }
    }
    
    public onShowChanges(): void {
        this.getChangesForSelectedDates();
    }
 
    public getDateErrorMessage(pickerInput: MatInput): string {
        if (!pickerInput.value || pickerInput.value === '') {
            return 'Please enter a date';
        }

        return 'Please enter a valid date';
    }

    private setStartAndEndDates(): void {
        this.globalSettingsQuery.getTimeZone().pipe(
            map((timeZone) => {
                //action start date and end date
                const firstDayOfTheCurrentWeek = new Date(this.dateTimeUtilityService.getFirstDayOfTheCurrentWeek(false));
                const sixDaysAgo = new Date(firstDayOfTheCurrentWeek);
                sixDaysAgo.setDate(firstDayOfTheCurrentWeek.getDate() - 6);
                this.actionNewStartDate = moment(sixDaysAgo).tz(timeZone).format('YYYY-MM-DDT00:00:00');
                this.actionNewEndDate = moment(this.currentDate).tz(timeZone).format('YYYY-MM-DDT23:59:59');
                this.dateTimeControls.actionFromDate.setValue(this.actionNewStartDate);
                this.dateTimeControls.actionTillDate.setValue(this.actionNewEndDate);
               
                //activity start date and end date
                const defaultEndDate = new Date(this.currentDate.getTime());
                defaultEndDate.setDate(this.currentDate.getDate() + 27);
                this.activityNewStartDate = moment(this.currentDate).tz(timeZone).format('YYYY-MM-DDT00:00:00');
                this.activityNewEndDate = moment(defaultEndDate).tz(timeZone).format('YYYY-MM-DDT23:59:59');
                this.dateTimeControls.activityFromDate.setValue(this.activityNewStartDate);
                this.dateTimeControls.activityTillDate.setValue(this.activityNewEndDate);
            }),
            first()
        ).subscribe();
    }

    private getChangesForSelectedDates(): void {
        const parameters: ActivityChangeRequestParameters = {
            activityTypeIdList: [],
            fromDateInclusive: this.actionNewStartDate,
            toDateExclusive: this.actionNewEndDate,
            activityFromDate: this.activityNewStartDate,
            activityToDate: this.activityNewEndDate,
            resourceIdList: []
        };
        this.activityChangesService.getActivityChanges(parameters).pipe(first()).subscribe();
    }
}