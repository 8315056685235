<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-card
    class="bol-card full-height"
    [ngClass]="cardClass"
>
    <mat-card-header class="bol-card-header mat-card-header-padding">
        <mat-card-title
            class="bol-card-title entity-title"
            [attr.data-cy]="dataCyLabel ? dataCyLabel : 'entityListTitle'"
            translate
        >
            {{ entityName }}
        </mat-card-title>
        @if (!hideAddButton) {
            <button
                mat-button
                class="bol-button-solid bol-default"
                [disabled]="!canAdd"
                (click)="onAddEntityClicked()"
                data-cy="addEntityButton"
            >
                <span translate>Add</span>
            </button>
        }
    </mat-card-header>
    <mat-card-content class="bol-card-content">
        @if (!loadingError) {
            @if (dataSource?.data?.length > 0 && searchProperties?.length > 0) {
                <mat-form-field class="bol-form-field entity-search">
                    <input
                        matInput
                        autocomplete="off"
                        placeholder="{{ 'Search' | translate }}"
                        (keyup)="searchEntities($event.target.value)"
                        data-cy="searchEntityField"
                    />
                </mat-form-field>
            }
            @if (dataSource?.data?.length > 0 && dataSource?.filteredData?.length !== 0) {
                <table
                    mat-table
                    class="bol-table bol-table-striped-even bol-table-row-hover"
                    [dataSource]="dataSource"
                    data-cy="entityTable"
                >
                    @for (column of columnDefinition; track $index; let isFirst = $first) {
                        <ng-container [matColumnDef]="column.entityProperty">
                            <th
                                *matHeaderCellDef
                                mat-header-cell
                            >
                                @if (enableSelectAll) {
                                    <mat-checkbox
                                        class="bol-checkbox first-column"
                                        [disabled]="selectAllDisabled"
                                        [checked]="isAllSelected()"
                                        (change)="onSelectAll($event)"
                                    >
                                    </mat-checkbox>
                                }
                                <span translate>{{ column.columnDisplayName }}</span>
                            </th>
                            <td
                                *matCellDef="let row"
                                mat-cell
                                class="table-cell-with-icon"
                            >
                                @if (isFirst) {
                                    @if (row.isLoading && row.id !== selectedEntityId) {
                                        <mat-spinner
                                            diameter="16"
                                            class="row-loading-spinner"
                                        >
                                        </mat-spinner>
                                    }
                                    @if (showCheckboxes) {
                                        <mat-checkbox
                                            class="bol-checkbox"
                                            [checked]="row.checked"
                                            [disabled]="row.disabled"
                                            (change)="onCheckboxClick($event, row)"
                                        >
                                        </mat-checkbox>
                                    }
                                    @if (showRadioButtons) {
                                        <mat-radio-group [ngModel]="row.checked ? row : undefined">
                                            <mat-radio-button
                                                class="bol-checkbox"
                                                [value]="row"
                                                [disabled]="row.disabled"
                                                (click)="onRadioButtonClick($event, row)"
                                            >
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    }
                                }
                                {{ row[column.entityProperty] }}
                                @if (row.icon) {
                                    <div class="icon">
                                        <mat-icon
                                            [bolPopoverHover]="popoverMessageHover"
                                            [svgIcon]="icon"
                                        ></mat-icon>
                                    </div>
                                }
                                <ng-template #popoverMessageHover>
                                    <div
                                        class="popover-message"
                                        translate
                                        data-cy="popoverMessage"
                                    >
                                        {{ popoverMessage }}
                                    </div>
                                </ng-template>
                            </td>
                        </ng-container>
                    }
                    <tr
                        *matHeaderRowDef="displayedColumns"
                        mat-header-row
                    ></tr>
                    <tr
                        *matRowDef="let row; columns: displayedColumns"
                        mat-row
                        [class.selected]="row.id === selectedEntityId"
                        (click)="onEntitySelected(row.id)"
                    ></tr>
                </table>
            }
            <mat-paginator
                showFirstLastButtons
                aria-label="Select page"
                [ngClass]="{ hidden: dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0 }"
                [pageSize]="10"
                [hidePageSize]="true"
            >
            </mat-paginator>
        }

        @if (!loadingError && (dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0)) {
            <p data-cy="entityNoEntitiesFound">
                {{ 'NO-ENTITIES-FOUND' | translate: { entityName: entityName | translate | lowercase } }}
            </p>
        }

        @if (loadingError) {
            <h4
                translate
                data-cy="entityLoadingError"
            >
                ERROR.LOADING-ENTITIES-ERROR
            </h4>
        }
    </mat-card-content>
</mat-card>

