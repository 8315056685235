import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService, OmrpAuthenticationService } from '../services/authentication';
import { API_SERVICE_OPTIONS, ApiServiceOptions } from 'src/app/app.module';


@Injectable({ providedIn: 'root' })
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly omrpAuthenticationService: OmrpAuthenticationService,
        @Inject(API_SERVICE_OPTIONS) protected apiServiceOptions: ApiServiceOptions
    ) { }

    public intercept(request: HttpRequest<any>, nextHttpHandler: HttpHandler): Observable<HttpEvent<any>> {
        const apiCallAttempt = request.url.indexOf(this.apiServiceOptions.apiUrl) > -1;

        return nextHttpHandler.handle(request).pipe(tap(
            (() => {
                //Success
            }),
            ((response: HttpErrorResponse) => {
                if (response instanceof HttpErrorResponse && apiCallAttempt && this.authenticationService.currentUserInStorage != null) {
                    if (response.status === 401)
                    {
                        this.omrpAuthenticationService.logout();
                    }
                }
            })
        ));
    }
}
