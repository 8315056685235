import { InjectionToken } from '@angular/core';

// This file constains declarations for the BroadcastService.
// Normally, these would be put in the same file as the service, but this results in an aot issue when building for production.
// Putting the declarations in a seperate file seems to be the best current solution.
// This is likely a bug and if it ever gets fixed we can put back the declarations in broadcast.service.ts.

export interface BroadcastMessage {
    type: string;
    payload: any;
}

export const BROADCAST_SETTING = new InjectionToken<CallbacksPerMessageType>('');

export type CallbackFunction =  (message: BroadcastMessage) => void;

export type CallbacksPerMessageType = { [type: string]: CallbackFunction };
