/* eslint-disable prefer-arrow/prefer-arrow-functions */

import { FormlyFieldConfig } from '@ngx-formly/core';

import { fieldNonEmptyValidatorKey } from 'src/app/shared/validators';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { Permission } from 'src/app/shared/models/Enums';
import { Skill } from 'src/app/shared/stores/skill-store/skill.model';
import { UserGroup } from 'src/app/shared/stores/user-group-store/user-group.model';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';

export function getResourceTypesFormFields(
    organizationUnits: Array<IOrganizationUnitTree>,
    userGroups: Array<UserGroup>,
    skills: Array<Skill>,
    resourceTypes: Array<ResourceType>,
    hasRestrictedResourceTypePermission: boolean
): Array<FormlyFieldConfig> {
    return [
        {
            key: 'displayName',
            type: 'bolster-input',
            props: {
                label: 'Display name',
                required: true,
                maxLength: 80,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        },
        {
            key: 'externalId',
            type: 'bolster-input',
            props: {
                label: 'External id',
                maxLength: 40,
                attributes: {
                    autocomplete: 'off'
                }
            }
        },
        {
            key: 'maximumOccupation',
            type: 'bolster-input',
            props: {
                label: 'Max. occupation',
                required: true,
                type: 'number',
                min: 1,
                maxLength: 80,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        },
        {
            key: 'validOrganizationUnitIds',
            type: 'multiselect-tree',
            props: {
                entities: organizationUnits,
                placeholderText: 'Coupled organization units',
                searchProperties: ['displayName']
            }
        },
        {
            key: 'validSkillIds',
            type: 'multiselect',
            props: {
                entities: skills,
                placeholderText: 'Coupled skills'
            }
        },
        {
            key: 'restrictedResourceTypeIds',
            type: 'multiselect',
            props: {
                entities: resourceTypes,
                placeholderText: 'Restricted resource types'
            },
            hideExpression: !hasRestrictedResourceTypePermission
        },        
        {
            key: 'userGroupPermissionList',
            type: 'permissions-tree',
            props: {
                title: 'Permissions',
                entities: userGroups,
                entityName: 'User groups',
                columnDefinition: [Permission.read, Permission.readWrite]
            }
        }
    ];
}
