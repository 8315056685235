<div
    class="bol-modal__header"
    ngClass="modal__header-{{ headerClass }}"
    [attr.data-cy]="confirmationHeader"
>
    <span class="modal-title">{{ confirmationHeader }}</span>
    <div
        class="bol-modal__close"
        data-cy="btnClose"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    <div
        class="confirmationDialogContent"
        [innerHtml]="confirmationMessage"
    ></div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            ngClass="{{ cancelButtonClass }}"
            data-cy="btnNo"
            id="btnNo"
            [mat-dialog-close]="false"
            translate
        >
            {{ cancelButtonText }}
        </button>
        <button
            mat-button
            ngClass="{{ confirmButtonClass }}"
            data-cy="btnYes"
            id="btnYes"
            [mat-dialog-close]="true"
            translate
        >
            {{ confirmButtonText }}
        </button>
    </div>
</mat-dialog-actions>
