<div class="bol-modal__header">
    <span class="modal-title">{{ dialogTitle | translate }}</span>
    <div class="bol-modal__close" (click)="onCloseModal()">
      <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>
  
<mat-dialog-content>
    <div>
        <app-multiselect-sorting-table
            [entities]="filteredSortableActivityTypes$ | async"
            [columnDefinition]="columnDefinition"
            [narrowRows]="true"
            [entityName]="entityName"
            [popoverMessage]="noRootSelectedMessage"
            (entitiesSortedIdsChanged)="onReorderdEntitiesChanged($event)"
        ></app-multiselect-sorting-table>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button 
        mat-button 
        data-cy="sortingActivityTypeDialogButton"
        class="bol-button-solid bol-positive"
        (click)="onConfirm()">
            <span translate>Confirm</span>
        </button>
    </div>
</mat-dialog-actions>