<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<mat-card class="bol-card changes-panel">
    <h3 class="changes-filter">Filters</h3>
    <div class="content">
        <div class="period-label-row">
            <p class="bol-text bol-text-regular period-actions-label">{{ 'Search period actions' | translate }}</p>
            <p class="bol-text bol-text-regular period-activities-label">
                {{ 'Search period activities' | translate }}
            </p>
        </div>
        <div class="period-dates">
            <mat-form-field class="bol-form-field bol-datepicker start-time-change">
                <mat-label>{{ 'Start date' | translate }}</mat-label>
                <input
                    #actionFromDateInput
                    matInput
                    required
                    [bolDatepicker]="actionFromDatePicker"
                    [min]="getMinDateStartDate()"
                    [max]="currentDate"
                    [formControl]="dateTimeControls.actionFromDate"
                    data-cy="changesStartDate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="actionFromDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #actionFromDatePicker
                    (closed)="actionFromDateClosed()"
                >
                </mat-datepicker>
                @if (dateTimeControls.actionFromDate.invalid) {
                    <mat-error>
                        {{ getDateErrorMessage(actionFromDateInput) | translate }}
                    </mat-error>
                }
            </mat-form-field>

            <mat-form-field
                class="bol-form-field bol-datepicker end-time-change bol-form-field dynamic-subscript-height"
            >
                <mat-label>{{ 'End date' | translate }}</mat-label>
                <input
                    #actionTillDateInput
                    matInput
                    required
                    [bolDatepicker]="actionTillDatePicker"
                    [min]="dateTimeControls.actionFromDate.value"
                    [max]="getMaxDateForActionEndDate()"
                    [formControl]="dateTimeControls.actionTillDate"
                    data-cy="changesEndDate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="actionTillDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #actionTillDatePicker> </mat-datepicker>
                @if (
                    dateTimeControls.actionTillDate.invalid && !dateTimeControls.actionTillDate.errors?.matDatepickerMin
                ) {
                    <mat-error>
                        {{ getDateErrorMessage(actionTillDateInput) | translate }}
                    </mat-error>
                }
                @if (dateTimeControls.actionTillDate.errors?.matDatepickerMin) {
                    <mat-error>{{ 'The end date is before the start date' | translate }}</mat-error>
                }
            </mat-form-field>

            <mat-form-field class="bol-form-field bol-datepicker start-time-change">
                <mat-label>{{ 'Start date' | translate }}</mat-label>
                <input
                    #activityFromDateInput
                    matInput
                    [matDatepicker]="activityFromDatePicker"
                    [min]="getMinDateStartDate()"
                    [formControl]="dateTimeControls.activityFromDate"
                    data-cy="changesActivityStartDate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="activityFromDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #activityFromDatePicker
                    (closed)="activityFromDateClosed()"
                ></mat-datepicker>
                @if (dateTimeControls.activityFromDate.invalid) {
                    <mat-error>
                        {{ getDateErrorMessage(activityFromDateInput) | translate }}
                    </mat-error>
                }
            </mat-form-field>

            <mat-form-field class="bol-form-field bol-datepicker end-time-change bol-form-field dynamic-subscript-height">
                <mat-label>{{ 'End date' | translate }}</mat-label>
                <input
                    #activityTillDateInput
                    matInput
                    [matDatepicker]="activityTillDatePicker"
                    [min]="getMinDateForActivityEndDate()"
                    [formControl]="dateTimeControls.activityTillDate"
                    data-cy="changesActivityEndDate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="activityTillDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #activityTillDatePicker> </mat-datepicker>
                @if (
                    dateTimeControls.activityTillDate.invalid &&
                    !dateTimeControls.activityTillDate.errors?.matDatepickerMin
                ) {
                    <mat-error>
                        {{ getDateErrorMessage(activityTillDateInput) | translate }}
                    </mat-error>
                }
                @if (dateTimeControls.activityTillDate.errors?.matDatepickerMin) {
                    <mat-error>{{ 'The end date is before the start date' | translate }}</mat-error>
                }
            </mat-form-field>
            <button
                mat-button
                class="redirect-button bol-button-solid bol-blue"
                [disabled]="
                    dateTimeControls.actionTillDate.invalid ||
                    dateTimeControls.actionFromDate.invalid ||
                    dateTimeControls.activityTillDate.invalid ||
                    dateTimeControls.activityFromDate.invalid
                "
                (click)="onShowChanges()"
                data-cy="showChangesButton"
            >
                <span>{{ 'Show changes' | translate }}</span>
            </button>
        </div>

        <mat-divider></mat-divider>

        <div class="changes-table">
            <app-changes-table
                [columns]="columns"
                [entities]="entities$ | async"
            >
            </app-changes-table>
        </div>
    </div>
</mat-card>

