import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, first, map, startWith, tap } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { filterUndefined } from 'src/app/core/rxjs-utils';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeCategory } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.model';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityTypeCategoryService } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.service';

import { SortingPaletteQuery } from './store/sorting-palette.query';
import { SortingPaletteService } from './store/sorting-palette.service';
import { SortableActivityType } from './store/sorting-palette.model';
import { Memoized } from '@ortec/utilities/core';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';

@Component({
    selector: 'app-sorting-palette',
    templateUrl: './sorting-palette.component.html',
    styleUrls: ['./sorting-palette.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortingPaletteComponent implements OnInit, OnDestroy {
    public columnDefinition = [{
        columnDisplayName: 'Short name',
        entityProperty: 'shortName',
        stylingProperty: 'nameStyling'
    },
    {
        columnDisplayName: 'Display name',
        entityProperty: 'displayName',
    }];
    
    private readonly autosaveService = inject(AutosaveService);
    private readonly displaySettingQuery = inject(DisplaySettingQuery);
    private readonly displaySettingService = inject(DisplaySettingService);
    private readonly activityTypeCategoryQuery = inject(ActivityTypeCategoryQuery);
    private readonly activityTypeCategoryService = inject(ActivityTypeCategoryService);
    private readonly sortingPaletteQuery = inject(SortingPaletteQuery);
    private readonly sortingPaletteService = inject(SortingPaletteService);
    private readonly activityTypeQuery = inject(ActivityTypeQuery);
    private readonly activityTypeService = inject(ActivityTypeService);

    public ngOnInit(): void {
        this.setInitialStatesFromDisplaySettings();
        this.activityTypeCategoryService.getActivityTypeCategories().pipe(first()).subscribe();
        this.activityTypeService.getMainActivityTypes().pipe(first()).subscribe();
        this.initActivityTypeTableData();
    }
    
    public ngOnDestroy(): void {
        this.autosaveService.saveUnsavedChanges();
    }

    public onApplySorting(event: MatCheckboxChange): void {
        this.sortingPaletteService.updateApplySortState(event.checked);
        this.sortingPaletteService.updatePageStatusState(STATUS.IS_LOADING);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.APPLY_ACTIVITY_TYPE_SORT, event.checked).pipe(
            first(),
            tap(() => this.sortingPaletteService.updatePageStatusState(STATUS.IS_FINISHED_SAVING))
        ).subscribe();
    }
    
    public onReorderdEntitiesChanged(activityTypeIds: Array<number>) : void {
        const selectedActivityTypeCategoryId = this.sortingPaletteQuery.getSelectedActivityTypeCategoryIdSync();
        const selectedActivityTypeIds = this.sortingPaletteQuery.getSelectedActivityTypeIdsSync();
        const sortedActivityTypeIdsForSelectedActivityTypeId: Array<SortableActivityType> = activityTypeIds.map((activityTypeId, index) => ({
            activityTypeId,
            sortOrder: index,
            categoryId: selectedActivityTypeCategoryId
        }));

        // Update the sort order in selectedActivityTypeIds based on sortedActivityTypeIds
        selectedActivityTypeIds.forEach((selectedActivityType) => {
            const matchingSortedActivityType = sortedActivityTypeIdsForSelectedActivityTypeId.find((sortedActivityType) => sortedActivityType.activityTypeId === selectedActivityType.activityTypeId);
            if (matchingSortedActivityType) {
                selectedActivityType.sortOrder = matchingSortedActivityType.sortOrder;
            }
        });

        // Update the sortedActivityTypeIds in service
        this.sortingPaletteService.updateSortedActivityTypeIds(selectedActivityTypeIds);

        const sortableActivityTypes: any = {};

        activityTypeIds.forEach((activityTypeId, index) => {
            sortableActivityTypes[activityTypeId] = index;
        });

        this.sortingPaletteService.updatePageStatusState(STATUS.HAS_PENDING_CHANGES);
        this.autosaveService.autoSave(sortableActivityTypes, this.updateSortedActivityTypes.bind(this));
    }

    public onSelectedActivityTypeCategoryChange(id: number): void {
        this.sortingPaletteService.updatePageStatusState(STATUS.DEFAULT);
        this.autosaveService.saveUnsavedChanges();
        this.sortingPaletteService.updateSelectedActivityTypeCategoryId(id);
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return combineLatest([
            this.sortingPaletteQuery.getEntitiesLoading(),
            this.activityTypeCategoryQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([loading, actTypeCatLoading]) => {
                return !loading && !actTypeCatLoading;
            }),
            map(() => true),
            first(),
            startWith(false)
        );
    }

    @Memoized public get applySortingState$(): Observable<boolean> {
        return this.sortingPaletteQuery.getApplySortState();
    }

    @Memoized public get selectedActivityTypeCategoryId$(): Observable<number> {
        return this.sortingPaletteQuery.getSelectedActivityTypeCategoryId();
    }

    @Memoized public get activityTypeCategories$(): Observable<Array<ActivityTypeCategory>> {
        return this.activityTypeCategoryQuery.getActivityTypeCategoriesForFiltering();
    }

    @Memoized public get filteredSortableActivityTypes$(): Observable<Array<ActivityType>> {
        return combineLatest([
            this.sortingPaletteQuery.getSelectedActivityTypeCategoryId(),
            this.activityTypeQuery.getMainActiveActivityTypes(),
            this.sortingPaletteQuery.getSelectedActivityTypeIds(),
        ]).pipe(
            filter(([selectedActivityCategoryId, activityTypes, selectedActivityTypeIds]) => selectedActivityCategoryId !== undefined && activityTypes.length > 0 && selectedActivityTypeIds !== undefined),
            map(([selectedActivityCategoryId, activityTypes, selectedActivityTypeIds]) => {
                // Filter the activity types based on selectedActivityTypeIds and selectedActivityCategoryId
                const sortOrderMap = new Map<number, number>();
                selectedActivityTypeIds.forEach((selectedActivity) => {
                    sortOrderMap.set(selectedActivity.activityTypeId, selectedActivity.sortOrder);
                });

                const updatedActivityTypes = activityTypes
                    .filter((activityType) => {
                        return selectedActivityTypeIds.some((selectedActivity) =>
                            selectedActivity.activityTypeId === activityType.id &&
                            selectedActivity.categoryId === selectedActivityCategoryId
                        );
                    })
                    .sort((a, b) => {
                        const sortOrderA = sortOrderMap.get(a.id) || 0;
                        const sortOrderB = sortOrderMap.get(b.id) || 0;

                        return sortOrderA - sortOrderB;
                    })
                    .map((activityType) => ({
                        ...activityType,
                        nameStyling: {
                            backgroundColor: '#' + activityType.backColor,
                            color: '#' + activityType.textColor,
                        },
                    }));

                return updatedActivityTypes;
            })
        );
    }

    @Memoized public get statusPageState$(): Observable<STATUS> {
        return this.sortingPaletteQuery.getStatusPageState();
    }
    
    private updateSortedActivityTypes(sortableActivityTypes: any): void {
        const selectedActivityTypeCategoryId = this.sortingPaletteQuery.getSelectedActivityTypeCategoryIdSync();
        this.sortingPaletteService.updateSortedActivityTypes(sortableActivityTypes, selectedActivityTypeCategoryId).pipe(first()).subscribe();
    }

    private initActivityTypeTableData(): void {
        this.sortingPaletteService.getSortedActivityTypes().pipe(first()).subscribe();
    }

    private setInitialStatesFromDisplaySettings(): void {
        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.APPLY_ACTIVITY_TYPE_SORT, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => {this.sortingPaletteService.updateApplySortState(state);})
        ).subscribe();
    }
}