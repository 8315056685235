@if ((initialLoadingFinished$ | async) === false) {
    <div class="bol-modal__header">
        <span class="modal-title">
            {{ 'ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.TITLE' | translate }}
            {{ activityTypeNameAndTimeslotPeriod$ | async }}
        </span>
        <div
            class="bol-modal__close"
            (click)="onCloseModal()"
        >
            <mat-icon svgIcon="close"></mat-icon>
        </div>
    </div>

    <mat-dialog-content>
        <div>
            <div class="top-filters">
                <div class="week-day-container">
                    <div class="week-input">
                        <mat-form-field
                            class="bol-form-field default-mat-form-field"
                            data-cy="weekField"
                        >
                            <mat-label translate>TIMEDURATION.WEEK</mat-label>
                            <mat-select
                                #weekSelect
                                [value]="weekSelect$ | async"
                                (openedChange)="openedWeekSelect($event)"
                                data-cy="weekSelect"
                            >
                                @for (week of weeks; track week.value) {
                                    <mat-option [value]="week.value">
                                        {{ week.type }}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="day-input">
                        <mat-form-field
                            class="bol-form-field default-mat-form-field"
                            data-cy="dayField"
                        >
                            <mat-label translate>TIMEDURATION.DAY</mat-label>
                            <mat-select
                                #daySelect
                                [value]="daySelect$ | async"
                                (openedChange)="openedDaySelect($event)"
                                data-cy="daySelect"
                            >
                                @for (day of days; track day.value) {
                                    <mat-option [value]="day.value">
                                        {{ day.type | translate }}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>

                    @if (missingPreferredResources$ | async) {
                        <div
                            class="info-icon-sign"
                            [bolPopoverHover]="popoverHover"
                            data-cy="missingResourcesInfoIcon"
                        >
                            <mat-icon svgIcon="information"></mat-icon>
                        </div>
                    }

                    <ng-template #popoverHover>
                        <!-- we need to add the logic to display Affected weeks: Week 1, Week 2, Week 3, etc. -->
                        <!-- Affected days for week {{current week number}}: Mon, Tue, Wed, etc. -->
                        <div class="popover-hover"></div>
                    </ng-template>
                </div>

                <div
                    class="demand-amount-info-message"
                    data-cy="demandAmount"
                >
                    <h4 class="bol-text bol-text-medium">
                        {{ 'ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.DEMAND-AMOUNT' | translate }}
                        {{ demandAmount$ | async }}
                    </h4>
                </div>
            </div>

            <div class="activity-types-table">
                <table
                    mat-table
                    class="bol-table bol-table-bordered-rows preferred-resources-table"
                    [dataSource]="activityTypes$ | async"
                >
                    <ng-container matColumnDef="shortName">
                        <th
                            *matHeaderCellDef
                            mat-header-cell
                            translate
                            class="column_shortname"
                        >
                            ENTITY.ACTIVITY-TYPE
                        </th>
                        <td
                            *matCellDef="let activityType"
                            mat-cell
                        >
                            <div
                                class="column_shortname-cell"
                                [ngStyle]="activityType.nameStyling"
                            >
                                {{ activityType.shortName }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="displayName">
                        <th
                            *matHeaderCellDef
                            mat-header-cell
                            translate
                            class="column_displayname"
                        >
                            COMMON.DISPLAY-NAME
                        </th>
                        <td
                            *matCellDef="let activityType"
                            mat-cell
                        >
                            {{ activityType.displayName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="preferredResources">
                        <th
                            *matHeaderCellDef
                            mat-header-cell
                            translate
                        >
                            ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.PREFERRED-RESOURCES
                        </th>
                        <td
                            *matCellDef="let activityType"
                            mat-cell
                            class="column_resources-cell-container"
                        >
                            @if (!activityType.hasChildren) {
                                @if (activityType.preferredResourceNames !== '') {
                                    <div class="column_resources-cell">
                                        <div
                                            class="column_resources-cell-inner"
                                            [bolPopoverOverflow]="popoverHover"
                                        >
                                            <ng-template #popoverHover>
                                                {{ activityType.preferredResourceNames }}
                                            </ng-template>
                                            {{ activityType.preferredResourceNames }}
                                        </div>
                                        <button
                                            mat-button
                                            class="bol-button-text icon-only column_resources-button"
                                            (click)="onSelectResources(activityType)"
                                            data-cy="selectPreferredResourcesButton"
                                        >
                                            <mat-icon svgIcon="pencil"></mat-icon>
                                        </button>
                                    </div>
                                } @else {
                                    <button
                                        mat-button
                                        class="bol-button-text"
                                        (click)="onSelectResources(activityType)"
                                        data-cy="selectPreferredResourcesButton"
                                    >
                                        <mat-icon svgIcon="plus"></mat-icon>
                                        <span translate>
                                            ACTIVITY-DEMAND.EDIT-TEMPLATES.PREFERRED-RESOURCE.SELECT-RESOURCES
                                        </span>
                                    </button>
                                }
                            }
                        </td>
                    </ng-container>

                    <tr
                        *matHeaderRowDef="displayedColumns; sticky: true"
                        mat-header-row
                    ></tr>
                    <tr
                        *matRowDef="let row; columns: displayedColumns"
                        mat-row
                        tabindex="0"
                    ></tr>
                </table>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="dialog-actions-buttons">
            <button
                mat-button
                (click)="onCancel()"
                data-cy="preferredResourceDialogConfirmButton"
            >
                <span translate>ACTION.CLOSE</span>
            </button>
        </div>
    </mat-dialog-actions>
}

@if ((initialLoadingFinished$ | async) === true || (showSpinner$ | async) === true) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}
