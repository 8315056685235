<div
    class="status-indication__bar"
    data-cy="loadingStatusIndication"
>
    @if (statusPageState === STATUS.IS_LOADING) {
        <div
            class="status-indication__text"
            translate
        >
            saving
        </div>
        <mat-spinner
            diameter="16"
            class="status-indication__spinner"
        ></mat-spinner>
    }

    @if (statusPageState === STATUS.IS_FINISHED_SAVING) {
        <div
            class="status-indication__text"
            translate
        >
            saved
        </div>
    }

    @if (statusPageState === STATUS.HAS_PENDING_CHANGES) {
        <div
            class="status-indication__text"
            translate
        >
            changes pending
        </div>
    }
</div>

