import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable} from 'rxjs';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { MANAGE_MODE } from 'src/app/shared/models/Enums';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { UserGroupQuery } from 'src/app/shared/stores/user-group-store/user-group.query';

import { OrganizationUnitsManagementState, OrganizationUnitsManagementStore } from './organization-units-management.store';

@Injectable({
    providedIn: 'root'
})
export class OrganizationUnitsManagementQuery extends Query<OrganizationUnitsManagementState> {
    constructor(
        protected store: OrganizationUnitsManagementStore,
        protected organizationUnitsQuery: OrganizationUnitQuery,
        private readonly userGroupQuery: UserGroupQuery,
        private readonly translateService: TranslateService,
    ) {
        super(store);
    }

    public allEntitiesLoaded(): Observable<boolean> {
        return combineLatest([
            this.userGroupQuery.getEntitiesLoadingState(),
            this.organizationUnitsQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([userGroupsLoading, organizationUnitsLoading]) => {
                return !userGroupsLoading && !organizationUnitsLoading;
            }),
            map(() => true),
            first(),
            startWith(false)
        );
    }
    
    public getSelectedUIOrganizationUnit(): Observable<EntityUI> {
        return this.getCurrentOrganizationUnitId().pipe(
            switchMap((organizationUnitId) => {
                return this.organizationUnitsQuery.getUIOrganizationUnit(organizationUnitId);
            })
        );
    }
    
    public getCurrentOrganizationUnitIdSync(): number {
        return this.getValue().selectedOrganizationUnitId;
    }

    public getCurrentOrganizationUnitId(): Observable<number> {
        return this.select(state => state.selectedOrganizationUnitId);
    }

    public getManageMode(): Observable<MANAGE_MODE> {
        return this.select(state => state.manageMode);
    }

    public getSelectedOrganizationUnit(): Observable<OrganizationUnit> {
        return this.getCurrentOrganizationUnitId().pipe(
            switchMap((organizationUnitId) => {
                return this.organizationUnitsQuery.getOrganizationUnit(organizationUnitId);
            })
        );
    }

    public getSelectedOrganizationSync(): OrganizationUnit {
        const selectedId = this.getCurrentOrganizationUnitIdSync();
        
        return this.organizationUnitsQuery.getOrganizationUnitSync(selectedId);
    }

    public getNewOrganizationUnitObject(): OrganizationUnit {
        return {
            id: -1,
            displayName: this.translateService.instant('New organization unit'),
            fte: this.getSelectedOrganizationSync()?.fte,
            parentId: this.getSelectedOrganizationSync()?.id
        };
    }
}
