<mat-card
    class="bol-card"
    class="content-wrapper"
>
    <mat-card-content>
        <div class="status-title">
            <h3
                class="configurations-title"
                translate
                data-cy="organizationUnitsConfigurationsTitle"
            >
                Organization units configurations
            </h3>
            <app-loading-status-indication [statusPageState]="statusPageState$ | async"></app-loading-status-indication>
        </div>
        <div class="configurations-container">
            <p
                class="bol-text label-filter-organization-units"
                translate
            >
                Organization unit:
            </p>
            <app-filter-tree-single-select
                labelText="Organization unit"
                [entities]="organizationUnits$ | async"
                [disabled]="false"
                [selectedEntityId]="selectedOrganizationUnits$ | async"
                (selectedEntityIdChanged)="onFilteredOrganizationsChanged($event)"
                data-cy="organizationUnitsFilter"
            ></app-filter-tree-single-select>
            <p
                class="bol-text label-filter-activity-types"
                translate
            >
                Activity type for part-time schedule:
            </p>
            <app-filter-tree-single-select
                labelText="Activity type"
                [entities]="activityTypes$ | async"
                [disabled]="false"
                [selectedEntityId]="selectedActivityTypeId$ | async"
                (selectedEntityIdChanged)="onSelectedActivityTypeChange($event)"
                data-cy="activityTypeFilter"
            ></app-filter-tree-single-select>
        </div>
    </mat-card-content>
</mat-card>

@if ((initialLoadingFinished$ | async) === false) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

