<mat-form-field
    class="bol-form-field"
    [formlyAttributes]="field"
>
    <mat-label>{{ props.placeholderText | translate }}</mat-label>
    <bol-select
        [selectionMode]="'multiple'"
        [control]="bolMultiSelectFormControl"
        [items]="multiselectOptions"
        [isSearchable]="true"
        [textConfiguration]="selectLabels$ | async"
    >
    </bol-select>
    @if (bolMultiSelectFormControl.invalid) {
        <mat-error>{{ 'Field is required' | translate }}</mat-error>
    }
</mat-form-field>
