import { Injectable } from '@angular/core';
import { EntityState, StoreConfig } from '@datorama/akita';
import { StatusPageState, StatusPageStore, initialStatusPageState } from '../status-page-store/status-page.store';
import { PreferredResourcesForTimeSlot, PreferredResourcesPerWeekDay } from './preferred-resources.model';

export interface PreferredResourcesState extends EntityState, StatusPageState {
    ui: {
        entitiesLoading: boolean;
        currentDay: number;
        currentWeek: number;
    },
    preferredResourcesPerWeekDay: Array<PreferredResourcesPerWeekDay>
}

const initialState = {
    ui: {
        entitiesLoading: false,
        currentDay: 1,
        currentWeek: 1 
    },
    preferredResourcesPerWeekDay: []
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'preferred-resources' })
export class PreferredResourcesStore extends StatusPageStore<PreferredResourcesState> {
    
    constructor() {
        super({...initialState, ...initialStatusPageState});
    }

    public updateEntitiesLoadingState(entitiesLoading: boolean): void {
        this.update(state => {
            const ui = { ...state.ui, entitiesLoading };
            
            return { ...state, ui };
        });
    }

    public updateCurrentDay(currentDay: number): void {
        this.update(state => {
            const ui = { ...state.ui, currentDay };
            
            return { ...state, ui };
        });
    }

    public updateCurrentWeek(currentWeek: number): void {
        this.update(state => {
            const ui = { ...state.ui, currentWeek };
            
            return { ...state, ui };
        });
    }

    public updatePreferredResourcesPerWeekDay(preferredResources: PreferredResourcesForTimeSlot, weekNumber: number, dayNumber: number): void {
        this.update(state => {
            const preferredResourcesPerWeekDay = [ ...state.preferredResourcesPerWeekDay ];

            const match = preferredResourcesPerWeekDay.find(entry => entry.weekNumber === weekNumber && entry.dayNumber === dayNumber);
            // if it already exists, update it
            if (match) {
                match.preferredResources = preferredResources
            }
            // otherwise, add it
            else {
                preferredResourcesPerWeekDay.push({ weekNumber, dayNumber, preferredResources });
            }
                        
            return { ...state, preferredResourcesPerWeekDay };
        });
    }
}
