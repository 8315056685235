<mat-card
    class="bol-card full-height"
    [ngClass]="cardClass"
>
    <mat-card-header class="bol-card-header mat-card-header-padding">
        <mat-card-title
            class="bol-card-title entity-title"
            translate
            [attr.data-cy]="dataCyLabel ? dataCyLabel : 'entityListTitle'"
        >
            {{ entityName }}
        </mat-card-title>
        @if (!hideAddButton) {
            <button
                mat-button
                class="bol-button-solid bol-default"
                (click)="onAddEntityClicked()"
                data-cy="addEntityButton"
            >
                <span translate>Add</span>
            </button>
        }
    </mat-card-header>
    <mat-card-content class="bol-card-content">
        @if (!loadingError) {
            @if (dataSource?.data?.length > 0 && searchProperties?.length > 0) {
                <mat-form-field class="bol-form-field entity-search">
                    <input
                        matInput
                        autocomplete="off"
                        placeholder="{{ 'Search' | translate }}"
                        (keyup)="searchEntities($event.target.value)"
                        data-cy="searchEntityField"
                    />
                </mat-form-field>
            }
            @if (dataSource?.data?.length > 0 && !noVisibleEntities) {
                <div
                    class="list-header"
                    translate
                >
                    Display name
                </div>
            }
            @if (dataSource?.data?.length > 0 && !noVisibleEntities) {
                <mat-tree
                    class="tree"
                    [dataSource]="dataSource"
                    [treeControl]="treeControl"
                    data-cy="entityTable"
                >
                    <!-- tree nodes without children -->
                    <mat-tree-node
                        *matTreeNodeDef="let node"
                        matTreeNodeToggle
                        [class.tree-invisible]="!node.visible"
                    >
                        <li
                            class="mat-tree-node"
                            [ngClass]="{ selected: selectedEntityId === node.id }"
                            [class.node-gray-background]="node.index % 2 === 0"
                            (click)="onEntitySelected(node)"
                        >
                            @if (node.isLoading && node.id !== selectedEntityId) {
                                <mat-spinner
                                    class="row-loading-spinner"
                                    diameter="16"
                                >
                                </mat-spinner>
                            }
                            <button
                                mat-icon-button
                                disabled
                            ></button>
                            @if (showCheckboxes) {
                                <mat-checkbox
                                    class="bol-checkbox"
                                    [checked]="node.checked"
                                    [disabled]="node.disabled"
                                    (change)="onCheckboxClick($event, node)"
                                >
                                </mat-checkbox>
                            }
                            <div class="node-label">{{ node.displayName }}</div>
                        </li>
                    </mat-tree-node>
                    <!-- tree nodes with children -->
                    <mat-nested-tree-node
                        *matTreeNodeDef="let node; when: hasChild"
                        [class.tree-invisible]="!node.visible"
                    >
                        <li>
                            <div
                                class="mat-tree-node"
                                [class.node-gray-background]="node.index % 2 === 0"
                                [ngClass]="{ selected: selectedEntityId === node.id }"
                                (click)="onEntitySelected(node)"
                            >
                                @if (node.isLoading && node.id !== selectedEntityId) {
                                    <mat-spinner
                                        class="row-loading-spinner"
                                        diameter="16"
                                    >
                                    </mat-spinner>
                                }
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'toggle ' + node.displayName"
                                >
                                    <mat-icon
                                        class="mat-icon-rtl-mirror"
                                        [svgIcon]="treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"
                                    ></mat-icon>
                                </button>
                                @if (showCheckboxes) {
                                    <mat-checkbox
                                        class="mat-tree-node__checkbox bol-checkbox"
                                        [checked]="node.checked"
                                        [disabled]="node.disabled"
                                        (change)="onCheckboxClick($event, node)"
                                    >
                                    </mat-checkbox>
                                }
                                <div class="node-label">{{ node.displayName }}</div>
                            </div>
                            <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                            <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            }
        }

        @if (!loadingError && (this.dataSource?.data?.length === 0 || noVisibleEntities)) {
            <p data-cy="entityNoEntitiesFound">
                {{ 'NO-ENTITIES-FOUND' | translate: { entityName: entityName | translate | lowercase } }}
            </p>
        }

        @if (loadingError) {
            <h4
                translate
                data-cy="entityLoadingError"
            >
                ERROR.LOADING-ENTITIES-ERROR
            </h4>
        }
    </mat-card-content>
</mat-card>

