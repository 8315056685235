<div class="flex-column">
    <mat-card class="menu-card">
        <mat-card-content>
            <a
                class="link"
                [attr.data-cy]="title"
                [routerLink]="url"
                [target]="target"
            >
                @if (!useCustomContent) {
                    <mat-icon
                        class="icon"
                        [svgIcon]="icon"
                    ></mat-icon>
                }
                @else {
                    <div class="custom-content">
                        <ng-content></ng-content>
                    </div>
                }
                <mat-divider class="divider"></mat-divider>
                <div class="title-container">
                    <div
                        class="title"
                        [bolPopoverOverflow]="popoverHover"
                    >
                        {{ title | translate }}
                    </div>
                    <ng-template #popoverHover>
                        {{ title | translate }}
                    </ng-template>
                </div>
            </a>
        </mat-card-content>
    </mat-card>
</div>
