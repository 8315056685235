/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { FormlyFieldConfig } from '@ngx-formly/core';

import { fieldNonEmptyValidatorKey } from 'src/app/shared/validators';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { SkillLevel } from 'src/app/shared/stores/skill-level-store/skill-level.model';

export function getSkillFormFields(
    organizationUnits: Array<IOrganizationUnitTree>,
    selectedOrganizationUnitIds: Array<number>,
    resourceTypes: Array<ResourceType>,
    skillLevels: Array<SkillLevel>,
    disableSkillsOptions: any,
    disableSkills: (selectedIds: any, entities: Array<any>, relatedOrgUnitIds: Array<number>) => Array<any>,
): Array<FormlyFieldConfig> {
    return [
        {
            key: 'displayName',
            type: 'bolster-input',
            props: {
                label: 'Display name',
                required: true,
                maxLength: 80,
                attributes: {
                    autocomplete: 'off'
                }
            },
            validators: {
                validation: [fieldNonEmptyValidatorKey]
            }
        },
        {
            key: 'externalId',
            type: 'bolster-input',
            props: {
                label: 'External id',
                maxLength: 40,
                attributes: {
                    autocomplete: 'off'
                }
            }
        },
        {
            key: 'validOrganizationUnitIds',
            type: 'multiselect-tree',
            props: {
                entities: organizationUnits,
                placeholderText: 'Coupled organization units',
                searchProperties: ['displayName'],
                preSelectedEntityIds: selectedOrganizationUnitIds
            }
        },
        {
            key: 'validResourceTypeIds',
            type: 'multiselect',
            props: {
                entities: resourceTypes,
                placeholderText: 'Coupled resource types'
            }
        },
        {
            key: 'validSkillLevelIds',
            type: 'multiselect-tree',
            props: {
                entities: skillLevels,
                placeholderText: 'Coupled skill levels',
                searchProperties: ['displayName'],
                disableOptions: disableSkillsOptions,
                disableOptionsFunction: disableSkills
            }
        }
    ];
}

export function getSkillSearchProperties (): Array<string> {
    return ['displayName', 'externalId'];
}
