<mat-card
    class="bol-card"
    class="content-wrapper"
>
    <div>
        <div>
            <h3
                data-cy="EntityTitle"
                translate
            >
                Holidays
            </h3>
        </div>
        <div class="outer">
            <div class="inner">
                <slim-dropdown
                    [selectedItem]="selectedItem"
                    [items]="items$ | async"
                    (selectedItemChanged)="onSelectedYear($event)"
                ></slim-dropdown>
            </div>
            <div class="divider"></div>
            <div class="inner">
                <button
                    mat-button
                    class="bol-button-solid"
                    (click)="onAddHoliday()"
                >
                    <div
                        translate
                        data-cy="AddEntityButton"
                    >
                        Add holiday
                    </div>
                </button>
            </div>
        </div>
        @if ((holidays$ | async)?.length > 0) {
            <div
                class="holidays-list-container"
                data-cy="HolidaysListContainer"
            >
                <div class="holidays-list-header holiday-row">
                    <div
                        class="holiday-date"
                        translate
                        data-cy="HolidayDateTitle"
                    >
                        Date
                    </div>
                    <div
                        class="holiday-text"
                        translate
                        data-cy="HolidayDescriptionTitle"
                    >
                        Description
                    </div>
                </div>
                @for (holiday of holidays$ | async; track holiday.id; let i = $index) {
                    <div>
                        <app-holiday-item
                            [ngModel]="holiday"
                            [holidays]="holidays$ | async"
                            [index]="i"
                            (removeHolidayEvent)="onDeleteHoliday($event)"
                            (updateHolidayEvent)="onUpdateHoliday($event)"
                        ></app-holiday-item>
                    </div>
                }
            </div>
        }
        @if (showEmptyState$ | async) {
            <div class="empty-state">
                <mat-icon svgIcon="timelapse"></mat-icon>
                <div
                    class="empty-state-description"
                    translate
                    data-cy="EntityNoEntitiesFound"
                >
                    No holidays available yet
                </div>
            </div>
        }
    </div>

    @if ((initialLoadingFinished$ | async) === false || (showLoadingSpinner$ | async)) {
        <div class="overlay-spinner">
            <mat-spinner diameter="100"></mat-spinner>
        </div>
    }
</mat-card>

