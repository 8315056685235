import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { GlobalSettingsQuery } from '../stores/global-settings/global-settings.query';
import { adjustDateForTimezone } from './adjust-date-for-timezone';

@Injectable({
    providedIn: 'root'
})
export class DateTimeUtilityService {
    constructor(private readonly globalSettingsQuery: GlobalSettingsQuery) { }

    public transformDateInTimeFormat(date: string): string {
        const hours = new Date(date).getHours();
        const minutes = new Date(date).getMinutes();

        return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0');
    }

    public transformTimeInMinutes(time: string): number {
        const timeInHoursAndMinutes = time.split(':');

        return +timeInHoursAndMinutes[0] * 60 + +timeInHoursAndMinutes[1];
    }

    public getFirstDayOfTheCurrentMonth(utc: boolean = true): string {
        const date = new Date();
        const firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);

        if (!utc) {
            const timeZone = this.globalSettingsQuery.getTimeZoneSync();

            return moment(!!timeZone ? adjustDateForTimezone(firstDayOfCurrentMonth, timeZone, false) : firstDayOfCurrentMonth).toISOString();
        }

        const newDate = firstDayOfCurrentMonth.getTime() - firstDayOfCurrentMonth.getTimezoneOffset() * 60 * 1000;

        return moment(newDate).toISOString();
    }

    public getFirstDayOfTheCurrentWeek(utc = true): string {
        const date = new Date();
        const firstDayOfWeek = new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)));
    
        if (!utc) {
            const timeZone = this.globalSettingsQuery.getTimeZoneSync();

            return moment(!!timeZone ? adjustDateForTimezone(firstDayOfWeek, timeZone, false) : firstDayOfWeek).toISOString();
        }
    
        const newDate = firstDayOfWeek.getTime() - firstDayOfWeek.getTimezoneOffset() * 60 * 1000;

        return moment(newDate).toISOString();
    }

    public getLastDayOfTheCurrentMonth(utc: boolean = true): string {
        const date = new Date();
        const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        if (!utc) {
            const timeZone = this.globalSettingsQuery.getTimeZoneSync();

            return moment(!!timeZone ? adjustDateForTimezone(lastDayOfCurrentMonth, timeZone, false) : lastDayOfCurrentMonth).toISOString();
        }

        const newDate = lastDayOfCurrentMonth.getTime() - lastDayOfCurrentMonth.getTimezoneOffset() * 60 * 1000;

        return moment(newDate).toISOString();
    }

    public getLastDayOfTheCurrentWeek(utc: boolean = true): string {
        const date = new Date();
        const lastDayOfWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (7 - date.getDay()));
    
        if (!utc) {
            const timeZone = this.globalSettingsQuery.getTimeZoneSync();
            
            return moment(!!timeZone ? adjustDateForTimezone(lastDayOfWeek, timeZone, false) : lastDayOfWeek).toISOString();
        }
    
        const newDate = lastDayOfWeek.getTime() - lastDayOfWeek.getTimezoneOffset() * 60 * 1000;

        return moment(newDate).toISOString();
    }

    public convertHoursToMinutes(time: string): number {
        // Split the time string into hours and minutes
        const [hoursStr, minutesStr] = time.split(':');

        // Parse the hours and minutes to numbers
        const hours = Number(hoursStr);
        const minutes = Number(minutesStr);

        // Convert to minutes and return the total
        return hours * 60 + minutes;
    }

    public convertMinutesToHours(minutes: number): string {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
    }

    public getDateFormat(cultureName: string): string {
        switch (cultureName.toLowerCase()) {
            case '':
            case 'nl-nl':
                return 'dd-MM-yyyy';
            case 'en-us':
                return 'MM/dd/yyyy';
            case 'en-gb':
                return 'dd/MM/yyyy';
            default:
                throw Error('Unknown locale ' + cultureName);
        }
    }

    public getDateTimeFormat(cultureName: string): string {
        switch (cultureName.toLowerCase()) {
        case '':
        case 'nl-nl':
            return 'dd-MM-yyyy HH:mm';
        case 'en-us':
            return 'MM/dd/yyyy hh:mm a';
        case 'en-gb':
            return 'dd/MM/yyyy hh:mm a';
        default:
            throw Error('Unknown locale ' + cultureName);
        }
    }

    public getDateFormatMoment(cultureName: string): string {
        switch (cultureName.toLowerCase()) {
            case '':
            case 'nl-nl':
                return 'DD-MM-YYYY';
            case 'en-us':
                return 'MM/DD/YYYY';
            case 'en-gb':
                return 'DD/MM/YYYY';
            default:
                throw Error('Unknown locale ' + cultureName);
        }
    }

    public getDateTimeFormatMoment(cultureName: string): string {
        switch (cultureName.toLowerCase()) {
            case '':
            case 'nl-nl':
                return 'DD-MM-YYYY HH:mm';
            case 'en-us':
                return 'MM/DD/YYYY hh:mm a';
            case 'en-gb':
                return 'DD/MM/YYYY hh:mm a';
            default:
                throw Error('Unknown locale ' + cultureName);
            }
    }
}
