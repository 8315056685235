<div class="bol-modal__header">
    <span class="modal-title">{{ 'Select resources to hide' | translate }}</span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    <mat-form-field class="bol-form-field">
        <mat-label>{{ 'Resource types' | translate }}</mat-label>
        <mat-select
            required
            [formControl]="resourceTypeControl"
            (openedChange)="openedResourceTypeSelect($event)"
            data-cy="resourceTypesField"
        >
            @for (resourceType of resourceTypes$ | async; track resourceType.id) {
                <mat-option [value]="resourceType.id">
                    {{ resourceType.displayName }}
                </mat-option>
            }
        </mat-select>
        @if (resourceTypeControl.invalid) {
            <mat-error translate>Field is required</mat-error>
        }
    </mat-form-field>

    <div class="resources-checkbox">
        <mat-checkbox
            class="bol-checkbox"
            [checked]="showOnlySelectedState$ | async"
            (change)="showOnlyResourcesSelected($event)"
            data-cy="showOnlySelectedResourceDialog"
        >
            <span>{{ 'Show only selected' | translate }}</span>
        </mat-checkbox>
    </div>

    <app-multiselect-table
        searchFieldPlaceholder="Resource filter"
        [entities]="filteredResources$ | async"
        [columnDefinition]="columnDefinition"
        [searchProperties]="searchProperties"
        [hideSearch]="false"
        (selectedEntityIdsChanged)="updateResourcesStates($event)"
    ></app-multiselect-table>

    <div
        class="resource-length-info-message"
        data-cy="resourceLengthInfoMessage"
    >
        {{ selectedResourceIds }} {{ RESOURCE_SELECTED_MESSAGE | translate }} {{ displayNameOfSelectedResourceType }}
        {{ SELECTED_TO_HIDE | translate }}
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            class="bol-button-solid bol-positive"
            (click)="onConfirm()"
            data-cy="confirmResourceDialogButton"
        >
            <span translate>Confirm</span>
        </button>
    </div>
</mat-dialog-actions>

