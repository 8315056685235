import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Memoized } from '@ortec/utilities/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, filter, finalize, first, map, mergeMap, startWith } from 'rxjs';
import { cache, combineSubscriptions } from '@ortec/utilities/rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { MANAGE_MODE } from 'src/app/shared/models/Enums';
import { AutosaveService, EntityToEdit } from 'src/app/shared/services/autosave.service';
import { ManageTemplateQuery } from 'src/app/shared/stores/manage-template-store/manage-template.query';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { ManageTimeslotService } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.service';
import { ManageTimeslotQuery } from 'src/app/shared/stores/manage-timeslot-store/manage-timeslot.query';

import { ADTActivityType } from '../../store/models';
import { AdtActivityTypeTimeslotsDialogComponent, IActivityTypeTimeslotsDialogCloseData, IActivityTypeTimeslotsDialogData } from '../adt-activity-type-timeslots-dialog/adt-activity-type-timeslots-dialog.component';
import { EditTemplatesManagementQuery, EditTemplatesManagementService } from '../../store';

@Component({
    selector: 'app-adt-activity-type-table',
    templateUrl: './adt-activity-type-table.component.html',
    styleUrls: ['./adt-activity-type-table.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdtActivityTypeTableComponent implements OnInit, OnDestroy {
    public searchTerm = new FormControl('');
    public formArray = new FormArray([]);
    public periodTotal: Array<number> = [];
    public weeklyTotals: Array<number> = [];
    public autoSaveLoadingStateSubject = new BehaviorSubject<boolean>(false);
    public navigateToWeekLoadingStateSubject = new BehaviorSubject<boolean>(false);

    private onAddTimeslotsClickedSubject = new Subject<void>();
    private currentTemplateId: number;
    private activityTypesTimeslotsSet: Set<ADTActivityType> = new Set();
    private readonly subscriptions = new Subscription();
    
    private readonly editTemplatesManagementService = inject(EditTemplatesManagementService);
    private readonly editTemplatesManagementQuery = inject(EditTemplatesManagementQuery);
    private readonly manageTemplateQuery = inject(ManageTemplateQuery);
    private readonly manageTimeslotService = inject(ManageTimeslotService);
    private readonly manageTimeslotQuery = inject(ManageTimeslotQuery);
    private readonly dialogService = inject(MatDialog);
    private readonly activityTypeService = inject(ActivityTypeService);
    private readonly organizationUnitsService = inject(OrganizationUnitService);
    private readonly autosaveService = inject(AutosaveService);

    public ngOnInit(): void {
        this.currentTemplateId = this.editTemplatesManagementQuery.getCurrentTemplateIdSync();
        this.organizationUnitsService.getOrganizationUnitsForActivityTypes().pipe(first()).subscribe();
        this.activityTypeService.getActivityTypes().pipe(first()).subscribe(
            (activityTypes) => {
                this.activityTypeService.createActivityTypeAndSorting(activityTypes);
            }
        );
        this.setEntitiesInTable();

        this.subscriptions.add(
            combineSubscriptions(
                this.calculateTheTotalAndWeeklyTotal(),
                this.getFilteredActivityTypesValuesBasedOnSearching(),
                this.updateAddTimeslotsAfterDialogClose()
            )
        );
    }
      
    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.autosaveService.saveUnsavedChangesWithValidState();
    }

    public onAddActivityTypeClicked(): void {
        this.onAddTimeslotsClickedSubject.next();
    }

    public onNavigateToNextWeek(): void {
        this.navigateToWeekLoadingStateSubject.next(true);
        this.autosaveService.saveUnsavedChangesWithValidState();
        this.editTemplatesManagementService.increaseWeekNumber();
    }

    public onNavigateToPastWeek(): void {
        this.navigateToWeekLoadingStateSubject.next(true);
        this.autosaveService.saveUnsavedChangesWithValidState();
        this.editTemplatesManagementService.decreaseWeekNumber();
    }

    public onCopyWeeks(): void {
        console.log('Copy weeks!');
    }

    public calculateWeeklyTotal(index: number): number {
        const weekValues = (this.formArray.at(index) as FormGroup).get('weekDemand') as FormArray;

        return weekValues.controls.reduce((acc, control) => acc + control.value, 0);
    }

    public calculateTotal(index: number): Observable<number> {
        const weekValues = (this.formArray.at(index) as FormGroup).get('weekDemand') as FormArray;
        const currentWeekSum = weekValues.controls.reduce((acc, control) => acc + control.value, 0);
        const timeslotId = this.getTimeslotIdFromFormGroup(this.formArray.at(index) as FormGroup);
    
        return this.editTemplatesManagementQuery.getSumOfWeeksDemandOfTimeslotsWithoutCurrentWeek().pipe(
            map(totalsMap => {
                const previousWeeksSum = totalsMap.get(timeslotId) || 0;

                return previousWeeksSum + currentWeekSum;
            })
        );
    }

    @Memoized public get currentWeek$(): Observable<number> {
        return this.editTemplatesManagementQuery.getCurrentWeekNumber().pipe(cache());
    }

    @Memoized public get maxWeeks$(): Observable<number> {
        return this.editTemplatesManagementQuery.getMaxWeekNumber().pipe(cache());
    }

    @Memoized public get activityTypeTimeslotsRows$(): Observable<Array<ADTActivityType>> {
        return this.editTemplatesManagementQuery.getActivityTypesEntities().pipe(cache());
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return combineLatest([
            this.manageTemplateQuery.getEntitiesLoadingState(),
            this.manageTimeslotQuery.getEntitiesLoadingState(),
        ]).pipe(
            filter(([loading, timeslotsLoading]) => {
                return !loading && !timeslotsLoading;
            }),
            map(() => true),
            first(),
            startWith(false),
            cache()
        );
    }

    @Memoized private get addTimeslotsDialogResponse$(): Observable<{ data: IActivityTypeTimeslotsDialogCloseData }> {
        return this.onAddTimeslotsClickedSubject.pipe(
            mergeMap(() => this.dialogService.open(AdtActivityTypeTimeslotsDialogComponent, {
                data: {
                    manageMode: MANAGE_MODE.ADD
                } as IActivityTypeTimeslotsDialogData,
                width: '600px',
                autoFocus: false,
            }).afterClosed())
        );
    }

    private updateAddTimeslotsAfterDialogClose(): Subscription {
        return this.addTimeslotsDialogResponse$.pipe(
            filter((dialogData) => !!dialogData)
        ).subscribe((dialogData: { data: IActivityTypeTimeslotsDialogCloseData }) => {
            // NOTE: if a timeslot was already added for the selected activity type, we need to perform an update instead of an add
            if (dialogData.data.activityTypeAlreadyAdded) {
                const updatedTimeslots = this.manageTimeslotQuery.getUpdatedActivityTypeTimeslotsSync(dialogData.data.selectedTimeslotIds, dialogData.data.selectedActivityTypeId);
                this.manageTimeslotService.updateActivityDemandTimeslots(updatedTimeslots.addedTimeslots, this.currentTemplateId).pipe(first()).subscribe();
            }
            else {
                const addedTimeslots = this.editTemplatesManagementQuery.createTimeslotsForAdding(dialogData.data.selectedTimeslotIds);
                this.manageTimeslotService.updateActivityDemandTimeslots(addedTimeslots, this.currentTemplateId).pipe(first()).subscribe();
            }
        });
    }

    private getTimeslotIdFromFormGroup(formGroup: FormGroup): number {
        return formGroup.get('id').value;
    }

    private updateTimeslots(timeslots: Array<ADTActivityType>): void {
        const updatedTimeslots = this.editTemplatesManagementQuery.getNewValuesTransformedSync(timeslots);
        this.autoSaveLoadingStateSubject.next(true);
        this.manageTimeslotService.updateActivityDemandTimeslots(updatedTimeslots, this.currentTemplateId).pipe(
            first(),
            finalize(() => {
                this.autoSaveLoadingStateSubject.next(false); 
                this.navigateToWeekLoadingStateSubject.next(false);
            })
        ).subscribe();
    }

    private getFilteredActivityTypesValuesBasedOnSearching(): Subscription {
        return this.searchTerm.valueChanges.pipe(
            startWith(''),
            map((searchTerm) => {
                if (this.searchTerm.valid) {
                    this.editTemplatesManagementService.updateSearchActivityTypesValue(searchTerm);
                }
            })
        ).subscribe()
    }

    private calculateTheTotalAndWeeklyTotal(): void {
        this.formArray.valueChanges.subscribe(() => {
            this.periodTotal = this.formArray.controls.map((control, index) => {
                let total = 0;
                this.calculateTotal(index).subscribe(value => total = value);
              
                return total;
            });
            this.weeklyTotals = this.formArray.controls.map((group, i) => this.calculateWeeklyTotal(i));
        })
    }

    private setEntitiesInTable(): void {
        this.activityTypeTimeslotsRows$.subscribe(activityTypeTimeslots => {
            this.activityTypesTimeslotsSet = new Set(activityTypeTimeslots);
    
            this.formArray.clear({ emitEvent: false });
    
            activityTypeTimeslots.forEach((activity) => {
                const currentWeekValues = activity.weekDemand[0] ? activity.weekDemand[0].weekdaysDemand : Array(7).fill({ amount: 0 });
    
                const formGroup = new FormGroup({
                    id: new FormControl(activity.id),
                    currentWeekNumber: new FormControl(activity.currentWeekNumber),
                    distributionTotal: new FormControl(activity.distributionTotal),
                    weekDemand: new FormArray(currentWeekValues.map(weight => new FormControl(weight.amount, Validators.max(99))))
                });
    
                this.formArray.push(formGroup);

                formGroup.valueChanges.subscribe((value) => {
                    const timeslotsToSave: EntityToEdit = {
                        entity: Array.from(this.activityTypesTimeslotsSet),
                        valid: formGroup.valid
                    };
   
                    this.updateActivityTypesSet(value as any as ADTActivityType);
                    this.editTemplatesManagementService.updateErrorState(!formGroup.valid);
                    this.manageTimeslotService.updatePageStatusState(STATUS.HAS_PENDING_CHANGES);
                    this.autosaveService.autoSaveWithValidState(timeslotsToSave, this.updateTimeslots.bind(this));
                });
            });
        })
    }

    private updateActivityTypesSet(updatedValue: ADTActivityType): void {
        const activityId = updatedValue.id;
    
        // Find the activity in the set
        const activity = Array.from(this.activityTypesTimeslotsSet).find(a => a.id === activityId);
        if (activity) {
            // Update the activity properties
            activity.currentWeekNumber = updatedValue.currentWeekNumber;
            activity.distributionTotal = updatedValue.distributionTotal;
            activity.weekDemand = updatedValue.weekDemand;
        }
    }
}
