import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { createTimeRange, TimeAdapter } from '@ortec/bolster/time-select';
import { observeProperty } from '@ortec/utilities/rxjs';
import { Memoized } from '@ortec/utilities/core';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, first, map, skip } from 'rxjs/operators';

import { DialogService } from 'src/app/shared/services';
import { DateTimeUtilityService } from 'src/app/shared/services/date-time-utility.service';
import { Daypart } from 'src/app/shared/stores/day-part-store/day-part.model';

export interface UpdatedDaypart {
    old: Daypart;
    new: Daypart;
}

@Component({
    selector: 'app-settings-daypart-item',
    templateUrl: './settings-daypart-item.component.html',
    styleUrls: ['./settings-daypart-item.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SettingsDaypartItemComponent,
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SettingsDaypartItemComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() public readonly daypartItemErrorClass: boolean = false;
    @Input() public readonly dayparts: Array<Daypart> = [];
    @Input() public readonly addDaypart!: boolean;
    @Input() public set errorOnUpdate(numberOfErrorsOnUpdate:  number) {
        this.updateDaypartIfNoErrorItPresent(numberOfErrorsOnUpdate);
    }

    @Output() public readonly removeDaypartEvent = new EventEmitter<Daypart>();
    @Output() public readonly updateDaypartEvent = new EventEmitter<UpdatedDaypart>();
    @Output() public readonly addDaypartEvent = new EventEmitter<Daypart>();

    public timeRange$!: Observable<any>;
    public dayparts$!: Observable<Array<Daypart>>;

    public readonly formControls = {
        name: new UntypedFormControl(undefined, [Validators.required]),
        start: new UntypedFormControl(undefined, [Validators.required])
    };
    public readonly formGroup = new UntypedFormGroup(this.formControls, { updateOn: 'blur' });
    public daypart!: Daypart;
    public oldDayparts: Array<Daypart> = [];
    public showFirstDaypartInfo = false;
    public requiredErrorMessage: string = 'VALIDATORS.REQUIRED'; 
    public uniqueNameErrorLabel = 'Please choose a unique daypart name';

    private updatedDaypart: Daypart;
    private readonly daypartOldValue!: Daypart;
    private readonly daypartSubject = new BehaviorSubject<Daypart>(undefined);

    private readonly subscription = new Subscription();

    constructor(
        private readonly timeAdapter: TimeAdapter<moment.Moment>,
        private readonly dialogService: DialogService,
        private readonly translateService: TranslateService,
        private readonly dateTimeUtilityService: DateTimeUtilityService,
    ) {
        this.timeRange$ = of(createTimeRange(timeAdapter, undefined, timeAdapter.create(23, 59, 0, 0), 30));

        this.dayparts$ = observeProperty(this as SettingsDaypartItemComponent, 'dayparts');
    }

    public ngOnInit(): void {
        this.oldDayparts = [...this.dayparts];
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // Implemented Control Value Accessor method
    public writeValue(daypart: Daypart | undefined): void {
        if (daypart) {
            this.daypart = daypart;

            const startTime: moment.Moment = daypart.start ? this.transformStartInTimeFormat(daypart.start) : null;
            this.formGroup.setValue({ name: daypart.name, start: startTime }, { emitEvent: true });

            this.daypartSubject.next(daypart);

            if (this.isFirstDaypart(daypart.start)) {
                this.formControls.start.disable();
                this.showFirstDaypartInfo = true;
            }
        }
    }

    // Implemented Control Value Accessor method
    public registerOnChange(onChange: (daypart: Daypart) => void): void {
        this.subscription.add(
            this.formGroup.valueChanges.pipe(
                skip(1)
            ).subscribe(() => {
                this.updatedDaypart = this.formGroup.getRawValue();
                const updatedDaypartCopy = { ...this.updatedDaypart };
                this.setCustomErrorsOnChange(this.updatedDaypart.name, this.updatedDaypart.start);
                if (this.formControls.name.invalid || this.formControls.start.invalid) {
                    return;
                }
                this.updatedDaypart.start = this.dateTimeUtilityService.transformDateInTimeFormat(this.updatedDaypart.start);

                if (this.updatedDaypart.name &&
                    this.updatedDaypart.start &&
                    (this.updatedDaypart.name !== this.daypart.name || this.updatedDaypart.start !== this.daypart.start)) {
                    onChange(this.updatedDaypart);

                    this.oldDayparts = this.dayparts.filter(daypart => daypart.start !== this.daypart.start && daypart.name !== this.daypart.name);
                    this.oldDayparts.push(updatedDaypartCopy);

                    this.emitEventOnChange(this.updatedDaypart);
                } else {
                    onChange(this.daypartOldValue);
                }
            })
        );
    }

    // Implemented Control Value Accessor method
    public registerOnTouched(): void { 
        return; 
    }

    public onDeleteDaypart(daypart: Daypart): void {
        if (!this.addDaypart) {
            const title = this.translateService.instant('Delete daypart');
            const message = this.translateService.instant('Are you sure that you want to delete daypart?', { daypartName: daypart.name });
            const cancelButton = this.translateService.instant('Cancel');
            const deleteButton = this.translateService.instant('Delete');

            this.dialogService.showConfirmDialog(title, message, deleteButton, cancelButton).pipe(
                first(),
                map(confirm => {
                    if (confirm) {
                        this.removeDaypartEvent.next(daypart);
                    }
                })
            ).subscribe();
        } else {
            this.removeDaypartEvent.next(daypart);
        }
    }

    @Memoized public get enableDelete$(): Observable<boolean> {
        return combineLatest([
            this.dayparts$,
            this.daypart$
        ]).pipe(
            filter(([_, daypart]) => !!daypart),
            map(([dayparts, daypart]) => {
                return !(this.isFirstDaypart(daypart.start) && dayparts.length > 1);
            })
        );
    }

    @Memoized public get disableDelete$(): Observable<boolean> {
        return combineLatest([
            this.dayparts$,
            this.daypart$
        ]).pipe(
            filter(([_, daypart]) => !!daypart),
            map(([dayparts, _]) => {
                return dayparts.length === 2;
            })
        );
    }
   
    @Memoized public get daypart$(): Observable<Daypart> {
        return this.daypartSubject.asObservable();
    }

    private setCustomErrorsOnChange(name: string, start: string): void {
        const trimmedName = name?.trim();
        const uniqueName = this.oldDayparts.filter(daypart => daypart.name.toLowerCase() === trimmedName?.toLowerCase()).length > 0;
    
        if (uniqueName && trimmedName !== this.daypart.name) {
            this.formControls.name.setErrors({ notUnique: true });
        }

        const uniqueStart = this.oldDayparts.filter(daypart => daypart.start === this.dateTimeUtilityService.transformDateInTimeFormat(start)).length > 0;

        if (uniqueStart && this.dateTimeUtilityService.transformDateInTimeFormat(start) !== this.daypart.start) {
            this.formControls.start.setErrors({notUnique: true});
        }
    }

    private emitEventOnChange(updatedDaypart: Daypart): void {
        if (!this.addDaypart) {
            updatedDaypart.id = this.daypart.id;
            this.updateDaypartEvent.next({
                old: this.daypart,
                new: updatedDaypart
            });
        } else {
            this.addDaypartEvent.next(updatedDaypart);
        }
    }

    private isFirstDaypart(start: string): boolean {
        return start === '0:0' || start === '00:00';
    }

    private transformStartInTimeFormat(start: string): moment.Moment {
        const splittedStart = start.split(':');

        return this.timeAdapter.create(+splittedStart[0], +splittedStart[1], 0, 0);
    }

    private updateDaypartIfNoErrorItPresent(numberOfErrorsOnUpdate: number): void {
        if (numberOfErrorsOnUpdate !== 0) {
            this.formGroup.setValue({ name: this.daypart.name, start: this.transformStartInTimeFormat(this.daypart.start) }, { emitEvent: false });
        } else {
            this.daypart = this.updatedDaypart;
        }
    }
}
