<div class="container">
    <div class="title">{{ 'Oh no!' | translate }}</div>
    <div class="error-icon">
        <mat-icon svgIcon="block-helper"></mat-icon>
    </div>
    <div class="error-description">{{ 'It seems you do not have permissions for this page...' | translate }}</div>
    <button
        mat-button
        class="redirect-button bol-button-solid bol-blue"
        (click)="onRedirectToMainPage()"
    >
        @if (isFullUser) {
            <span>{{ 'Take me to the portal page' | translate }}</span>
        }
        @else {
            <span>{{ 'Take me to the overviews page' | translate }}</span>
        }
    </button>
</div>
