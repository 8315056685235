import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { defaultColumnDefinition, EntityListColumnDefinition } from 'src/app/shared/models';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';

interface ColumnEntity {
    id: number;
    level: number;
}

@Component({
    selector: 'app-entity-sortable-list-panel',
    templateUrl: './entity-sortable-list-panel.component.html',
    styleUrls: ['./entity-sortable-list-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntitySortableListPanelComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) public paginator: MatPaginator;

    @Output() public readonly selectedEntityIdChanged = new EventEmitter<number>();
    @Output() public readonly reorderdEntities = new EventEmitter<Array<ColumnEntity>>();
    @Output() public readonly addEntityClicked = new EventEmitter<undefined>();

    @Input() public cardClass?: string;
    @Input() public readonly entityName: string;
    @Input() public readonly columnDefinition: Array<EntityListColumnDefinition> = defaultColumnDefinition;
    @Input() public readonly loadingError: boolean;
    @Input() public readonly selectedEntityId: number;
    @Input() public readonly mode: MANAGE_MODE;
    @Input() public readonly hideAddButton: boolean = false;
    @Input() private set entities(entities: Array<ColumnEntity>) {
        this.setEntities(entities);
    };

    public dataSource = new MatTableDataSource<ColumnEntity>();
    public displayedColumns = [];

    public ngOnInit(): void {
        this.displayedColumns = this.columnDefinition.map(column => column.entityProperty);
    }

    public ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    public onEntitySelected(id: number): void {
        this.selectedEntityIdChanged.emit(id);
    }

    public onAddEntityClicked(): void {
        this.addEntityClicked.emit(undefined);
    }

    public onMoveEntityUp(entity: ColumnEntity, index: number): void {
        if (index > 0) {
            const tmp = this.dataSource.data[index - 1];
            this.dataSource.data[index - 1] = {...entity, level: tmp.level};
            this.dataSource.data[index] = {...tmp , level: entity.level};

            this.reorderdEntities.next([ this.dataSource.data[index - 1], this.dataSource.data[index] ]);
            this.onEntitySelected(entity.id);

            this.setTableDataSource(this.dataSource.data);
        }
    }

    public onMoveEntityDown(entity: ColumnEntity, index: number): void {
        if (index < this.dataSource.data.length) {
            const tmp = this.dataSource.data[index + 1];
            this.dataSource.data[index + 1] = {...entity, level: tmp.level};
            this.dataSource.data[index] = {...tmp, level: entity.level};

            this.reorderdEntities.next([ this.dataSource.data[index], this.dataSource.data[index + 1] ]);
            this.onEntitySelected(entity.id);

            this.setTableDataSource(this.dataSource.data);
        }
    }

    private setEntities(entities: Array<ColumnEntity>): void {
        if (!!entities) {
            this.setTableDataSource(entities);
        }

        // If the selected entity cannot be found in the entities, deselect the entity
        if (this.mode !== MANAGE_MODE.ADD && this.selectedEntityId && !entities.find(entity => entity.id === this.selectedEntityId)) {
            this.selectedEntityIdChanged.emit(undefined);
        }
        
        // Navigate to the page of the selected element
        else {
            const indexOfSelectedElement = this.dataSource?.filteredData.findIndex(row => row.id === this.selectedEntityId);
            if (indexOfSelectedElement > -1 && this.paginator !== undefined) {
                const pageSize = this.paginator.pageSize;
                const pageNumberOfSelectedEntity = Math.ceil((indexOfSelectedElement + 1) / pageSize) - 1;

                this.paginator.pageIndex = pageNumberOfSelectedEntity;
                this.paginator.page.next({      
                    pageIndex: pageNumberOfSelectedEntity,
                    pageSize: this.paginator.pageSize,
                    length: this.paginator.length
                });
            }
        }
    }

    private setTableDataSource(entities: Array<ColumnEntity>): void {
        this.dataSource.data = entities;
    }
}
