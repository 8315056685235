<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <app-entity-filter-panel [cardClass]="'top-card'">
                <app-skill-levels-filters></app-skill-levels-filters>
            </app-entity-filter-panel>

            <div class="flex-growing-child">
                @if (initialLoadingFinished$ | async) {
                    <app-entity-sortable-list-panel
                        entityName="Skill levels"
                        [entities]="filteredSkillLevel$ | async"
                        [columnDefinition]="columnDefinition"
                        [loadingError]="loadingError$ | async"
                        [mode]="manageMode$ | async"
                        [selectedEntityId]="selectedSkillLevelId$ | async"
                        [cardClass]="'bottom-card'"
                        (selectedEntityIdChanged)="onSelectedSkillLevelChange($event)"
                        (addEntityClicked)="onAddNewSkillLevel()"
                        (reorderdEntities)="onReorderdEntitiesChanged($event)"
                    ></app-entity-sortable-list-panel>
                }
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="Skill level"
                    [formFields]="formFields"
                    [mode]="manageMode$ | async"
                    [model]="selectedSkillLevel$ | async"
                    [uiEntity]="selectedUISkillLevel$ | async"
                    (deleteEntity)="onDeleteSkillLevel($event)"
                    (editEntity)="onEditSkillLevel($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>

@if ((initialLoadingFinished$ | async) === false || (showLoadingSpinner$ | async)) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}
