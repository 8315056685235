import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthOptions, AuthorizeOptions, WebAuth } from 'auth0-js';
import { BehaviorSubject, Observable, ObservableInput, of } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { BroadcastService } from '../broadcast/broadcast.service';

export interface AuthenticationServiceOptions extends AuthOptions {
    preferredConnection?: string;
    forceLogin?: boolean;
    secureState?: boolean;
}

export interface IdentityDataServiceOptions {
    apiUrl: string;
    useLocalLogin?: boolean;
    broadcastLogoutMessage?: boolean;
  }

export const AUTHENTICATION_SERVICE_OPTIONS = new InjectionToken<AuthenticationServiceOptions>('authenticationServiceOptions');
export const IDENTITY_DATA_SERVICE_OPTIONS = new InjectionToken<IdentityDataServiceOptions>('identityDataServiceOptions');

export interface ICurrentUserStorage {
    username: string;
    userId: string;
    token: string;
    idToken?: string;
    expires: string;
}

export interface IRefreshTokenStorage {
    token: string;
    refreshMoment: string;
}

export enum BroadcastTypes {
    Logout = 'logout'
}

interface TokenErrorResponse extends HttpErrorResponse {
    error: {
        error: string;
        error_description: string;
    };
}

export interface ITokenResponse {
    access_token: string;
    expires: Date;
    expires_in: number;
    refresh_token: string;
    token_type: string;
    userId: string;
    userName: string;
    error_description: string;
}

@Injectable()
export class AuthenticationService {
    private readonly expirationMarginSecondsMax = 60;
    private readonly expirationMarginSecondsMin = 10;

    auth0: WebAuth;
    currentScheduledRefreshTokenTimer;
    currentScheduledRefreshToken = '';

    public readonly currentUserStorageKey = 'ngStorage-currentUser';
    public readonly refreshTokenStorageKey = 'ngStorage-refreshToken';
    public readonly stateStorageKey = 'ngStorage-state';
    public isAuthenticated$: Observable<boolean>;
    private isAuthenticatedSubject: BehaviorSubject<boolean>;

    constructor(
        public router: Router,
        protected http: HttpClient,
        protected broadcastService: BroadcastService,
        @Inject(AUTHENTICATION_SERVICE_OPTIONS) protected authenticationServiceOptions: AuthenticationServiceOptions,
        @Inject(IDENTITY_DATA_SERVICE_OPTIONS) protected identityDataServiceOptions: IdentityDataServiceOptions

    ) {
        this.auth0 = new WebAuth(authenticationServiceOptions);
        this.isAuthenticatedSubject = new BehaviorSubject<boolean>(this.isAuthenticated());
        this.isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

        router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                if (this.refreshTokenInStorage != null) {
                    this.scheduleRefreshToken();
                }
            }
        });
    }

    //#region public getters
    public getUserId(): string {
        if (this.isAuthenticated()) {
            return this.currentUserInStorage.userId;
        } else {
            throw new Error('user not logged in');
        }
    }

    public isAuthenticated(): boolean {
        if (!this.currentUserInStorage || !this.currentUserInStorage.expires) {
            return false;
        }
        const expiresAt = JSON.parse(this.currentUserInStorage.expires);
        return new Date().getTime() < expiresAt;
    }

    public get accessToken(): string {
        return this.currentUserInStorage.token;
    }

    public get currentUserInStorage(): ICurrentUserStorage {
        if (localStorage.getItem(this.currentUserStorageKey) === undefined) {
            return undefined;
        }
        else
        {
            return JSON.parse(localStorage.getItem(this.currentUserStorageKey));
        }
    }

    public get refreshTokenInStorage(): IRefreshTokenStorage {
        if (localStorage.getItem(this.refreshTokenStorageKey) === undefined) {
            return undefined;
        }
        else
        {
            return JSON.parse(localStorage.getItem(this.refreshTokenStorageKey));
        }
    }
    //#endregion

    public login(returnUrl: string = '', usePreferredConnection = false): void {
        if (this.identityDataServiceOptions.useLocalLogin) {
            this.router.navigate(['/login'], { queryParams: { returnUrl }});
        }
        else {
            const options: AuthorizeOptions = {};
            if (usePreferredConnection && this.authenticationServiceOptions.preferredConnection) {
                options.connection = this.authenticationServiceOptions.preferredConnection;
            }

            if (this.authenticationServiceOptions.forceLogin) {
              // `AuthorizeOptions` does not contain `max_age` property, but it is supported by Auth0
              options['max_age'] = 0;
            }

            if (this.authenticationServiceOptions.secureState) {
              options.state = this.randomString(32);
              this.setPersistedState(options.state, { redirectUrl: returnUrl ?? window.location.pathname });
            }
            else {
              options.state = returnUrl ?? window.location.pathname;
            }

            this.auth0.authorize(options);
        }
    }

    public loginWithUsernameAndPassword(username: string, password: string, additionalHeaders?: Array<{key: string; value: string}>): Observable<string> {
        return this.getToken(username, password, additionalHeaders).pipe(
            map((response: ITokenResponse) => {
                // login successful if there's a token in the response
                return this.handleTokenResponse(response);
            }),
            catchError((errorResponse: TokenErrorResponse) => {
                const message = errorResponse.error != null ? errorResponse.error.error_description : errorResponse.message;
                throw new Error(message);
            })
        );
    }

    public loginWithAuth0Token(clientId: string, authorizationCode: string, redirectUri: string): Observable<string> {
        const body = new URLSearchParams();
        body.set('grant_type', 'authorization_code');
        body.set('client_id', clientId);
        body.set('client_secret', '');
        body.set('code', authorizationCode);
        body.set('redirect_uri', redirectUri);

        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(this.identityDataServiceOptions.apiUrl + '/Token', body.toString(), options).pipe(
            map((response: any) => {
            // login successful if there's a token in the response
                return this.handleTokenResponse(response);
            }),
            catchError((error: Error | any) => {
                let errorMessage = error.message;
                if (error.error) {
                    errorMessage = JSON.stringify(error.error);
                }

                throw new Error('Unable to get authorization token. ' + errorMessage);
            })
        );
    }

    public handleAuthentication(): void {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                window.location.hash = '';

                if (this.authenticationServiceOptions.secureState) {
                    const persistedState = this.getPersistedState(authResult.state);
                    if (!persistedState.redirectUrl) {
                      console.error('No redirectUrl found in persisted state');
                      this.router.navigate(['/login']);
                      return;
                    }
                }

                this.auth0.client.userInfo(authResult.accessToken, (_err, profile) => {
                    if (profile) {
                        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

                        const currentUser: ICurrentUserStorage = {
                            token: authResult.accessToken,
                            idToken: authResult.idToken,
                            expires: expiresAt,
                            userId: profile.user_id,
                            username: profile.name
                        };

                        localStorage.setItem(this.currentUserStorageKey, JSON.stringify(currentUser));
                        this.isAuthenticatedSubject.next(this.isAuthenticated());

                        if (this.authenticationServiceOptions.secureState) {
                          const persistedState = this.getPersistedState(authResult.state)
                          this.router.navigateByUrl(persistedState.redirectUrl);
                        }
                        else if (authResult.state) {
                            this.router.navigateByUrl(authResult.state);
                        }
                        else {
                            this.router.navigate(['/home']);
                        }
                    }
                    else {
                        this.router.navigate(['/login']);
                    }
                });
            } else if (err) {
                this.router.navigate(['/login']);
            }
        });
    }

    public logout(redirectAfterLogoutUrl?: string, federated = false, beforeRedirect?: () => void): void {
        this.doLogout(redirectAfterLogoutUrl, federated, beforeRedirect).subscribe();
    }

    public doLogout(redirectAfterLogoutUrl?: string, federated = false, beforeRedirect?: () => void): Observable<boolean> {
        return this.http.post(this.identityDataServiceOptions.apiUrl + '/Auth/Logout', null).pipe(
            tap(() => {
                localStorage.removeItem(this.currentUserStorageKey);
                localStorage.removeItem(this.refreshTokenStorageKey);
                this.isAuthenticatedSubject.next(this.isAuthenticated());
                this.currentScheduledRefreshToken = '';
                clearTimeout(this.currentScheduledRefreshTokenTimer);

                if (beforeRedirect != null){
                    beforeRedirect();
                }

                this.redirectAfterLogout(redirectAfterLogoutUrl, federated);

                if (this.identityDataServiceOptions.broadcastLogoutMessage) {
                    this.broadcastService.publish({type: BroadcastTypes.Logout, payload: ''});
                }
            }),
            catchError(error => {
                console.error(error);

                throw error;
            }),
            map(() => true)
        );
    }

    public logoutTab(redirectAfterLogoutUrl?: string): void {
        this.redirectAfterLogout(redirectAfterLogoutUrl);
    }

    public validatePassword(username: string, password: string, additionalHeaders?: Array<{key: string; value: string}>): Observable<boolean> {
        return this.getToken(username, password, additionalHeaders).pipe(
            map((response) => {
                if (response.access_token) {
                    this.storeAccessAndRefreshToken(response);
                    return true;
                }
                return false;
            }),
            catchError<boolean, ObservableInput<boolean>>(() => {
                return of(false);
            })
        );
    }

    public tryLoginWithRefreshToken(additionalHeaders?: Array<{key: string; value: string}>): void {
        if (this.refreshTokenInStorage != null) {
            this.loginWithRefreshToken(additionalHeaders).subscribe();
        } else {
            this.logout();
        }
    }

    public loginWithRefreshToken(additionalHeaders?: Array<{key: string; value: string}>): Observable<any> {
        const body = new URLSearchParams();
        body.set('grant_type', 'refresh_token');
        body.set('refresh_token', this.refreshTokenInStorage.token);

        const options = {
            headers: (additionalHeaders ?? [])
                .reduce((sum, add) => sum.set(add.key, add.value), new HttpHeaders())
                .set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post(this.identityDataServiceOptions.apiUrl + '/Token', body.toString(), options).pipe(
            first(),
            map((response: ITokenResponse) => {
                // login successful if there's a token in the response
                if (response.access_token) {
                    this.storeAccessAndRefreshToken(response);
                } else if (!this.isAuthenticated()) {
                    this.logout();
                }
            }),
            catchError((error: Error) => {
                if (!this.isAuthenticated()) {
                    this.logout();
                }

                throw error;
            }));
    }

    public handleTokenResponse(response: ITokenResponse): string {
        if (response.access_token) {
            this.storeAccessAndRefreshToken(response);
            return response.userId;
        } else {
            throw response.error_description;
        }
    }

    public getPersistedState(state: string): { redirectUrl: string | undefined, [propName: string]: unknown; } {
      try {
        const persistedState: unknown = JSON.parse(localStorage.getItem(this.stateStorageKey));
        return persistedState[state] ?? { redirectUrl: undefined };
      }
      catch {
        return { redirectUrl: undefined };
      }
    }

    public setPersistedState(state: string, contents: { redirectUrl: string, [propName: string]: unknown; }): void {
      let persistedState: unknown = {};
      try {
        persistedState = JSON.parse(localStorage.getItem(this.stateStorageKey));
      }
      catch {}

      persistedState ??= {};
      persistedState[state] = contents;
      localStorage.setItem(this.stateStorageKey, JSON.stringify(persistedState));
    }

    private storeAccessAndRefreshToken(response: ITokenResponse): void {
        // store username and token in local storage to keep user logged in between page refreshes
        const currentUser: ICurrentUserStorage = {
            username: response.userName,
            userId: response.userId,
            token: response.access_token,
            expires: (new Date().getTime() + response.expires_in * 1000).toString()
        };

        localStorage.setItem(this.currentUserStorageKey, JSON.stringify(currentUser));

        const refreshToken: IRefreshTokenStorage = {
            token: response.refresh_token,
            refreshMoment: (new Date().getTime() + response.expires_in * 1000).toString()
        };

        localStorage.setItem(this.refreshTokenStorageKey, JSON.stringify(refreshToken));
        this.isAuthenticatedSubject.next(this.isAuthenticated());

        this.scheduleRefreshToken();
    }

    private scheduleRefreshToken(): void {
        if (this.refreshTokenInStorage.token != null && this.currentScheduledRefreshToken !== this.refreshTokenInStorage.token &&
            this.refreshTokenInStorage.refreshMoment != null) {
            const timeExpirationInMs =
                new Date(parseInt(this.refreshTokenInStorage.refreshMoment, 10)).getTime() - new Date().getTime() - 1000 * this.getRandomExpirationMarginSeconds();

            if (!isNaN(timeExpirationInMs) && timeExpirationInMs > 0) {
                if (this.currentScheduledRefreshTokenTimer != null) {
                    clearTimeout(this.currentScheduledRefreshTokenTimer);
                }
                this.currentScheduledRefreshTokenTimer = setTimeout(() => this.tryLoginWithRefreshToken(), timeExpirationInMs);
                this.currentScheduledRefreshToken = this.refreshTokenInStorage.token;
            }
        }
    }

    private getToken(username: string, password: string, additionalHeaders?: Array<{key: string; value: string}>): Observable<ITokenResponse> {
        const body = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('username', username);
        body.set('password', password);

        const options = {
            headers: (additionalHeaders ?? [])
                .reduce((sum, add) => sum.set(add.key, add.value), new HttpHeaders())
                .set('Content-Type', 'application/x-www-form-urlencoded')
        };

        return this.http.post<ITokenResponse>(this.identityDataServiceOptions.apiUrl + '/Token', body.toString(), options);
    }

    // For logout of other tabs: we don't want to run the logout logic such as the API call twice
    // The main tab already performs that logout logic
    // So all other tabs can simply perform the auth0 logout action
    private redirectAfterLogout(redirectAfterLogoutUrl?: string, federated = false): void {
        if (redirectAfterLogoutUrl === undefined && !this.identityDataServiceOptions.useLocalLogin){
            redirectAfterLogoutUrl = window.location.protocol + '//' + window.location.host;
        }
        else if (redirectAfterLogoutUrl === undefined && this.identityDataServiceOptions.useLocalLogin) {
            redirectAfterLogoutUrl = '/login';
        }

        if (this.identityDataServiceOptions.useLocalLogin)
        {
            this.router.navigateByUrl(redirectAfterLogoutUrl);
        }
        else
        {
            this.auth0.logout({
                returnTo: redirectAfterLogoutUrl,
                federated
            });
        }
    }

    private getRandomExpirationMarginSeconds(): number {
        return Math.floor(Math.random() * (this.expirationMarginSecondsMax - this.expirationMarginSecondsMin)) + this.expirationMarginSecondsMin;
    }

    /**
     * Generates a random string of a given length, as taken from the auth0.js sdk
     * @param length The length of the string to generate
     */
    private randomString(length) {
      // eslint-disable-next-line
      var bytes = new Uint8Array(length);
      var result = [];
      var charset =
        '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

      var cryptoObj = window.crypto;;
      if (!cryptoObj) {
        throw new Error('Your browser does not support window.crypto. Please upgrade your browser.');
      }

      var random = cryptoObj.getRandomValues(bytes);

      for (var a = 0; a < random.length; a++) {
        result.push(charset[random[a] % charset.length]);
      }

      return result.join('');
    }
}
