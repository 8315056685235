<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <mat-card class="bol-card full-height top-card"></mat-card>
            </div>
        </div>

        <div class="flex-column">
            <app-entity-filter-panel [cardClass]="'top-card'">
                <div>
                    <mat-checkbox
                        class="bol-checkbox"
                        [checked]="hideUnlinkedOwsDepartments$ | async"
                        (change)="onHideUnlinkedOwsDepartments($event)"
                        data-cy="checkboxHideLinkedDepartments"
                    >
                        {{ 'Hide linked Departments' | translate }}
                    </mat-checkbox>
                </div>

                <div class="unlinked__container">
                    <span class="unlinked__title">{{ 'Unlinked Departments' | translate }}:</span>
                    <div
                        class="bol-badge-solid"
                        [ngClass]="{
                            'bol-warning': (unlinkedDepartmentCount$ | async) > 0,
                            'bol-primary': (unlinkedDepartmentCount$ | async) === 0,
                        }"
                        data-cy="numberUnlinkedDepartments"
                    >
                        {{ unlinkedDepartmentCount$ | async }}
                    </div>
                </div>

                @if ((selectedOrganizationUnitId$ | async) === undefined && (owsDepartments$ | async)?.length > 0) {
                    <p data-cy="noSelectionWarningMessage">
                        <mat-icon
                            svgIcon="alert"
                            class="inline-icon"
                        ></mat-icon>
                        <span
                            class="bol-text"
                            translate
                        >
                            Please select an OMRP Organization Unit from the left panel to map to!
                        </span>
                    </p>
                }
            </app-entity-filter-panel>
        </div>
    </div>

    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-tree-list-panel
                    entityName="OMRP Organization Units"
                    [entities]="organizationUnits$ | async"
                    [searchProperties]="['displayName']"
                    [selectedEntityId]="selectedOrganizationUnitId$ | async"
                    [hideAddButton]="true"
                    [cardClass]="'bottom-card'"
                    (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
                >
                </app-entity-tree-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-tree-list-panel
                    entityName="OWS Departments"
                    [entities]="owsDepartments$ | async"
                    [disableSelection]="true"
                    [showCheckboxes]="true"
                    [searchProperties]="['displayName']"
                    [hideAddButton]="true"
                    [cardClass]="'bottom-card'"
                    (selectionClickChanged)="onSelectOwsDepartmentForMapping($event)"
                >
                </app-entity-tree-list-panel>
            </div>
        </div>
    </div>
</div>

