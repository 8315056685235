import { Injectable } from '@angular/core';
import { FormlyConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

import { emailValidatorKey, fieldNonEmptyValidatorKey } from '../validators';
import { LanguageService } from '.';

@Injectable({
    providedIn: 'root'
})
export class FormlyTranslateService {
    constructor(
        private readonly languageService: LanguageService,
        private readonly translateService: TranslateService,
        private readonly config: FormlyConfig
    ) {}

    public registerFormlyTranslations(): void {
        this.languageService.currentLanguage$.subscribe(() => {
            this.config.addValidatorMessage('required', () => this.translateService.instant('VALIDATORS.REQUIRED'));
            this.config.addValidatorMessage('min', (err, field) => this.translateService.instant('VALIDATORS.MIN_VALUE', { minValue: field.props.min }));
            this.config.addValidatorMessage('max', (err, field) => this.translateService.instant('VALIDATORS.MAX_VALUE', { maxValue: field.props.max }));
            this.config.addValidatorMessage(emailValidatorKey, () => this.translateService.instant('VALIDATORS.EMAIL'));
            this.config.addValidatorMessage(fieldNonEmptyValidatorKey, () => this.translateService.instant('VALIDATORS.REQUIRED'));
        });
    }
}
