import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-application-settings',
    templateUrl: './application-settings.component.html',
    styleUrls: ['./application-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationSettingsComponent {
    public menuItems: Array<any> = [{
        title: 'Dayparts',
        icon: 'clock-outline',
        url: '/application-settings/dayparts'
    }];
}
