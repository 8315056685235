import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { IManageEntitiesGridColumn } from 'src/app/shared/models';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { ManageCounter } from 'src/app/shared/stores/manage-counter-store/manage-counter.model';
import { ManageCounterQuery } from 'src/app/shared/stores/manage-counter-store/manage-counter.query';
import { ManageCounterService } from 'src/app/shared/stores/manage-counter-store/manage-counter.service';
import { SkillQuery } from 'src/app/shared/stores/skill-store/skill.query';
import { SkillService } from 'src/app/shared/stores/skill-store/skill.service';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { DaypartQuery } from 'src/app/shared/stores/day-part-store/day-part.query';
import { DaypartService } from 'src/app/shared/stores/day-part-store/day-part.service';
import { EntityManageGridMenuOptions, RequestFinished, STATUS_REQUEST_FINISHED, UpdatedEntityStructure } from 'src/app/shared/components/entity-management/entity-manage-grid/entity-manage-grid.model';
import { EntityManageGridComponent } from 'src/app/shared/components/entity-management/entity-manage-grid/entity-manage-grid.component';

import { ManageCountersManagementQuery } from './store/manage-counters-management.query';
import { ManageCountersManagementService } from './store/manage-counters-management.service';
import { getManageCountersFormFields, getManageCounterGridColumns } from './manage-counters-form-definition';
import { ManageCountersHelperService } from './manage-counters-helpers/manage-counters-helpers.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
    selector: 'app-manage-counters',
    templateUrl: './manage-counters.component.html',
    styleUrls: ['./manage-counters.component.scss'],
    providers: [AutosaveService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageCountersComponent implements OnInit {
    @ViewChild('entityManageGrid') public entityManageGrid: EntityManageGridComponent;
    
    public initialLoadingFinished$: Observable<boolean>;
    public requestFinished$: Observable<RequestFinished>;
    public requestFinishedSubject = new BehaviorSubject<RequestFinished>(undefined);
    public entities$: Observable<any>;
    public rowMenuOptions: Array<EntityManageGridMenuOptions> = [];
    public columns: Array<IManageEntitiesGridColumn>;
    public newEntityModel: ManageCounter;
    public formFields: Array<FormlyFieldConfig>;
    public initialLoadingStateForGrid$: Observable<boolean>;

    constructor(
        private readonly manageCounterService: ManageCounterService,
        private readonly manageCounterQuery: ManageCounterQuery,
        private readonly skillQuery: SkillQuery,
        private readonly skillService: SkillService,
        private readonly daypartQuery: DaypartQuery,
        private readonly daypartService: DaypartService,
        private readonly resourceTypeQuery: ResourceTypeQuery,
        private readonly resourceTypeService: ResourceTypeService,
        private readonly manageCountersManagementQuery: ManageCountersManagementQuery,
        private readonly manageCountersManagementService: ManageCountersManagementService,
        protected manageCountersHelperService: ManageCountersHelperService,
        protected utilsService: UtilsService
    ) { }

    public ngOnInit(): void {
        this.initialLoadingStateForGrid$ = this.manageCountersManagementQuery.getInitialLoadingStateForGrid();
        this.newEntityModel = this.manageCountersHelperService.getNewManageCounterObject();
        this.requestFinished$ = this.requestFinishedSubject.asObservable();
        this.manageCounterService.getCounters().pipe(first()).subscribe();
        this.resourceTypeService.getResourceTypesNewApi().pipe(first()).subscribe();
        this.skillService.getSkills().pipe(first()).subscribe();
        this.daypartService.getDayparteNew().pipe(first()).subscribe();
 
        this.initialLoadingFinished$ = combineLatest([
            this.resourceTypeQuery.getEntitiesLoadingState(),
            this.skillQuery.getEntitiesLoadingState(),
            this.daypartQuery.getEntitiesLoadingState(),
            this.manageCounterQuery.getEntitiesLoadingState()
        ]).pipe(
            filter(([resourceTypesLoading, skillsLoading, dayparts, counters]) => {
                return !resourceTypesLoading && !skillsLoading && !dayparts && !counters;
            }),
            map(() => true),
            first(),
            startWith(false)
        );

        this.initialLoadingFinished$.pipe(
            filter((initialLoadingFinished) => initialLoadingFinished),
            switchMap(() => combineLatest([
                this.resourceTypeQuery.getMutableResources(),
                this.skillQuery.getSkills(),
                this.daypartQuery.getDayPartsForFilteringWithEmptyOption(),
            ]).pipe(
                filter(([resourceTypes, skills, daypartsWithEmptyOption]) => resourceTypes.length > 0 && skills.length > 0 && daypartsWithEmptyOption.length > 0),
                first(),
                tap(([resourceTypes, skills, daypartsWithEmptyOption]) => {
                    const dayparts = daypartsWithEmptyOption.filter(daypart => daypart.id !== null);
                    
                    this.columns = getManageCounterGridColumns(resourceTypes, skills, dayparts, this.utilsService);
                    this.formFields = getManageCountersFormFields(resourceTypes, skills, daypartsWithEmptyOption);
                    this.manageCountersManagementService.updateInitialLoadingStateForGrid(false);
                })
            ))
        ).subscribe();

        this.entities$ = this.manageCounterQuery.getCounters();
        this.rowMenuOptions = [
            {
                icon: 'pencil',
                label: 'Edit',
                click: (row) => { this.entityManageGrid.editSelectedGridEntity(row) },
            },
            {
                icon: 'delete',
                label: 'Delete',
                click: (row) => { this.entityManageGrid.deleteSelectedGridEntity(row) },
            }
        ];
    }

    public onAddEntityClicked(): void {
        this.entityManageGrid.addNewGridEntity();
    }

    public onDeleteCounter(counter: ManageCounter): void {
        this.manageCounterService.deleteCounter(counter.id).pipe(first()).subscribe(() => {
            this.requestFinishedSubject.next({ statusDeleteRequest: STATUS_REQUEST_FINISHED.SUCCESS });
        },
        (error) => {
            this.requestFinishedSubject.next({ statusDeleteRequest: STATUS_REQUEST_FINISHED.FAILED });
        });
    };

    public onAddNewCounter(counter: ManageCounter): void {
        this.manageCounterService.saveCounter(counter).pipe(first()).subscribe(() => {
            this.requestFinishedSubject.next({ statusAddRequest: STATUS_REQUEST_FINISHED.SUCCESS });
        },
        (error) => {
            this.requestFinishedSubject.next({ statusDeleteRequest: STATUS_REQUEST_FINISHED.FAILED });
        });
    };

    public onEditCounter(updatedEntityStructure: UpdatedEntityStructure): void {
        this.manageCounterService.updateCounter(updatedEntityStructure.newVersionOfEntity, updatedEntityStructure.lastCorrectVersionOfEntity).pipe(
            first()
        ).subscribe(() => {
            this.requestFinishedSubject.next({ statusEditRequest: STATUS_REQUEST_FINISHED.SUCCESS });
        },
        (error) => {
            this.requestFinishedSubject.next({ statusDeleteRequest: STATUS_REQUEST_FINISHED.FAILED });
        });
    }
}
