import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';
import { MANAGE_MODE, Permission } from 'src/app/shared/models/Enums';
import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { GlobalSettingsQuery } from 'src/app/shared/stores/global-settings/global-settings.query';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { UserGroupQuery } from 'src/app/shared/stores/user-group-store/user-group.query';
import { User } from 'src/app/shared/stores/user-store/user.model';
import { UserQuery } from 'src/app/shared/stores/user-store/user.query';

import { UsersManagementState, UsersManagementStore } from './users-management.store';

@Injectable({
    providedIn: 'root'
})
export class UsersManagementQuery extends Query<UsersManagementState> {
    constructor(
        protected store: UsersManagementStore,
        protected userQuery: UserQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        protected userGroupQuery: UserGroupQuery,
        protected globalSettingsQuery: GlobalSettingsQuery,
    ) {
        super(store);
    }

    public allEntitiesLoaded(): Observable<boolean> {
        return combineLatest([
            this.userQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingState(),
            this.userGroupQuery.getEntitiesLoadingState()
        ]).pipe(
            filter(([usersLoading, unitsLoading, userGroupsLoading]) => {
                return !usersLoading && !unitsLoading && !userGroupsLoading;
            }),
            map(() => true),
            first(),
            startWith(false)
        );
    }

    public getFilteredUsers(): Observable<Array<User>> {
        return combineLatest([
            this.userQuery.getUsers(),
            this.getShowWithoutUserGroupsState(),
            this.getSelectedOrganizationUnit(),
            this.getShowUnderlyingUnitsState(),
            this.getShowAllUsersState(),
        ]).pipe(
            map(([users, showWithoutUserGroups, selectedOrganizationUnitId, getShowUnderlyingUnitsState, getShowAllUsersState]) => {
                if (getShowAllUsersState) {
                    return users;
                }
                if (showWithoutUserGroups) {
                    return users.filter(user => (user.userGroupIds || []).length === 0);
                }
                
                return this.filterUsersOnOrganizationUnit(users, selectedOrganizationUnitId, getShowUnderlyingUnitsState);
            })
        );
    }

    public isUserVisible(id: number): boolean {
        if (this.getValue().showAll) {
            return true;
        }

        const users = this.userQuery.getUsersSync();
        let visibleUsers: Array<User>;

        if (this.getValue().showWithoutUserGroups) {
            visibleUsers = users.filter(user => (user.userGroupIds || []).length === 0);
        }
        else {
            visibleUsers = this.filterUsersOnOrganizationUnit(
                users,
                this.getValue().selectedOrganizationUnitId,
                this.getValue().showUnderlyingUnits
            );
        }

        return visibleUsers.find(entity => entity.id === id) !== undefined;
    }

    public getShowWithoutUserGroupsState(): Observable<boolean> {
        return this.select(state => state.showWithoutUserGroups);
    }

    public getShowAllUsersState(): Observable<boolean> {
        return this.select(state => state.showAll);
    }

    public getManageMode(): Observable<MANAGE_MODE> {
        return this.select(state => state.manageMode);
    }

    public getSelectedUser(): Observable<User> {
        return combineLatest([
            this.getCurrentUserId(),
            this.userGroupQuery.getUserGroups().pipe(map(userGroups => userGroups?.map(ug => ug.id)))
        ]).pipe(
            switchMap(([userId, userGroupIds]) => {
                if (userId === -1) {
                    return of(this.getNewUserObject());
                }
                else {
                    return this.userQuery.getUser(userId).pipe(
                        map(user => {
                            const selectedUser = {...user};
                            return selectedUser;
                        })
                    );
                }
            })
        );
    }

    public getNewUserObject(): User {
        return {
            externalId: '',
            id: -1,
            language: this.globalSettingsQuery.getSystemLanguageSync(),
            userGroupIds: [],
            userName: '',
            displayName: '',
            emailAddress: ''
        };
    }

    public getselectedUIUser(): Observable<EntityUI> {
        return this.getCurrentUserId().pipe(
            switchMap((userId) => {
                return this.userQuery.getUIUser(userId);
            })
        );
    }

    public getCanDeleteSelectedEntity(): Observable<boolean> {
        return combineLatest([
            this.getSelectedUser(),
            this.userGroupQuery.getUserGroups()            
        ]).pipe(
            map(([selectedUser, userGroups]) => {
                return selectedUser.userGroupIds === undefined || selectedUser.userGroupIds?.length === 0 || selectedUser.userGroupIds === null ||
                    userGroups?.some(ug => selectedUser.userGroupIds.includes(ug.id) && ug.maxPermissionForCurrentUser === Permission.owner);
            })
        );
    }

    public getCurrentUserId(): Observable<number> {
        return this.select(state => state.selectedUserId);
    }

    public getCurrentUserIdSync(): number {
        return this.getValue().selectedUserId;
    }

    public getSelectedOrganizationUnit(): Observable<number> {
        return this.select(state => state.selectedOrganizationUnitId);
    }

    public getShowUnderlyingUnitsState(): Observable<boolean> {
        return this.select(state => state.showUnderlyingUnits);
    }

    private filterUsersOnOrganizationUnit(users: Array<User>, organizationUnitId: number, showUnderlyingUnits: boolean): Array<User> {
        const organizationUnit = this.organizationUnitQuery.getOrganizationUnitByIdSync(organizationUnitId);
        if (organizationUnit) {
            const validUserGroupIds = [];
            organizationUnit.userGroupPermissionList.forEach(permission => {
                if (permission.permission !== 0) {
                    validUserGroupIds.push(permission.userGroupId);
                }
            });
            if (showUnderlyingUnits) {
                const childUnits = this.organizationUnitQuery.getAllChildUnitsForUnitSync(organizationUnitId);
                childUnits.forEach(unit => {
                    unit.userGroupPermissionList.forEach(permission => {
                        if (permission.permission !== 0 && !validUserGroupIds.includes(permission.userGroupId)) {
                            validUserGroupIds.push(permission.userGroupId);
                        }
                    });
                });
            }

            return users.filter(user => (user.userGroupIds || []).some(group => validUserGroupIds.includes(group)));
        }

        return [];
    }
}
