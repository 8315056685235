import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { SelectionClickEvent } from 'src/app/shared/models';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';

import { OwsDepartmentTableElement } from './ows-department-store/ows-department.model';
import { DepartmentMappingService } from './department-mapping-store/department-mapping.service';
import { OwsDepartmentService } from './ows-department-store/ows-department.service';
import { DepartmentMappingQuery } from './department-mapping-store/department-mapping.query';

@Component({
    selector: 'app-ows-department-mapping',
    templateUrl: './ows-department-mapping.component.html',
    styleUrls: ['./ows-department-mapping.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwsDepartmentMappingComponent implements OnInit {
    public organizationUnits$: Observable<Array<OrganizationUnit>>;

    public selectedOrganizationUnitId$: Observable<number>;
    public organizationIdsWithNoWritePermission$: Observable<Array<number>>;

    public owsDepartments$: Observable<Array<OwsDepartmentTableElement>>;
    public hideUnlinkedOwsDepartments$: Observable<boolean>;
    public unlinkedDepartmentCount$: Observable<number>;

    constructor(
        private readonly departmentMappingService: DepartmentMappingService,
        private readonly departmentMappingQuery: DepartmentMappingQuery,
        private readonly organizationUnitQuery: OrganizationUnitQuery,
        private readonly organizationUnitService: OrganizationUnitService,
        private readonly owsDepartmentService: OwsDepartmentService,
    ) { }

    public ngOnInit() {
        this.organizationUnitService.get().pipe(first()).subscribe();
        this.owsDepartmentService.get().pipe(first()).subscribe();
        this.departmentMappingService.get().pipe(first()).subscribe();

        this.organizationUnits$ = this.organizationUnitQuery.getOrganizationsForFiltering();
        this.selectedOrganizationUnitId$ = this.departmentMappingQuery.getSelectedOrganizationUnitId();

        this.owsDepartments$ = this.departmentMappingQuery.getFilteredOwsDepartments();
        this.hideUnlinkedOwsDepartments$ = this.departmentMappingQuery.getUnlinkedOwsDepartmentsVisibility();
        this.unlinkedDepartmentCount$ = this.departmentMappingQuery.getUnlinkedDepartmentsCount();
    }

    public onSelectOwsDepartmentForMapping(event: SelectionClickEvent) {
        this.departmentMappingService.updateMappings(event.entity);
    }

    public onSelectedOrganizationUnitChange(id: number) {
        this.departmentMappingService.updateSelectedOrganizationUnitId(id);
    }

    public onHideUnlinkedOwsDepartments(event: MatCheckboxChange) {
        this.departmentMappingService.updateHideUnlinkedOwsDepartments(event.checked);
    }
}
