import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';

export interface ResourceTypesManagementState {
    selectedResourceTypeId: number;
    selectedOrganizationUnitId: number;
    showUnderlyingUnits: boolean;
    manageMode: MANAGE_MODE;
}

const initialState = {
    selectedResourceTypeId: undefined,
    selectedOrganizationUnitId: undefined,
    showUnderlyingUnits: false,
    manageMode: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'resource-types-management' })
export class ResourceTypesManagementStore extends Store<ResourceTypesManagementState> {
    constructor() {
        super(initialState);
    }

    public updateSelectedResourceTypeId(id: number): void {
        this.update(state => ({ ...state, selectedResourceTypeId: id }));
    }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.update(state => ({ ...state, selectedOrganizationUnitId: id }));
    }

    public updateShowChildUnits(value: boolean): void {
        this.update(state => ({ ...state, showUnderlyingUnits: value }));
    }

    public updateManageMode(manageMode: MANAGE_MODE): void {
        this.update(state => ({ ...state, manageMode }));
    }

    public setStateForNewResourceType(id: number): void {
        this.update(state => (
            { 
                ...state, 
                selectedResourceTypeId: id,
                manageMode: MANAGE_MODE.EDIT
            })
        );
    }
}