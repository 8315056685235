<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="bol-modal__header">
    <span class="modal-title">{{ 'Display options' | translate }}</span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>
<mat-dialog-content class="dialog">
    <div class="show-options-container">
        <div
            class="row-display-options"
            data-cy="showOptionsTitlesColumn"
        >
            <div
                class="bol-text bol-text-bold title"
                translate
            >
                Show...
            </div>
            @for (showOpt of showOptions; track showOpt.value) {
                <div class="column">
                    {{ showOpt.value | translate }}
                </div>
            }
        </div>
        <div
            class="row-display-options"
            data-cy="activityViewColumn"
        >
            <div
                class="bol-text bol-text-bold title_checkbox"
                translate
            >
                Activity view
            </div>
            @for (showOpt of showOptions; track showOpt.value) {
                <div
                    class="column_checkbox"
                    [attr.data-cy]="showOpt.value"
                >
                    @if (isAResourceScheduleOptionOnly(showOpt)) {
                        <mat-checkbox
                            class="bol-checkbox bol-primary mapping-checkbox"
                            [hidden]="isAResourceScheduleOptionOnly(showOpt)"
                            [checked]="findActShowOptionState(showOpt)"
                            (change)="updateActivityShowOptionsStates($event, findActShowOption(showOpt))"
                        >
                        </mat-checkbox>
                    }
                </div>
            }
        </div>
        <div
            class="row-display-options"
            data-cy="resourceViewColumn"
        >
            <div
                class="bol-text bol-text-bold title_checkbox"
                translate
            >
                Resource view
            </div>
            @for (showOpt of showOptions; track showOpt.value) {
                <div
                    class="column_checkbox"
                    [attr.data-cy]="showOpt.value"
                >
                    <mat-checkbox
                        class="bol-checkbox bol-primary mapping-checkbox"
                        [checked]="showOpt.state"
                        (change)="updateShowOptionsStates($event, showOpt)"
                    ></mat-checkbox>
                </div>
            }
        </div>
    </div>
    <div class="warning-message">
        @if (shouldShowWarning()) {
            <bol-callout
                [type]="'warning'"
                data-cy="warningMessageDialog"
            >
                {{ 'SCHEDULE_OVERVIEW.WARNINGS.SELECT_ACTIVE_PANEL' | translate }}
            </bol-callout>
        }
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button
        mat-button
        class="bol-button-solid bol-positive"
        [disabled]="shouldShowWarning()"
        (click)="onConfirm()"
        data-cy="displayOptionsDialogConfirmButton"
    >
        <span translate>Confirm</span>
    </button>
</mat-dialog-actions>
