<div class="bol-modal__header">
    <span
        class="modal-title"
        translate
    >
        ACTIVITY-DEMAND.EDIT-TEMPLATES.COUNTERS.DIALOG-TITLE
    </span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    <div>
        <div class="search-counter">
            <mat-form-field class="bol-form-field">
                <mat-label>{{ 'ACTIVITY-DEMAND.SEARCH-COUNTERS' | translate }}</mat-label>
                <input
                    matInput
                    autocomplete="off"
                    [formControl]="searchTerm"
                />
            </mat-form-field>
        </div>

        <div class="counters-table">
            <table
                mat-table
                [dataSource]="counters$ | async"
                class="bol-table bol-table-striped-odd"
            >
                <ng-container matColumnDef="displayName">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        translate
                    >
                        COMMON.DISPLAY-NAME
                    </th>
                    <td
                        *matCellDef="let counter"
                        mat-cell
                    >
                        {{ counter.displayName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="addToTemplate">
                    <th
                        *matHeaderCellDef
                        mat-header-cell
                        translate
                    >
                        ACTIVITY-DEMAND.EDIT-TEMPLATES.ADD-TO-TEMPLATE
                    </th>
                    <td
                        *matCellDef="let counter"
                        mat-cell
                    >
                        <mat-checkbox
                            class="bol-checkbox"
                            [disabled]="counter.disabledInAddMode"
                            [checked]="counter.addToTemplate"
                            (change)="onUpdateCounter($event, counter)"
                            data-cy="addToTemplate"
                        >
                        </mat-checkbox>
                    </td>
                </ng-container>

                <tr
                    *matHeaderRowDef="displayedColumns; sticky: true"
                    mat-header-row
                ></tr>
                <tr
                    *matRowDef="let row; columns: displayedColumns"
                    mat-row
                    tabindex="0"
                ></tr>
                <tr
                    *matNoDataRow
                    class="bol-table-empty-state"
                >
                    @if ((counters$ | async)?.length === 0) {
                        <td
                            [attr.colspan]="displayedColumns.length"
                            translate
                        >
                            ACTIVITY-DEMAND.EDIT-TEMPLATES.COUNTERS.NO-COUNTERS-AVAILABLE
                        </td>
                    }
                </tr>
            </table>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            (click)="onCancel()"
            data-cy="activityTypeDialogConfirmButton"
        >
            <span translate>ACTION.CANCEL</span>
        </button>
    </div>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            class="bol-button-solid"
            [disabled]="disableConfirmButton$ | async"
            (click)="onConfirm()"
            data-cy="activityTypeDialogConfirmButton"
        >
            <span translate>ACTION.CONFIRM</span>
        </button>
    </div>
</mat-dialog-actions>

