import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const emailValidatorKey = 'email-validator';

export const emailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value === '') {
        return null;
    }
    const digitValidator = /\S+@\S+\.\S+/;
    return !digitValidator.test(control.value) ? { [emailValidatorKey]: true } : null;
}
