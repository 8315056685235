import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { filterUndefined } from 'src/app/core/rxjs-utils';

import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DisplaySettingService } from 'src/app/shared/stores/display-setting-store/display-setting.service';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { OwsDepartmentQuery } from '../ows-department-mapping/ows-department-store/ows-department.query';
import { OwsDepartmentService } from '../ows-department-mapping/ows-department-store/ows-department.service';

import { OwsResourceTypeTableElement } from './ows-resource-type-store/ows-resource-type.model';
import { OwsResourceTypeService } from './ows-resource-type-store/ows-resource-type.service';
import { ResourceTypeMappingQuery } from './resource-type-mapping-store/resource-type-mapping.query';
import { ResourceTypeMappingService } from './resource-type-mapping-store/resource-type-mapping.service';
import { SelectionClickEvent } from 'src/app/shared/models';

@Component({
    selector: 'app-ows-resource-type-mapping',
    templateUrl: './ows-resource-type-mapping.component.html',
    styleUrls: ['./ows-resource-type-mapping.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwsResourceTypeMappingComponent implements OnInit {
    public defaultToggle: string = 'default';
    public icon: string = 'business';
    public popoverMessage: string = 'For this resource type a department (OWS) specific mapping is created.';
    public departmentSpecificToggle: string = 'specific';
    public currentMapping: string ='default';
    public visibleOwsResourceTypes$: Observable<Array<OwsResourceTypeTableElement>>;
    public visibleResourceTypes$: Observable<Array<ResourceType>>;
    public unlinkedResourceTypeCount$: Observable<number>;
    public selectedResourceTypeId$: Observable<number>;
    public selectedOwsDepartmentIdForMapping$: Observable<number>;
    public hideUnlinkedOwsResourceTypes$: Observable<boolean>;
    public showLinkedOwsResourceTypes$: Observable<boolean>;
    public organizationsForFiltering$: Observable<Array<IOrganizationUnitTree>>;
    public selectedOrganizationUnit$: Observable<number>;
    public owsDepartmentsForFiltering$: Observable<Array<IOrganizationUnitTree>>;
    public selectedOwsDepartment$: Observable<number>;
    public selectedOwsDepartmentForMapping$: Observable<number>;
    public showUnderlyingUnits$: Observable<boolean>;
    public showAllOwsResourceTypes$: Observable<boolean>;
    public enableSelectAllPosition$: Observable<boolean>;

    private readonly organizationUnitQuery = inject(OrganizationUnitQuery);
    private readonly organizationUnitService = inject(OrganizationUnitService);
    private readonly owsResourceTypeService = inject(OwsResourceTypeService);
    private readonly resourceTypeService = inject(ResourceTypeService);
    private readonly resourceTypeMappingService = inject(ResourceTypeMappingService);
    private readonly resourceTypeMappingQuery = inject(ResourceTypeMappingQuery);
    private readonly owsDepartmentService = inject(OwsDepartmentService);
    private readonly owsDepartmentQuery = inject(OwsDepartmentQuery);
    private readonly displaySettingQuery = inject(DisplaySettingQuery);
    private readonly displaySettingService = inject(DisplaySettingService);

    public ngOnInit(): void {
        this.owsResourceTypeService.get().pipe(first()).subscribe();
        this.resourceTypeService.getResourceTypes().pipe(first()).subscribe();
        this.resourceTypeMappingService.get().pipe(first()).subscribe();
        this.organizationUnitService.get().pipe(first()).subscribe();
        this.owsDepartmentService.get().pipe(first()).subscribe();

        this.visibleOwsResourceTypes$ = this.resourceTypeMappingQuery.getFilteredOwsResourceTypes();
        this.visibleResourceTypes$ = this.resourceTypeMappingQuery.getFilteredResourceTypes();
        this.unlinkedResourceTypeCount$ = this.resourceTypeMappingQuery.getUnlinkedResourceTypeCount();
        this.selectedResourceTypeId$ = this.resourceTypeMappingQuery.getSelectedResourceTypeId();
        this.selectedOwsDepartmentIdForMapping$ = this.resourceTypeMappingQuery.getSelectedOwsDepartmentIdForMapping();
        this.hideUnlinkedOwsResourceTypes$ = this.resourceTypeMappingQuery.getUnlinkedOwsResourceTypeVisibility();
        this.showLinkedOwsResourceTypes$ = this.resourceTypeMappingQuery.getLinkedOwsResourceTypeVisibility();
        this.organizationsForFiltering$ = this.organizationUnitQuery.getOrganizationsForFiltering();
        this.selectedOrganizationUnit$ = this.resourceTypeMappingQuery.getSelectedOrganizationUnit();
        this.owsDepartmentsForFiltering$ = this.owsDepartmentQuery.getOwsDepartmentsForFiltering();
        this.selectedOwsDepartment$ = this.resourceTypeMappingQuery.getSelectedOwsDepartment();
        this.selectedOwsDepartmentForMapping$ = this.resourceTypeMappingQuery.getSelectedOwsDepartmentForMapping();
        this.showUnderlyingUnits$ = this.resourceTypeMappingQuery.getShowUnderlyingUnitsState();
        this.showAllOwsResourceTypes$ = this.resourceTypeMappingQuery.getShowAllOwsResourceTypes();
        this.enableSelectAllPosition$ = this.resourceTypeMappingQuery.getEnableDisableSelectAll();
        this.setInitialStatesFromDisplaySettings();
    }

    public onToggleChange(event: string): void {
        const value: boolean = event !== this.departmentSpecificToggle;
        this.currentMapping = event === this.departmentSpecificToggle ? this.departmentSpecificToggle : this.defaultToggle;
        this.resourceTypeMappingService.updateIsGlobal(value);
    }

    public onSelectOwsResourceTypeForMapping(event: SelectionClickEvent): void {
        this.resourceTypeMappingService.updateMappings(event.entity);
    }

    public onSelectAllOwsResourceTypeForMapping(event: boolean): void {
        this.resourceTypeMappingService.updateMappingsSelectAll(event);
    }

    public onSelectResourceType(id: number): void {
        this.resourceTypeMappingService.updateSelectedResourceTypeId(id);
    }

    public onHideUnlinkedOwsResourceTypesChange(event: MatCheckboxChange): void {
        this.resourceTypeMappingService.updateHideUnlinkedOwsResourceTypes(event.checked);
    }

    public onShowLinkedOwsResourceTypesChange(event: MatCheckboxChange): void {
        this.resourceTypeMappingService.updateShowLinkedOwsResourceTypes(event.checked);
    }

    public onSelectedOrganizationUnitChange(id: number): void {
        this.resourceTypeMappingService.updateSelectedOrganizationUnitId(id);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, id).pipe(first()).subscribe();
    }

    public onUpdateShowChildUnits(event: MatCheckboxChange): void {
        this.resourceTypeMappingService.updateShowChildUnits(event.checked);
        this.displaySettingService.createDisplaySetting(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, event.checked).pipe(first()).subscribe();
    }

    public onSelectedOwsDepartmentChange(id: number): void {
        this.resourceTypeMappingService.updateSelectedOwsDepartmentId(id);
    }

    public onSelectedOwsDepartmentSpecificMappingChange(id: number): void {
        this.resourceTypeMappingService.updateSelectedOwsDepartmentIdForMapping(id);
    }

    public onShowAllOwsResourceTypesChange(event: MatCheckboxChange): void {
        this.resourceTypeMappingService.updateShowAllOwsResourceTypes(event.checked);
    }

    private setInitialStatesFromDisplaySettings(): void {
        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.ORGANIZATION_UNIT, 'number').pipe(
            filterUndefined(), 
            first(),
            tap(id => this.resourceTypeMappingService.updateSelectedOrganizationUnitId(id))
        ).subscribe();

        this.displaySettingQuery.getValueBySettingName<boolean>(DISPLAY_SETTING_NAMES.INCLUDE_CHILD_ORGANIZATION_UNIT, 'boolean').pipe(
            filterUndefined(), 
            first(),
            tap(state => this.resourceTypeMappingService.updateShowChildUnits(state))
        ).subscribe();
    }
}
