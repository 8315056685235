<mat-card
    class="bol-card"
    class="content-wrapper"
>
    <mat-card-content>
        <div class="title-status">
            <h3
                class="sorting-title"
                translate
                data-cy="sortingResourcesTitle"
            >
                Sorting resources
            </h3>
            <app-loading-status-indication [statusPageState]="statusPageState$ | async"></app-loading-status-indication>
        </div>

        <p
            class="bol-text"
            translate
        >
            Select resource properties to sort on
        </p>

        <div class="resources__container">
            <app-filter-chip-list
                noResultsFoundText="{{ 'No resource properties found' | translate }}"
                placeholderText="{{ 'Resource property' | translate }}"
                class="sort_order__container"
                [entities]="allResourceProperties$ | async"
                [preselectedEntities]="selectedResourceProperties$ | async"
                (selectedEntityIdsChanged)="onFilteredResourcePropertiesChanged($event)"
                data-cy="resourcePropertyToSortOn"
            ></app-filter-chip-list>
        </div>

        <div class="sort-checkbox">
            <mat-checkbox
                class="bol-checkbox"
                [disabled]="(selectedResourceProperties$ | async)?.length === 0"
                [checked]="sortDescending$ | async"
                (change)="onSortDescending($event)"
                data-cy="sortDescending"
            >
                <span>{{ 'Sort descending' | translate }}</span>
            </mat-checkbox>
        </div>

        <p
            class="bol-text"
            translate
        >
            Select resources on top
        </p>
        <p
            class="bol-text label-filter-resource-type"
            translate
        >
            Valid for
        </p>
        <div class="resource_types_container">
            <app-filter-tree-single-select
                labelText="Resource types"
                [entities]="resourceTypesForFiltering$ | async"
                [disabled]="false"
                [selectedEntityId]="selectedResourceTypeId$ | async"
                (selectedEntityIdChanged)="onSelectedResourceTypeChange($event)"
                data-cy="resourceTypesSorting"
            >
            </app-filter-tree-single-select>
        </div>
        <div class="flex-wrapper">
            <div class="flex-column">
                <div class="flex-growing-child">
                    <p
                        class="bol-text bol-text-medium"
                        translate
                    >
                        Set sort order resources on top
                    </p>
                    <div>
                        <app-multiselect-sorting-table
                            [entities]="sortableResources$ | async"
                            [columnDefinition]="columnDefinition"
                            [stripedTable]="true"
                            [narrowRows]="true"
                            [entityName]="'resources'"
                            (entitiesSortedIdsChanged)="onReorderdEntitiesChanged($event)"
                            (selectedEntityIdsChanged)="onSelectedResourcesChange($event)"
                        ></app-multiselect-sorting-table>
                    </div>
                </div>
            </div>

            <div class="arrow-buttons-column">
                <button
                    mat-button
                    class="bol-button-solid bol-blue"
                    [disabled]="(isMoveToSortableListEnabled$ | async) === false"
                    (click)="moveToSortableList()"
                    data-cy="moveToSortableListButton"
                >
                    <mat-icon svgIcon="arrow-left"></mat-icon>
                </button>
                <button
                    mat-button
                    class="bol-button-solid bol-blue"
                    [disabled]="(isMoveToUnsortedListEnabled$ | async) === false"
                    (click)="moveToUnsortedList()"
                    data-cy="moveToUnsortedListButton"
                >
                    <mat-icon svgIcon="arrow-right"></mat-icon>
                </button>
            </div>

            <div class="flex-column">
                <div class="flex-growing-child">
                    <p
                        class="bol-text bol-text-medium"
                        translate
                    >
                        Select resources to sort
                    </p>
                    <div>
                        <app-multiselect-table
                            searchFieldPlaceholder="Resource filter"
                            [entities]="unsortableResources$ | async"
                            [columnDefinition]="columnDefinition"
                            [searchProperties]="searchProperties"
                            [hideSearch]="false"
                            [stripedTable]="true"
                            [narrowRows]="true"
                            (selectedEntityIdsChanged)="updateResourceStates($event)"
                        ></app-multiselect-table>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

@if ((initialLoadingFinished$ | async) === false) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

