import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, first, map, startWith, take, tap } from 'rxjs/operators';
import { Memoized } from '@ortec/utilities/core';

import { filterUndefined } from 'src/app/core/rxjs-utils';
import { IFilterChipListObject } from 'src/app/shared/components/inputs/filter-chip-list/filter-chip-list.component';
import { ResourcePropertyLanguageService } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.service';
import { ResourcePropertyLanguageQuery } from 'src/app/shared/stores/resource-property-language-store/resource-property-language.query';
import { LanguageService } from 'src/app/shared/language';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { Skill } from 'src/app/shared/stores/skill-store/skill.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { OrganizationUnitService } from 'src/app/shared/stores/organization-unit-store/organization-unit.service';
import { SkillService } from 'src/app/shared/stores/skill-store/skill.service';
import { ResourceTypeService } from 'src/app/shared/stores/resource-type-store/resource-type.service';
import { ResourceTypeQuery } from 'src/app/shared/stores/resource-type-store/resource-type.query';
import { SkillQuery } from 'src/app/shared/stores/skill-store/skill.query';
import { ResourceService } from 'src/app/shared/stores/resource-store/resource.service';
import { ResourceType } from 'src/app/shared/stores/resource-type-store/resource-type.model';
import { ActivityTypeCategory } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.model';
import { Resource, ResourceRequestParameters } from 'src/app/shared/stores/resource-store/resource.model';
import { DisplaySettingQuery } from 'src/app/shared/stores/display-setting-store/display-setting.query';
import { DISPLAY_SETTING_NAMES } from 'src/app/shared/stores/display-setting-store/display-setting-names';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { ActivityTypeService } from 'src/app/shared/stores/activity-type-store/activity-type.service';
import { ActivityTypeCategoryService } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.service';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityType } from 'src/app/shared/stores/activity-type-store/activity-type.model';

import { FilteringHelperService } from './filtering-helpers/filtering-helpers.service';
import { FilteringQuery } from './store/filtering.query';
import { FilteringService } from './store/filtering.service';
import { ResourcesToHide } from './store/filtering.model';
import { ResourceQuery } from 'src/app/shared/stores/resource-store/resource.query';
import { STATUS } from 'src/app/shared/stores/status-page-store/status-page.store';

@Component({
    selector: 'app-filtering',
    templateUrl: './filtering.component.html',
    styleUrls: ['./filtering.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteringComponent implements OnInit, OnDestroy {
    public totalActivityTypesCount: number;

    private readonly subscription: Subscription = new Subscription();
    
    private readonly organizationUnitQuery = inject(OrganizationUnitQuery);
    private readonly organizationUnitService = inject(OrganizationUnitService);
    private readonly skillService = inject(SkillService);
    private readonly skillQuery = inject(SkillQuery);
    private readonly resourceService = inject(ResourceService);
    private readonly resourceQuery = inject(ResourceQuery);
    private readonly resourceTypeService = inject(ResourceTypeService);
    private readonly resourceTypeQuery = inject(ResourceTypeQuery);
    private readonly filteringService = inject(FilteringService);
    private readonly filteringQuery = inject(FilteringQuery);
    private readonly languageService = inject(LanguageService);
    private readonly resourcePropertyLanguageService = inject(ResourcePropertyLanguageService);
    private readonly resourcePropertyLanguageQuery = inject(ResourcePropertyLanguageQuery);
    private readonly filteringHelpersService = inject(FilteringHelperService);
    private readonly displaySettingQuery = inject(DisplaySettingQuery);
    private readonly activityTypeQuery = inject(ActivityTypeQuery);
    private readonly activityTypeService = inject(ActivityTypeService);
    private readonly activityTypeCategoryService = inject(ActivityTypeCategoryService);
    private readonly activityTypeCategoryQuery = inject(ActivityTypeCategoryQuery);

    public ngOnInit(): void {
        this.setupLanguageSubscription();
        this.initCallsForDataRetrieval();
    }
    
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onFilteredResourcePropertiesChanged(ids: Array<number>): void {
        this.filteringService.updatePageStatusState(STATUS.IS_LOADING);
        const standardResourceProperties = this.filteringHelpersService.getStandardResourceProperties();
        const filteredIds = ids.filter(id => !standardResourceProperties.some(prop => prop.resourcePropertyId === id));
        this.filteringService.updateSelectedResourcePropertyIds(filteredIds).pipe(first()).subscribe();
    }

    public onFilteredOrganizationsChanged(ids: Array<number>): void {
        this.filteringService.updatePageStatusState(STATUS.IS_LOADING);
        this.filteringService.updateSelectedOrganizationUnitIds(ids).pipe(first()).subscribe();
    }

    public updateSelectedResourceIdsEvent(ids: Array<ResourcesToHide>): void {
        this.filteringService.updatePageStatusState(STATUS.IS_LOADING);
        this.filteringService.updateSelectedResourcesToHide(ids).pipe(first()).subscribe();
    }

    public onFilteredResourceSkillsChanged(ids): void {
        this.filteringService.updatePageStatusState(STATUS.IS_LOADING);
        this.filteringService.updateSelectedResourceSkillIds(ids).pipe(first()).subscribe();
    }

    public updateSelectedActivityTypeIds(ids: Array<number>): void {
        this.filteringService.updatePageStatusState(STATUS.IS_LOADING);
        this.filteringService.updateSelectedActivityTypes(ids).pipe(first()).subscribe();
    }

    @Memoized public get allResourceProperties$(): Observable<Array<IFilterChipListObject>> {
        return this.resourcePropertyLanguageQuery.getResourcePropertiesLanguageSorted().pipe(
            map(resourceProperties => {
                return resourceProperties.map(rp => ({id: rp.resourcePropertyId, displayName: rp.text }));
            })
        );
    }

    @Memoized public get initialLoadingFinished$(): Observable<boolean> {
        return combineLatest([
            combineLatest([
                this.filteringQuery.getEntitiesLoading(),
                this.filteringQuery.getEntitiesLoadingOrganizationUnitSelection(),
                this.filteringQuery.getEntitiesLoadingResourcePropertySelection(),
                this.filteringQuery.getEntitiesLoadingSelectedActivityTypes(),
                this.filteringQuery.getEntitiesLoadingSelectedHideResources(),
                this.filteringQuery.updateEntitiesLoadingSelectedResourceTypeId()
            ]),
            combineLatest([
                this.organizationUnitQuery.getEntitiesLoadingState(),
                this.activityTypeQuery.getEntitiesLoadingState(),
                this.activityTypeCategoryQuery.getEntitiesLoadingState(),
                this.resourceTypeQuery.getEntitiesLoadingState(),
                this.skillQuery.getEntitiesLoadingState(),
                this.resourceQuery.getEntitiesLoading()
            ])
        ]).pipe(
            filter(([[filteringLoading, filteringLoadingOrgUnitSel, filteringLoadingResProp, filteringActType, filteringLoadingHideRes, filteringLoadingSelectedResourceTypeId], [orgUnitsLoading, activityTypeLoading, actvityTypeCategoryLoading, resourceTypeLoading, skillsLoading, resourceLoading]]) => {
                return !filteringLoading && !filteringLoadingOrgUnitSel && !filteringLoadingResProp && !filteringActType && !filteringLoadingHideRes && !filteringLoadingSelectedResourceTypeId && !orgUnitsLoading && !activityTypeLoading && !actvityTypeCategoryLoading && !resourceTypeLoading && !skillsLoading && !resourceLoading;
            }),
            map(() => true),
            take(1),
            startWith(false)
        );
    }

    @Memoized public get organizationUnits$(): Observable<Array<IOrganizationUnitTree>> {
        return this.organizationUnitQuery.getOrganizationsForFiltering();
    }

    @Memoized public get selectedOrganizationUnits$(): Observable<Array<number>> {
        return this.filteringQuery.getSelectedOrganizationUnitIds();
    }

    @Memoized public get skills$(): Observable<Array<Skill>> {
        return this.skillQuery.getSkills()
    }

    @Memoized public get selectedSkillIds$(): Observable<Array<number>> {
        return this.filteringQuery.getSelectedResourceSkillIds();
    }

    @Memoized public get resources$(): Observable<Array<Resource>> {
        return this.filteringQuery.getResourceForFiltering();
    }

    @Memoized public get selectedResourceIds$(): Observable<Array<ResourcesToHide>> {
        return combineLatest([
            this.filteringQuery.getSelectedResourcesToHide(),
            this.filteringQuery.getResourceForFiltering(),
          ]).pipe(
            filter(([selectedResourceIds, resources]) => selectedResourceIds !== undefined && resources !== undefined),
            map(([selectedResourceIds, resources]) => {
                const resourceIds = resources.map(resource => resource.id);
            
                return selectedResourceIds.filter(id => id.resourceIds.every(resourceId => resourceIds.includes(resourceId)));
            })
        );
    }

    @Memoized public get resourceTypes$(): Observable<Array<ResourceType>> {
        return this.resourceTypeQuery.getResourceTypes();
    }

    @Memoized public get selectedResourceTypeId$(): Observable<number> {
        return this.filteringQuery.getSelectedResourceTypeId();
    }

    @Memoized public get selectedResourceProperties$(): Observable<Array<IFilterChipListObject>> {
        const standardResourceProperties = this.filteringHelpersService.getStandardResourceProperties().map(rp => ({ ...rp, removable: false }));

        return combineLatest([
            this.filteringQuery.getSelectedVisibleColumns(),
            this.resourcePropertyLanguageQuery.getResourcePropertiesLanguage()
        ]).pipe(
            filter(([_, resourceProperties]) => resourceProperties.length > 0),
            map(([resourcePropertiesIds, resourceProperties]) => {
                const sortedStandardResourceProperties = standardResourceProperties.sort((a, b) => a.text.localeCompare(b.text))
                .map(rp => ({ id: rp.resourcePropertyId, displayName: rp.text, removable: rp.removable }));

                const sortedFilteredProperties = resourceProperties.filter(rp => resourcePropertiesIds.includes(rp.resourcePropertyId))
                .sort((a, b) => {
                    const indexA = resourcePropertiesIds.indexOf(a.resourcePropertyId);
                    const indexB = resourcePropertiesIds.indexOf(b.resourcePropertyId);

                    return indexA - indexB;
                })
                .map(rp => ({ id: rp.resourcePropertyId, displayName: rp.text }));

 
                const combinedProperties = [
                    ...sortedStandardResourceProperties,
                    ...sortedFilteredProperties
                ];

                return combinedProperties;
            })
        );
    }

    @Memoized public get activityTypes$(): Observable<Array<ActivityType>> {
        return this.activityTypeQuery.getNestedActvityTypes();
    }

    @Memoized public get activityTypeCategories$(): Observable<Array<ActivityTypeCategory>> {
        return this.activityTypeCategoryQuery.getActivityTypeCategories();
    }

    @Memoized public get selectedActivityTypeIds$(): Observable<Array<number>> {
        return this.filteringQuery.getSelectedActivityTypeIds();
    }

    @Memoized public get statusPageState$(): Observable<STATUS> {
        return this.filteringQuery.getStatusPageState();
    }

    private initCallsForDataRetrieval(): void {
        //calls for organization units filter
        this.organizationUnitService.get().pipe(first()).subscribe();
        this.filteringService.getOrganizationUnitSelection().pipe(first()).subscribe();

        //calls for skill resources filter
        this.skillService.getSkills().pipe(first()).subscribe();
        this.filteringService.getResourceSkillsSelection().pipe(first()).subscribe();

        //call for resource properties filter
        this.filteringService.getResourcePropertySelection().pipe(first()).subscribe();

        //calls for resource filter
        const parameters: ResourceRequestParameters = {
            includeResourceTypeMemberships: true,
            includeOrganizationUnitMemberships: true
        };
     
        this.filteringService.getResourcesToHideSelection().pipe(first()).subscribe();
        this.resourceService.getResources(parameters).pipe(first()).subscribe();

        //calls for resource type filter
        this.resourceTypeService.getResourceTypesNewApi().pipe(first()).subscribe();
        this.filteringService.updateEntitiesLoadingSelectedResourceTypeId(true);
        this.displaySettingQuery.getValueBySettingName<number>(DISPLAY_SETTING_NAMES.PLANBOARD_RESOURCE_SELECTION_ID, 'number').pipe(
            filterUndefined(),
            first(),
            tap(id => {
                this.filteringService.updateSelectedResourceTypeId(id);
                this.filteringService.updateEntitiesLoadingSelectedResourceTypeId(false);
            })
        ).subscribe();

        //calls for activity type filter
        this.activityTypeService.getActivityTypes().pipe(first()).subscribe();
        this.filteringService.getSelectedActivityTypes().pipe(first()).subscribe();

        //call for activity type categories filter
        this.activityTypeCategoryService.getActivityTypeCategories().pipe(first()).subscribe();
    }

    private setupLanguageSubscription(): void {
        this.subscription.add(
            this.languageService.currentLanguage$.subscribe((lang) => {
                const standardResourceProperties = this.filteringHelpersService.getStandardResourceProperties();
                this.resourcePropertyLanguageService.getResourcePropertiesByLanguageCode(lang.codeDto, standardResourceProperties).pipe(first()).subscribe();
            })
        );
    }
}