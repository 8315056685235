import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first, map, skip } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { noEmptySpacesValidator, noEmptySpacesValidatorKey } from 'src/app/shared/validators';
import { Holiday } from 'src/app/shared/stores/holiday-store/holiday.model';
import { HolidayQuery } from 'src/app/shared/stores/holiday-store/holiday.query';
import { DialogService } from 'src/app/shared/services';

export interface UpdatedHoliday {
    old: Holiday;
    new: Holiday;
}

@Component({
    selector: 'app-holiday-item',
    templateUrl: './holiday-item.component.html',
    styleUrls: ['./holiday-item.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: HolidayItemComponent,
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HolidayItemComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() public readonly holidays: Array<Holiday> = [];
    @Input() public readonly index: number;
    @Output() public readonly removeHolidayEvent = new EventEmitter<number>();
    @Output() public readonly updateHolidayEvent = new EventEmitter<UpdatedHoliday>();

    public readonly formControls = {
        id: new UntypedFormControl(undefined, [Validators.required]),
        text: new UntypedFormControl(undefined, [Validators.required, noEmptySpacesValidator]),
        holidayDate: new UntypedFormControl(undefined, [Validators.required]),
    };
    public readonly formGroup = new UntypedFormGroup(this.formControls, { updateOn: 'blur' });
    public holiday!: Holiday;
    public oldHolidays: Array<Holiday> = [];
    public minDate: Date;
    public maxDate: Date;
    public requiredErrorMessage: string = 'VALIDATORS.REQUIRED'; 
    public validErrorMessage: string = 'Please enter a valid date';
    public noEmptySpacesValidator = noEmptySpacesValidatorKey;

    private readonly holidayOldValue!: Holiday;
    private readonly holidaySubject = new BehaviorSubject<Holiday>(undefined);
    private readonly subscription = new Subscription();

    constructor(
        private readonly dialogService: DialogService,
        private readonly translateService: TranslateService,
        private readonly holidayQuery: HolidayQuery,
    ) {
    }

    public ngOnInit(): void {
        this.oldHolidays = [...this.holidays];
        const currentYear = this.holidayQuery.getCurrentYearSync();
        this.minDate = new Date(currentYear , 0, 1);
        this.maxDate = new Date(currentYear , 11, 31);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // Implemented Control Value Accessor method
    public writeValue(holiday: Holiday | undefined): void {
        if (holiday) {
            this.holiday = holiday;
            this.formGroup.setValue({id: holiday.id, text: holiday.text, holidayDate: holiday.holidayDate}, { emitEvent: true });
            this.holidaySubject.next(holiday);
        }
    }

    // Implemented Control Value Accessor method
    public registerOnChange(onChange: (holiday: Holiday) => void): void {
        this.subscription.add(
            this.formGroup.valueChanges.pipe(
                skip(1)
            ).subscribe(() => {
                const updatedValue = this.formGroup.getRawValue();
                
                if (this.formControls.text.invalid || this.formControls.holidayDate.invalid) {
                    return;
                }

                const updatedHolidayDate = new Date(updatedValue.holidayDate);
                updatedValue.holidayDate = DateTime.utc(updatedHolidayDate.getFullYear(), updatedHolidayDate.getMonth() + 1, updatedHolidayDate.getDate()).toString();
               
                // Only update if the new values differ from the old
                if (
                    updatedValue.text && updatedValue.holidayDate && 
                    (this.holiday.text !== updatedValue.text || 
                    this.holiday.holidayDate !== updatedValue.holidayDate)
                ) {
                    onChange(updatedValue);

                    this.oldHolidays.push(updatedValue);

                    this.emitEventOnChange(updatedValue);
                    this.holiday = updatedValue;
                } else {
                    onChange(this.holidayOldValue);
                }
            })
        );
    }

    // Implemented Control Value Accessor method
    public registerOnTouched(): void { return; }

    public onDeleteHoliday(holiday: Holiday) {
        const title = this.translateService.instant('Delete holiday');
        const message = this.translateService.instant('Are you sure that you want to delete holiday?', { holidayName: holiday.text });
        const cancelButton = this.translateService.instant('Cancel');
        const deleteButton = this.translateService.instant('Delete');

        this.dialogService.showConfirmDialog(title, message, deleteButton, cancelButton).pipe(
            first(),
            map(confirm => {
                if (confirm) {
                    this.removeHolidayEvent.next(holiday.id);
                }
            })
        ).subscribe();
    }

    private emitEventOnChange(updatedHoliday: Holiday): void {
        this.updateHolidayEvent.next({
            old: this.holiday,
            new: updatedHoliday
        });
    }
}
