<div class="bol-modal__header">
    <span class="modal-title">{{ 'Select activity types' | translate }}</span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    <div class="filters-activity-types">
        <app-multiselect-tree
            multiselectTreePlaceholder="Activity type categories"
            class="activity-type-categories-filter-activity-type"
            [entities]="activityTypeCategories$ | async"
            [preSelectedEntities]="selectedActivityTypeCategoryIds"
            [showSelectAll]="true"
            [required]="true"
            [showSearch]="false"
            (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnActivityTypeCategories($event)"
        ></app-multiselect-tree>

        <app-multiselect-tree
            searchFieldPlaceholder="Search organization unit"
            multiselectTreePlaceholder="Organization units"
            class="organization-unit-filter-activity-type"
            [entities]="organizationUnits$ | async"
            [preSelectedEntities]="filteredOrganizationUnits$ | async"
            [showSelectAll]="true"
            (selectedEntityIdsChanged)="updateActivityTypeTableBasedOnOrganizationUnits($event)"
        ></app-multiselect-tree>
    </div>

    @if (numberOfActivityTypes) {
        <div class="activities-length-info-message">
            <div class="bol-text bol-text-regular">
                {{ numberOfActivityTypes }} {{ '' + ACTIVITY_LENGTH_MESSAGE | translate }}
            </div>
        </div>
    }
    <app-multiselect-table
        searchFieldPlaceholder="Activity type filter"
        [entities]="filteredActivityTypes$ | async"
        [columnDefinition]="columnDefinition"
        [searchProperties]="searchProperties"
        [hideSearch]="false"
        (selectedEntityIdsChanged)="updateActivityTypeStates($event)"
    ></app-multiselect-table>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            class="bol-button-solid bol-positive"
            [disabled]="selectedActivityTypeCategoryIds?.length === 0 || selectedOrganizationIds?.length === 0"
            (click)="onConfirm()"
        >
            <span translate>Confirm</span>
        </button>
    </div>
</mat-dialog-actions>
