<div class="content-wrapper">
    <mat-card class="bol-card">
        <mat-card-content class="bol-card-content align-center">
            @if (loginBusy$ | async) {
                <p translate>Authentication in progress. Please wait a moment...</p>
            }

            <div class="inline-spinner">
                @if (loginBusy$ | async) {
                    <mat-spinner diameter="30"></mat-spinner>
                }
            </div>

            @if (showError$ | async) {
                <div id="loginAlert" class="message error">
                    <h3 translate>Error while trying to authenticate user</h3>
                    <p translate>See details below:</p>
                    <pre>{{ message }}</pre>
                </div>
            }
        </mat-card-content>
    </mat-card>
</div>
