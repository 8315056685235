import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { first, map, skip } from 'rxjs/operators';
import { createTimeRange, TimeAdapter } from '@ortec/bolster/time-select';
import * as moment from 'moment';
import { DateTimeUtilityService } from 'src/app/shared/services/date-time-utility.service';
import { OwsIntervalScheduling } from '../../ows-interval-scheduling-store/ows-interval-scheduling.model';
import { DialogService } from 'src/app/shared/services';

export interface UpdatedInterval {
    old: OwsIntervalScheduling;
    new: OwsIntervalScheduling;
}

@Component({
    selector: 'app-resource-activity-import-item',
    templateUrl: './resource-activity-import-item.component.html',
    styleUrls: ['./resource-activity-import-item.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ResourceActivityImportItemComponent,
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ResourceActivityImportItemComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() public readonly intervals: Array<OwsIntervalScheduling> = [];
    @Input() public readonly addInterval!: boolean;

    @Output() public readonly removeIntervalEvent = new EventEmitter<OwsIntervalScheduling>();
    @Output() public readonly updateIntervalEvent = new EventEmitter<UpdatedInterval>();
    @Output() public readonly addIntervalEvent = new EventEmitter<OwsIntervalScheduling>();

    public intervals$!: Observable<Array<OwsIntervalScheduling>>;
    public interval$!: Observable<OwsIntervalScheduling>;

    public readonly formControls = {
        startMinutes: new UntypedFormControl(undefined, [Validators.required]),
        endMinutes: new UntypedFormControl(undefined, [Validators.required]),
        interval: new UntypedFormControl(undefined, [Validators.required, Validators.min(1), Validators.max(60)])
    };

    public readonly formGroup = new UntypedFormGroup(this.formControls, { updateOn: 'blur' });
    public interval!: OwsIntervalScheduling;
    public timeRange$!: Observable<any>;
    public requiredErrorMessage: string = 'VALIDATORS.REQUIRED';
    public startTimeAfterEndTime: string = 'The end time must be after the start time';
    public intervalExceedsAmountOfMinutes: string = 'The interval cannot exceed 60 minutes';

    private readonly intervalOldValue!: OwsIntervalScheduling;
    private readonly intervalSubject = new BehaviorSubject<OwsIntervalScheduling>(undefined);
    private readonly subscription = new Subscription();

    constructor(
        private readonly timeAdapter: TimeAdapter<moment.Moment>,
        private readonly dialogService: DialogService,
        private readonly dateTimeUtilityService: DateTimeUtilityService,
        private readonly translateService: TranslateService,
    ) {
        this.timeRange$ = of(createTimeRange(timeAdapter, undefined, timeAdapter.create(23, 59, 0, 0), 30));
    }

    public ngOnInit(): void {
        this.interval$ = this.intervalSubject.asObservable();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // Implemented Control Value Accessor method
    public writeValue(interval: OwsIntervalScheduling | undefined): void {
        if (interval) {
            this.interval = interval;
            const startMinutesInit: moment.Moment = interval.startMinutes ? this.transformMinutesInTimeFormat(interval.startMinutes as any) : null;
            const endMinutesInit: moment.Moment = interval.endMinutes ? this.transformMinutesInTimeFormat(interval.endMinutes as any) : null;
            const intervalPeriod: number = interval.interval || interval.interval === 0 ? interval.interval : null;
            this.formGroup.setValue({ startMinutes: startMinutesInit, endMinutes: endMinutesInit, interval: intervalPeriod }, { emitEvent: true });
            this.intervalSubject.next(interval);
        }
    }

    // Implemented Control Value Accessor method
    public registerOnChange(onChange: (interval: OwsIntervalScheduling) => void): void {
        this.subscription.add(
            this.formGroup.valueChanges.pipe(
                skip(1)
            ).subscribe(() => {
                const updatedValue = this.formGroup.getRawValue();
                this.setCustomErrorsOnChange(updatedValue.startMinutes, updatedValue.endMinutes);
                if (this.formControls.startMinutes.invalid || this.formControls.endMinutes.invalid || this.formControls.interval.invalid) {
                    return;
                }
                updatedValue.startMinutes = this.dateTimeUtilityService.transformDateInTimeFormat(updatedValue.startMinutes);
                updatedValue.endMinutes = this.dateTimeUtilityService.transformDateInTimeFormat(updatedValue.endMinutes);

                if (updatedValue.startMinutes &&
                    updatedValue.endMinutes && updatedValue.interval >= 0 &&
                    (updatedValue.startMinutes !== this.interval.startMinutes || updatedValue.endMinutes !== this.interval.endMinutes || updatedValue.interval !== this.interval.interval)) {
                    onChange(updatedValue);
                    this.emitEventOnChange({ ...updatedValue, id: this.interval.id, intervalType: this.interval.intervalType });
                    this.interval = updatedValue;

                } {
                    onChange(this.intervalOldValue);
                }
            })
        );
    }

    // Implemented Control Value Accessor method
    public registerOnTouched(): void { return; }

    public onDeleteImport(interval: OwsIntervalScheduling) {
        if (!this.addInterval) {
            const title = this.translateService.instant('Delete interval');
            const message = this.translateService.instant('Are you sure that you want to delete the interval?');
            const cancelButton = this.translateService.instant('Cancel');
            const deleteButton = this.translateService.instant('Delete');

            this.dialogService.showConfirmDialog(title, message, deleteButton, cancelButton).pipe(
                first(),
                map(confirm => {
                    if (confirm) {
                        this.removeIntervalEvent.next(interval);
                    }
                })
            ).subscribe();
        } else {
            this.removeIntervalEvent.next(interval);
        }
    }

    private setCustomErrorsOnChange(startMinutes: string, endMinutes: string): void {
        const minutes1 = moment.duration(this.dateTimeUtilityService.transformDateInTimeFormat(startMinutes)).asMinutes();
        const minutes2 = moment.duration(this.dateTimeUtilityService.transformDateInTimeFormat(endMinutes)).asMinutes();
        if (minutes1 > minutes2 && endMinutes !== null) {
            this.formControls.endMinutes.setErrors({ startEndMinuteValidator: true });
            this.formControls.endMinutes.markAllAsTouched();
        }
    }

    private emitEventOnChange(updatedInterval: OwsIntervalScheduling): void {
        if (!this.addInterval) {
            this.updateIntervalEvent.next({
                old: this.interval,
                new: updatedInterval
            });
        } else {
            this.addIntervalEvent.next(updatedInterval);
        }
    }

    private transformMinutesInTimeFormat(minutes: string): moment.Moment {
        const splittedStart = minutes.split(':');

        return this.timeAdapter.create(+splittedStart[0], +splittedStart[1], 0, 0);
    }
}
