@if (daypartsReadySubject | async) {
    <div class="schedule__overview__container">
        <app-empty-filters-panel
            [collapsePanelMessage]="'Show schedule'"
            [filtersPanelState]="filtersPanelState$ | async"
            (updateShowFiltersState)="updateShowFiltersStateAndGetScheduleData($event)"
        >
            <app-schedule-filters-header
                filtersHeader
                (hideFiltersPanelOnSelectedFilterSetting)="hidePanelOnSelectedFilterSetting()"
            ></app-schedule-filters-header>
            <div
                filtersContent
                class="filters__content"
            >
                <app-schedule-filters></app-schedule-filters>
            </div>
        </app-empty-filters-panel>
        @if (
            (initialLoadingFinished$ | async) === true &&
            (filterSettingsAvailable$ | async) === false &&
            (loading$ | async) === false &&
            (filtersPanelState$ | async) === true
        ) {
            <div class="empty__state__container">
                <div class="empty__state__container__content">
                    <mat-icon svgIcon="calendar-today"></mat-icon>
                    <div class="empty__state__container__message__huge">
                        {{ 'No schedules to show yet.' | translate }}
                    </div>
                    <div>
                        {{ 'Use the filters panel to create a planning overview.' | translate }}
                    </div>
                </div>
            </div>
        }
        @if ((loading$ | async) === true) {
            <div class="overlay-spinner">
                <mat-spinner diameter="100"></mat-spinner>
            </div>
        }
        @if ((initialLoadingFinished$ | async) === true && (filtersPanelState$ | async) === false) {
            <div class="schedule__container">
                <div class="schedule">
                    <app-schedule-calendar></app-schedule-calendar>
                </div>
            </div>
        }
    </div>
}

