import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SelectionCheckBoxClickEvent } from 'src/app/shared/models';
import { ActivityTypeCategory } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.model';
import { ActivityTypeCategoryQuery } from 'src/app/shared/stores/activity-type-category-store/activity-type-category.query';
import { ActivityType, IActivityTypeTree } from 'src/app/shared/stores/activity-type-store/activity-type.model';
import { ActivityTypeQuery } from 'src/app/shared/stores/activity-type-store/activity-type.query';
import { IOrganizationUnitTree } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';

export interface IActivityTypeModalData {
    activityTypes$?: Observable<Array<ActivityType | IActivityTypeTree>>;
    selectedActivityTypeIds: Array<number>;
    activityTypeCategories$?: Observable<Array<ActivityTypeCategory>>;
    organizationUnits$?: Observable<Array<IOrganizationUnitTree>>;
    totalActivityTypesCount?: number;
    noActivityTypesSelectedByDefault?: boolean;
    applyFilterState: boolean;
    dialogTitle: string;
    showApplyFilter: boolean;
}

export interface ActivityTypeSelection extends IActivityTypeTree {
    checked: boolean;
}

@Component({
    selector: 'app-activity-type-dialog-new',
    templateUrl: './activity-type-dialog.component.html',
    styleUrls: ['./activity-type-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTypeDialogNewComponent implements OnInit, OnDestroy {
    public activityTypes: Array<ActivityTypeSelection>;
    public filteredActivityTypes: Array<ActivityTypeSelection>;
    public selectedOrganizationIds: Array<number>;
    public selectedActivityTypeCategoryIds: Array<number>;
    public filteredActivityTypes$: Observable<Array<ActivityTypeSelection>>;
    public activityTypeCategories$!: Observable<Array<ActivityTypeCategory>>;
    public organizationUnits$: Observable<Array<IOrganizationUnitTree>>;
    public filteredActivityTypesSubject = new BehaviorSubject<Array<ActivityTypeSelection>>([]);
    public selectedOrganizationUnitIdsSubject = new BehaviorSubject<Array<number>>([]);
    public selectedActivityTypeCategoryIdsSubject = new BehaviorSubject<Array<number>>([]);
    public applyFilterStateSubject = new BehaviorSubject<boolean>(false);
    public applyFilterState$: Observable<boolean>;
    public showOnlySelectedStateSubject = new BehaviorSubject<boolean>(undefined);
    public showOnlySelectedState$: Observable<boolean>;
    public selectActivityTypeEventSubject = new BehaviorSubject<boolean>(false);
    public selectActivityTypeEvent$: Observable<boolean>;
    public disableFilterFields: boolean = false;
    public selectedActivityTypeIdsSet = new Set<number>();
    public selectedActivityTypeIds = [];
    public dialogTitle: string;
    public showApplyFilter: boolean;

    public columnDefinition = [];
    public ACTIVITY_SELECTED_MESSAGE = 'activity types selected';
    public searchProperties: Array<string> = ['displayName', 'shortName'];

    private readonly subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<IActivityTypeModalData>,
        public organizationUnitQuery: OrganizationUnitQuery,
        public activityTypeQuery: ActivityTypeQuery,
        public activityTypeCategoryQuery: ActivityTypeCategoryQuery,
        private readonly translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) dialogData: IActivityTypeModalData,
    ) {
        this.dialogTitle = dialogData.dialogTitle;
        this.showApplyFilter = dialogData.showApplyFilter;
        this.filteredActivityTypes$ = this.filteredActivityTypesSubject.asObservable();
        this.applyFilterState$ = this.applyFilterStateSubject.asObservable();
        this.selectActivityTypeEvent$ = this.selectActivityTypeEventSubject.asObservable();
        this.showOnlySelectedState$ = this.showOnlySelectedStateSubject.asObservable();
        this.activityTypeCategories$ = dialogData.activityTypeCategories$.pipe(
            map((categories) => {
                return categories?.map(category => ({
                    ...category,
                    displayName: this.translateService.instant(category.displayName),
                }));
            })
        );

        this.selectedActivityTypeIds = dialogData.selectedActivityTypeIds;
        this.selectedActivityTypeIds?.forEach(id => this.selectedActivityTypeIdsSet.add(id));
        this.applyFilterStateSubject.next(dialogData.applyFilterState);
        this.organizationUnits$ = dialogData.organizationUnits$;

        this.subscription.add(
            combineLatest([
                dialogData.activityTypes$,
                dialogData.activityTypeCategories$,
                dialogData.organizationUnits$,
                this.applyFilterState$
            ]).pipe(
                filter(([activityTypes, activityTypeCategories, organizationUnits, applyFilterState]) => activityTypes !== undefined && activityTypes.length > 0 &&
                    activityTypeCategories !== undefined && activityTypeCategories.length > 0 && organizationUnits !== undefined && organizationUnits.length > 0 && (applyFilterState || !this.showApplyFilter)),
                tap(([activityTypes, _]) => {
                    this.activityTypes = this.updateActivityTypeAndChildrenUnchecked(activityTypes as any);
                    if (dialogData.noActivityTypesSelectedByDefault && (!dialogData.selectedActivityTypeIds || dialogData.selectedActivityTypeIds.length === 0)) {
                        this.filteredActivityTypesSubject.next([])
                    }
                    this.showOnlySelectedStateSubject.next(false)
                })
            ).subscribe()
        );
    }

    public ngOnInit(): void {
        this.columnDefinition.push( 
            {
                columnDisplayName: 'Short name',
                entityProperty: 'shortName',
                stylingProperty: 'nameStyling'
            },
            {
                columnDisplayName: 'Display name',
                entityProperty: 'displayName',
            }
        )

        this.setFilteredActivityTypes();
    }
    
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onConfirm(): void {
        this.dialogRef.close({
            data: {
                selectedActivityTypeIds: this.selectedActivityTypeIds,
                applyFilterState: this.applyFilterStateSubject.value
            } as IActivityTypeModalData
        });
    }

    public updateActivityTypeStates(event: SelectionCheckBoxClickEvent): void {
        const activityTypeIds = event.entityIds;
        if(this.showOnlySelectedStateSubject.value) {
            const activityTypes = this.updateActivityTypeAndChildrenChecked(this.filteredActivityTypes, activityTypeIds);
            const activityTypeStructuresThatHaveSelectedActivityTypeId = this.showOnlyActivityTypeStructuresThatHaveSelectedActivityTypeId(activityTypes, this.selectedActivityTypeIds);
            this.filteredActivityTypes = this.showOnlySelectedActivityTypes(activityTypeStructuresThatHaveSelectedActivityTypeId, this.selectedActivityTypeIds);
            this.updateFilteredActivityTypes();
        } else {
            this.filteredActivityTypes = this.updateActivityTypeAndChildrenChecked(this.filteredActivityTypes, activityTypeIds);
        }
    }

    
    public updateActivityTypeTableBasedOnOrganizationUnits(orgUnitsIds: Array<number>): void {
        this.selectedOrganizationIds = orgUnitsIds;
        this.selectedOrganizationUnitIdsSubject.next(orgUnitsIds);
    }

    public updateActivityTypeTableBasedOnActivityTypeCategories(actTypeCategoryIds: Array<number>): void {
        this.selectedActivityTypeCategoryIds = actTypeCategoryIds;
        this.selectedActivityTypeCategoryIdsSubject.next(actTypeCategoryIds);
    }

    public onCloseModal(): void {
        this.dialogRef.close();
    }

    public onApplyFilter(event: MatCheckboxChange): void {
        this.applyFilterStateSubject.next(event.checked);
    }

    public showOnlyActivityTypesSelected(event: MatCheckboxChange): void {
        this.disableFilterFields = event.checked;
        if(this.disableFilterFields) {
            this.selectedOrganizationIds = [];
            this.selectedActivityTypeCategoryIds = []
            this.selectedOrganizationUnitIdsSubject.next([]);
            this.selectedActivityTypeCategoryIdsSubject.next([]);
        }
        this.showOnlySelectedStateSubject.next(event.checked);
    }

    private updateActivityTypeAndChildrenChecked(activityTypes: Array<ActivityTypeSelection>, activityTypeIds: Array<number | string>): Array<ActivityTypeSelection> {
        const updatedActivityTypes = activityTypes.map(activityType => {
        const activitySelected = activityTypeIds?.includes(activityType.id);
        const updatedChildren = activityType?.children  ? this.updateActivityTypeAndChildrenChecked(activityType.children as any, activityTypeIds) : [];
        
            return {
                ...activityType,
                checked: activitySelected,
                children: updatedChildren,
                nameStyling: {
                    backgroundColor: '#' + activityType.backColor,
                    color: '#' + activityType.textColor
                }
            };
        });

        updatedActivityTypes.forEach(activityType => {
            if (activityType.checked) {
                this.selectedActivityTypeIdsSet.add(activityType.id);
            } else {
                this.selectedActivityTypeIdsSet.delete(activityType.id);
            }
        });
    
        this.selectedActivityTypeIds = Array.from(this.selectedActivityTypeIdsSet);
    
        return updatedActivityTypes;
    }

    private setFilteredActivityTypes(): void {
        this.subscription.add(
            combineLatest([
                this.selectedActivityTypeCategoryIdsSubject,
                this.selectedOrganizationUnitIdsSubject,
                this.showOnlySelectedStateSubject,
            ]).pipe(
                filter(([_,__, showOnlyActivityTypesSelected]) => showOnlyActivityTypesSelected !== undefined),
                tap(([selectedActivityCategoryIds, selectedOrganizationUnits, showOnlyActivityTypesSelected]) => {
                    if (showOnlyActivityTypesSelected) {
                        const activityTypeStructuresThatHaveSelectedActivityTypeId = this.showOnlyActivityTypeStructuresThatHaveSelectedActivityTypeId(this.activityTypes, this.selectedActivityTypeIds);
                        this.filteredActivityTypes = this.showOnlySelectedActivityTypes(activityTypeStructuresThatHaveSelectedActivityTypeId, this.selectedActivityTypeIds);
                        this.updateFilteredActivityTypes();

                        return;
                    }

                    const filteredByOrgUnits = this.filterActivityTypesByOrganizationUnits(selectedOrganizationUnits);
            
                    this.filteredActivityTypes = selectedActivityCategoryIds.length > 0
                        ? this.filterActivityTypesByActivityTypeCategories(filteredByOrgUnits, selectedActivityCategoryIds)
                        : filteredByOrgUnits;
            
                    this.updateFilteredActivityTypes();
                })
            ).subscribe()
        );
    }

    private filterActivityTypesByOrganizationUnits(selectedOrganizationUnits: Array<number>): Array<ActivityTypeSelection> {
        if (selectedOrganizationUnits && selectedOrganizationUnits.length > 0) {
            const updatedActivityTypes = this.updateActivityTypeAndChildren(this.activityTypes, this.selectedActivityTypeIds);

            return this.getFilteredActivityTypesByOrganizationUnits(updatedActivityTypes, selectedOrganizationUnits);
        } else {
            return this.updateActivityTypeAndChildren(this.activityTypes, this.selectedActivityTypeIds);
        }
    }

    private getFilteredActivityTypesByOrganizationUnits(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeOrganizationUnitsIds: Array<number>): Array<ActivityTypeSelection> {
        if (selectedActivityTypeOrganizationUnitsIds === null || selectedActivityTypeOrganizationUnitsIds === undefined || selectedActivityTypeOrganizationUnitsIds.length === 0) {
            return activityTypes;
        }

        const activityTypesFilteredByOrganizationUnits = activityTypes.filter(activityType => selectedActivityTypeOrganizationUnitsIds ? selectedActivityTypeOrganizationUnitsIds.includes(activityType.ownerOrganizationUnitId) : true);

        return activityTypesFilteredByOrganizationUnits;
    }

    private updateFilteredActivityTypes(): void {
        this.filteredActivityTypesSubject.next(this.filteredActivityTypes);
    }
      
    private filterActivityTypesByActivityTypeCategories(activityTypes: Array<ActivityTypeSelection>, selectedCategoryIds: Array<number>): Array<ActivityTypeSelection> {
        return activityTypes.filter((activityType) =>
            selectedCategoryIds.includes(activityType.categoryId)
        );
    }

    private updateActivityTypeAndChildrenUnchecked(activityTypes: Array<ActivityTypeSelection>): Array<ActivityTypeSelection> {
        return activityTypes.map(activityType => ({
            ...activityType,
            checked: false,
            nameStyling: {
                backgroundColor: '#' + activityType.backColor,
                color: '#' + activityType.textColor
            },
            children: activityType.children ? this.updateActivityTypeAndChildrenUnchecked(activityType.children as any) : []
        }));
    }

    private showOnlySelectedActivityTypes(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeIds: Array<number>): Array<ActivityTypeSelection> {
        const selectOnlyActivityTypesWithSelectedId = (activityType: ActivityTypeSelection): ActivityTypeSelection => {
            const children = (activityType.children || []).map(selectOnlyActivityTypesWithSelectedId);
            const isChecked = selectedActivityTypeIds.includes(activityType.id);
        
            return {
              ...activityType,
              checked: isChecked,
              children,
            };
          };
    
        const result = activityTypes.map(selectOnlyActivityTypesWithSelectedId);
    
        return result;
    }

    private showOnlyActivityTypeStructuresThatHaveSelectedActivityTypeId(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeIds: Array<number>): Array<ActivityTypeSelection> {
        const selectedIdsSet = new Set(selectedActivityTypeIds);

        const selectActivityTypeParentIfChildIsSelected = (activityType: ActivityTypeSelection): ActivityTypeSelection => {
            const children = (activityType.children || []).map(selectActivityTypeParentIfChildIsSelected);
            const isChecked = selectedIdsSet.has(activityType.id) || children.some(child => child.checked);
        
            return {
              ...activityType,
              checked: isChecked,
              children,
            };
        };
    
        const result = activityTypes.map(selectActivityTypeParentIfChildIsSelected).filter(activityType => activityType.checked);
    
        return result;
    }

    private updateActivityTypeAndChildren(activityTypes: Array<ActivityTypeSelection>, selectedActivityTypeIds: Array<number>): Array<ActivityTypeSelection> {
        return activityTypes.map(activityType => {
          const activitySelected = selectedActivityTypeIds?.includes(activityType.id);
          const updatedChildren = activityType?.children  ? this.updateActivityTypeAndChildren(activityType.children as any, selectedActivityTypeIds) : [];
          
            return {
                ...activityType,
                checked: activitySelected,
                children: updatedChildren,
                nameStyling: {
                    backgroundColor: '#' + activityType.backColor,
                    color: '#' + activityType.textColor
                }
            };
        });
    }
}
