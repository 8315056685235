<div class="event-container">
    @for (act of allLeaves; track act.id) {
        <div class="event-element">
            @if (
                (act.parentId === null && showActivityShortName) || (act.parentId !== null && showActivityShortNameLeaf)
            ) {
                <div
                    class="activity-code-container"
                    [class.root]="act.parentId === null"
                    [style.background]="'#' + act?.backColor"
                    [style.color]="'#' + act?.textColor"
                >
                    {{ act.shortName }}
                </div>
            }
            @if ((act.parentId === null && showActivityName) || (act.parentId !== null && showActivityNameLeaf)) {
                <div
                    class="activity-displayname"
                    [class.root]="act.parentId === null"
                >
                    {{ act.displayName }}
                </div>
            }
        </div>
    }
</div>
