/* eslint-disable @typescript-eslint/member-ordering */
import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { Permission } from 'src/app/shared/models/Enums';
import { NestedTreeService } from 'src/app/shared/services';

import { PermissionsTreeAdapterService } from './services/permissions-tree-adapter.service';
import { PermissionFlatNode, PermissionItem, PermissionColumnDefinition } from './permissions-tree.model';
import { PermissionsTreePropagationService } from './services/permissions-tree-propagation.service';
import { PermissionsTreeStateService } from './services/permissions-tree-state.service';

@Component({
    selector: 'app-permissions-tree',
    templateUrl: './permissions-tree.component.html',
    styleUrls: ['./permissions-tree.component.scss'],
    providers: [PermissionsTreeAdapterService, PermissionsTreePropagationService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionsTreeComponent implements OnInit {
    @Input() public columnDefinition: Array<Permission> = [];
    @Input() public readonly entityName: string;
    @Input() public readonly entities: Array<PermissionFlatNode>;
    @Input() public readonly disabledPermissions: boolean = false;
    @Input() private set initialEntitiesWithPermissions(ids: Array<PermissionItem>) {
        this.entitiesWithPermissions = ids;
    };

    @Output() public readonly permissionItemsChanged = new EventEmitter<Array<PermissionItem>>();

    public displayedColumns: Array<string>;
    public definedColumnDefinitions: Array<PermissionColumnDefinition>;
    public expandedNodeIds: Array<number> = [];
    public dataSource: MatTreeFlatDataSource<any, PermissionFlatNode>;
    public treeControl: FlatTreeControl<PermissionFlatNode, PermissionFlatNode>;
    private treeFlattener: MatTreeFlattener<PermissionFlatNode, PermissionFlatNode>;
    private entitiesWithPermissions: Array<PermissionItem> = [];

    public hasChild = (_: number, node: PermissionFlatNode) => node.expandable;

    constructor(
        private readonly nestedTreeService: NestedTreeService,
        private readonly permissionsTreeStateService: PermissionsTreeStateService,
        private readonly permissionsTreeAdapterService: PermissionsTreeAdapterService,
        private readonly permissionsTreePropagationService: PermissionsTreePropagationService,
    ) {
    }

    public ngOnInit(): void {
        this.treeControl = new FlatTreeControl<PermissionFlatNode>(
            node => node.level, node => node.expandable);
        this.treeFlattener = new MatTreeFlattener(
            this.transformToPermissionsTable, node => node.level, 
            node => node.expandable, node => node.children);

        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.dataSource.data = this.entities;
        this.expandedNodeIds = this.permissionsTreeStateService.getExpandedNodeIds();
        this.expandDataNodes(this.expandedNodeIds, this.treeControl.dataNodes);
    
        if (this.columnDefinition) {
            this.definedColumnDefinitions = this.permissionsTreeAdapterService.permissionColumnDefinitions.filter(def => this.columnDefinition.includes(def.permissionType));
            this.displayedColumns = ['displayName'].concat(this.definedColumnDefinitions.map(column => column.entityProperty));
        }
    }

    public onSelectEntity(entityId: number, permissionName: string, permissionType: Permission): void {
        const entity = this.nestedTreeService.findChildInNestedArray(this.dataSource.data, entityId);

        const newStatus = !entity[permissionName];
        const updatedPermissionTypeStatus = this.permissionsTreeAdapterService.getUpdatedPermissionTypeStatus(newStatus, permissionType);

        this.permissionsTreePropagationService.getPermissionItemsOnStatusChange(entity, updatedPermissionTypeStatus, newStatus)
            .pipe(first())
            .subscribe(permissionTypes => {
                this.permissionItemsChanged.emit(permissionTypes);

                this.updatePermissionsTreeState(entity, permissionType, newStatus, this.permissionsTreePropagationService.permissionsTreePropagationOptions.shouldPropagate);
            });
    }

    public toggleExpandableEntity(entity: any): void {
        this.treeControl.toggle(entity);
        this.updateExpandedNodeIds();
        this.permissionsTreeStateService.setExpandedNodeIds(this.expandedNodeIds);
    }

    public hasChildrenSelected(data: PermissionFlatNode): boolean {
        const ids = this.nestedTreeService.getAllChildIdsForNestedEntities(data.children) || [];

        return ids.some(id => this.entitiesWithPermissions.filter(ent => ent.permission !== Permission.noPermission).map(ent => ent.userGroupId).includes(id));
    }

    public getNodePermissionState(data: PermissionFlatNode, permission: Permission): boolean {
        const permissionItem = this.entitiesWithPermissions.find(opt => opt.userGroupId === data.id);

        return permissionItem ? this.permissionsTreeAdapterService.getNodeStatusForPermissionType(true, permissionItem.permission, permission) : false;
    }

    private readonly transformToPermissionsTable = (node: PermissionFlatNode, level: number): PermissionFlatNode  => {
        return {
            ...node,
            expandable: !!node.children && node.children.length > 0,
            level,
        };
    };

    private updateExpandedNodeIds(): void {
        this.expandedNodeIds = this.treeControl.expansionModel.selected.map(node => node.id);
    }

    private expandDataNodes(expandedEntityIds: Array<number>, dataNodes: Array<any>): void {
        expandedEntityIds.forEach(id => {
            const expandableNode = dataNodes.find(node => node.id === id);
            if (expandableNode && !this.treeControl.isExpanded(expandableNode)) {
                this.treeControl.expand(expandableNode);
            }
        });
    }

    private updatePermissionsTreeState(entity: PermissionFlatNode, permissionType: Permission, newStatus: boolean, updateChildren: boolean): void {
        // the demoteParentPermission check is made to see if we should skip or not editing a child node when its parent has the permission reverted from Owner to Read permission 
        if (!(this.permissionsTreePropagationService.permissionsTreePropagationOptions.demoteParentPermission
                && ((permissionType === Permission.owner && !entity.readAndWritePermission) || (permissionType === Permission.readWrite && !entity.readAndWritePermission && !entity.readPermission)))) {
            this.setPermissionsOnEntities(entity, permissionType, newStatus);
        }

        if (updateChildren) {
            entity.children?.map(childNode => {
                this.updatePermissionsTreeState(childNode, permissionType, newStatus, updateChildren);
            });
        }
    }

    private updateEntitiesWithPermissions(id: number, permission: Permission): void { 
        const nodePermissionIndex = this.entitiesWithPermissions.findIndex((item => item.userGroupId === id));
        if (nodePermissionIndex !== -1 && permission === Permission.noPermission) {
            this.entitiesWithPermissions.splice(nodePermissionIndex, 1);

            return;
        }

        if (nodePermissionIndex !== -1) {
            this.entitiesWithPermissions[nodePermissionIndex].permission = permission;
        } else {
            this.entitiesWithPermissions.push({ userGroupId: id, permission });
        }
    }

    private setPermissionsOnEntities(entity: PermissionFlatNode, permissionType: Permission, newStatus: boolean): void {
        entity.readPermission = this.permissionsTreeAdapterService.getNodeStatusForPermissionType(newStatus, permissionType, Permission.read);
        entity.readAndWritePermission = this.permissionsTreeAdapterService.getNodeStatusForPermissionType(newStatus, permissionType, Permission.readWrite);
        entity.ownerPermission = this.permissionsTreeAdapterService.getNodeStatusForPermissionType(newStatus, permissionType, Permission.owner);

        this.updateEntitiesWithPermissions(entity.id, this.permissionsTreeAdapterService.getUpdatedPermissionTypeStatus(newStatus, permissionType));
    }
}
