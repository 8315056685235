@if (mode === manageMode.ADD) {
    <div class="add-entity-backbdrop mat-elevation-z8"></div>
}
<mat-card
    class="bol-card full-height"
    [class.mat-elevation-z8]="mode === manageMode.ADD"
>
    @if (mode !== undefined) {
        <mat-card-title
            class="bol-card-title entity-title"
            data-cy="entityManageTitle"
        >
            @if (mode === manageMode.VIEW) {
                <span>{{ 'VIEW-ENTITY-TITLE' | translate: { entityName: prefixedEntityName | translate } }}</span>
            }
            @if (mode === manageMode.EDIT) {
                <span>{{ 'EDIT-ENTITY-TITLE' | translate: { entityName: prefixedEntityName | translate } }}</span>
            }
            @if (mode === manageMode.ADD) {
                <span>{{ 'ADD-ENTITY-TITLE' | translate: { entityName: prefixedEntityName | translate } }}</span>
            }
        </mat-card-title>
        <div
            class="status-indication__bar"
            data-cy="loadingStatusIndication"
        >
            @if (uiEntity?.isLoading) {
                <div
                    class="status-indication__text"
                    translate
                >
                    saving
                </div>
                <mat-spinner
                    class="status-indication__spinner"
                    diameter="16"
                ></mat-spinner>
            }
            @if (uiEntity?.isFinishedSaving) {
                <div
                    class="status-indication__text"
                    translate
                >
                    saved
                </div>
            }
            @if (uiEntity?.hasPendingChanges) {
                <div
                    class="status-indication__text"
                    translate
                >
                    changes pending
                </div>
            }
        </div>
        <form
            class="form-padding"
            [formGroup]="form"
            (ngSubmit)="onAddEntity()"
        >
            <formly-form
                [form]="form"
                [model]="internalModel"
                [fields]="internalFormFields"
                (modelChange)="onModelChanged()"
            ></formly-form>
            <div class="entity-buttons__wrapper">
                <div class="entity-buttons">
                    @if (mode === manageMode.ADD) {
                        <button
                            mat-button
                            type="submit"
                            class="bol-button-solid bol-positive"
                            data-cy="saveEntityButton"
                        >
                            <span translate>Save</span>
                        </button>
                        <button
                            mat-button
                            type="button"
                            class="bol-button-outlined"
                            (click)="onCancelAdd()"
                            data-cy="cancelEntityButton"
                        >
                            <span translate>Cancel</span>
                        </button>
                    }
                    @if (mode === manageMode.EDIT) {
                        @if (canClone) {
                            <button
                                mat-button
                                type="button"
                                class="bol-button-outlined"
                                (click)="onClone()"
                                data-cy="cloneEntityButton"
                            >
                                <span translate>Clone</span>
                            </button>
                        }
                        @if (canDelete) {
                            <button
                                mat-button
                                type="button"
                                class="bol-button-outlined bol-negative"
                                (click)="onDelete()"
                                data-cy="deleteEntityButton"
                            >
                                <span translate>Delete</span>
                            </button>
                        }
                    }
                </div>
            </div>
            @if (message) {
                <div [ngClass]="messageStyleClass">{{ message | translate }}</div>
            }
        </form>
    }
</mat-card>

