
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BolPopoverModule } from '@ortec/bolster/popover';

@Component({
    standalone: true,
    selector: 'menu-card',
    templateUrl: './menu-card.component.html',
    styleUrls: ['./menu-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
    RouterModule,
    TranslateModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    BolPopoverModule
]
})
export class MenuCardComponent {
    @Input() public title: string;
    @Input() public icon: string;
    @Input() public url: string;
    @Input() public target = '_self';
    @Input() public useCustomContent = false;
}
