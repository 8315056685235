import { Injectable } from '@angular/core';

import { EditTemplatesManagementStore } from './edit-template-management.store';
import { ManageTemplate } from 'src/app/shared/stores/manage-template-store/manage-template.model';
import { ManageTemplateStore } from 'src/app/shared/stores/manage-template-store/manage-template.store';

@Injectable({
    providedIn: 'root'
})
export class EditTemplatesManagementService {
    constructor(
        protected editTemplatesManagementStore: EditTemplatesManagementStore,
        protected manageTemplateStore: ManageTemplateStore
    ) { }

    public increaseWeekNumber(): void {
        this.editTemplatesManagementStore.increaseWeekNumber();
    }

    public decreaseWeekNumber(): void {
        this.editTemplatesManagementStore.decreaseWeekNumber();
    }

    public updateSearchActivityTypesValue(value: string): void {
        this.editTemplatesManagementStore.updateSearchActivityTypesValue(value);
    }

    public updateMaxWeeksValue(value: number): void {
        this.editTemplatesManagementStore.updateMaxWeeksValue(value);
    }

    public updateSearchCountersValue(value: string): void {
        this.editTemplatesManagementStore.updateSearchCountersValue(value);
    }

    public updateEditedTemplate(editedTemplate: ManageTemplate): void {
        this.editTemplatesManagementStore.updateMaxWeeksValue(editedTemplate.lengthInWeeks);
        this.manageTemplateStore.updateSelectedTemplateForEdit(editedTemplate);
        this.editTemplatesManagementStore.updateCurrentTemplateId(editedTemplate.id);
    }

    public updateCurrentTemplateId(templateId: number): void {
        this.editTemplatesManagementStore.updateCurrentTemplateId(templateId);
    }

    public updateSelectedActivityTimeslotId(timeslotId: number): void {
        this.editTemplatesManagementStore.updateSelectedActivityTimeslotId(timeslotId);
    }

    public updateErrorState(hasError: boolean): void {
        this.editTemplatesManagementStore.updateHasErrorState(hasError);
    }
}
