@if (loadingSubject | async) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

@if (!(error$ | async)) {
    @if (!(emptyMatrixSubject | async)) {
        <div class="slider__container">
            <span translate>Name column width</span>
            <mat-slider
                #ngSlider
                class="column-width-slider"
                [min]="150"
                [max]="300"
            >
                <input
                    #ngSliderThumbName="matSliderThumb"
                    matSliderThumb
                    [formControl]="nameColumnSliderControl"
                    (input)="onNameColumnWidthChange(ngSliderThumbName)"
                />
            </mat-slider>
            <span translate>Data column width</span>
            <mat-slider
                #ngSlider
                class="column-width-slider"
                [min]="dataColumnMin"
                [max]="200"
            >
                <input
                    #ngSliderThumbData="matSliderThumb"
                    matSliderThumb
                    [formControl]="dataColumnSliderControl"
                    (input)="onDataColumnWidthChange(ngSliderThumbData)"
                />
            </mat-slider>
            <span translate>Total table width</span>
            <mat-slider
                #ngSlider
                class="column-width-slider"
                [min]="25"
                [max]="100"
            >
                <input
                    #ngSliderThumbTotal="matSliderThumb"
                    matSliderThumb
                    [formControl]="totalTableSliderControl"
                    (input)="onTotalTableWidthChange(ngSliderThumbTotal)"
                />
            </mat-slider>
            <mat-checkbox
                class="bol-checkbox"
                [checked]="hideZeroValues"
                (change)="onUpdateHideZeroValues($event)"
            >
                <span>{{ 'Hide 0 values' | translate }}</span>
            </mat-checkbox>
        </div>
    }
    @if (emptyMatrixSubject | async) {
        <div class="empty__state__container">
            <div class="empty__state__container__content">
                <mat-icon svgIcon="chart-box"></mat-icon>
                <div class="empty__state__container__message__huge">
                    {{ 'No counters to show.' | translate }}
                </div>
                <div>
                    {{ 'Use the filters panel to create a counter overview.' | translate }}
                </div>
            </div>
        </div>
    }
    @if (!(emptyMatrixSubject | async)) {
        <div class="counters__table__container">
            <mat-table
                class="counters__table__content"
                [dataSource]="dataSource"
                [ngStyle]="{ 'min-width': totalTableWidth, width: totalTableWidth, 'max-width': totalTableWidth }"
            >
                <ng-container matColumnDef="name">
                    <mat-header-cell
                        *matHeaderCellDef
                        [ngStyle]="{ 'min-width': nameColumnWidth }"
                    >
                    </mat-header-cell>

                    <mat-cell
                        *matCellDef="let counter"
                        class="name-column-cell {{ counter.cssClassName }}"
                        [ngStyle]="{ 'min-width': nameColumnWidth }"
                    >
                        <button
                            mat-icon-button
                            [style.visibility]="!counter.expandable ? 'hidden' : ''"
                            [style.marginLeft.px]="counter.level * 16"
                            (click)="treeControl.toggle(counter)"
                        >
                            <mat-icon
                                [svgIcon]="treeControl.isExpanded(counter) ? 'chevron-down' : 'chevron-right'"
                            ></mat-icon>
                        </button>
                        {{ counter.name }}
                    </mat-cell>
                </ng-container>
                @for (column of columnDefinitions; track column.columnName; let i = $index) {
                    <ng-container [matColumnDef]="column.columnName">
                        <mat-header-cell
                            *matHeaderCellDef
                            [ngStyle]="{ 'min-width': dataColumnWidth, width: dataColumnWidth }"
                        >
                            {{ column.columnLabel }}
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let element"
                            class="{{ column.cssClassName === '' ? 'no-background' : column.cssClassName }}"
                            [ngStyle]="{ 'min-width': dataColumnWidth, width: dataColumnWidth }"
                        >
                            {{ element.data[i] }}
                        </mat-cell>
                    </ng-container>
                }
                <mat-header-row *matHeaderRowDef="columns; sticky: true"> </mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: columns"
                    [class.hidden]="hideZeroValues && row.hasZeroValue"
                >
                </mat-row>
                <tr
                    *matNoDataRow
                    class="bol-table-empty-state empty-table"
                >
                    @if (dataSource.data.length === 0) {
                        <td>{{ 'No counters to show.' | translate }}</td>
                    }
                </tr>
            </mat-table>
        </div>
    }
}

@if (error$ | async) {
    <div class="error-container">
        <div class="error-icon">
            <mat-icon svgIcon="alert-circle"></mat-icon>
        </div>
        <span translate>An error occurred.</span>
    </div>
}

