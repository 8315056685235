<div class="language-selector__container">
    <mat-button-toggle-group
        name="language"
        class="bol-button-toggle-group language-selector__toggle"
        [value]="currentLanguageCode$ | async"
    >
        @for (language of languages; track language.code) {
            <mat-button-toggle
                (click)="selectedLanguageChange(language)"
                [value]="language.code"
            >
                {{ language.name | uppercase }}
            </mat-button-toggle>
        }
    </mat-button-toggle-group>
</div>

