<mat-chip-listbox aria-label="Chip input">
    <mat-chip-option
        class="bol-chip-input"
        [selectable]="false"
        [matMenuTriggerFor]="menu"
    >
        <span class="bol-chip-input-content">
            {{ placeholder }}
            <mat-icon svgIcon="menu-down"></mat-icon>
        </span>
    </mat-chip-option>
</mat-chip-listbox>

<mat-menu
    #menu="matMenu"
    class="dropdown bol-menu"
>
    @for (item of items; track item.value) {
        <button
            mat-menu-item
            [value]="item.value"
            [disabled]="disabled"
            [ngClass]="{ selected: selectedItem && item.value === selectedItem.value }"
            (click)="onSelectItem(item)"
        >
            {{ item.label }}
        </button>
    }
    @if (items.length === 0) {
        <button
            mat-menu-item
            disabled="true"
            class="without-options"
        >
            {{ 'NO-ENTITIES-FOUND' | translate: { entityName: entityName | translate | lowercase } }}
        </button>
    }
</mat-menu>

