import { SharedModule } from 'src/app/shared/shared.module';

import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BolTimeSelectModule } from '@ortec/bolster/time-select';

import { SettingsRoutingModule } from './application-settings-routing';
import { ApplicationSettingsComponent } from './application-settings.component';
import {
    SettingsDaypartItemComponent
} from './components/settings-dayparts/settings-daypart-item/settings-daypart-item.component';
import {
    SettingsDaypartsComponent
} from './components/settings-dayparts/settings-dayparts.component';

@NgModule({
    declarations: [
        ApplicationSettingsComponent,
        SettingsDaypartsComponent,
        SettingsDaypartItemComponent
    ],
    imports: [
        CommonModule,
        BolTimeSelectModule,

        SettingsRoutingModule,
        SharedModule
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class ApplicationSettingsModule { }
