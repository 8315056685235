<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                @if (initialLoadingFinished$ | async) {
                    <app-entity-list-panel
                        entityName="Resource Properties"
                        [loadingError]="loadingError$ | async"
                        [mode]="manageMode$ | async"
                        [entities]="resourceProperties$ | async"
                        [searchProperties]="searchProperties"
                        [selectedEntityId]="selectedResourcePropertyId$ | async"
                        (selectedEntityIdChanged)="onSelectedResourcePropertyChange($event)"
                        (addEntityClicked)="onAddNewResourceProperty()"
                    ></app-entity-list-panel>
                }
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="Resource Property"
                    [mode]="manageMode$ | async"
                    [model]="selectedResourceProperty$ | async"
                    [uiEntity]="selectedUIResourceProperty$ | async"
                    [formFields]="formFields"
                    (editEntity)="onEditResourceProperty($event)"
                    (deleteEntity)="onDeleteResourceProperty($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>

@if ((initialLoadingFinished$ | async) === false || (showLoadingSpinner$ | async)) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

