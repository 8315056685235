@if ((initialLoadingFinished$ | async) === true) {
    <div class="adt-table">
        <div class="adt-table__header">
            <div class="adt-table__section-info">
                <div class="adt-table__header-bar">
                    <button 
                        mat-button 
                        class="bol-button-solid" 
                        (click)="onAddCounter()" 
                        data-cy="addCounterButton"
                    >
                        <mat-icon svgIcon="plus"></mat-icon>
                        <span translate>Add</span>
                    </button>

                    <mat-form-field class="bol-form-field adt-table__search">
                        <mat-label>{{ 'ACTIVITY-DEMAND.SEARCH-COUNTERS' | translate }}</mat-label>
                        <input
                            matInput
                            autocomplete="off"
                            [formControl]="searchTerm"
                        />
                    </mat-form-field>
                    
                    @if ((showCountersRefreshButton$ | async) === true) {
                        <button
                            mat-button
                            class="bol-button-text bol-accent"
                            (click)="onRefreshCounters()"
                            data-cy="refreshCountersButton"
                        >
                            <mat-icon svgIcon="refresh"></mat-icon>
                            <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.REFRESH-COUNTERS</span>
                        </button>
                    }
                </div>
                <div class="adt-table__header-bar bol-text-medium">
                    <div translate class="flex4">Counter</div>
                </div>
            </div>
            <div class="adt-table__section-week">
                <div class="adt-table__header-bar"></div>
                <div class="adt-table__header-bar bol-text-medium">
                    <div translate class="flex1">WEEK.MON</div>
                    <div translate class="flex1">WEEK.TUE</div>
                    <div translate class="flex1">WEEK.WED</div>
                    <div translate class="flex1">WEEK.THU</div>
                    <div translate class="flex1">WEEK.FRI</div>
                    <div translate class="flex1">WEEK.SAT</div>
                    <div translate class="flex1">WEEK.SUN</div>
                    <div translate class="flex1">TOTAL.WEEK</div>
                </div>
            </div>
            <div class="adt-table__section-total">
                <div class="adt-table__header-bar"></div>
                <div class="adt-table__header-bar bol-text-medium">
                    <div translate class="flex1">TOTAL.PERIOD</div>
                    <div class="flex1"></div>
                </div>
            </div>
        </div>
        @if ((calculatingCountersInProgress$ | async) === false) {
            @if (counterRows$ | async; as counterRows) {
                <div class="adt-table__content">
                    <div class="adt-table__section-info">
                        @for (counter of counterRows$ | async; track counter.id) {
                            <div class="adt-table__row">
                                <div class="flex4">{{ counter.activityDemandCounterInfo.displayName }}</div>
                            </div>
                        }
                    </div>
                    <div class="adt-table__section-week">
                        <!-- TODO: find a unique way to track currentWeekValues -->
                        @for (currentWeekValue of currentWeekValues$ | async; track currentWeekValue) {
                            <div class="adt-table__row">
                                @for (weekdayValue of currentWeekValue.weekdaysDemand; track weekdayValue.dayNumber) {
                                    <div class="flex1 adt-table__cell-input">
                                        {{ weekdayValue.amount }}
                                    </div>
                                }
                                <div class="flex1 adt-table__cell-input">
                                    {{ currentWeekValue.weekDemand ?? 0 }}
                                </div>
                            </div>
                        }
                    </div>
                    <div class="adt-table__section-total">
                        @for (counter of counterRows$ | async; track counter.id) {
                            <div class="adt-table__row">
                                <div class="flex1 adt-table__cell-input">
                                    {{ counter.periodDemandTotal ?? 0 }}
                                </div>
                                <div class="flex1 adt-table__cell-menu">
                                    <button
                                        mat-button
                                        class="bol-button-text bol-gray icon-only small-icon"
                                        [matMenuTriggerFor]="counterMenu.menu"
                                        [matMenuTriggerData]="{ counter }"
                                    >
                                        <mat-icon svgIcon="dots-vertical"></mat-icon>
                                    </button>
                                    <app-adt-counter-table-menu
                                        #counterMenu
                                        [activityDemandCounterId]="counter.activityDemandCounterInfo.id"
                                        [counterId]="counter.id"
                                        [templateId]="currentTemplateId"
                                    >
                                    </app-adt-counter-table-menu>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            @if ((counterRows$ | async)?.length === 0) {
                <div class="no-entities-message">
                    <p data-cy="entityNoEntitiesFound">
                        {{ 'NO-ENTITIES-FOUND' | translate: { entityName: 'counters' | translate | lowercase } }}
                    </p>
                </div>
            }
        }
    </div>
}
@if ((calculatingCountersInProgress$ | async) === true && (initialLoadingFinished$ | async) === true) {
    <div class="counters-loading-spinner">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
}
