import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';


export interface ResourcePropertiesManagementState {
    selectedResourcePropertyId: number;
    manageMode: MANAGE_MODE;
}

const initialState = {
    selectedResourcePropertyId: undefined,
    manageMode: undefined
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'resource-properties-management' })
export class ResourcePropertiesManagementStore extends Store<ResourcePropertiesManagementState> {
    constructor() {
        super(initialState);
    }

    public updateSelectedResourcePropertyId(id: number): void {
        this.update(state => ({ ...state, selectedResourcePropertyId: id }));
    }

    public updateManageMode(manageMode: MANAGE_MODE): void {
        this.update(state => ({ ...state, manageMode }));
    }

    public setStateForNewResourceProperty(id: number): void {
        this.update(state => (
            { 
                ...state, 
                selectedResourcePropertyId: id,
                manageMode: MANAGE_MODE.EDIT,  
            }));
    }
}
