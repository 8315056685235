<mat-card
    class="bol-card full-height"
    [ngClass]="cardClass"
>
    <mat-card-header class="bol-card-header mat-card-header-padding">
        <mat-card-title
            class="bol-card-title entity-title"
            translate
            data-cy="entityListTitle"
            >{{ entityName }}</mat-card-title
        >
        @if (!hideAddButton) {
            <button
                mat-button
                class="bol-button-solid bol-default"
                (click)="onAddEntityClicked()"
                data-cy="addEntityButton"
            >
                <span translate>Add</span>
            </button>
        }
    </mat-card-header>

    <mat-card-content class="bol-card-content">
        @if (!loadingError) {
            @if (dataSource?.data?.length > 0 && dataSource?.filteredData?.length !== 0) {
                <table
                    mat-table
                    class="bol-table bol-table-striped-even bol-table-row-hover"
                    [dataSource]="dataSource"
                >
                    @for (column of columnDefinition; track $index) {
                        <ng-container [matColumnDef]="column.entityProperty">
                            <th
                                *matHeaderCellDef
                                mat-header-cell
                            >
                                <span translate>{{ column.columnDisplayName }}</span>
                            </th>
                            <td
                                *matCellDef="let row; let i = index"
                                mat-cell
                            >
                                @if (row.isLoading && row.id !== selectedEntityId) {
                                    <mat-spinner
                                        class="row-loading-spinner"
                                        diameter="16"
                                    >
                                    </mat-spinner>
                                }
                                <div class="entity-name">{{ row.displayName }}</div>
                                <div class="sorting-buttons">
                                    <button
                                        mat-button
                                        class="bol-button-text icon-only"
                                        (click)="onMoveEntityUp(row, i)"
                                    >
                                        <mat-icon svgIcon="arrow-up"></mat-icon>
                                    </button>
                                    <button
                                        mat-button
                                        class="bol-button-text icon-only"
                                        (click)="onMoveEntityDown(row, i)"
                                    >
                                        <mat-icon svgIcon="arrow-down"></mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                    }
                    <tr
                        *matHeaderRowDef="displayedColumns"
                        mat-header-row
                    ></tr>
                    <tr
                        *matRowDef="let row; columns: displayedColumns"
                        mat-row
                        [class.selected]="row.id === selectedEntityId"
                        (click)="onEntitySelected(row.id)"
                    ></tr>
                </table>
            }
            <mat-paginator
                showFirstLastButtons
                aria-label="Select page"
                [ngClass]="{ hidden: this.dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0 }"
                [pageSize]="10"
                [hidePageSize]="true"
            ></mat-paginator>
        }

        @if (!loadingError && (this.dataSource?.data?.length === 0 || dataSource?.filteredData?.length === 0)) {
            <p data-cy="entityNoEntitiesFound">
                {{ 'NO-ENTITIES-FOUND' | translate: { entityName: entityName | translate | lowercase } }}
            </p>
        }

        @if (loadingError) {
            <h4
                translate
                data-cy="entityLoadingError"
            >
                ERROR.LOADING-ENTITIES-ERROR
            </h4>
        }
    </mat-card-content>
</mat-card>

