<full-calendar
    #calendar
    [ngClass]="{ 'hide-date-one-day-view': oneDayViewMode }"
    [options]="calendarOptions$ | async"
>
</full-calendar>

@if (isLoadingSchedule$ | async) {
    <div class="overlay-spinner-container">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}
