/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, Injector, LOCALE_ID, NgModule, NgZone } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BolTimeSelectConfigurationModule } from '@ortec/bolster/time-select';
import { BolLuxonAdapterModule } from '@ortec/bolster-luxon-adapter';
import { BolDurationInputModule } from '@ortec/bolster/duration-input';
import { provideAngularMaterialDefaults } from '@ortec/bolster/utils';
import { AuthOptions } from 'auth0-js';

import { environment } from 'src/environments/environment';
import { AuthenticationHttpInterceptor, BaseUrlInterceptor, TabLogoutInterceptor, UnauthorizedInterceptor } from './shared/http-interceptors';
import { AUTHENTICATION_SERVICE_OPTIONS, AuthenticationMode, AuthenticationService, BroadcastTypes, IDENTITY_DATA_SERVICE_OPTIONS, IdentityDataServiceOptions } from './shared/services/authentication';
import { BroadcastService } from './shared/services';
import { emailValidator, emailValidatorKey, fieldNonEmptyValidator, fieldNonEmptyValidatorKey } from './shared/validators';
import { DEFAULT_COLUMN_OPTIONS, EntityListColumnDefinition } from './shared/models';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { ApplicationSettingsModule } from './features/application-settings/application-settings.module';
import { AuthorizeComponent } from './features/login/authorize.component';
import { OverviewsModule } from './features/overviews/overviews.module';
import { OwsInterfaceModule } from './features/ows-interface/ows-interface.module';
import { ProgramManagementModule } from './features/program-management/program-management.module';
import { FormlyEntityListDropdownComponent } from './shared/formly-components/formly-entity-list-dropdown/formly-entity-list-dropdown.component';
import { FormlyMultiSelectTreeComponent } from './shared/formly-components/formly-multiselect-tree/formly-multiselect-tree.component';
import { FormlyMultiSelectComponent } from './shared/formly-components/formly-multiselect/formly-multiselect.component';
import { FormlyPermissionsTreeComponent } from './shared/formly-components/formly-permissions-tree/formly-permissions-tree.component';
import { FormlyTreeSingleSelectComponent } from './shared/formly-components/formly-tree-single-select/formly-tree-single-select.component';
import { LanguageService } from './shared/language';
import { FormlyTranslateService } from './shared/language/formly-translate.service';
import { CustomMatPaginatorIntl } from './shared/language/mat-paginator-translate.service';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from './shared/services/custom-date-adapter';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { SharedModule } from './shared/shared.module';
import { PlanboardModule } from './features/planboard/planboard.module';
import { ActivityDemandModule } from './features/activity-demand/activity-demand.module';
import { FormlyBolsterInput } from './shared/formly-components/formly-bolster-input/formly-bolster-input.component';
import { FormlyFieldInfoBlock } from './shared/formly-components/formly-field-info-block/formly-field-info-block.component';
import { dataCyExtension } from './shared/formly-components/utils/data-cy.extension';
import { registerTranslateExtension } from './shared/formly-components/utils/formly-translate.extension';
import { OmrpTranslateLoader } from './shared/language/translate-loader';

registerLocaleData(localeNl, 'nl');

export interface ApiServiceOptions {
    apiUrl: string;
}

export const API_SERVICE_OPTIONS = new InjectionToken<ApiServiceOptions>('apiServiceOptions');

const apiServiceOptions: ApiServiceOptions = {
    apiUrl: environment.omrpApiUrl,
};

const authenticationServiceOptions: AuthOptions = {
    clientID: environment.auth0Constants.clientId,
    domain: environment.auth0Constants.domain,
    responseType: environment.auth0Constants.responseType,
    redirectUri: environment.auth0Constants.redirectUri,
    scope: environment.auth0Constants.scope
};

const identityDataServiceOptions: IdentityDataServiceOptions = {
    apiUrl: environment.omrpApiUrl,
    useLocalLogin: environment.authMode !== AuthenticationMode.Auth0,
    broadcastLogoutMessage: true
};

const columnDefinitionOptions: EntityListColumnDefinition = {
    columnDisplayName: 'Display name',
    entityProperty: 'displayName'
};

const broadcastServiceFactory = (zone: NgZone, _, injector: Injector) => {
    const callbackLogout = () => injector.get(AuthenticationService).logoutTab();
    const callbacksPerMessageType = {};
    callbacksPerMessageType[BroadcastTypes.Logout] = callbackLogout;

    return new BroadcastService(callbacksPerMessageType, zone);
};

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({ 
    declarations: [
        AppComponent,
        AuthorizeComponent,
        EmptyRouteComponent
    ],
    bootstrap: [AppComponent], 
    imports: [CoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: OmrpTranslateLoader, deps: [HttpClient] }
        }),
        (environment.production || environment.acceptance) ? [] : AkitaNgDevtools.forRoot(),
        MatProgressSpinnerModule,
        MatIconModule,
        FormlyModule.forRoot({
            types: [
                { name: 'multiselect-tree', component: FormlyMultiSelectTreeComponent },
                { name: 'multiselect', component: FormlyMultiSelectComponent },
                { name: 'tree-single-select', component: FormlyTreeSingleSelectComponent },
                { name: 'permissions-tree', component: FormlyPermissionsTreeComponent },
                { name: 'entity-list-dropdown', component: FormlyEntityListDropdownComponent },
                { name: 'bolster-input', component: FormlyBolsterInput },
                { name: 'info-block', component: FormlyFieldInfoBlock },
            ],
            validators: [
                { name: emailValidatorKey, validation: emailValidator },
                { name: fieldNonEmptyValidatorKey, validation: fieldNonEmptyValidator }
            ],
            extensions: [
                { name: 'data-cy-extension', extension: dataCyExtension }
            ]
        }),
        FormlyMaterialModule,
        BolLuxonAdapterModule.forRoot(),
        BolTimeSelectConfigurationModule.forRoot(),
        BolDurationInputModule.forRoot(),
        SharedModule,
        AppRoutingModule,
        ActivityDemandModule,
        OwsInterfaceModule,
        OverviewsModule,
        PlanboardModule,
        ApplicationSettingsModule,
        ProgramManagementModule
    ], 
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: setupTranslateFactory,
            deps: [LanguageService], multi: true
        },
        {
            provide: API_SERVICE_OPTIONS,
            useValue: apiServiceOptions
        },
        {
            provide: AUTHENTICATION_SERVICE_OPTIONS,
            useValue: authenticationServiceOptions
        },
        {
            provide: IDENTITY_DATA_SERVICE_OPTIONS,
            useValue: identityDataServiceOptions
        },
        {
            provide: DEFAULT_COLUMN_OPTIONS,
            useValue: columnDefinitionOptions
        },
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: BaseUrlInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: AuthenticationHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: UnauthorizedInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: TabLogoutInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        },
        {
            provide: LOCALE_ID,
            useFactory: (languageService: LanguageService) => languageService.getCurrentLanguage(),
            deps: [LanguageService]
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMATS
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
        {
            provide: FORMLY_CONFIG,
            multi: true,
            useFactory: registerTranslateExtension,
            deps: [TranslateService]
        },
        {
            provide: BroadcastService,
            useFactory: broadcastServiceFactory,
            deps: [NgZone, Router, Injector]
        },
        AuthenticationService,
        provideHttpClient(withInterceptorsFromDi()),
        provideAngularMaterialDefaults()
    ] })
export class AppModule {
    constructor(
        formlyTranslateService: FormlyTranslateService,
        matIconRegistry: MatIconRegistry, 
        domSanitizer: DomSanitizer
    ) {
        formlyTranslateService.registerFormlyTranslations();
        matIconRegistry.addSvgIconSet(
            domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
        );
    }
}

export function setupTranslateFactory(languageService: LanguageService): () => void {
    return () => languageService.initialize();
}
