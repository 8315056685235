<mat-form-field
    #matFormField
    floatLabel="{{ floatLabel ? 'always' : 'auto' }}"
    class="bol-form-field select-form-field"
    [ngClass]="{
        'select-form-field-underline':
            (entitySelectControl.valid || !entitySelectControl.touched) && removeUnderlinePadding,
    }"
>
    <mat-label translate>{{ singleSelectTablePlaceholder }}</mat-label>
    <mat-select
        #matSelect
        ngDefaultControl
        matInput
        multiple
        placeholder="{{ placeholderText }}"
        panelClass="select-panel"
        [required]="required"
        [formControl]="entitySelectControl"
    >
        @if (dataSource?.data?.length > 0) {
            <mat-form-field class="bol-form-field entity-search">
                <input
                    #input
                    matInput
                    placeholder="{{ searchFieldPlaceholder | translate }}"
                    autocomplete="off"
                    [formControl]="searchControl"
                    data-cy="SearchSingleSelectTable"
                />
                @if (!searchControl || !searchControl.value || searchControl.value.length === 0) {
                    <button
                        matSuffix
                        mat-button
                        disabled
                        class="bol-button-text"
                    >
                        <mat-icon
                            svgIcon="magnify"
                            class="search-icon"
                        ></mat-icon>
                    </button>
                }
                @if (searchControl && searchControl.value && searchControl.value.length > 0) {
                    <button
                        matSuffix
                        mat-button
                        class="cancel-button bol-button-text icon-only bol-gray"
                        (click)="onResetValue()"
                    >
                        <mat-icon
                            svgIcon="close"
                            class="search-icon"
                        ></mat-icon>
                    </button>
                }
            </mat-form-field>
        }

        <div class="table-container">
            @if (dataSource?.data?.length > 0) {
                <mat-table
                    matSort
                    matSortDisableClear
                    class="bol-table bol-table-row-hover"
                    [dataSource]="dataSource"
                    [ngClass]="{ 'striped-table': stripedTable }"
                >
                    @for (column of columnDefinition; track $index; let isFirst = $first) {
                        <ng-container [matColumnDef]="column.entityProperty">
                            <mat-header-cell
                                *matHeaderCellDef
                                mat-sort-header
                            >
                                <span translate>{{ column.columnDisplayName }}</span>
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let row"
                                [ngStyle]="column.stylingProperty ? row[column.stylingProperty] : ''"
                            >
                                <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                                {{ column.cell ? column.cell(row) : row[column.entityProperty] }}
                            </mat-cell>
                        </ng-container>
                    }
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        mat-row
                        [class.selected]="row.id === internalSelectedEntityId"
                        (click)="onEntitySelected(row)"
                    >
                    </mat-row>
                    @if (!dataSource?.data?.length) {
                        <tr
                            matNoDataRow
                            class="bol-table-empty-state"
                        >
                            <td [attr.colspan]="displayedColumns.length">
                                {{
                                    'NO-ENTITIES-FOUND' | translate: { entityName: entityName | translate | lowercase }
                                }}
                            </td>
                        </tr>
                    }
                </mat-table>
            }
        </div>
        <mat-option
            class="hide-option"
            disabled
        ></mat-option>

        @if (dataSource?.data?.length === 0) {
            <div class="no-results-founds">
                {{ 'NO-ENTITIES-FOUND' | translate: { entityName: entityName | translate | lowercase } }}
            </div>
        }
    </mat-select>

    @if (entitySelectControl.invalid) {
        <mat-error translate>{{ errorMessage }}</mat-error>
    }
</mat-form-field>

