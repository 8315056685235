<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <app-entity-filter-panel [cardClass]="'top-card'">
                <app-users-filters></app-users-filters>
            </app-entity-filter-panel>

            <div class="flex-growing-child">
                @if (initialLoadingFinished$ | async) {
                    <app-entity-list-panel
                        entityName="Users"
                        [entities]="filteredUsers$ | async"
                        [loadingError]="loadingError$ | async"
                        [mode]="manageMode$ | async"
                        [selectedEntityId]="selectedUserId$ | async"
                        [searchProperties]="searchProperties"
                        [cardClass]="'bottom-card'"
                        (selectedEntityIdChanged)="onSelectedUserChange($event)"
                        (addEntityClicked)="onAddEntityClicked()"
                    ></app-entity-list-panel>
                }
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-manage-panel
                    entityName="User"
                    [mode]="manageMode$ | async"
                    [model]="selectedUser$ | async"
                    [uiEntity]="selectedUIUser$ | async"
                    [formFields]="formFields"
                    [canDelete]="canDeleteSelectedEntity$ | async"
                    [message]="entityManageMessage$ | async"
                    [messageStyleClass]="'warning'"
                    (cancelAdd)="onCancelAdd()"
                    (deleteEntity)="onDeleteUser($event)"
                    (addNewEntity)="onAddNewUser($event)"
                    (editEntity)="onEditUser($event)"
                ></app-entity-manage-panel>
            </div>
        </div>
    </div>
</div>

@if ((initialLoadingFinished$ | async) === false || (showLoadingSpinner$ | async)) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

