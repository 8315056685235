<div class="filters-header">
    @if ((filterSettings$ | async) && (filterSettings$ | async).length > 0) {
        <div class="filters-field">
            <mat-form-field class="bol-form-field">
                <mat-label>{{ 'Filters' | translate }}</mat-label>
                <mat-select
                    #filterSettingSelect
                    required
                    [formControl]="filterSettingControl"
                    (openedChange)="openedFilterSettingSelect($event)"
                >
                    @for (filterSetting of filterSettings$ | async; track filterSetting.id) {
                        <mat-option [value]="filterSetting.id">
                            {{ filterSetting.displayName }}
                        </mat-option>
                    }
                </mat-select>
                @if (filterSettingControl.invalid) {
                    <mat-error translate>Field is required</mat-error>
                }
            </mat-form-field>
        </div>
    }

    @if (!(filterSettings$ | async).length) {
        <div
            class="highlight-summary"
            translate
        >
            No filter selected
        </div>
    }

    @if (selectedGroupingOptionType$ | async; as selectedOption) {
        <div class="grouping-option">
            <div class="highlight-summary">{{ 'Grouping option' | translate }}</div>
            &nbsp;&nbsp;&nbsp;{{ selectedOption | translate }}
        </div>
    }
</div>

