<mat-expansion-panel
    class="filter-panel"
    [ngClass]="cardClass"
    [expanded]="true"
    [hideToggle]="disableCollapse"
    [disabled]="disableCollapse"
>
    <mat-expansion-panel-header data-cy="filterPanelCollapse">
        <mat-panel-description>
            <h3 translate>Filters</h3>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
</mat-expansion-panel>
