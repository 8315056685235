import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { FormlyModule } from '@ngx-formly/core';
import { BolDatepickerModule } from '@ortec/bolster/datepicker';
import { BolPopoverModule } from '@ortec/bolster/popover';

import { EmptyFiltersPanelComponent } from './components/empty-filters-panel/empty-filters-panel.component';
import {
    FilterSettingConfigurationDialogComponent,
} from './components/filter-setting-configuration/components/filter-setting-configuration-dialog/filter-setting-configuration-dialog.component';
import {
    FilterSettingSaveDialogComponent,
} from './components/filter-setting-configuration/components/filter-setting-save-dialog/filter-setting-save-dialog.component';
import {
    FilterSettingConfigurationComponent,
} from './components/filter-setting-configuration/filter-setting-configuration.component';
import { EntityFilterPanelComponent } from './components/entity-management/entity-filter-panel/entity-filter-panel.component';
import { NoPermissionsComponent } from './components/no-permissions/no-permissions.component';
import { EntityListPanelComponent } from './components/entity-management/entity-list-panel/entity-list-panel.component';
import { EntityManagePanelComponent } from './components/entity-management/entity-manage-panel/entity-manage-panel.component';
import { FormlyMultiSelectTreeComponent } from './formly-components/formly-multiselect-tree/formly-multiselect-tree.component';
import { FilterChipListComponent } from './components/inputs/filter-chip-list/filter-chip-list.component';
import { FilterTreeSingleSelectComponent } from './components/inputs/filter-tree-single-select/filter-tree-single-select.component';
import { FormlyMultiSelectComponent } from './formly-components/formly-multiselect/formly-multiselect.component';
import { PermissionsTreeComponent } from './components/inputs/permissions-tree/permissions-tree.component';
import { EntityTreeListPanelComponent } from './components/entity-management/entity-tree-list-panel/entity-tree-list-panel.component';
import { FormlyTreeSingleSelectComponent } from './formly-components/formly-tree-single-select/formly-tree-single-select.component';
import { FormlyPermissionsTreeComponent } from './formly-components/formly-permissions-tree/formly-permissions-tree.component';
import { PermissionsTreePropagationDialogComponent } from './components/inputs/permissions-tree/components/permissions-tree-propagation-dialog/permissions-tree-propagation-dialog.component';
import { EntitySortableListPanelComponent } from './components/entity-management/entity-sortable-list-panel/entity-sortable-list-panel.component';
import { FormlyEntityListDropdownComponent } from './formly-components/formly-entity-list-dropdown/formly-entity-list-dropdown.component';
import { ActivityTypeSelectionOldComponent } from './components/activity-type-selection-old/activity-type-selection.component';
import { ActivityTypeDialogOldComponent } from './components/activity-type-selection-old/components/activity-type-dialog/activity-type-dialog.component';
import { ActivityTypeSelectionComponent } from './components/activity-type-selection/activity-type-selection.component';
import { ActivityTypeDialogComponent } from './components/activity-type-selection/components/activity-type-dialog/activity-type-dialog.component';
import { ResourceSelectionComponent } from './components/resource-selection/resource-selection.component';
import { ResourceDialogComponent } from './components/resource-selection/components/resource-dialog.component';
import { ActivityTypeSelectionNewComponent } from './components/activity-type-selection-new/activity-type-selection.component';
import { ActivityTypeDialogNewComponent } from './components/activity-type-selection-new/components/activity-type-dialog.component';
import { MultiselectSortingTableComponent } from './components/inputs/multiselect-sorting-table/multiselect-sorting-table.component';
import { SortSelectionDialogComponent } from './components/inputs/multiselect-sorting-table/sort-selection-dialog/sort-selection-dialog.component';
import { ActivityTypeSortingComponent } from './components/activity-type-sorting/activity-type-sorting.component';
import { ActivityTypeSortingDialogComponent } from './components/activity-type-sorting/components/activity-type-sorting-dialog.component';
import { EntityManageGridComponent } from './components/entity-management/entity-manage-grid/entity-manage-grid.component';
import { EntityManageDialogComponent } from './components/entity-management/entity-manage-dialog/entity-manage-dialog.component';
import { BolSelectComponent } from '@ortec/bolster/select';
import { MultiselectTableComponent } from './components/inputs/multiselect-table/multiselect-table.component';
import { FormlyBolsterInput } from './formly-components/formly-bolster-input/formly-bolster-input.component';
import { MultiselectTreeComponent } from './components/inputs/multiselect-tree/multiselect-tree.component';
import { TestBorderComponent } from './components/core/test-border/test-border.component';
import { FormlyFieldInfoBlock } from './formly-components/formly-field-info-block/formly-field-info-block.component';
import { MenuCardComponent } from './components/core/menu-card/menu-card.component';
import { SlimDropdownComponent } from './components/inputs/slim-dropdown/slim-dropdown.component';

@NgModule({
    declarations: [
        FilterChipListComponent,
        NoPermissionsComponent,
        EntityFilterPanelComponent,
        EntityListPanelComponent,
        EntitySortableListPanelComponent,
        EntityManagePanelComponent,
        EntityTreeListPanelComponent,
        EntityManageGridComponent,
        EntityManageDialogComponent,
        MultiselectSortingTableComponent,
        MultiselectTableComponent,
        ActivityTypeSortingComponent,
        ActivityTypeSortingDialogComponent,
        SortSelectionDialogComponent,
        ActivityTypeDialogOldComponent,
        ActivityTypeSelectionOldComponent,
        ActivityTypeSelectionNewComponent,
        ActivityTypeDialogNewComponent,
        ActivityTypeDialogComponent,
        ActivityTypeSelectionComponent,
        ResourceSelectionComponent,
        ResourceDialogComponent,
        EmptyFiltersPanelComponent,
        FilterSettingConfigurationComponent,
        FilterSettingConfigurationDialogComponent,
        FilterSettingSaveDialogComponent,
        FilterTreeSingleSelectComponent,
        PermissionsTreeComponent,
        PermissionsTreePropagationDialogComponent,
        FormlyMultiSelectTreeComponent,
        FormlyMultiSelectComponent,
        FormlyTreeSingleSelectComponent,
        FormlyPermissionsTreeComponent,
        FormlyEntityListDropdownComponent,
        FormlyFieldInfoBlock,
        FormlyBolsterInput,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FormlyModule.forRoot({
            extras: {
              checkExpressionOn: 'changeDetectionCheck',
              lazyRender: false,
              resetFieldOnHide: false
            }
        }),

        BolDatepickerModule,
        BolPopoverModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatMenuModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTreeModule,
        BolSelectComponent,
        MultiselectTreeComponent,
        TestBorderComponent,
        MenuCardComponent,
        SlimDropdownComponent
    ],
    exports: [
        FilterChipListComponent,
        EntityFilterPanelComponent,
        ActivityTypeSortingComponent,
        EntityListPanelComponent,
        EntitySortableListPanelComponent,
        EntityManagePanelComponent,
        EntityManageGridComponent,
        EntityManageDialogComponent,
        EntityTreeListPanelComponent,
        ActivityTypeSelectionOldComponent,
        ActivityTypeSelectionComponent,
        ActivityTypeSelectionNewComponent,
        MultiselectSortingTableComponent,
        MultiselectTableComponent,
        ResourceSelectionComponent,
        EmptyFiltersPanelComponent,
        FilterSettingConfigurationComponent,
        FilterSettingConfigurationDialogComponent,
        FilterSettingSaveDialogComponent,
        FilterTreeSingleSelectComponent,

        PermissionsTreeComponent,
        PermissionsTreePropagationDialogComponent,
        FormlyMultiSelectTreeComponent,
        FormlyMultiSelectComponent,
        FormlyTreeSingleSelectComponent,
        FormlyPermissionsTreeComponent,
        FormlyEntityListDropdownComponent,
        FormlyFieldInfoBlock,
        FormlyBolsterInput,
        BolSelectComponent,
        TestBorderComponent,
        MenuCardComponent,
        SlimDropdownComponent,
        
        FormsModule,
        ReactiveFormsModule,

        TranslateModule,

        BolDatepickerModule,
        BolPopoverModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatMenuModule,
        MatOptionModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatTreeModule,
        MatProgressSpinnerModule,
    ]
})
export class SharedModule { }
