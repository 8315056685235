import { InjectionToken } from "@angular/core";

export interface EntityListColumnDefinition {
    columnDisplayName: string;
    entityProperty: string;
    stylingProperty?: string;
    header?: string;
    cell?: (element: any) => any;
    showInfoIcon?: boolean;
}

export interface IManageEntitiesGridColumn {
    columnDef: string;
    header: string;
    stylingProperty?: string;
    enableSort?: boolean;
    hidden?: boolean;
    cell: (element: any) => any;
    class?: (element: any) => string;
    sort?: { direction: 'asc' | 'desc' };
    sortValue?: (element: any) => any;
    hideSearch?: boolean;
}

export interface ColumnEntity {
    id: number;
    level: number;
}

export interface SlimDropdownItem<T> {
    label: string;
    value: T;
}

export interface SelectionClickEvent {
    selected: boolean;
    entity: any;
}

export interface SelectionCheckBoxClickEvent {
    selected?: boolean;
    selectedAll?: boolean;
    entityIds: Array<number | string>;
}

export const defaultColumnDefinition: Array<EntityListColumnDefinition> = [
    {
        columnDisplayName: 'Display name',
        entityProperty: 'displayName'
    }
];

export const DEFAULT_COLUMN_OPTIONS = new InjectionToken<EntityListColumnDefinition>('columnDefinitionOptions');
