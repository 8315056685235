import { OperatorFunction, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export function filterUndefined<T>(): OperatorFunction<T | undefined, T> {
    return (input$: Observable<T | undefined>) => input$.pipe(
        filter((value) => value !== undefined),
        // tslint:disable-next-line: no-non-null-assertion
        map((value) => value!)
    );
}
