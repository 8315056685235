@if ((initialLoadingFinished$ | async) === true) {
    <div class="adt-table">
        <div class="adt-table__header">
            <div class="adt-table__section-info">
                <div class="adt-table__header-bar">
                    <button
                        mat-button
                        class="bol-button-solid"
                        (click)="onAddActivityTypeClicked()"
                    >
                        <mat-icon svgIcon="plus"></mat-icon>
                        <span translate>ACTION.ADD</span>
                    </button>
                    <mat-form-field class="bol-form-field adt-table__search">
                        <mat-label>{{ 'ACTIVITY-DEMAND.SEARCH-ACTIVITIES' | translate }}</mat-label>
                        <input
                            matInput
                            autocomplete="off"
                            [formControl]="searchTerm"
                        />
                    </mat-form-field>
                </div>
                <div class="adt-table__header-bar bol-text-medium">
                    <div
                        translate
                        class="flex2"
                    >
                        ENTITY.ACTIVITY-TYPE
                    </div>
                    <div
                        translate
                        class="flex1"
                    >
                        COMMON.START-TIME
                    </div>
                    <div
                        translate
                        class="flex1"
                    >
                        COMMON.END-TIME
                    </div>
                    <div
                        translate
                        class="flex1 distribtion-cell"
                    >
                        ACTIVITY-DEMAND.DISTRIBUTION-TOTAL
                    </div>
                </div>
            </div>
            <div class="adt-table__section-week">
                <div class="adt-table__header-bar">
                    @if ((maxWeeks$ | async) > 1) {
                        <div class="adt-table__week-wrapper">
                            <div class="adt-table__week-navigation">
                                <button
                                    mat-button
                                    class="bol-button-text bol-gray icon-only"
                                    [disabled]="(currentWeek$ | async) === 1"
                                    (click)="onNavigateToPastWeek()"
                                >
                                    <mat-icon svgIcon="chevron-left"></mat-icon>
                                </button>
                                {{ 'TIMEDURATION.WEEK' | translate }} {{ currentWeek$ | async }} /
                                {{ maxWeeks$ | async }}
                                <button
                                    mat-button
                                    class="bol-button-text bol-gray icon-only"
                                    [disabled]="(currentWeek$ | async) === (maxWeeks$ | async)"
                                    (click)="onNavigateToNextWeek()"
                                >
                                    <mat-icon svgIcon="chevron-right"></mat-icon>
                                </button>
                            </div>
                            <button
                                mat-button
                                class="bol-button-solid"
                                (click)="onCopyWeeks()"
                            >
                                <mat-icon svgIcon="content-copy"></mat-icon>
                                <span translate>ACTIVITY-DEMAND.EDIT-TEMPLATES.COPY-WEEKS</span>
                            </button>
                        </div>
                    }
                </div>
                <div class="adt-table__header-bar bol-text-medium">
                    <div
                        translate
                        class="flex1"
                    >
                        WEEK.MON
                    </div>
                    <div
                        translate
                        class="flex1"
                    >
                        WEEK.TUE
                    </div>
                    <div
                        translate
                        class="flex1"
                    >
                        WEEK.WED
                    </div>
                    <div
                        translate
                        class="flex1"
                    >
                        WEEK.THU
                    </div>
                    <div
                        translate
                        class="flex1"
                    >
                        WEEK.FRI
                    </div>
                    <div
                        translate
                        class="flex1"
                    >
                        WEEK.SAT
                    </div>
                    <div
                        translate
                        class="flex1"
                    >
                        WEEK.SUN
                    </div>
                    <div
                        translate
                        class="flex1"
                    >
                        TOTAL.WEEK
                    </div>
                </div>
            </div>
            <div class="adt-table__section-total">
                <div class="adt-table__header-bar"></div>
                <div class="adt-table__header-bar bol-text-medium">
                    <div
                        translate
                        class="flex1"
                    >
                        TOTAL.PERIOD
                    </div>
                    <div class="flex1"></div>
                </div>
            </div>
        </div>
        <form [formGroup]="formArray">
            @if (activityTypeTimeslotsRows$ | async; as activityTypeTimeslotsRows) {
                <div class="adt-table__content">
                    <div class="adt-table__section-info">
                        @for (group of formArray.controls; track group.value.id; let i = $index) {
                            <div
                                [formGroupName]="i"
                                class="adt-table__row"
                            >
                                <div class="flex2">
                                    {{ activityTypeTimeslotsRows[i].displayName }}
                                </div>
                                <div class="flex1">
                                    {{ activityTypeTimeslotsRows[i].startTime }}
                                </div>
                                <div class="flex1">
                                    {{ activityTypeTimeslotsRows[i].endTime }}
                                </div>
                                <div class="flex1 distribtion-cell">
                                    <input
                                        formControlName="distributionTotal"
                                        type="number"
                                        min="0"
                                        max="9999"
                                        class="adt-input"
                                        [class.invalid-input]="!group.get('distributionTotal').valid"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div class="adt-table__section-week">
                        @for (group of formArray.controls; track group.value.id; let i = $index) {
                            <div
                                class="adt-table__row"
                                [formGroupName]="i"
                            >
                                @for (control of group.get('weekDemand')['controls']; track $index; let j = $index) {
                                    <div
                                        class="flex1 adt-table__cell-input"
                                        formArrayName="weekDemand"
                                    >
                                        <input
                                            type="number"
                                            min="0"
                                            max="99"
                                            class="adt-input"
                                            [formControlName]="j"
                                            [class.invalid-input]="!control.valid"
                                        />
                                    </div>
                                }
                                <div class="flex1 adt-table__cell-input">
                                    {{ weeklyTotals[i] }}
                                </div>
                            </div>
                        }
                    </div>
                    <div class="adt-table__section-total">
                        @for (group of formArray.controls; track group.value.id; let i = $index) {
                            <div class="adt-table__row">
                                <div class="flex1 adt-table__cell-input">
                                    {{ periodTotal[i] }}
                                </div>
                                <div class="flex1 adt-table__cell-menu">
                                    <button
                                        mat-button
                                        class="bol-button-text bol-gray icon-only small-icon"
                                        [matMenuTriggerFor]="activityMenu.menu"
                                        [matMenuTriggerData]="{ activity: activityTypeTimeslotsRows[i] }"
                                    >
                                        <mat-icon svgIcon="dots-vertical"></mat-icon>
                                    </button>
                                    <app-adt-activity-type-table-menu
                                        #activityMenu
                                        [activityTypeTimeslot]="activityTypeTimeslotsRows[i]"
                                    >
                                    </app-adt-activity-type-table-menu>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </form>
        @if ((activityTypeTimeslotsRows$ | async)?.length === 0) {
            <div class="no-entities-message">
                <p data-cy="entityNoEntitiesFound">
                    {{ 'NO-ENTITIES-FOUND' | translate: { entityName: 'activity types' | translate | lowercase } }}
                </p>
            </div>
        }
    </div>
}

@if ((autoSaveLoadingStateSubject | async) === true && (navigateToWeekLoadingStateSubject | async) === true) {
    <div class="overlay-spinner">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}

