import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from './dialogs/dialog-service.service';

export const InternalServerErrorWithNotSpecifiedCase = 'InternalServerError.NotSpecified';

@Injectable({
    providedIn: 'root'
})
export class ErrorDialogService {
    private readonly dialogService = inject(DialogService);
    private readonly translateService = inject(TranslateService);

    public showErrorDialog(translatedErrorMessage: string, errorCode?: string): void {
        const errorTitle = this.translateService.instant('Error');
        if (errorCode) {
            const code = this.extractStatusCodeFromStatusText(errorCode);
            translatedErrorMessage += ' ' + this.translateService.instant(code);
        } else if (!translatedErrorMessage) {
            translatedErrorMessage = this.translateService.instant('ERROR.UNKNOWN-ERROR');
        }  
        
        this.dialogService.showInfoDialog(errorTitle, translatedErrorMessage, 'OK', 'bol-button-text bol-gray', 'error', 'dialog-medium', true);
    }

    public showErrorDialogV1(errorCode: string, statusText?: string): void {
        const errorTitle = this.translateService.instant('Error');
        let translatedErrorMessage = errorCode ? this.translateService.instant(errorCode) : 'ERROR.UNKNOWN-ERROR';
    
        if (errorCode.endsWith(InternalServerErrorWithNotSpecifiedCase)) {
            translatedErrorMessage += ' ' + statusText;
        }
    
        this.dialogService.showInfoDialog(errorTitle, translatedErrorMessage, 'OK', 'bol-button-text bol-gray', 'error', 'dialog-medium', true);
    }

    private extractStatusCodeFromStatusText(statusText: string): string {
        if (statusText !== null && statusText !== undefined && statusText.length >= 4) {
            return statusText.substring(0, 5); // status code is F + the 4 letter code in the statusText
        }

        return statusText;
    }
}
