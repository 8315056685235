<mat-form-field class="bol-form-field">
    <mat-label translate>{{ labelText }}</mat-label>
    <mat-select
        #matSelect
        ngDefaultControl
        name="entitySelect"
        panelClass="bol-select"
        [disabled]="disabled"
        [required]="required"
        [ngModel]="selectedEntity?.displayName"
        [attr.data-cy]="dataCyLabel ? dataCyLabel : 'treeSingleSelectTitle'"
    >
        <mat-tree
            class="tree"
            [dataSource]="dataSource"
            [treeControl]="treeControl"
        >
            <mat-tree-node
                *matTreeNodeDef="let node"
                matTreeNodeToggle
                [class.tree-invisible]="!node.visible || node.id === hiddenEntityId"
            >
                <li
                    class="mat-tree-node"
                    [ngClass]="{ selected: selectedEntity?.id === node.id }"
                    (click)="onTreeNodeSelect(node)"
                >
                    <div class="childless-tree-node">{{ node.displayName }}</div>
                </li>
            </mat-tree-node>

            <mat-nested-tree-node
                *matTreeNodeDef="let node; when: hasChild"
                [class.tree-invisible]="!node.visible || node.id === hiddenEntityId"
            >
                <li>
                    <div
                        class="mat-tree-node"
                        [ngClass]="{ selected: selectedEntity?.id === node.id }"
                        (click)="onTreeNodeSelect(node)"
                    >
                        <button
                            mat-icon-button
                            matTreeNodeToggle
                            [class.button-children-selected]="node.childrenSelected"
                            [attr.aria-label]="'toggle ' + node.displayName"
                        >
                            <mat-icon
                                class="mat-icon-rtl-mirror"
                                [svgIcon]="treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"
                            ></mat-icon>
                        </button>
                        <div class="single-select-text-option">{{ node.displayName }}</div>
                    </div>
                    <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                    <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>

        <!-- We need an option with the correct value & name in order to show the selected entity in the mat-select -->
        <mat-option
            class="hide-option"
            disabled
            [value]="selectedEntity?.displayName"
        >
            {{ selectedEntity?.displayName }}
        </mat-option>
        <!-- use for the "no results found" option after implementing search -->
        <mat-option
            [ngClass]="{ 'hide-option': true }"
            disabled
        >
            <span translate>{{ noResultsFoundText }}</span>
        </mat-option>
        @if (dataSource.data.length === 0) {
            <mat-option disabled><span translate>No results found.</span></mat-option>
        }
    </mat-select>
    @if (matSelect.ngControl.invalid) {
        <mat-error translate>{{ errorMessage }}</mat-error>
    }
</mat-form-field>

