<div class="content-wrapper">
    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel
                    [disableCollapse]="true"
                    [cardClass]="'top-card'"
                >
                    <app-filter-tree-single-select
                        [entities]="organizationsForFiltering$ | async"
                        [labelText]="'Organization unit'"
                        [selectedEntityId]="selectedOrganizationUnit$ | async"
                        [dataCyLabel]="'organizationUnitFilter'"
                        (selectedEntityIdChanged)="onSelectedOrganizationUnitChange($event)"
                    >
                    </app-filter-tree-single-select>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showUnderlyingUnits$ | async"
                            (change)="onUpdateShowChildUnits($event)"
                            data-cy="showSkillsForUnderlyingOrganizationUnits"
                        >
                            {{ 'Also show skills for underlying organization units' | translate }}
                        </mat-checkbox>
                    </div>
                </app-entity-filter-panel>
            </div>
        </div>
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-filter-panel
                    [disableCollapse]="true"
                    [cardClass]="'top-card'"
                >
                    <app-filter-tree-single-select
                        [entities]="owsDepartmentsForFiltering$ | async"
                        [labelText]="'OWS Departments'"
                        [selectedEntityId]="selectedOwsDepartment$ | async"
                        [dataCyLabel]="'departmentFilterOWS'"
                        (selectedEntityIdChanged)="onSelectedOwsDepartmentChange($event)"
                    >
                    </app-filter-tree-single-select>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showAllOwsSkills$ | async"
                            (change)="onShowAllOwsSkillsChange($event)"
                            data-cy="showOwsSkillsFromAllDepartments"
                        >
                            {{ 'Show OWS Skills from all Departments' | translate }}
                        </mat-checkbox>
                    </div>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="hideUnlinkedOwsSkills$ | async"
                            (change)="onHideUnlinkedOwsSkillsChange($event)"
                            data-cy="hideLinkedSkills"
                        >
                            {{ 'Hide linked Skills' | translate }}
                        </mat-checkbox>
                    </div>

                    <div>
                        <mat-checkbox
                            class="bol-checkbox"
                            [checked]="showLinkedOwsSkills$ | async"
                            (change)="onShowLinkedOwsSkillsChange($event)"
                            data-cy="onlySelectedSkills"
                        >
                            {{ 'Show linked Skills' | translate }}
                        </mat-checkbox>
                    </div>

                    <div class="unlinked__container">
                        <span class="unlinked__title">{{ 'Unlinked Skills' | translate }}:</span>
                        <div
                            class="bol-badge-solid"
                            [ngClass]="{
                                'bol-warning': (unlinkedSkillCount$ | async) > 0,
                                'bol-primary': (unlinkedSkillCount$ | async) === 0,
                            }"
                            data-cy="numberUnlinkedSkills"
                        >
                            {{ unlinkedSkillCount$ | async }}
                        </div>
                    </div>

                    @if ((selectedSkillId$ | async) === undefined && (visibleOwsSkills$ | async)?.length > 0) {
                        <div data-cy="noSelectionWarningMessage">
                            <mat-icon
                                svgIcon="alert"
                                class="inline-icon"
                            ></mat-icon>
                            <span
                                class="bol-text"
                                translate
                            >
                                Please select an OMRP Skill from the left panel to map to!
                            </span>
                        </div>
                    }
                </app-entity-filter-panel>
            </div>
        </div>
    </div>

    <div class="flex-wrapper">
        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OMRP Skills"
                    hideAddButton="true"
                    [entities]="visibleSkills$ | async"
                    [selectedEntityId]="selectedSkillId$ | async"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'skillsOMRP'"
                    [cardClass]="'bottom-card'"
                    (selectedEntityIdChanged)="onSelectSkill($event)"
                ></app-entity-list-panel>
            </div>
        </div>

        <div class="flex-column">
            <div class="flex-growing-child">
                <app-entity-list-panel
                    entityName="OWS Skills"
                    showCheckboxes="true"
                    hideAddButton="true"
                    [entities]="visibleOwsSkills$ | async"
                    [searchProperties]="['displayName']"
                    [dataCyLabel]="'skillsOWS'"
                    [cardClass]="'bottom-card'"
                    (selectionClickChanged)="onSelectOwsSkillForMapping($event)"
                ></app-entity-list-panel>
            </div>
        </div>
    </div>
</div>

