import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { OmrpAuthGuard } from 'src/app/shared/guards/omrp-authentication.guard';
import { PermissionsGuard } from 'src/app/shared/guards/permissions.guard';
import { PERMISSIONS } from 'src/app/shared/models/Enums';

import { OwsInterfaceComponent } from './ows-interface.component';
import { OwsSkillMappingComponent } from './ows-skill-mapping/ows-skill-mapping.component';
import { OwsInterfaceSchedulingComponent } from './ows-interface-scheduling/ows-interface-scheduling.component';
import { OwsDepartmentMappingComponent } from './ows-department-mapping/ows-department-mapping.component';
import { OwsActivityTypeMappingOldComponent } from './ows-activity-type-mapping-old/ows-activity-type-mapping.component';
import { OwsActivityTypeMappingComponent } from './ows-activity-type-mapping/ows-activity-type-mapping.component';
import { OwsResourceTypeMappingComponent } from './ows-resource-mapping/ows-resource-type-mapping.component';
import { WhatIsWorkComponent } from './what-is-work/what-is-work.component';
import { OwsOtherSettingsComponent } from './ows-other-settings/ows-other-settings.component';

export const owsInterfaceRoutes: Routes = [
    {
        path: 'ows-interface',
        canActivate: [OmrpAuthGuard, PermissionsGuard],
        data: {
            permission: PERMISSIONS.MANAGE_OWS_INTERFACE_SETTINGS,
        },
        children: [
            {
                path: '',
                component: OwsInterfaceComponent,
                data: {
                    breadcrumbs: [
                        {
                            caption: 'OWS Interface',
                        },
                    ],
                },
            },
            {
                path: 'skill-mapping',
                component: OwsSkillMappingComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/ows-interface',
                            caption: 'OWS Interface',
                        },
                        {
                            caption: 'Skill mapping'
                        },
                    ],
                },
            },
            {
                path: 'scheduling',
                component: OwsInterfaceSchedulingComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/ows-interface',
                            caption: 'OWS Interface',
                        },
                        {
                            caption: 'Interface scheduling'
                        },
                    ],
                },
            },
            {
                path: 'department-mapping',
                component: OwsDepartmentMappingComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/ows-interface',
                            caption: 'OWS Interface',
                        },
                        {
                            caption: 'Department mapping'
                        },
                    ],
                },
            },
            {
                path: 'activity-type-mapping',
                component: OwsActivityTypeMappingOldComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/ows-interface',
                            caption: 'OWS Interface',
                        },
                        {
                            caption: 'Activity Type mapping'
                        },
                    ],
                },
            },
            {
                path: 'activity-type-mapping-new',
                component: OwsActivityTypeMappingComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/ows-interface',
                            caption: 'OWS Interface',
                        },
                        {
                            caption: 'Activity Type mapping'
                        },
                    ],
                },
            },
            {
                path: 'resource-type-mapping',
                component: OwsResourceTypeMappingComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/ows-interface',
                            caption: 'OWS Interface',
                        },
                        {
                            caption: 'Resource Type mapping'
                        },
                    ],
                },
            },
            {
                path: 'what-is-work',
                component: WhatIsWorkComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/ows-interface',
                            caption: 'OWS Interface',
                        },
                        {
                            caption: 'What is work'
                        },
                    ],
                },
            },
            {
                path: 'other-settings',
                component: OwsOtherSettingsComponent,
                data: {
                    breadcrumbs: [
                        {
                            route: '/ows-interface',
                            caption: 'OWS Interface',
                        },
                        {
                            caption: 'Other settings'
                        },
                    ],
                },
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(owsInterfaceRoutes)],
    exports: [RouterModule],
})
export class OwsRoutingModule {}
