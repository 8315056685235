import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationMode, AuthenticationService } from '../services/authentication';

// NOTE: we can remove this interceptor when we don't have any AngularJS code left
@Injectable({ providedIn: 'root' })
export class TabLogoutInterceptor implements HttpInterceptor {

    constructor(
        private readonly authenticationService: AuthenticationService,
        public readonly router: Router
    ) { }

    public intercept(request: HttpRequest<any>, nextHttpHandler: HttpHandler): Observable<HttpEvent<any>> {
        return nextHttpHandler.handle(request).pipe(tap(
            (() => {
                //Success
            }),
            (() => {
                const token = this.authenticationService.currentUserInStorage;
                const tokenIsExpired = token ? new Date().getTime() > + token.expires : false;
                if (environment.authMode !== AuthenticationMode.Saml2 && tokenIsExpired) {
                    const refreshToken = this.authenticationService.refreshTokenInStorage;
                    if (refreshToken !== undefined) {
                        this.authenticationService.tryLoginWithRefreshToken();
                    }
                    else {
                        this.authenticationService.logoutTab();
                    }
                }
            })
        ));
    }
}