<div class="bol-modal__header">
    <span class="modal-title">{{ 'Filters' | translate }}</span>
    <div
        class="bol-modal__close"
        (click)="onCloseModal()"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </div>
</div>

<mat-dialog-content>
    @if ((dataSource$ | async).filteredData.length === 0) {
        <div class="empty-state">
            <mat-icon svgIcon="cog"></mat-icon>
            <div
                class="empty-state-description"
                translate
                data-cy="noFilterAvailableYet"
            >
                No filters available yet
            </div>
        </div>
    }

    @if ((dataSource$ | async).filteredData.length > 0) {
        <div>
            <mat-table
                matSort
                matSortActive="displayName"
                matSortDirection="asc"
                class="bol-table bol-table-striped-even bol-table-hovered"
                [dataSource]="dataSource$ | async"
            >
                <ng-container matColumnDef="displayName">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="display-name-column"
                    >
                        <span translate>Display name</span>
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let setting"
                        class="display-name-column"
                    >
                        <mat-form-field class="bol-form-field form-field-white setting-name-input">
                            <input
                                #inputFilterSettingName
                                matInput
                                required
                                [ngModel]="setting.displayName"
                                (change)="onChangeFilterSetting($event.target.value, setting)"
                            />
                            <mat-icon
                                matSuffix
                                svgIcon="pencil"
                            ></mat-icon>
                            @if (inputFilterSettingName?.value === '') {
                                <mat-error translate> Please enter a filter name </mat-error>
                            }
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="creationDate">
                    <mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        translate
                    >
                        <span translate>Creation date</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let setting"
                        >{{ setting.creationDate | date: (dateFormat$ | async) }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="deleteSetting">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let setting">
                        <div>
                            <button
                                mat-button
                                class="bol-button-text icon-only bol-gray"
                                (click)="onDeleteFilterSetting(setting)"
                                data-cy="deleteFilterButton"
                            >
                                <mat-icon svgIcon="delete"></mat-icon>
                            </button>
                            <button
                                mat-button
                                class="bol-button-text"
                                [disabled]="selectedFilterSetting(setting.id) | async"
                                (click)="onSelectFilterSetting(setting)"
                            >
                                {{ ((selectedFilterSetting(setting.id) | async) ? 'Selected' : 'Select') | translate }}
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="['displayName', 'creationDate', 'deleteSetting']"></mat-header-row>
                <mat-row *matRowDef="let row; columns: ['displayName', 'creationDate', 'deleteSetting']"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="5"></mat-paginator>
        </div>
    }
</mat-dialog-content>

<mat-dialog-actions>
    <div class="dialog-actions-buttons">
        <button
            mat-button
            class="bol-button-text bol-gray"
            (click)="onCloseModal()"
            data-cy="closeFiltersButton"
        >
            <span translate>Close</span>
        </button>
    </div>
</mat-dialog-actions>

