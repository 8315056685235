import { Component, ChangeDetectionStrategy, OnInit, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, first, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { OmrpAuthenticationService } from './shared/services/authentication';
import { LanguageService } from './shared/language';

import { UserLanguageInitializeService } from './shared/language/user-language-initialize.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { InitializationService } from './shared/services/initialization.service';
import { ConfigurationService } from './shared/stores/configuration-store/configuration.service';
import { DisplaySettingService } from './shared/stores/display-setting-store/display-setting.service';
import { GlobalSettingsService } from './shared/stores/global-settings/global-settings.service';
import { UserInfoService } from './shared/stores/user-info-store/user-info.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
    public title = 'omrp';
    public showRedBorder: boolean;
    public setupInProgress$ = new BehaviorSubject<boolean>(true);
    public authenticationInProgress$: BehaviorSubject<boolean>;
    public errorInAuthentication$: BehaviorSubject<boolean>;
    
    private readonly authorizeNavigationSubscription = new Subscription();
    private readonly configLoaded$ = new BehaviorSubject<boolean>(false);
    private readonly globalSettingsLoaded$ = new BehaviorSubject<boolean>(false);
    private readonly userInfoLoaded$ = new BehaviorSubject<boolean>(false);

    constructor(
        userLanguageInitializeService: UserLanguageInitializeService,
        protected omrpAuthenticationService: OmrpAuthenticationService,
        private readonly userInfoService: UserInfoService,
        private readonly globalSettingsService: GlobalSettingsService,
        private readonly languageService: LanguageService,
        private readonly displaySettingService: DisplaySettingService,
        private readonly configurationService: ConfigurationService,
        private readonly initializationService: InitializationService,
        private readonly router: Router,
        private readonly errorHandlerService: ErrorHandlerService
    ) {
        userLanguageInitializeService.initialize();
        this.setUpOnAuthenticated();
    }

    @HostListener('window:keyup', ['$event']) private hideRedBorder(event: KeyboardEvent) {
        if (event.key === 'ArrowDown' && event.ctrlKey && event.shiftKey && environment.enableTestBorderHiding === 'true'){
            sessionStorage.setItem('redBorder', 'false');
            this.showRedBorder = false;
            this.removeHideRedBorderHandler();
        }
    }

    public ngOnInit(): void {
        if (!environment.production) {
            const redBorderFromSession = sessionStorage.getItem('redBorder');
            this.showRedBorder = redBorderFromSession !== 'false';

            if (!environment.enableTestBorderHiding) {
                this.removeHideRedBorderHandler();
            }
        }

        this.authenticationInProgress$ = this.initializationService.authenticationInProgress$;
        this.errorInAuthentication$ = this.initializationService.errorInAuthentication$;

        this.authorizeNavigationSubscription.add(
            this.router.events.pipe(
                filter(event => event instanceof NavigationStart)
            ).subscribe((event: NavigationStart) =>
                this.setupInProgress$.next(!event.url.startsWith('/authorize')))
        );
        
        combineLatest([
            this.configLoaded$,
            this.globalSettingsLoaded$,
            this.userInfoLoaded$
        ]).pipe(
            filter(([configLoaded, globalSettingsLoaded, userInfoLoaded]) => 
                configLoaded && globalSettingsLoaded && userInfoLoaded
            ),
            first(),
            tap(() => {
                this.setupInProgress$.next(false);
                this.authorizeNavigationSubscription.unsubscribe();
            })
        ).subscribe();
    }

    private removeHideRedBorderHandler(): void {
        this.hideRedBorder = () => {};
    }

    private setUpOnAuthenticated(): void {
        // We currently don't have an observable to check if a user is logged in
        // So for now we repeat it every 100 ms until authentication is finished
        const isAuthenticatedTimes = setInterval(()=> { 
            if (this.omrpAuthenticationService.isAuthenticated) {

                this.configurationService.getConfiguration().pipe(
                    first(),
                    catchError((err) => this.onError(err))
                ).subscribe(() => {
                    this.configLoaded$.next(true);
                });

                this.globalSettingsService.getGlobalSettings().pipe(
                    first(),
                    catchError((err) => this.onError(err))
                ).subscribe(() => {
                    this.globalSettingsLoaded$.next(true);
                });

                this.userInfoService.getCurrentUserInfo().pipe(
                    first(),
                    catchError((err) => this.onError(err))
                ).subscribe(userInfo => {
                    this.userInfoLoaded$.next(true);
                    if (userInfo.id > 0) { // is full user
                        this.displaySettingService.getDisplaySettings().pipe(first()).subscribe();
                    }
                });
                this.languageService.setUserLanguage();

                clearInterval(isAuthenticatedTimes);
            }
        }, 100);
    }

    private onError(err): Observable<never> {
        this.authorizeNavigationSubscription.unsubscribe();
        this.setupInProgress$.next(false);
        this.errorHandlerService.setError(err.message);
        this.router.navigate(['error']);

        return throwError(err);
    }
}
