import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, take, tap } from 'rxjs/operators';

import { cache } from 'src/app/core/rxjs-utils';
import { EmptyFiltersPanelComponent } from 'src/app/shared/components/empty-filters-panel/empty-filters-panel.component';
import { LanguageService } from 'src/app/shared/language';
import { DaypartService } from 'src/app/shared/stores/day-part-store/day-part.service';
import { FILTER_SETTING_TYPE } from 'src/app/shared/stores/filter-settings-store/filter-setting.model';
import { FilterSettingQuery } from 'src/app/shared/stores/filter-settings-store/filter-setting.query';
import { OrganizationUnit } from 'src/app/shared/stores/organization-unit-store/organization-unit.model';

import { SCHEDULE_STORAGE_KEYS } from './schedule-helpers/enums';
import { ResetScheduleService } from './schedule-helpers/reset-schedule.service';
import { ScheduleHelperService } from './schedule-helpers/schedule-helper.service';
import { ScheduleRequestParameters } from './stores/schedule-store/schedule-request-parameters.model';
import { Schedule } from './stores/schedule-store/schedule.model';
import { ScheduleQuery } from './stores/schedule-store/schedule.query';
import { ScheduleService } from './stores/schedule-store/schedule.service';

@Component({
    selector: 'app-schedule-overview-old',
    templateUrl: './schedule-overview.component.html',
    styleUrls: ['./schedule-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleOverviewOldComponent implements OnInit, OnDestroy {
    @ViewChild(EmptyFiltersPanelComponent) public filterPanel: EmptyFiltersPanelComponent;

    public schedules$!: Observable<Array<Schedule>>;
    public organizations$!: Observable<Array<OrganizationUnit>>;
    public scheduleNoOfDays$!: Observable<number>;
    public scheduleStartDate$!: Observable<Date>;
    public scheduleEndDate$!: Observable<Date>;
    public minWidthSlider$!: Observable<number>;
    public isLoadingSchedule$!: Observable<boolean>;
    public dateFormat$!: Observable<string>;
    public filterSettings$!: Observable<number>;

    public slotMinWidth: number;
    public sliderControl = new UntypedFormControl();
    public activityDialogControl = new UntypedFormControl(false);
    public daypartsReadySubject = new BehaviorSubject<boolean>(false);
    public filtersPanelState = true;

    private readonly subscription: Subscription = new Subscription();

    constructor(
        private readonly scheduleQuery: ScheduleQuery,
        private readonly scheduleService: ScheduleService,
        private readonly scheduleHelperService: ScheduleHelperService,
        private readonly languageService: LanguageService,
        private readonly daypartService: DaypartService,
        private readonly filterSettingQuery: FilterSettingQuery,
        private readonly resetScheduleService: ResetScheduleService,
    ) {
        this.daypartService.getDayparteNew().pipe(first()).subscribe(() => this.daypartsReadySubject.next(true));
    }

    public ngOnInit(): void {
        this.schedules$ = this.scheduleQuery.getSchedule().pipe(cache());
        this.scheduleNoOfDays$ = this.scheduleQuery.getNumberOfDays();
        this.scheduleStartDate$ = this.scheduleQuery.getScheduleStartDate();
        this.scheduleEndDate$ = this.scheduleQuery.getScheduleEndDate();
        this.minWidthSlider$ = this.scheduleQuery.getNumberOfDays().pipe(map(noOfDays => noOfDays === 14 ? 160 : 80));
        this.isLoadingSchedule$ = combineLatest([
            this.scheduleQuery.getScheduleLoadingState(),
            this.scheduleQuery.getScheduleFiltersLoadingState()
        ]).pipe(
            map(([scheduleLoading, filtersLoading]) => scheduleLoading || filtersLoading) 
        );
        this.filterSettings$ = this.filterSettingQuery.getFilterSettingsByType(FILTER_SETTING_TYPE.SCHEDULE_OVERVIEW_FILTER_SETTING).pipe(map(fs => fs.length));

        this.dateFormat$ = this.languageService.getDateFormatByCurrentLanguage();

        this.resetStoreOnFilterChange();
        this.setSlotMinWidth();
        this.setActivityDialogState();
        this.setFiltersPanelState();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onUpdatePeriod(days: number): void {
        const parameters: ScheduleRequestParameters = this.scheduleQuery.getRequestParametersSync();

        this.resetScheduleService.resetSchedule(false);
        this.scheduleService.updateScheduleNoOfDaysToNavigate(days);
        this.scheduleService.updateDatesParameters(moment.utc(parameters.startDate).add(days, 'day').toISOString());
    
        this.subscription.add(
            this.scheduleQuery.getRequestParameters().pipe(
                first(),
                switchMap((params) => {
                    this.scheduleService.updateScheduleNoOfDaysToNavigate(0);
                    
                    return this.scheduleService.get(params);
                })
            ).subscribe(() => this.resetScheduleService.resetSchedule(true))
        );
    }

    public updateShowFiltersStateAndGetScheduleData(state: boolean): void {
        this.scheduleHelperService.setInStorage(SCHEDULE_STORAGE_KEYS.FILTERS_PANEL_STATE, state);

        if (!state && this.scheduleQuery.getScheduleFiltersValiditySync()) {
            this.scheduleService.updateScheduleNoOfDaysToNavigate(0);

            this.scheduleService.get(this.scheduleQuery.getRequestParametersSync()).pipe(first()).subscribe();

            this.scheduleQuery.getScheduleLoadingState()
                .pipe(
                    filter(scheduleLoading => !!scheduleLoading),
                    take(1),
                    tap(() => {
                        this.resetScheduleService.resetSchedule(true);
                    })
                ).subscribe();
        }
    }

    public hidePanelOnSelectedFilterSetting(): void {
        if (this.filterPanel.filtersPanelState) {
            this.filterPanel.setFilterPanel(false);
        }
    }

    private setSlotMinWidth(): void {
        this.subscription.add(
            this.scheduleQuery.getSlotMinWidth().subscribe(width => {
                const sliderControlValue = width ? width : this.sliderControl.value;

                this.sliderControl.setValue(sliderControlValue, { emitEvent: false });
            })
        );

        this.subscription.add(
            this.sliderControl.valueChanges
                .pipe(debounceTime(1000))
                .subscribe(width =>
                    this.scheduleService.updateSlotMinWidth(width)
                )
        );
    }

    private setActivityDialogState(): void {
        this.subscription.add(
            this.scheduleQuery.getActivityDialogState().subscribe(state => {
                this.activityDialogControl.setValue(state, { emitEvent: false });
            })
        );

        this.subscription.add(
            this.activityDialogControl.valueChanges.subscribe(state => {
                this.scheduleService.updateActivityDialogState(state);
            })
        );
    }

    private setFiltersPanelState(): void {
        const filtersPanelState = this.scheduleHelperService.getScheduleItemFromStorage<boolean>(SCHEDULE_STORAGE_KEYS.FILTERS_PANEL_STATE);

        if (filtersPanelState !== null) {
            this.filtersPanelState = filtersPanelState;
        }
    }

    private resetStoreOnFilterChange(): void {
        this.subscription.add(
            combineLatest([
                this.scheduleQuery.getRequestParameters(),
                this.scheduleQuery.getGroupingOptionType(),
                this.scheduleQuery.getSelectedActivityTypeIds(),
                this.scheduleQuery.getShowOptions()
            ]).subscribe(([]) => {
                if (this.resetScheduleService.resetScheduleState) {
                    this.scheduleService.resetStore();
                }
            })
        );
    }
}
