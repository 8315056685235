import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, first, map, startWith, switchMap } from 'rxjs/operators';

import { EntityUI } from 'src/app/shared/stores/entity-ui-models';
import { OrganizationUnitQuery } from 'src/app/shared/stores/organization-unit-store/organization-unit.query';
import { SkillLevel } from 'src/app/shared/stores/skill-level-store/skill-level.model';
import { SkillLevelQuery } from 'src/app/shared/stores/skill-level-store/skill-level.query';
import { SkillLevelsManagementState, SkillLevelsManagementStore } from './skill-levels-management.store';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';

@Injectable({
    providedIn: 'root'
})
export class SkillLevelsManagementQuery extends Query<SkillLevelsManagementState> {
    constructor(
        protected store: SkillLevelsManagementStore,
        protected skillLevelQuery: SkillLevelQuery,
        protected organizationUnitQuery: OrganizationUnitQuery,
        private readonly translateService: TranslateService
    ) {
        super(store);
    }

    public allEntitiesLoaded(): Observable<boolean> {
        return combineLatest([
            this.skillLevelQuery.getEntitiesLoadingState(),
            this.organizationUnitQuery.getEntitiesLoadingState()
        ]).pipe(
            filter(([skillLevelsLoading, unitsLoading]) => {
                return !skillLevelsLoading && !unitsLoading;
            }),
            map(() => true),
            first(),
            startWith(false)
        );
    }

    public getFilteredSkillLevels(): Observable<Array<SkillLevel>> {
        return combineLatest([
            this.skillLevelQuery.getSkillLevels(),
            this.getSelectedOrganizationUnitId(),
        ]).pipe(
            map(([skillLevel, selectedOrganizationUnitId] ) => {
                const filteredSkillLevelsOnOrganizationUnit = this.filterSkillLevelsOnOrganizationUnit(skillLevel, selectedOrganizationUnitId);

                const filteredSkillLevelsByLevel = filteredSkillLevelsOnOrganizationUnit.sort((a, b) => a.level < b.level ? 1 : -1);

                return filteredSkillLevelsByLevel;
            })
        );
    }

    public getSelectedSkillLevel(): Observable<SkillLevel> {
        return this.getCurrentSkillLevelId().pipe(
            switchMap((skillLevelId) => {
                if (skillLevelId === -1) {
                    return of(this.getNewSkillLevelObject());
                } else {
                    return this.skillLevelQuery.getSkillLevel(skillLevelId);
                }
            })
        );
    }

    public getSelectedOrganizationUnitId(): Observable<number> {
        return this.select(state => state.selectedOrganizationUnitId);
    }

    public getSelectedOrganizationUnitIdSync(): number {
        return this.getValue().selectedOrganizationUnitId;
    }

    public getSelectedUISkillLevel(): Observable<EntityUI> {
        return this.getCurrentSkillLevelId().pipe(
            switchMap((skillLevelId) => {
                return this.skillLevelQuery.getUISkillLevel(skillLevelId);
            })
        );
    }

    public getManageMode(): Observable<MANAGE_MODE> {
        return this.select(state => state.manageMode);
    }

    public getCurrentSkillLevelId(): Observable<number> {
        return this.select(state => state.selectedSkillLevelId);
    }

    public getCurrentSkillLevelIdSync(): number {
        return this.getValue().selectedSkillLevelId;
    }

    public getSelectedSkillLevelSync(): SkillLevel {
        const selectedId = this.getCurrentSkillLevelIdSync();
        
        return this.skillLevelQuery.getSkillLevelSync(selectedId);
    }

    public getNewSkillLevelObject(): SkillLevel {
        return {
            id: -1,
            displayName: this.translateService.instant('New skill level'),
            organizationUnitId: this.getSelectedOrganizationUnitIdSync(),
            level: this.skillLevelQuery.getHighestSkillLevelSync() + 1
        };
    }

    private filterSkillLevelsOnOrganizationUnit(skillLevels: Array<SkillLevel>, organizationUnitId: number): Array<SkillLevel> {
        const organizationUnit = this.organizationUnitQuery.getOrganizationUnitByIdSync(organizationUnitId);
        if (organizationUnit) {
            const validUnitIds = [organizationUnit.id];
           
           
            return skillLevels.filter(skillLevel => validUnitIds.includes(skillLevel.organizationUnitId));
        }
        
        return [];
    }

}