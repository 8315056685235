import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { STATUS } from '../../stores/status-page-store/status-page.store';

@Component({
    standalone: true,
    selector: 'app-loading-status-indication',
    templateUrl: './loading-status-indication.component.html',
    styleUrls: ['./loading-status-indication.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
    TranslateModule,
    MatInputModule,
    MatProgressSpinnerModule
],
})
export class LoadingStatusIndicationComponent {
    @Input() public statusPageState: STATUS;

    public readonly STATUS = STATUS;
}