/* eslint-disable @typescript-eslint/naming-convention */
export const enum Permission {
    noPermission = 0,
    read = 1,
    readWrite = 2,
    owner = 3
}

export const enum ACTIVITY_CATEGORY_TYPE {
    ACTIVITY = 'Activity',
    DAY_MARK = 'DayMark',
    PART_TIME = 'PartTime'
}

export const enum ACTIVITY_CATEGORY_TYPE_ID {
    SHIFT = 1,
    ACTIVITY = 2,
    ABSENCE = 3,
    DAY_MARK = 4
}

export const enum PERMISSIONS {
    MANAGE_OWS_INTERFACE_SETTINGS = 'ManageOWSInterfaceSettings',
    MANAGE_APPLICATION_SETTINGS = 'ManageApplicationSettings',
    MANAGE_RESTRICTED_RESOURCETYPES = 'ManageRestrictedResourceTypes',
    USERS = 'Users',
    USER_GROUPS = 'UserGroups',
    SKILLS = 'Skills',
    SKILL_LEVELS = 'SkillLevels',
    RESOURCE_PROPERTIES = 'ResourceProperties',
    RESOURCE_TYPES = 'ResourceTypes',
    ORGANIZATION_UNITS = 'OrganizationUnits',
    ACTIVITY_DEMAND = 'ActivityDemand',
    ACTIVITY_DEMAND_MANAGE_COUNTERS = 'ActivityDemandManageCounters',
    ACTIVITY_DEMAND_ROLLOUT_ACTIVITY_PLAN = 'ActivityDemandRolloutActivityPlan',
    HOLIDAYS = 'ManageHolidays',
}

export enum YES_NO_TYPES {
    YES = 'Yes',
    NO = 'No'
}

export enum MANAGE_MODE {
    ADD = 'Add',
    EDIT = 'Edit',
    VIEW = 'View',
    CLONE = 'Clone'
}