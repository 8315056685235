<button
    mat-button
    class="bol-button-outlined"
    (click)="onDisplaySelectionDialog()"
>
    <mat-icon svgIcon="subtitles"></mat-icon>
    <span translate>Selected activity types</span>
    @if (selectedActivityTypeIds && (activityTypes$ | async); as activityTypes) {
        <span>
            @if (required) {
                <span>&nbsp;*</span>
            }
            :
            {{
                activityTypes.length === selectedActivityTypeIds.length
                    ? ('All' | translate)
                    : selectedActivityTypeIds.length + ' / ' + activityTypes.length
            }}
        </span>
    }
</button>
