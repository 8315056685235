<mat-form-field
    #matFormField
    floatLabel="{{ floatLabel ? 'always' : 'auto' }}"
    class="bol-form-field multiselect"
>
    <mat-label translate>{{ multiselectTreePlaceholder }}</mat-label>
    <mat-select
        #matSelect
        ngDefaultControl
        matInput
        multiple
        panelClass="multiselect-panel"
        placeholder="{{ placeholderText }}"
        class="select-element"
        [required]="required"
        [formControl]="entitiesSelectControl"
    >
        @if (showSelectAll) {
            <div class="select-all-checkbox">
                <mat-checkbox
                    class="bol-checkbox"
                    [checked]="IsAllSelected()"
                    [attr.data-cy]="multiselectTreePlaceholder + 'SelectAll'"
                    (change)="$event ? onToggleAll($event) : null"
                >
                    {{ 'Select all' | translate }}
                </mat-checkbox>
            </div>
        }

        @if (internalEntities.length > 0 && showSearch) {
            <mat-form-field
                bolSearchField
                class="bol-form-field multiselect-search"
            >
                <mat-label>{{ searchFieldPlaceholder | translate }}</mat-label>
                <input
                    #input
                    matInput
                    autocomplete="off"
                    [formControl]="searchControl"
                    [attr.data-cy]="multiselectTreePlaceholder + 'Search'"
                />
                @if (!searchControl || !searchControl.value || searchControl.value.length === 0) {
                    <button
                        mat-button
                        matSuffix
                        disabled
                        class="bol-button-text"
                    >
                        <mat-icon
                            svgIcon="magnify"
                            class="search-icon"
                        ></mat-icon>
                    </button>
                }
                @if (searchControl && searchControl.value && searchControl.value.length > 0) {
                    <button
                        matSuffix
                        mat-button
                        class="cancel-button bol-button-text icon-only bol-gray"
                        (click)="onResetValue()"
                    >
                        <mat-icon
                            svgIcon="close"
                            class="search-icon"
                        ></mat-icon>
                    </button>
                }
            </mat-form-field>
        }

        <mat-tree
            class="tree"
            [dataSource]="dataSource"
            [treeControl]="treeControl"
        >
            <!-- tree nodes without children -->
            <mat-tree-node
                *matTreeNodeDef="let node"
                matTreeNodeToggle
                [class.tree-invisible]="!node.visible"
            >
                <li class="mat-tree-node">
                    <button
                        mat-icon-button
                        disabled
                    ></button>
                    <mat-checkbox
                        class="bol-checkbox bol-primary"
                        [checked]="node.checked"
                        [disabled]="node.disabled"
                        [attr.data-cy]="'select' + node[entityProperty]"
                        (change)="onSelectEntity(node)"
                    >
                        @if (translationNeeded) {
                            <div class="childless-tree-node">{{ node[entityProperty] | translate }}</div>
                        }
                        @else {
                            <div class="childless-tree-node">{{ node[entityProperty] }}</div>
                        }
                    </mat-checkbox>
                </li>
            </mat-tree-node>

            <!-- tree nodes with children -->
            <mat-nested-tree-node
                *matTreeNodeDef="let node; when: hasChild"
                [class.tree-invisible]="!node.visible"
            >
                <li>
                    <div class="mat-tree-node">
                        <button
                            matTreeNodeToggle
                            mat-icon-button
                            [class.button-children-selected]="node.childrenSelected"
                            [attr.aria-label]="'toggle ' + node[entityProperty]"
                        >
                            <mat-icon
                                class="mat-icon-rtl-mirror"
                                [svgIcon]="treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"
                            >
                            </mat-icon>
                        </button>
                        <mat-checkbox
                            class="bol-checkbox bol-primary"
                            [checked]="node.checked"
                            [attr.data-cy]="'select' + node[entityProperty]"
                            (change)="onSelectEntity(node)"
                        >
                            @if (translationNeeded) {
                                <div>{{ node[entityProperty] | translate }}</div>
                            }
                            @else {
                                <div>{{ node[entityProperty] }}</div>
                            }
                        </mat-checkbox>
                    </div>
                    <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
                    <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>

        <!-- We need a hidden option here so the tree can render -->
        <mat-option
            class="hide-option"
            disabled
        ></mat-option>
        @if (noVisibleEntities || dataSource?.data?.length === 0) {
            <div
                class="no-results-founds"
                translate
            >
                No results found.
            </div>
        }
    </mat-select>

    @if (entitiesSelectControl.invalid) {
        <mat-error translate>{{ errorMessage }}</mat-error>
    }
</mat-form-field>

