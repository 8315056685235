import { Injectable } from '@angular/core';
import { SkillLevelsManagementStore } from './skill-levels-management.store';
import { MANAGE_MODE } from 'src/app/shared/models/Enums';

@Injectable({
    providedIn: 'root'
})
export class SkillLevelsManagementService {
    constructor(
        protected skillLevelManagementStore: SkillLevelsManagementStore
    ) { }

    public updateSelectedOrganizationUnitId(id: number): void {
        this.skillLevelManagementStore.updateSelectedOrganizationUnitId(id);
    }

    public updateSelectedSkillId(id: number): void {
        this.skillLevelManagementStore.updateSelectedSkillLevelId(id);
    }

    public updateManageMode(mode: MANAGE_MODE): void {
        this.skillLevelManagementStore.updateManageMode(mode);
    }

    public setStateForNewSkillLevel(skillLevelId: number): void {
        this.skillLevelManagementStore.setStateForNewSkillLevel(skillLevelId);
    }

}