import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';

import { DateTimeUtilityService } from '../../services/date-time-utility.service';
import { StatusPageQuery } from '../status-page-store/status-page.query';
import { PreferredResourcesState, PreferredResourcesStore } from './preferred-resources.store';
import { ActivityTypeQuery } from '../activity-type-store/activity-type.query';
import { PreferredResourcesForTimeSlot, PreferredResourcesInfoForTable } from './preferred-resources.model';

@Injectable({
    providedIn: 'root'
})
export class PreferredResourcesQuery extends StatusPageQuery<PreferredResourcesState> {
    
    constructor(
        protected store: PreferredResourcesStore,
        protected dateTimeUtilityService: DateTimeUtilityService,
        protected activityTypeQuery: ActivityTypeQuery
    ) {
        super(store);
    }

    public getEntitiesLoadingState(): Observable<boolean> {
        return this.select(state => state.ui.entitiesLoading);
    }

    public getCurrentWeek(): Observable<number> {
        return this.select(state => state.ui.currentWeek);
    }

    public getCurrentWeekSync(): number {
        return this.getValue().ui.currentWeek;
    }

    public getCurrentDay(): Observable<number> {
        return this.select(state => state.ui.currentDay);
    }

    public getCurrentDaySync(): number {
        return this.getValue().ui.currentDay;
    }

    public getPreferredResourcesForCurrentWeekDay(): Observable<PreferredResourcesForTimeSlot> {
        return combineLatest({
            week: this.getCurrentWeek(),
            day: this.getCurrentDay(),
            preferredResourcesPerWeekDay: this.select(state => state.preferredResourcesPerWeekDay)
        }).pipe(
            map(({ week, day, preferredResourcesPerWeekDay }) => {
                const match = preferredResourcesPerWeekDay.find(entry => entry.weekNumber === week && entry.dayNumber === day);

                return match?.preferredResources;
            })
        );
    }

    public getDemandForCurrentWeekDay(): Observable<number> {
        return combineLatest({
            week: this.getCurrentWeek(),
            day: this.getCurrentDay(),
            preferredResourcesPerWeekDay: this.select(state => state.preferredResourcesPerWeekDay)
        }).pipe(
            map(({ week, day, preferredResourcesPerWeekDay }) => {
                const match = preferredResourcesPerWeekDay.find(entry => entry.weekNumber === week && entry.dayNumber === day);

                return match?.preferredResources.amount ?? 0;
            })
        );
    }

    public getDemandForCurrentWeekDaySync(): number {
        const week = this.getCurrentWeekSync();
        const day = this.getCurrentDaySync();
        const preferredResourcesPerWeekDay = this.getValue().preferredResourcesPerWeekDay;
        const match = preferredResourcesPerWeekDay.find(entry => entry.weekNumber === week && entry.dayNumber === day);

        return match?.preferredResources.amount ?? 0;
    }

    public getActivityTypesForCurrentWeekDay(activityTypeId: number): Observable<Array<PreferredResourcesInfoForTable>> {
        return combineLatest({
            activityTypes: this.activityTypeQuery.getFullActivityStructureAndSortByRootId(activityTypeId),
            preferredResourcesPerWeekDay: this.getPreferredResourcesForCurrentWeekDay()
        }).pipe(
            map(({ activityTypes, preferredResourcesPerWeekDay }) => {
                return activityTypes.map(activityType => {
                    const match = preferredResourcesPerWeekDay?.activityType?.find(type => type.activityTypeId === activityType.id);
                    const preferredResourceIds = match ? match.preferredResources.map(resource => resource.id) : [];
                    const preferredResourceNames = match ? match.preferredResources.map(resource => resource.displayName).join(', ') : '';

                    return {
                        id: activityType.id, 
                        shortName: activityType.shortName,
                        nameStyling: {
                            backgroundColor: '#' + activityType.backColor,
                            color: '#' + activityType.textColor
                        },
                        displayName: activityType.displayName,
    
                        resourceTypeNames: activityType.resourceTypeList?.map(resourceType => resourceType.displayName).join(', '),
                        resourceTypeIds: activityType.resourceTypeList?.map(resourceType => resourceType.id) ?? [],
                        hasChildren: activityTypes.some(type => type.parentId === activityType.id),
                        preferredResourceIds,
                        preferredResourceNames
                    }
                })
            })
        )
    }
}
