import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SlimDropdownItem } from 'src/app/shared/models';

@Component({
    standalone: true,
    selector: 'slim-dropdown',
    templateUrl: './slim-dropdown.component.html',
    styleUrls: ['./slim-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslateModule,
        MatIconModule,
        MatMenuModule,
        MatChipsModule
    ]
})
export class SlimDropdownComponent<T> implements OnChanges {
    @Input() public items!: Array<SlimDropdownItem<T>>;
    @Input() public disabled: boolean;
    @Input() public selectedItem: SlimDropdownItem<T>;
    @Input() public placeholderText: string;
    @Input() public readonly entityName: string = 'items';

    @Output() public readonly selectedItemChanged = new EventEmitter<SlimDropdownItem<T>>();

    public placeholder: string;

    constructor(
        private readonly translateService: TranslateService
    ){ }

    public ngOnChanges() {
        const selectedItemFound = this.items.find(item => item.value == this.selectedItem?.value);
        if (!selectedItemFound) {
            this.selectedItem = undefined;
        }
        if (this.selectedItem) {
            this.placeholder = this.selectedItem.label;
        }
        else {
            const textToTranslate = this.placeholderText ?? 'No selected item'
            this.placeholder = this.translateService.instant(textToTranslate);
        }
    }

    public onSelectItem(item: SlimDropdownItem<T>): void {
        this.selectedItem = item;
        this.placeholder = this.selectedItem.label;

        this.selectedItemChanged.next(item);
    }
}
