<div class="filters__panel">
    <div class="date_organizations_container">
        <div class="date__input">
            <mat-form-field class="bol-form-field bol-datepicker">
                <mat-label translate>Start date</mat-label>
                <input
                    #fromDateInput
                    matInput
                    [bolDatepicker]="fromDatePicker"
                    [formControl]="dateTimeControls.fromDate"
                    data-cy="OverviewStartDate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="fromDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
                @if (dateTimeControls.fromDate.invalid) {
                    <mat-error data-cy="OverviewStartDateError">
                        @if (!fromDateInput.value || fromDateInput.value === '') {
                            <span translate> Please enter a date </span>
                        } @else {
                            <span translate>Please enter a valid date</span>
                        }
                        <span></span>
                    </mat-error>
                }
            </mat-form-field>
        </div>

        <div class="organization-units_container">
            <app-multiselect-tree
                multiselectTreePlaceholder="{{ 'Organization Units' }}"
                errorMessage="{{ 'Please select an organization unit' }}"
                [entities]="organizationsForFiltering$ | async"
                [preSelectedEntities]="preselectedOrganizationUnits$ | async"
                (selectedEntityIdsChanged)="onFilteredOrganizationsChanged($event)"
                data-cy="OverviewOrganizationUnitsFilter"
            >
            </app-multiselect-tree>
        </div>
    </div>

    <div class="resources__container">
        <app-filter-chip-list
            errorMessage="{{ 'Please select a base resource type' | translate }}"
            noResultsFoundText="{{ 'No base resource types found' | translate }}"
            placeholderText="{{ 'Base Resource Types...' | translate }}"
            required="true"
            class="resources__container__base-resources"
            [entities]="baseResourceTypesForFiltering$ | async"
            [preselectedEntities]="selectedBaseResourceTypes$ | async"
            [forceErrorState]="forceErrorStateOnBaseResourceTypes$ | async"
            (selectedEntityIdsChanged)="onFilteredBaseResourceTypesChanged($event)"
            data-cy="ScheduleOverviewBaseResourceTypes"
        ></app-filter-chip-list>

        <app-filter-chip-list
            noResultsFoundText="{{ 'No extra resource types found' | translate }}"
            placeholderText="{{ 'Extra Resource Types...' | translate }}"
            class="resources__container__extra-resources"
            [entities]="extraResourceTypesForFiltering$ | async"
            [preselectedEntities]="selectedExtraResourceTypes$ | async"
            (selectedEntityIdsChanged)="onFilteredExtraResourceTypesChanged($event)"
            data-cy="ScheduleOverviewExtraResourceTypes"
        ></app-filter-chip-list>
    </div>

    <div class="resource-options__container">
        <div
            [ngClass]="{ 'extended-container': (selectedResourcePropertiesLength$ | async) > 0 }"
            class="resource-options__container__activity-types"
        >
            <app-activity-type-selection-old
                [activityTypes]="activityTypes$ | async"
                [activityTypeCategories]="activityTypeCategories$ | async"
                [selectedActivityTypeIds]="selectedActivityTypeIds$ | async"
                [selectedActivityTypeCategoryIds]="selectedActivityTypeCategoryIds$ | async"
                [required]="true"
                (updateSelectedActivityTypeIdsEvent)="updateSelectedActivityTypeIds($event)"
                (updateSelectedActivityTypeCategoryIdsEvent)="updateSelectedActivityTypeCategoryIds($event)"
            ></app-activity-type-selection-old>
        </div>

        <div class="resource-options__container__sort-options">
            <mat-form-field class="bol-form-field default-mat-form-field">
                <mat-label translate>Grouping option</mat-label>
                <mat-select
                    #groupingOptionSelect
                    [formControl]="resourceControls.groupingOption"
                    (openedChange)="openedGroupingOptionSelect($event)"
                    data-cy="OverviewGroupingOptionsSelect"
                >
                    @for (groupingOption of groupingOptions; track groupingOption.type) {
                        <mat-option [value]="groupingOption.type">{{ groupingOption.value | translate }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>

        <div class="resource-options__container__number-of-days">
            <mat-form-field class="bol-form-field default-mat-form-field">
                <mat-label translate>No. of days</mat-label>
                <mat-select [formControl]="resourceControls.numberOfDays">
                    @for (numberOfDaysOption of numberOfDays; track $index) {
                        <mat-option [value]="numberOfDaysOption">{{ numberOfDaysOption }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>

        <app-filter-chip-list
            errorMessage="{{ 'Please select a resource property' | translate }}"
            noResultsFoundText="{{ 'No resource properties found' | translate }}"
            placeholderText="{{ 'Resource Properties...' | translate }}"
            class="resource-options__container__extra-properties"
            [entities]="allResourceProperties$ | async"
            [preselectedEntities]="selectedResourceProperties$ | async"
            (selectedEntityIdsChanged)="onFilteredResourcePropertiesChanged($event)"
            data-cy="ScheduleOverviewResourceProperties"
        ></app-filter-chip-list>
    </div>

    <p
        class="bol-text bol-text-medium"
        translate
    >
        Show...
    </p>
    <div class="show_options_container">
        @for (option of scheduleShowOptions$ | async; track option.value) {
            <div class="show_option">
                <mat-checkbox
                    class="bol-checkbox"
                    [value]="option.value"
                    [checked]="option.state"
                    (change)="updateShowOptions(option, $event)"
                    >{{ option.value | translate }}
                </mat-checkbox>
            </div>
        }
    </div>
</div>

